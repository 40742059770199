import React, { Component } from 'react';
// Composants
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Icon, Segment, Label, TransitionablePortal, Modal, Message, Select, Checkbox } from 'semantic-ui-react';
// Services
import UsersService from '../../../services/UsersService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faCheck, faGear, faTimes } from '@fortawesome/pro-solid-svg-icons';
import MembersManagementForm from './MembersManagementForm';
import UserProjects from './UserProjects';
import OrganizationsService from '../../../services/OrganizationsService';

const initialFilters = {
    label: '',
    owner: '',
    creationDate: '',
    members: '',
    subscriptionType: '',
    expirationDate: '',
    nbUsers: '',
    nbTrees: '',
    nbGreenSpaces: '',
    nbFurnitures: '',
    import: '',
    branding: '',
    reminderMails: '',
    costEstimation: '',
    customFields: '',
    customCharts: '',
    thematicMaps: '',
    expertMode: ''
};

const initialError = {
    messages: [],
    sourceOrganization: false,
    targetOrganization: false
};

const initialTransfer = {
    sourceOrganization: null,
    targetOrganization: null,
    baseProjects: false,
    customFields: false,
    priceLists: false,
    themes: false
};

class OrganizationTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false,
        isExporting: false,
        organizationToManage: null,
        showTransferForm: false,
        isTransferringData: false,
        transferData: initialTransfer,
        error: initialError,
        showProsOnly: true
    }

    render() {
        const {
            data, elementsToModify, modificationsHistory, modificationsHistoryIndex, organizationToManage, users, showTransferForm, showProsOnly,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isExporting, isLoading, projects, transferData,
            isTransferringData, error
        } = this.state;
        const rows = this.getFilteredRows();

        const organizationOptions = rows.map(row => ({ text: `${row.label} (${row.owner})`, value: row.id }));

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                {showTransferForm &&
                    <Dimmer active style={{ position: 'fixed' }}>
                        <Segment loading={isTransferringData}>
                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '500px' }}>
                                <span style={{ marginRight: '7px', fontWeight: 'bold' }}>{i18n.t("De")} :</span>
                                <Select
                                    placeholder={i18n.t("Sélectionner une organisation...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                    options={organizationOptions.filter(option => option.value !== transferData.targetOrganization)} value={transferData.sourceOrganization}
                                    error={error.sourceOrganization} onChange={(_, { value }) => this.setState(prevState => ({ transferData: { ...prevState.transferData, sourceOrganization: value }, error: initialError }))}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '500px', marginTop: '10px' }}>
                                <span style={{ marginRight: '7px', fontWeight: 'bold' }}>{i18n.t("À")} :</span>
                                <Select
                                    placeholder={i18n.t("Sélectionner une organisation...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                    options={organizationOptions.filter(option => option.value !== transferData.sourceOrganization)} value={transferData.targetOrganization}
                                    error={error.targetOrganization} onChange={(_, { value }) => this.setState(prevState => ({ transferData: { ...prevState.transferData, targetOrganization: value }, error: initialError }))}
                                />
                            </div>
                            <div style={{ marginTop: '10px', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 'bold' }}>{i18n.t("Données à transférer")} :</span>
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Projets/dossiers")} name='baseProjects' value={transferData.baseProjects} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Champs personnalisés")} name='customFields' value={transferData.customFields} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Listes de prix")} name='priceLists' value={transferData.priceLists} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Thèmes")} name='themes' value={transferData.themes} onChange={this.handleCheckboxChange} />
                            </div>
                            <Message
                                error style={{ textAlign: 'left' }} hidden={!error.messages.length ? true : false}
                                header={i18n.t("Erreur")} list={error.messages}
                            />
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <Button type='button' color='red' onClick={() => this.setState({ showTransferForm: false, error: initialError })}>
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button type='submit' color='green' onClick={this.transferData}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>
                        </Segment>
                    </Dimmer>}
                <TransitionablePortal open={organizationToManage ? true : false} transition={{ animation: 'fade up', duration: 300 }}>
                    <Modal open mountNode={document.getElementById('admin-panel__grid')} style={{ maxHeight: '80%', overflowY: 'auto' }} onClose={() => { this.setState({ organizationToManage: null }) }}>
                        <MembersManagementForm
                            organizationId={organizationToManage} rows={data.rows} users={users}
                            updateOrganizationMembers={this.updateOrganizationMembers}
                        />
                    </Modal>
                </TransitionablePortal>
                <TransitionablePortal open={projects ? true : false} transition={{ animation: 'fade up', duration: 300 }}>
                    <Modal open mountNode={document.getElementById('admin-panel__grid')} style={{ maxHeight: '80%', overflowY: 'auto' }} onClose={() => { this.setState({ projects: null }) }}>
                        <UserProjects projects={projects} history={this.props.history} />
                    </Modal>
                </TransitionablePortal>
                <Dimmer active={isUpdating || isExporting} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={isExporting ? i18n.t("Export des organisations en cours...") : i18n.t("Mise à jour des organisations en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des organisations")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(_, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                        disabled={!this.props.isOnline} onClick={this.exportXLSX}
                                    />
                                    <Button
                                        title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                        onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                    />
                                    <Button
                                        title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                        disabled={modificationsHistoryIndex === modificationsHistory.length}
                                        onClick={this.restoreNextModification}
                                    />
                                    <Button
                                        title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                        onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1}
                                    />
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <Button className='button--secondary' style={{ padding: '11px 14px' }} onClick={() => this.setState({ showTransferForm: true })}>
                                    <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ marginRight: '8px' }} />
                                    {i18n.t("Transfert de données")}
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <div>
                                    <Checkbox
                                        toggle label={i18n.t("Licence payante")} checked={showProsOnly}
                                        onChange={(_, { checked }) => this.setState({ showProsOnly: checked })}
                                    />
                                </div>
                            </Menu.Item>
                            <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                <Label color='green' content='< 70%' />
                                <Label color='yellow' content='70%' />
                                <Label color='orange' content='90%' />
                                <Label color='red' content='95%' />
                            </Menu.Item>
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => (
        <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
            <GridRow {...props} />
        </ContextMenuTrigger>
    );

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState(prevState => ({
            transferData: { ...prevState.transferData, [name]: checked }, error: initialError
        }));
    }

    loadData = () => {
        let data = { columns: [], rows: [] };

        const subscription = this.props.activeOrganization?.subscription.shortName;
        const canEdit = (subscriptionToEdit) => subscription === 'Dev' || (subscription === 'Admin' && subscriptionToEdit === 'Pro');

        UsersService.getUsers().then(users => this.setState({ users: users.map(user => ({ id: user.id, name: FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName), email: user.email })) }));

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 250,
                frozen: true, sortable: true, editable: (props) => canEdit(props.subscriptionType),
                formatter: (props) => <div className={`${!canEdit(props.row.subscriptionType) ? 'disabled' : ''}`}>{props.row.label}</div>,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Propriétaire"), key: 'owner', width: 200,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.owner}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Date de création"), key: 'creationDate', width: 180,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.creationDate}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Membres"), key: 'members', width: 300,
                sortable: true, editable: false,
                formatter: (props) => {
                    return (
                        <div>
                            {props.row.members.map(member => member.name).join(', ')}
                            <Button
                                color='blue' title={i18n.t("Gérer les membres")} style={{ padding: 0, height: '25px', width: '25px', position: 'absolute', top: '12.5%', right: 0 }}
                                disabled={false}  //TODO 
                                onClick={() => this.setState({ organizationToManage: props.row.id })}
                            >
                                <FontAwesomeIcon icon={faGear} />
                            </Button>
                        </div>
                    );
                },
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Licence"), key: 'subscriptionType', width: 100,
                sortable: true, editable: (props) => {
                    const subscription = this.props.activeOrganization?.subscription;
                    return ['Free', 'Pro'].includes(props.subscriptionType) || subscription.shortName === 'Dev';
                },
                formatter: (props) => {
                    const subscription = this.props.activeOrganization?.subscription;
                    return (
                        <div className={!(['Free', 'Pro'].includes(props.row.subscriptionType) || subscription.shortName === 'Dev') ? 'disabled' : ''}>
                            {props.row.subscriptionType}
                        </div>
                    );
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    {
                        options: this.props.activeOrganization?.subscription.shortName === 'Dev'
                            ? ['Free', 'Pro', 'Admin', 'Dev', 'Com', 'Demo'].map(subscriptionType => ({ label: subscriptionType, value: subscriptionType }))
                            : ['Free', 'Pro'].map(subscriptionType => ({ label: subscriptionType, value: subscriptionType }))
                    }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Date d'expiration"), key: 'expirationDate', width: 180,
                sortable: true, editable: (props) => !['Free', 'Dev', 'Admin', 'Com'].includes(props.subscriptionType),
                formatter: (props) => (
                    <div className={`${['Free', 'Dev', 'Admin', 'Com'].includes(props.row.subscriptionType) ? 'disabled' : ''} ${props.row.rawExpirationDate && new Date(props.row.rawExpirationDate) < new Date() ? 'red' : ''}`}>
                        {!['Free', 'Dev', 'Admin', 'Com'].includes(props.row.subscriptionType) && props.row.expirationDate}
                    </div>
                ),
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('date', row, column, onRowChange, onClose, { futureDate: true })
            },
            ...[
                { name: i18n.t("Utilisateurs"), key: 'nbUsers' },
                { name: i18n.t("Arbres"), key: 'nbTrees' },
                { name: i18n.t("Espaces verts"), key: 'nbGreenSpaces' },
                { name: i18n.t("Mobiliers"), key: 'nbFurnitures' }
            ].map(({ name, key }) => ({
                name, key, width: 185, sortable: true, editable: (props) => canEdit(props.subscriptionType),
                formatter: (props) => {
                    const percentage = props.row[key + 'Added'] / props.row[key] * 100;
                    const isEditable = canEdit(props.row.subscriptionType);
                    return (
                        <div className={`${!isEditable ? 'disabled' : ''} ${percentage >= 95 ? 'red' : percentage >= 90 ? 'orange' : percentage >= 75 ? 'yellow' : 'green'}`}>
                            {this.getFormattedUsage(props.row, key)}
                        </div>
                    );
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: -1 })
            })),
            ...[
                { name: i18n.t("Import"), key: 'import', width: 110 },
                { name: i18n.t("Thèmes"), key: 'branding', width: 110 },
                { name: i18n.t("Rappels par mail"), key: 'reminderMails', width: 150 },
                { name: i18n.t("Estimation des coûts"), key: 'costEstimation', width: 175 },
                { name: i18n.t("Champs personnalisés"), key: 'customFields', width: 180 },
                { name: i18n.t("Graphiques personnalisés"), key: 'customCharts', width: 200 },
                { name: i18n.t("Cartes thématiques personnalisées"), key: 'thematicMaps', width: 275 },
                { name: i18n.t("Module expert"), key: 'expertMode', width: 135 },
            ].map(({ name, key, width }) => ({
                name, key, width,
                sortable: true, editable: (props) => canEdit(props.subscriptionType),
                formatter: (props) => <div className={!canEdit(props.row.subscriptionType) ? 'disabled' : ''} style={!canEdit(props.row.subscriptionType) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row[key] === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            })),
        ];

        // Ajout des données
        OrganizationsService.getOrganizationRows().then(organizationRows => {
            data.rows = organizationRows.map(row => this.getRowValue(row)).reverse();
            const elements = organizationRows.reverse();

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data, elements, initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const { filters, showProsOnly, data, enableFilterRow } = this.state;
        let rows = [...data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => (
            (!showProsOnly || r.subscriptionType === 'Pro') && (!enableFilterRow || (
                (filters.label ? $(r.label).includes($(filters.label)) : true)
                && (filters.owner ? $(r.owner).includes($(filters.owner)) : true)
                && (filters.creationDate ? $(r.creationDate).includes($(filters.creationDate)) : true)
                && (filters.members ? r.members.some(member => $(member.name).includes($(filters.members)) || $(member.email).includes($(filters.members))) : true)
                && (filters.subscriptionType ? $(r.subscriptionType).includes($(filters.subscriptionType)) : true)
                && (filters.expirationDate ? $(r.expirationDate).includes($(filters.expirationDate)) : true)
                && (filters.nbUsers ? $(this.getFormattedUsage(r, 'nbUsers')).includes($(filters.nbUsers)) : true)
                && (filters.nbTrees ? $(this.getFormattedUsage(r, 'nbTrees')).includes($(filters.nbTrees)) : true)
                && (filters.nbGreenSpaces ? $(this.getFormattedUsage(r, 'nbGreenSpaces')).includes($(filters.nbGreenSpaces)) : true)
                && (filters.nbFurnitures ? $(this.getFormattedUsage(r, 'nbFurnitures')).includes($(filters.nbFurnitures)) : true)
                && (filters.import ? r.import === filters.import : true)
                && (filters.branding ? r.branding === filters.branding : true)
                && (filters.reminderMails ? r.reminderMails === filters.reminderMails : true)
                && (filters.costEstimation ? r.costEstimation === filters.costEstimation : true)
                && (filters.customFields ? r.customFields === filters.customFields : true)
                && (filters.customCharts ? r.customCharts === filters.customCharts : true)
                && (filters.thematicMaps ? r.thematicMaps === filters.thematicMaps : true)
                && (filters.expertMode ? r.expertMode === filters.expertMode : true)
            ))
        ));
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'projects')
                rows = rows.sort((a, b) => (a.ownedProjects.length + a.joinedProjects.length) - (b.ownedProjects.length + b.joinedProjects.length));
            else if (['nbUsers', 'nbTrees', 'nbGreenSpaces', 'nbFurnitures'].includes(sortColumn))
                rows = rows.sort((a, b) => a[sortColumn] - b[sortColumn]);
            else if (['registrationDate', 'lastLoginDate', 'expirationDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                const value = this.getPropertyValue(property, oldValue);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                const value = this.getPropertyValue(property, oldValue);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'expirationDate': return value ? new Date(value.replaceAll('-', '/').split('/').reverse().join('/')).toISOString() : null;
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (_, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => ({
        ...element,
        rawExpirationDate: element.expirationDate,
        expirationDate: element.expirationDate ? DatesUtil.getFormattedLocaleDateString(element.expirationDate) : '',
        import: element.import ? i18n.t("Oui") : i18n.t("Non"),
        branding: element.branding ? i18n.t("Oui") : i18n.t("Non"),
        reminderMails: element.reminderMails ? i18n.t("Oui") : i18n.t("Non"),
        costEstimation: element.costEstimation ? i18n.t("Oui") : i18n.t("Non"),
        customFields: element.customFields ? i18n.t("Oui") : i18n.t("Non"),
        customCharts: element.customCharts ? i18n.t("Oui") : i18n.t("Non"),
        thematicMaps: element.thematicMaps ? i18n.t("Oui") : i18n.t("Non"),
        expertMode: element.expertMode ? i18n.t("Oui") : i18n.t("Non")
    });

    exportXLSX = () => {
        const { elements } = this.state;
        const elementsToExport = this.getFilteredRows().map(row => row.id);
        this.setState({ isExporting: true });
        OrganizationsService.exportOrganizationsAsExcel(elements.filter(element => elementsToExport.includes(element.id))).then(() => this.setState({ isExporting: false }));
    }

    updateOrganizationMembers = (organizationId, members) => {
        const { data, elements, elementsToModify } = this.state;
        const rows = JSON.parse(JSON.stringify(data.rows));

        const index = elementsToModify.findIndex(element => element.id === organizationId);
        const owner = members.find(member => member.isOwner)?.name || '';
        if (index === -1) {
            const element = JSON.parse(JSON.stringify(elements.find(element => element.id === organizationId)));
            element.members = members;
            element.owner = owner;
            elementsToModify.push(element);
        } else {
            elementsToModify[index].members = members;
            elementsToModify[index].owner = owner;
        }
        this.changeElementsToModify(elementsToModify);

        const rowIndex = rows.findIndex(row => row.id === organizationId);
        this.pushToModificationsHistory([
            { property: 'members', elementId: organizationId, oldValue: rows[rowIndex].members },
            { property: 'owner', elementId: organizationId, oldValue: rows[rowIndex].owner }
        ]);
        rows[rowIndex].members = members;
        rows[rowIndex].owner = owner;

        this.setState(prevState => ({ data: { ...prevState.data, rows } }));
    }

    handleSubmit = () => {
        let { elementsToModify, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach(elementToModify => {
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id)))
                elementsNotToModifyAnymore.push(elementToModify);
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0) {
            this.setState({ isUpdating: true });
            let promises = [];
            if (elementsToModify.length > 0)
                promises.push(new Promise(resolve => {
                    OrganizationsService.updateOrganizations(elementsToModify).then(response => {
                        if (response === 200) {
                            let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                            elementsToModify.forEach(elementToModify => {
                                const index = elements.findIndex(element => element.id === elementToModify.id);
                                elements[index] = elementToModify;
                            });
                            this.setState(prevState => ({ data: { ...prevState.data, rows }, elementsToModify: [] }), () => resolve());
                        }
                    });
                }));

            Promise.all(promises).then(() => {
                this.setState({ elements: elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('users_updated');
        }
    }

    transferData = () => {
        const { transferData } = this.state;

        let isValid = true, messages = [];
        const errors = { sourceOrganization: false, targetOrganization: false };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            isValid = false;
        };

        if (!transferData.sourceOrganization) addError('sourceOrganization', i18n.t("Veuillez sélectionner une organisation source"));
        if (!transferData.targetOrganization) addError('targetOrganization', i18n.t("Veuillez sélectionner une organisation cible"));

        if (!['baseProjects', 'customFields', 'priceLists', 'themes'].some(property => transferData[property]))
            addError('properties', i18n.t("Veuillez cocher au minimum un type de données à transférer"));

        if (isValid) {
            this.setState({ isTransferringData: true });
            OrganizationsService.transferOrganizationData(transferData, true).then(() => {
                this.setState({ showTransferForm: false, isTransferringData: false, transferData: initialTransfer });
            });
        } else this.setState({ error: { messages, ...errors } });
    }

    getFormattedUsage = (row, key) => {
        const percentage = row[key + 'Added'] / row[key] * 100;
        return `${row[key + 'Added']}${row[key] > 0 ? ` / ${row[key]} (${Math.round(percentage * 100) / 100}%)` : ''}`
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization
    };
};

export default connect(mapStateToProps)(OrganizationTable);