import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesPacking, faCalendar, faCheck, faCheckCircle, faCopy, faCrown, faDownload, faEnvelope, faEraser, faEye, faFileAlt, faFolder, faFolderOpen, faFolderTree, faGear, faHome, faLock, faMap, faRightLeft, faShieldPlus, faSignOut, faSignOutAlt, faStar, faTimesCircle, faTrash, faTrashAlt, faUpload, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/pro-regular-svg-icons';
import { faDiagramSankey } from '@fortawesome/pro-duotone-svg-icons';
import MapPreview from '../Utils/MapPreview';
import InputPopupForm from '../Utils/InputPopupForm';
import MoveBaseProjects from '../Utils/MoveBaseProjects';
import Breadcrumb from '../Navbars/Breadcrumb';
import DimmerConfirmation from '../../utils/DimmerConfirmation';
import ContextMenu from '../Utils/ContextMenu';
import { Virtuoso } from 'react-virtuoso';
import OwnershipTransferForm from '../Forms/Projects/OwnershipTransferForm';
// Librairies
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { isMobile, isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect';
import i18n from '../../locales/i18n';
import tinycolor from 'tinycolor2';
import { contextMenu } from 'react-contexify';
// Redux
import { connect } from 'react-redux';
import { setNewProject, setRights, setProjects, setUserProjects, setProjectCollaborators, setViewProjectAsData, setRootBaseProjectIds, setProject } from '../../actionCreators/projectsActions';
import { setProjectListState, setCurrentFolderState } from '../../actionCreators/componentsActions';
import { setProjectListLoadingStatus } from '../../actionCreators/appActions';
// Semantic UI
import { Button, Popup, Input, Label, Dropdown, Form, Header, Dimmer, Loader } from 'semantic-ui-react';
// Services
import ProjectsService from '../../services/ProjectsService';
// Utils
import DatesUtil from '../../utils/DatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import ThemesUtil from '../../utils/ThemesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import { showToast } from '../../utils/ToastsUtil';
import RightsUtil from '../../utils/RightsUtil';

const DISPLAY_IN_CACHE = 'projectListDisplay';
const SORT_IN_CACHE = 'projectListSort';
const dragAndDropInitialState = {
    isDropped: false,
    isLoading: false,
    source: [],
    target: null
};

class ProjectList extends Component {
    state = {
        isLoading: false,
        isOpeningFolder: false,
        search: '',
        filter: 'all',
        sort: 'lastOpening',
        display: 'compact',
        rootFolder: null,
        currentFolder: null,
        selectedBaseProjects: [],
        showMoveBaseProjects: false,
        baseProjectToDelete: null,
        baseProjectToLeave: null,
        newFolder: null,
        folderToRename: null,
        baseProjectToTransfer: null,
        dragAndDrop: dragAndDropInitialState,
        updatingNotifications: []
    };

    render() {
        const { activeOrganization, project, projects, isLandscape } = this.props;
        const {
            isLoading, isOpeningFolder, baseProjectToDelete, baseProjectToLeave, folderToRename, baseProjectToTransfer, newFolder, showMoveBaseProjects,
            search, display, rootFolder, currentFolder, dragAndDrop
        } = this.state;
        const filteredProjects = this.getFilteredProjects();
        const userBaseProjects = this.getUserBaseProjects(filteredProjects);
        const sections = this.getPathFromRoot();
        const typeOfBaseProjectToDelete = i18n.t([baseProjectToDelete?.type, baseProjectToLeave?.type].includes('project') ? "projet" : "dossier");

        return (<>
            {isLoading || isOpeningFolder ?
                <Dimmer active inverted>
                    <Loader inverted>{isLoading ? i18n.t("Chargement des projets...") : i18n.t("Chargement des données dossier...")}</Loader>
                </Dimmer>
                : <>
                    <div className='modal-content'>
                        <div className='modal-content-header' style={{ marginBottom: '5px' }}>
                            <div style={{ display: 'flex', flexDirection: isMobileOnly && !isLandscape && 'column', marginTop: '10px', marginBottom: '10px', alignItems: 'center' }}>
                                <Form style={{ flexGrow: 1, width: isMobileOnly && '100%' }}>
                                    <Form.Field
                                        id='Sp6cRuq0' control={Input} type='text' placeholder={i18n.t("Rechercher...")} name='search' value={search || ''} autoComplete='off'
                                        onChange={(_, { value }) => this.setState({ search: value })} style={{ width: '100%' }}
                                    />
                                </Form>
                                {this.renderDropdowns(projects || [], filteredProjects)}
                                {!isMobileOnly && this.renderCreateButton(isLoading, project, userBaseProjects, activeOrganization)}
                            </div>
                            <Breadcrumb
                                sections={sections} showPreviousButton={true} isPreviousButtonDisabled={!this.state.rootFolder} onPreviousButtonClick={this.goToPreviousFolder}
                                onDrop={this.onDrop} style={{ marginTop: '10px', marginLeft: '10px' }}
                            />
                        </div>
                        <div className='modal-content-body'>
                            {filteredProjects.length
                                ?
                                <Virtuoso
                                    id='project-list' className={display}
                                    style={{ height: '100%' }}
                                    data={filteredProjects}
                                    itemContent={(index, baseProject) => this.renderBaseProject(baseProject, index)}
                                />
                                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                                    <FontAwesomeIcon icon={faFolder} size='6x' style={{ marginTop: 'auto' }} />
                                    <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                                </div>}
                        </div>
                        {isMobileOnly && <div className='modal-content-footer'>{this.renderCreateButton(isLoading, project, userBaseProjects, activeOrganization)}</div>}
                        {(baseProjectToDelete || baseProjectToLeave) &&
                            <DimmerConfirmation
                                title={baseProjectToDelete ? i18n.t("Supprimer") : i18n.t("Quitter")} loadingMessage={i18n.t(`Suppression du {{type}} {{label}} en cours...`, { type: typeOfBaseProjectToDelete, label: baseProjectToDelete?.label || baseProjectToLeave.label })}
                                content={<>
                                    <Label content={baseProjectToDelete?.label || baseProjectToLeave.label} style={{ backgroundColor: 'var(--primary-100)', color: 'white', margin: '15px 0' }} />
                                    <div style={{ marginBottom: '10px' }}>
                                        {baseProjectToDelete
                                            ? i18n.t("Êtes-vous certain de vouloir supprimer ce {{type}} ? La suppression du {{type}} engendrera la suppression de toutes les données.", { type: typeOfBaseProjectToDelete })
                                            : i18n.t("Êtes-vous certain de vouloir quitter ce {{type}} ? Vous n'aurez plus accès aux données de celui-ci.", { type: typeOfBaseProjectToDelete })}
                                    </div>
                                </>}
                                submitColor='red' submitIcon={baseProjectToDelete ? faTrash : faSignOutAlt} submitLabel={baseProjectToDelete ? i18n.t("Supprimer") : i18n.t("Quitter")}
                                cancel={() => this.setState({ baseProjectToDelete: null, baseProjectToLeave: null })}
                                submit={() => this.deleteBaseProject(baseProjectToDelete || baseProjectToLeave, baseProjectToDelete ? true : false)}
                            />}
                        {dragAndDrop.isDropped &&
                            <DimmerConfirmation
                                title={i18n.t("Déplacer")} loadingMessage={dragAndDrop.source.length > 1 ? i18n.t("Déplacement de {{count}} éléments en cours...", { count: dragAndDrop.source.length }) : i18n.t("Déplacement de l'élément en cours...")}
                                content={<>
                                    <div style={{ margin: '10px 0' }}>
                                        {dragAndDrop.source.length > 1
                                            ? i18n.t("Êtes-vous certain de vouloir déplacer ces éléments ?")
                                            : i18n.t("Êtes-vous certain de vouloir déplacer cet élément ?")}
                                        {dragAndDrop.source.length > 1
                                            ? i18n.t("Vous ne serez plus propriétaire de ceux-ci")
                                            : i18n.t("Vous ne serez plus propriétaire de celui-ci")}
                                    </div>
                                </>}
                                cancel={() => this.setState({ dragAndDrop: dragAndDropInitialState })}
                                submitColor='blue' submitIcon={faCheck} submitLabel={i18n.t("Déplacer")} submit={this.moveBaseProjects}
                            />}
                        {newFolder &&
                            <InputPopupForm
                                title={i18n.t("Création d'un dossier")} value={newFolder.label} selection={false}
                                submitButtonIcon={faCheck} submitButtonLabel={i18n.t("Valider")} showCurrentValue={false}
                                submit={(value) => {
                                    this.setState({ isLoading: true });
                                    ProjectsService.addFolder({ ...this.state.newFolder, label: value })
                                        .then(({ folder, addedAlertingBaseProject }) => {
                                            this.addNewBaseProject({ baseProject: folder, addedAlertingBaseProject });
                                            this.setState({ isLoading: false, newFolder: null })
                                        })
                                }}
                                cancel={() => this.setState({ newFolder: null })}
                            />}
                        {folderToRename && <InputPopupForm value={folderToRename.label} submit={this.renameFolder} cancel={() => this.setState({ folderToRename: false })} />}
                        {baseProjectToTransfer &&
                            <OwnershipTransferForm
                                baseProject={baseProjectToTransfer} userProjects={[...(this.props.userProjects || []), ...(baseProjectToTransfer.userBaseProjects || [])]}
                                close={() => this.setState({ baseProjectToTransfer: null })}
                            />}
                        {showMoveBaseProjects &&
                            <MoveBaseProjects
                                baseProjectsToMove={this.getSelectedBaseProjects()} cancel={() => this.setState({ showMoveBaseProjects: false })}
                                currentProjectList={projects} rootFolder={rootFolder} currentFolder={currentFolder}
                                setCurrentProjectList={this.setCurrentProjectList}
                            />}
                        <div id='ghost-image' style={{ zIndex: '-1', backgroundColor: 'var(--primary-100)', width: 'fit-content', padding: '3px 10px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
                    </div>
                    <ContextMenu id='context-menu-project-list' items={this.getContextMenuItems()} />
                </>}
        </>);
    }

    componentDidMount = () => {
        const { newProject, projects, projectListState, currentFolderState, projectListLoadingStatus } = this.props;
        const display = localStorage.getItem(DISPLAY_IN_CACHE) || 'compact';
        const sort = localStorage.getItem(SORT_IN_CACHE);

        if (this.props.project) ThemesUtil.applyTheme(null);
        if (currentFolderState) {
            const { userProjects, ...state } = currentFolderState;
            this.props.setUserProjects(userProjects);
            this.setState(state);
        } else if (projectListState) {
            if (!projectListState.rootFolder && JSON.stringify(this.props.projects) !== JSON.stringify(this.props.projects))
                projectListState.projects = this.props.projects;
            this.setState({ ...projectListState }, () => {
                this.addNewBaseProject(newProject).then(() => {
                    this.props.setProjectListState(null);
                    if (newProject?.baseProject?.type === 'project') {
                        const userBaseProjects = [...(this.props.userProjects || []), ...(newProject.baseProject.userBaseProjects || [])];
                        const userBaseProject = userBaseProjects.find(x => x.user.id === jwtDecode(new Cookies().get('token')).id);
                        const rights = RightsUtil.getUserProjectRights(userBaseProject);
                        this.openProject(newProject.baseProject, rights);
                    }
                });
            });
        } else if (projectListLoadingStatus.rootBaseProjects1Status !== 1 || projectListLoadingStatus.rootBaseProjects2Status !== 1 || projectListLoadingStatus.subBaseProjectsStatus !== 1)
            this.loadBaseProjects();
        else this.setState({ projects, display, sort: sort || 'lastOpening' }, () => this.addNewBaseProject(newProject));
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.projects && this.props.projects && JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects) && !this.state.newFolder) {
            let rootFolder = this.state.rootFolder;
            let currentFolder = this.state.currentFolder;

            if (currentFolder) {
                const baseProject = ProjectsUtil.getBaseProject(currentFolder.id, this.props.projects);
                if (!baseProject) {
                    rootFolder = null;
                    currentFolder = null;
                    this.props.setUserProjects(null);
                }
            }

            this.setState({ rootFolder, currentFolder });
        }
    }

    loadBaseProjects = () => {
        const display = localStorage.getItem(DISPLAY_IN_CACHE) || 'compact';
        const sort = localStorage.getItem(SORT_IN_CACHE) || 'lastOpening';

        const processBaseProjects = (projectListLoadingStatusProp, baseProjects, rootBaseProjects = true) => {
            console.log(projectListLoadingStatusProp, baseProjects);
            if (baseProjects) {
                baseProjects.forEach(baseProject => {
                    ProjectsUtil.assignProjectFormulaVersionsType(baseProject, this.props.formulas);
                });

                if (this.props.project?.fileInfos?.length) {
                    const index = baseProjects.findIndex(x => x.id === this.props.project.id);
                    if (index !== -1) baseProjects[index].fileInfos = this.props.project.fileInfos;
                }

                if (rootBaseProjects) this.props.setRootBaseProjectIds([...(this.props.rootBaseProjectIds || []), ...baseProjects.map(bp => bp.id)]);
                this.props.setProjects([...(this.props.projects || []), ...baseProjects]);
                this.props.setProjectListLoadingStatus({ ...this.props.projectListLoadingStatus, [projectListLoadingStatusProp]: 1 });
            } else this.props.setProjectListLoadingStatus({ ...this.props.projectListLoadingStatus, [projectListLoadingStatusProp]: 3 });
        };

        this.setState({ isLoading: !this.props.projectListLoadingStatus.rootBaseProjects1Status }, () => {
            let nbRootBaseProjectsToLoad = 20;
            if (isMobileOnly) {
                if (display === 'normal') nbRootBaseProjectsToLoad = 8;
                else if (display === 'compact') nbRootBaseProjectsToLoad = 10;
                else nbRootBaseProjectsToLoad = 15;
            } else if (isTablet) {
                if (display === 'normal') nbRootBaseProjectsToLoad = 12;
                else if (display === 'compact') nbRootBaseProjectsToLoad = 15;
                else nbRootBaseProjectsToLoad = 26;
            } else {
                if (display === 'normal') nbRootBaseProjectsToLoad = 8;
                else if (display === 'compact') nbRootBaseProjectsToLoad = 12;
                else nbRootBaseProjectsToLoad = 18;
            }

            if (this.props.projectListLoadingStatus.rootBaseProjects1Status !== 1)
                ProjectsService.getUserRootBaseProjects(display === 'normal', sort, nbRootBaseProjectsToLoad).then(baseProjects => {
                    processBaseProjects('rootBaseProjects1Status', baseProjects);
                    this.setState({ isLoading: false, display, sort: sort }, () => this.addNewBaseProject(this.props.newProject));
                });

            if (this.props.projectListLoadingStatus.rootBaseProjects2Status !== 1)
                ProjectsService.getUserRootBaseProjects(display === 'normal', sort, -1, nbRootBaseProjectsToLoad).then(baseProjects => {
                    processBaseProjects('rootBaseProjects2Status', baseProjects);
                });

            if (this.props.projectListLoadingStatus.subBaseProjectsStatus !== 1) {
                if (this.props.projectListLoadingStatus.subBaseProjectsStatus)
                    this.props.setProjectListLoadingStatus({ ...this.props.projectListLoadingStatus, subBaseProjectsStatus: 0 });
                ProjectsService.getUserSubBaseProjects(display === 'normal').then(baseProjects => {
                    processBaseProjects('subBaseProjectsStatus', baseProjects, false);
                });
            }
        });
    }

    renderCreateButton = (isLoading, project, userBaseProjects, activeOrganization) => {
        const { isOnline, loginAsData } = this.props;
        const userBaseProject = (this.props.userProjects || []).find(x => x.user.id === jwtDecode(new Cookies().get('token')).id);
        const rights = RightsUtil.getUserProjectRights(userBaseProject);
        const isReadOnly = this.state.rootFolder ? RightsUtil.isReadOnly(rights) : false;
        const maxItems = activeOrganization?.subscription.nbProjects;
        const addBtnMessage = isReadOnly ? i18n.t("Vous n'avez pas le droit de créer des projets/dossiers ici") :
            isLoading ? i18n.t("Veuillez attendre la fin du chargement") :
                !isOnline ? i18n.t("Veuillez vous connecter à un reseau") :
                    i18n.t("Vous avez atteint le nombre maximum de projets ({{maxItems}})", { maxItems });
        const isDisabled = (!isOnline || isLoading || isReadOnly || FormattersUtil.checkIfNbElementsReached(userBaseProjects.length, activeOrganization?.subscription.nbProjects)) ? true : false;

        const dropdown = (
            <Dropdown
                text={i18n.t("Nouveau")} item floating button direction='left' className={`button--${isDisabled ? 'grey' : 'primary'}${isMobileOnly ? ' form-button' : ''}`}
                disabled={isDisabled || loginAsData?.readOnly} style={{ textAlign: isMobileOnly && 'center' }} onClick={() => contextMenu.hideAll()}
            >
                <Dropdown.Menu>
                    <Dropdown.Item onClick={this.createProject} disabled={isDisabled}>
                        <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />{i18n.t("Projet")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.createFolder} disabled={isDisabled}>
                        <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }} />{i18n.t("Dossier")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );

        return !isDisabled ? dropdown : <Popup position={!isMobileOnly ? 'bottom left' : 'top center'} content={addBtnMessage} trigger={<div>{dropdown}</div>} />;
    }

    renderBaseProject = (baseProject, index) => {
        const { display } = this.state;
        const isNormal = display === 'normal';
        const isSimplified = display === 'simplified';
        const isCompact = display === 'compact';
        const isTutorial = this.props.userInfos ? (!this.props.userInfos?.lastLoginDate || ['false', undefined].includes(new Cookies().get('isTutorialDone'))) : false;

        const userBaseProjects = [...(this.props.userProjects || []), ...(baseProject.userBaseProjects || [])];
        const userBaseProject = userBaseProjects.find(x => x.user.id === jwtDecode(new Cookies().get('token')).id);

        if (userBaseProject) {
            const rights = RightsUtil.getUserProjectRights(userBaseProject);
            return baseProject.type === 'project'
                ? this.renderProject(baseProject, index, userBaseProject, userBaseProjects, rights, isNormal, isSimplified, isCompact, isTutorial)
                : this.renderFolder(baseProject, index, userBaseProject, userBaseProjects, rights, isNormal, isSimplified, isCompact, isTutorial);
        }

        return;
    }

    renderProject = (project, index, userBaseProject, userBaseProjects, rights, isNormal, isSimplified, isCompact, isTutorial) => {
        const { isDarkTheme } = this.props;
        const owner = userBaseProjects.find(ubp => ubp.projectRole.type === 'owner');
        const projectSubscription = project.organization.subscription;
        const isOpened = this.props.project?.id === project.id;
        const isFavorite = userBaseProject.favoriteBaseProjects?.find(fbp => fbp.baseProjectId === project.id) ? true : false;
        const isSelected = this.state.selectedBaseProjects.find(id => id === project.id);
        let selectedColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
        selectedColor.setAlpha(isDarkTheme ? .2 : .4);

        return (
            <div
                key={project.id} className='project-list-row' onClick={(e) => this.handleRowClick(e, project.id)} onContextMenu={(event) => this.handleContextMenu(event, { baseProject: project, projectSubscription, userBaseProject, rights })}
                style={{ backgroundColor: isSelected && selectedColor, cursor: 'pointer' }}
            >
                <div draggable onDragStart={(e) => this.onDragStart(e, project)} style={{ width: '100%', display: 'flex' }}>
                    <div style={{ paddingRight: 0, display: 'flex', flexDirection: !isSimplified && 'column', flex: 1, width: 0 }}>
                        <div style={{ display: 'flex', alignItems: isSimplified ? 'center' : isCompact && 'baseline', flex: 1 }}>
                            <div style={{ display: 'flex' }}>
                                <Header
                                    title={!isOpened ? i18n.t("Ouvrir le projet") : i18n.t("Le projet est ouvert")} size='medium'
                                    className={isOpened ? 'opened-project' : 'link'}
                                    style={{ marginBottom: isCompact ? '5px' : '0px', marginRight: isNormal && 'auto', width: 'fit-content' }}
                                    onClick={() => { if (!isOpened) this.openProject(project, rights); else this.props.hideForm() }}
                                >
                                    <FontAwesomeIcon className='header-icon' icon={!isSelected ? faFileAlt : faCheckCircle} style={{ marginRight: !isSelected ? '10px' : '5.5px', color: isSelected && 'var(--primary-100)' }} />
                                    {project.label}
                                </Header>
                                {project.recommendedProjectId > 0 &&
                                    <Label title={project.recommendedProject?.label || ''} style={{ fontSize: '10px', backgroundColor: 'var(--yellow-100)', color: 'var(--white-100)', marginLeft: '10px' }}>
                                        <FontAwesomeIcon icon={faDiagramSankey} style={{ marginRight: !isMobileOnly && '10px' }} />
                                        {!isMobileOnly && i18n.t("Recommandation")}
                                    </Label>}
                            </div>
                            {(isCompact && !isMobileOnly) &&
                                <>
                                    <small style={{ marginLeft: '10px', color: 'var(--black-10)' }}>
                                        {`${i18n.t("Organisation")} : ${project.organization.label} | `}
                                    </small>
                                    <small style={{ marginLeft: '4px', color: 'var(--black-10)' }}>
                                        {i18n.t("Propriétaire : {{username}}", { username: FormattersUtil.formatLastNameAndFirstName(owner.user.lastName, owner.user.firstName) })}
                                    </small>
                                </>}
                            {isSimplified && !isMobileOnly &&
                                <div className='userBaseProjects'>
                                    {this.renderUserBaseProjects(userBaseProjects, index)}
                                </div>}
                        </div>
                        {isNormal &&
                            <div style={{ marginBottom: '5px' }}>
                                <small style={{ color: 'var(--black-10)' }}>
                                    {`${i18n.t("Organisation")} : ${project.organization.label} | `}
                                </small>
                                <small style={{ color: 'var(--black-10)' }}>
                                    {i18n.t("Propriétaire : {{username}}", { username: FormattersUtil.formatLastNameAndFirstName(owner.user.lastName, owner.user.firstName) })}
                                </small>
                            </div>}
                        {!isSimplified && !isMobileOnly && <div className='userBaseProjects' style={{ marginLeft: 0, flex: 'none' }}>{this.renderUserBaseProjects(userBaseProjects, index)}</div>}
                        {(isNormal || (isMobileOnly && !isSimplified)) &&
                            <div style={{ marginTop: '5px' }}>{this.renderAdditionnalInfos(project, projectSubscription)}</div>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingLeft: 0, marginLeft: 'auto' }}>
                        <div style={{ textAlign: 'right', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                            {this.renderNotificationsMenu(project, projectSubscription, userBaseProject, isTutorial)}
                            {!isMobileOnly &&
                                <Button
                                    className='I9TTYHFc' icon={isFavorite ? 'star' : 'star outline'} color={isFavorite ? 'yellow' : 'grey'} size='mini' disabled={!this.props.isOnline || this.props.loginAsData?.readOnly}
                                    title={!isFavorite ? i18n.t("Ajouter aux favoris") : i18n.t("Retirer des favoris")} onClick={() => this.toggleFavorite(project, !isFavorite)}
                                />}
                            <Dropdown icon='ellipsis vertical' floating button direction='left' closeOnBlur={!isTutorial} className='w8GBoALg icon' style={{ padding: '7px' }} onClick={() => contextMenu.hideAll()}>
                                <Dropdown.Menu>
                                    <Dropdown.Menu scrolling>
                                        {this.renderOptions(project, projectSubscription, userBaseProject, rights)}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {isCompact && !isMobileOnly &&
                            <div style={{ alignSelf: 'end', marginTop: isCompact ? '5px' : 'auto' }}>
                                {this.renderAdditionnalInfos(project, projectSubscription)}
                            </div>}
                        {!isMobileOnly && isNormal &&
                            <MapPreview
                                key={project.id} id={project.id} surroundings={JSON.parse(project.surroundings)} style={{ height: '55px', width: '100px', borderRadius: '10px', marginTop: '10px' }}
                                title={!isOpened ? i18n.t("Ouvrir le projet") : i18n.t("Le projet est ouvert")} onClick={() => { if (!isOpened) this.openProject(project, rights); else this.props.hideForm() }}
                                noLayers={true}
                            />}
                    </div>
                </div>
            </div>
        );
    }

    renderFolder = (folder, index, userBaseProject, userBaseProjects, rights, isNormal, isSimplified, isCompact, isTutorial) => {
        const { isDarkTheme } = this.props;
        const isDisabled = !this.props.projectListLoadingStatus.subBaseProjectsStatus;
        const owner = userBaseProjects.find(x => x.projectRole.type === 'owner');
        const projectSubscription = folder.organization.subscription;
        const isFavorite = userBaseProject.favoriteBaseProjects?.find(fbp => fbp.baseProjectId === folder.id) ? true : false;
        const isSelected = this.state.selectedBaseProjects.find(id => id === folder.id);
        let selectedColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
        selectedColor.setAlpha(isDarkTheme ? .2 : .4);
        const nbFolders = this.props.projects.filter(p => p.parentFolderId === folder.id && p.type === 'folder').length;
        const nbProjects = this.props.projects.filter(p => p.parentFolderId === folder.id && p.type === 'project').length;

        return (
            <div
                key={folder.id} className='project-list-row' onClick={(e) => { if (!isDisabled) this.handleRowClick(e, folder.id) }}
                onContextMenu={(event) => { if (!isDisabled) this.handleContextMenu(event, { baseProject: folder, projectSubscription, userBaseProject, rights }) }}
                style={{ backgroundColor: isSelected && selectedColor, opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'default' : 'pointer', pointerEvents: isDisabled ? 'none' : 'initial' }}
            >
                <div draggable onDragStart={(e) => this.onDragStart(e, folder)} onDragOver={this.onDragOver} onDrop={(e) => this.onDrop(e, folder)} style={{ width: '100%', display: 'flex' }}>
                    <div style={{ paddingRight: 0, display: 'flex', flexDirection: !isSimplified && 'column', flex: 1, width: 0 }}>
                        <div style={{ display: 'flex', alignItems: isSimplified ? 'center' : isCompact && 'baseline', flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Header
                                    title={i18n.t("Accéder au dossier")} size='medium' className='link'
                                    style={{ marginBottom: 0, marginRight: isNormal && 'auto', width: 'fit-content' }}
                                    onClick={() => this.openFolder(folder)}
                                >
                                    <FontAwesomeIcon className='header-icon' icon={!isSelected ? faFolder : faCheckCircle} style={{ marginRight: '10px', color: isSelected && 'var(--primary-100)' }} />
                                    {folder.label}
                                </Header>
                            </div>
                            {(isCompact && !isMobileOnly) &&
                                <>
                                    <small style={{ marginLeft: '10px', color: 'var(--black-10)' }}>
                                        {`${i18n.t("Organisation")} : ${folder.organization.label} | `}
                                    </small>
                                    <small style={{ marginLeft: '4px', color: 'var(--black-10)' }}>
                                        {i18n.t("Propriétaire : {{username}}", { username: FormattersUtil.formatLastNameAndFirstName(owner.user.lastName, owner.user.firstName) })}
                                    </small>
                                </>}
                            {isSimplified && !isMobileOnly &&
                                <div className='userBaseProjects'>
                                    {this.renderUserBaseProjects(userBaseProjects, index)}
                                </div>}
                            {!nbFolders && !nbProjects && <>
                                {!this.props.projectListLoadingStatus.subBaseProjectsStatus ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', backgroundColor: 'var(--grey-100)', padding: '3px 10px', borderRadius: '10px', pointerEvents: 'none' }}>
                                        <Loader active inline size='mini' style={{ marginRight: '5px' }} />
                                        <small>{i18n.t("Chargement du contenu du dossier...")}</small>
                                    </div>
                                    : this.props.projectListLoadingStatus.subBaseProjectsStatus === 3 &&
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', backgroundColor: 'var(--red-100)', padding: '3px 10px', borderRadius: '10px', pointerEvents: 'none' }}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '5px' }} />
                                        <small>{i18n.t("Impossible de charger le contenu du dossier")}</small>
                                    </div>}
                            </>}
                        </div>
                        {isNormal &&
                            <div style={{ marginBottom: '5px', pointerEvents: 'none' }}>
                                <small style={{ color: 'var(--black-10)' }}>
                                    {`${i18n.t("Organisation")} : ${folder.organization.label} | `}
                                </small>
                                <small style={{ color: 'var(--black-10)' }}>
                                    {i18n.t("Propriétaire : {{username}}", { username: FormattersUtil.formatLastNameAndFirstName(owner.user.lastName, owner.user.firstName) })}
                                </small>
                            </div>}
                        {!isSimplified && !isMobileOnly && <div className='userBaseProjects' style={{ marginLeft: 0, flex: 'none' }}>{this.renderUserBaseProjects(userBaseProjects, index)}</div>}
                        {(isNormal || (isMobileOnly && !isSimplified)) &&
                            <div style={{ marginTop: '5px' }}>{this.renderAdditionnalInfos(folder, projectSubscription, nbFolders, nbProjects)}</div>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingLeft: 0, marginLeft: 'auto' }}>
                        <div style={{ textAlign: 'right', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                            {this.renderNotificationsMenu(folder, projectSubscription, userBaseProject, isTutorial)}
                            {!isMobileOnly &&
                                <Button
                                    className='I9TTYHFc' icon={isFavorite ? 'star' : 'star outline'} color={isFavorite ? 'yellow' : 'grey'} size='mini' disabled={!this.props.isOnline || this.props.loginAsData?.readOnly}
                                    title={!isFavorite ? i18n.t("Ajouter aux favoris") : i18n.t("Retirer des favoris")} onClick={() => this.toggleFavorite(folder, !isFavorite)}
                                />}
                            <Dropdown icon='ellipsis vertical' floating button direction='left' closeOnBlur={!isTutorial} className='w8GBoALg icon' style={{ padding: '7px' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Menu scrolling>
                                        {this.renderOptions(folder, projectSubscription, userBaseProject, rights)}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {isCompact && !isMobileOnly &&
                            <div style={{ alignSelf: 'end', marginTop: isCompact ? '5px' : 'auto' }}>
                                {this.renderAdditionnalInfos(folder, projectSubscription, nbFolders, nbProjects)}
                            </div>}
                    </div>
                </div>
            </div>
        );
    }

    renderUserBaseProjects = (userBaseProjects, projectIndex) => {
        userBaseProjects.sort((up) => up.projectRole.type !== 'manager' ? 1 : -1);
        userBaseProjects.sort((up) => up.projectRole.type !== 'owner' ? 1 : -1);

        let userBaseProjectsToRender = [];
        userBaseProjects = userBaseProjects.filter(ubp => !ubp.deletionDate);
        const nbUserProjects = userBaseProjects.length;
        const nbMaxCollaborators = isMobile ? 6 : 8;
        const nbCollaboratorsHidden = nbUserProjects > nbMaxCollaborators ? nbUserProjects - nbMaxCollaborators : 0;
        userBaseProjects
            .slice(0, nbUserProjects - nbCollaboratorsHidden)
            .forEach((userBaseProject) => {
                const name = FormattersUtil.formatLastNameAndFirstName(userBaseProject.user.lastName, userBaseProject.user.firstName);
                const isDark = tinycolor(userBaseProject.projectRole?.color).getBrightness() < 180;
                userBaseProjectsToRender.push(<Popup
                    key={`${userBaseProject.baseProjectId}-${userBaseProject.userId}`}
                    header={<>
                        <FontAwesomeIcon icon={userBaseProject.projectRole?.icon} style={{ marginRight: '7px' }} />
                        {userBaseProject.projectRole.type ? i18n.t(userBaseProject.projectRole.label) : userBaseProject.projectRole.label}
                    </>}
                    content={<small style={{ display: 'block' }}>{userBaseProject.user.email}</small>}
                    hoverable
                    mouseEnterDelay={500} position={projectIndex ? 'top right' : 'bottom left'}
                    trigger={<Label key={`${userBaseProject.baseProjectId}-${userBaseProject.userId}`} style={{
                        padding: '5px', backgroundColor: userBaseProject.projectRole?.color,
                        color: `var(--${isDark ? 'white' : 'black'}-100)`
                    }}>
                        <FontAwesomeIcon icon={userBaseProject.projectRole?.icon} style={{ marginRight: '7px' }} />{name}
                    </Label>}
                />)
            });

        if (nbCollaboratorsHidden)
            userBaseProjectsToRender.push(
                <Popup
                    key={userBaseProjects[0].baseProjectId}
                    content={<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', maxHeight: '250px', overflowY: 'auto', width: 'fit-content' }}>
                        {userBaseProjects
                            .slice(nbMaxCollaborators)
                            .map((ubp, index) => {
                                const name = FormattersUtil.formatLastNameAndFirstName(ubp.user.lastName, ubp.user.firstName);
                                return (
                                    <div key={index} style={{ display: 'flex', gap: '7px' }}>
                                        <FontAwesomeIcon icon={ubp.projectRole?.icon} title={ubp.projectRole.type ? i18n.t(ubp.projectRole.label) : ubp.projectRole.label} style={{ color: ubp.projectRole?.color }} />
                                        <p title={ubp.user.email} style={{ margin: 0 }}>{name}</p>
                                    </div>
                                )
                            })}
                    </div>}
                    hoverable
                    mouseEnterDelay={500} position={projectIndex ? 'top right' : 'bottom left'}
                    trigger={
                        <Label key={0} style={{
                            padding: '5px', backgroundColor: 'var(--blue-100)', color: 'var(--white-100)'
                        }}>
                            <FontAwesomeIcon icon={faUsers} style={{ marginRight: '5px' }} />{nbCollaboratorsHidden}
                        </Label>}
                />
            );

        return userBaseProjectsToRender;
    }

    renderAdditionnalInfos = (baseProject, ownerSubscription, nbFolders, nbProjects) => {
        const isNormal = this.state.display === 'normal';
        const isCompact = this.state.display === 'compact';
        const isProject = baseProject.type === 'project';
        const nbUserProjects = [...(this.props.userProjects || []), ...(baseProject.userBaseProjects || [])].length;
        const status = ProjectsUtil.getStatus();
        return (<div style={{ display: 'flex', color: 'var(--black-10)' }}>
            <div title={i18n.t("Nombre de collaborateurs")}><FontAwesomeIcon icon={faUser} style={{ marginRight: '7px' }} />{nbUserProjects}</div>
            {isProject && (!isCompact || !isTablet) && <div title={i18n.t("Accessibilité")} style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={faEye} style={{ marginRight: '7px' }} />{baseProject.isPublic ? i18n.t("Public") : i18n.t("Privé")}</div>}
            {!isProject && (!isCompact || !isTablet) && <div title={i18n.t("Nombre de dossiers")} style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={faFolder} style={{ marginRight: '7px' }} />{nbFolders}</div>}
            {!isProject && (!isCompact || !isTablet) && <div title={i18n.t("Nombre de projets")} style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '7px' }} />{nbProjects}</div>}
            {!isMobileOnly && (!isCompact || !isTablet) && <div title={i18n.t("Date de création")} style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={faCalendar} style={{ marginRight: '7px' }} />{DatesUtil.getFormattedLocaleDateString(baseProject.date)}</div>}
            <div title={i18n.t("Abonnement du propriétaire")} style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={faCrown} style={{ marginRight: '7px' }} />{ownerSubscription.name}</div>
            {isProject && !isMobileOnly && <div title={i18n.t("Statut")} style={{ marginLeft: !isMobileOnly && '15px' }}><FontAwesomeIcon icon={faBoxesPacking} style={{ marginRight: '7px' }} />{status.find(s => s.value === baseProject.status).text}</div>}
            {isProject && !isMobileOnly && isNormal && baseProject.surroundings && <div title={i18n.t("Zone géographique")} style={{ marginLeft: !isMobileOnly && '15px' }}><FontAwesomeIcon icon={faMap} style={{ marginRight: '7px' }} />{JSON.parse(baseProject.surroundings).properties?.name || i18n.t("Zone personnalisée")}</div>}
        </div>)
    }

    renderNotificationsMenu = (baseProject, projectSubscription, userBaseProject, isTutorial) => {
        const { updatingNotifications } = this.state;

        const pathSplit = baseProject.path?.split('/').filter(id => id).map(id => Number(id));
        const isNotifiable = projectSubscription.reminderMails;
        const parentNotificationsRecurrence = pathSplit && userBaseProject.alertingBaseProjects?.find(abp => pathSplit.includes(abp.baseProjectId))?.recurrence;
        const notificationsRecurrence = userBaseProject.alertingBaseProjects?.find(abp => abp.baseProjectId === baseProject.id)?.recurrence;
        const notificationsBtnColor = isNotifiable && (parentNotificationsRecurrence || notificationsRecurrence) ? 'green' : 'grey';
        const notificationsBtnIcon = isNotifiable && (parentNotificationsRecurrence || notificationsRecurrence) ? 'bell' : 'bell slash';
        const notificationsMessage = isNotifiable && (parentNotificationsRecurrence || notificationsRecurrence) ? i18n.t("Notifications activées") : i18n.t("Notifications désactivées");

        const renderText = (text, recurrence) => {
            const isChecked = [parentNotificationsRecurrence, notificationsRecurrence].includes(recurrence)
                || (recurrence === 'none' && !parentNotificationsRecurrence && !notificationsRecurrence);

            if (!isChecked) return text;
            else return (
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ marginRight: '15px' }}>{text}</div>
                    <div style={{ marginLeft: 'auto', marginRight: '8px' }}><FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} /></div>
                </div>
            );
        };

        return (
            <Dropdown
                floating button direction='left' closeOnBlur={!isTutorial}
                className={`ggxXoDeE icon ${notificationsBtnColor}`} style={{ padding: '7px' }} icon={notificationsBtnIcon} title={notificationsMessage}
                disabled={parentNotificationsRecurrence || updatingNotifications.includes(baseProject.id) || !this.props.isOnline || this.props.loginAsData?.readOnly}
                loading={updatingNotifications.includes(baseProject.id)}
            >
                <Dropdown.Menu>
                    <Dropdown.Menu scrolling>
                        <Dropdown.Item text={renderText(i18n.t("Aucun"), 'none')} onClick={() => this.handleNotificationsChange(baseProject, 'none', isNotifiable && !parentNotificationsRecurrence)} />
                        <Dropdown.Item text={renderText(i18n.t("Journalier"), 'daily')} icon={!isNotifiable && 'lock'} disabled={!isNotifiable} onClick={() => this.handleNotificationsChange(baseProject, 'daily', isNotifiable && !parentNotificationsRecurrence)} />
                        <Dropdown.Item text={renderText(i18n.t("Hebdomadaire"), 'weekly')} icon={!isNotifiable && 'lock'} disabled={!isNotifiable} onClick={() => this.handleNotificationsChange(baseProject, 'weekly', isNotifiable && !parentNotificationsRecurrence)} />
                        <Dropdown.Item text={renderText(i18n.t("Mensuel"), 'monthly')} icon={!isNotifiable && 'lock'} disabled={!isNotifiable} onClick={() => this.handleNotificationsChange(baseProject, 'monthly', isNotifiable && !parentNotificationsRecurrence)} />
                        <Dropdown.Item text={renderText(i18n.t("Trimestriel"), 'quarterly')} icon={!isNotifiable && 'lock'} disabled={!isNotifiable} onClick={() => this.handleNotificationsChange(baseProject, 'quarterly', isNotifiable && !parentNotificationsRecurrence)} />
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    renderOptions = (baseProject, projectSubscription, userBaseProject, rights) => {
        const { activeOrganization, isDarkTheme, isOnline, loginAsData } = this.props;
        const userId = jwtDecode(new Cookies().get('token')).id;
        const isOpened = this.props.project?.id === baseProject.id;
        const isEditable = (rights.parameters || rights.publicFields || rights.requiredFields || rights.formulas) && this.props.isOnline;
        const isDuplicable = RightsUtil.isOwnerOrManager(rights) && isOnline && !loginAsData?.readOnly;
        const areCollaboratorsChangeable = rights.collaborators && projectSubscription.nbCollaborators && this.props.isOnline;
        const areRightsChangeable = rights.rights && projectSubscription.nbCollaborators && this.props.isOnline;
        const isImportable = RightsUtil.canImportGlobally(rights) && projectSubscription.import && activeOrganization?.subscription.import && this.props.isOnline && !loginAsData?.readOnly;
        const isExportable = RightsUtil.canExportGlobally(rights) && projectSubscription.export && activeOrganization?.subscription.export && this.props.isOnline;
        const isFileGalleryAvailable = projectSubscription.filesSharing;
        const isDeletable = (!this.props.baseProject || this.props.baseProject?.id !== baseProject.id) && this.props.project?.id !== baseProject.id && isOnline && !loginAsData?.readOnly;
        const showDeletion = RightsUtil.isOwner(rights) || baseProject.userBaseProjects?.find(ubp => ubp.userId === userId) ? true : false;
        const isFavorite = userBaseProject.favoriteBaseProjects?.find(fbp => fbp.baseProjectId === baseProject.id) ? true : false;
        const isProject = baseProject.type === 'project';

        return (<>
            {!isProject ? <Dropdown.Item icon='folder open' text={i18n.t("Ouvrir le dossier")} onClick={() => this.openFolder(baseProject)} />
                : <Dropdown.Item icon='file alternate' text={i18n.t("Ouvrir le projet")} id='vHKfvMoT' onClick={() => { if (!isOpened) this.openProject(baseProject, rights); else this.props.hideForm() }} />}
            {!isProject && <Dropdown.Item icon='eye' text={i18n.t("Visualiser le dossier")} disabled={!isOnline} onClick={() => { this.openFolder(baseProject, true, rights) }} />}
            <Dropdown.Item
                icon={projectSubscription.nbCollaborators ? 'users' : 'lock'} text={i18n.t("Gérer les collaborateurs")} id='pk9gikBv'
                disabled={!areCollaboratorsChangeable} onClick={() => this.changeModalContentType('CollaboratorsForm', 'Gestion des collaborateurs', baseProject, areCollaboratorsChangeable, rights)}
            />
            <Dropdown.Item id='pk5gzkBd' disabled={!areRightsChangeable} onClick={() => this.manageRoles(baseProject, areRightsChangeable, rights)}>
                <FontAwesomeIcon icon={projectSubscription.nbCollaborators ? faShieldPlus : faLock} style={{ marginRight: '12px' }} />{i18n.t("Gérer les rôles")}
            </Dropdown.Item>
            {isProject && <>
                <Dropdown.Item
                    icon={projectSubscription.import ? 'upload' : 'lock'} text={i18n.t("Importer des données")} id='PcCOJRRk'
                    disabled={!isImportable} onClick={() => this.changeModalContentType('ImportForm', i18n.t("Import de données"), baseProject, isImportable, rights)}
                />
                <Dropdown.Item
                    icon={projectSubscription.export ? 'download' : 'lock'} text={i18n.t("Exporter des données")} id='YaXq6tX8'
                    disabled={!isExportable} onClick={() => this.changeModalContentType('ExportForm', i18n.t("Export de données"), baseProject, isExportable, rights)}
                />
                <Dropdown.Item
                    icon={projectSubscription.filesSharing ? 'file alternate' : 'lock'} text={i18n.t("Fichiers")} id='EMtRaRHB'
                    disabled={!isFileGalleryAvailable} onClick={() => this.changeModalContentType('ProjectFilesGallery', i18n.t("Fichiers"), baseProject, isFileGalleryAvailable, rights)}
                />
            </>}
            {isProject && <Dropdown.Item icon='setting' text={i18n.t("Paramètres")} id='wwx9xxyd' disabled={!isEditable} onClick={() => this.openSettings(baseProject, isEditable, rights)} />}
            {!isProject && <Dropdown.Item icon='erase' text={i18n.t("Renommer")} disabled={!isEditable} onClick={() => this.setState({ folderToRename: baseProject })} />}
            {isProject && <Dropdown.Item icon='copy' text={i18n.t("Dupliquer")} disabled={!isDuplicable} onClick={() => this.duplicateProject(baseProject, isDuplicable, rights)} />}
            {RightsUtil.isOwner(rights) && ((baseProject.type === 'folder' && !baseProject.path?.length) || baseProject.type === 'project') &&
                <Dropdown.Item text={<><FontAwesomeIcon icon={faRightLeft} style={{ marginRight: '13px' }} />{i18n.t("Céder la propriété")}</>} onClick={() => this.setState({ baseProjectToTransfer: baseProject })} />}
            {showDeletion &&
                <Dropdown.Item id='bFfw2QxC' className='delete' disabled={!isDeletable} onClick={() => this.showProjectDeletion(baseProject, isDeletable, RightsUtil.isOwner(rights))}>
                    <FontAwesomeIcon icon={RightsUtil.isOwner(rights) ? faTrashAlt : faSignOut} style={{ marginRight: '12px' }} />{RightsUtil.isOwner(rights) ? i18n.t("Supprimer") : i18n.t("Quitter")}
                </Dropdown.Item>}
            {isMobileOnly && <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />}
            {isMobileOnly &&
                <Dropdown.Item
                    icon={isFavorite ? 'star' : 'star outline'} text={isFavorite ? i18n.t("Retirer des favoris") : i18n.t("Ajouter aux favoris")}
                    disabled={!this.props.isOnline || this.props.loginAsData?.readOnly} onClick={() => this.toggleFavorite(baseProject, !isFavorite)} />}
        </>)
    }

    renderDropdowns = (projects, filteredProjects) => {
        const { isOnline } = this.props;
        const { filter, sort, display, rootFolder, selectedBaseProjects } = this.state;
        const { isDarkTheme, isLandscape } = this.props;
        const userId = jwtDecode(new Cookies().get('token')).id;
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;
        const nbPublics = projects.filter(x => x.isPublic).length;
        const nbPrivates = projects.length - nbPublics;
        const nbMyProjects = !this.state.rootFolder
            ? projects.filter(x => x.userBaseProjects.find(y => y.projectRole.type === 'owner' && y.user.id === userId)).length
            : this.state.rootFolder.userBaseProjects.find(ubp => ubp.projectRole.type === 'owner' && ubp.userId === userId)
                ? projects.length : 0;
        const nbFavorites = filteredProjects.filter(fp => (rootFolder || fp).userBaseProjects.find(y => y.user.id === userId).favoriteBaseProjects?.find(fbp => fbp.baseProjectId === fp.id)).length;
        const nbNotifications = filteredProjects.filter(fp => {
            const alertingBaseProjects = (rootFolder || fp).userBaseProjects.find(y => y.user.id === userId).alertingBaseProjects;
            const pathSplit = fp.path?.split('/').filter(id => id).map(id => Number(id));
            return alertingBaseProjects?.find(fbp => fbp.baseProjectId === fp.id || pathSplit?.includes(fbp.baseProjectId))
        }).length;
        const isSelectAllDisabled = projects ? projects.filter(project => !isNaN(project.id)).length === selectedBaseProjects.length : true;

        return (<Button.Group style={{ marginLeft: (!isMobileOnly || isLandscape) && '15px', marginRight: (!isMobileOnly || isLandscape) && '15px', marginTop: isMobileOnly && !isLandscape && '10px' }}>
            <Dropdown floating button icon='filter' className='icon button--secondary' title={i18n.t("Filtrer les projets")} onClick={() => contextMenu.hideAll()}>
                <Dropdown.Menu>
                    <Dropdown.Header content={i18n.t("Filtrer les projets")} />
                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Tous"), filter, 'all', checkIcon, projects.length)}
                        onClick={() => this.setState({ filter: 'all' })}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Publics"), filter, 'public', checkIcon, nbPublics)}
                        onClick={() => this.setState({ filter: 'public' })}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Privés"), filter, 'private', checkIcon, nbPrivates)}
                        onClick={() => this.setState({ filter: 'private' })}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Mes projets"), filter, 'my_projects', checkIcon, nbMyProjects)}
                        onClick={() => this.setState({ filter: 'my_projects' })}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown floating button icon='sort' className='icon button--secondary' title={i18n.t("Trier les projets")} onClick={() => contextMenu.hideAll()}>
                <Dropdown.Menu>
                    <Dropdown.Header content={`${i18n.t("Tirer les projets")}(${filteredProjects.length})`} />
                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Le plus récent"), sort, 'recent', checkIcon, null)}
                        onClick={() => this.setState({ sort: 'recent' }, () => localStorage.setItem(SORT_IN_CACHE, 'recent'))}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Le plus ancien"), sort, 'old', checkIcon, null)}
                        onClick={() => this.setState({ sort: 'old' }, () => localStorage.setItem(SORT_IN_CACHE, 'old'))}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Nom"), sort, 'name', checkIcon, null)}
                        onClick={() => this.setState({ sort: 'name' }, () => localStorage.setItem(SORT_IN_CACHE, 'name'))}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Dernière ouverture"), sort, 'lastOpening', checkIcon, null)}
                        onClick={() => this.setState({ sort: 'lastOpening' }, () => localStorage.setItem(SORT_IN_CACHE, 'lastOpening'))}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Favoris"), sort, 'favorite', checkIcon, nbFavorites)}
                        onClick={() => this.setState({ sort: 'favorite' }, () => localStorage.setItem(SORT_IN_CACHE, 'favorite'))}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Notifications"), sort, 'notifications', checkIcon, nbNotifications)}
                        onClick={() => this.setState({ sort: 'notifications' }, () => localStorage.setItem(SORT_IN_CACHE, 'notifications'))}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown floating button icon='tv' className='icon button--secondary' title={i18n.t("Affichage")} onClick={() => contextMenu.hideAll()}>
                <Dropdown.Menu>
                    <Dropdown.Header content={i18n.t("Affichage")} />
                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Normal"), display, 'normal', checkIcon, null)}
                        onClick={() => this.handleDisplayChange('normal')}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Compact"), display, 'compact', checkIcon, null)}
                        onClick={() => this.handleDisplayChange('compact')}
                    />
                    <Dropdown.Item
                        text={this.renderDropdownText(i18n.t("Simplifié"), display, 'simplified', checkIcon, null)}
                        onClick={() => this.handleDisplayChange('simplified')}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <Button
                icon='list' className='icon button--secondary' title={i18n.t("Sélectionner tous les éléments")} disabled={isSelectAllDisabled}
                onClick={() => this.setState({
                    selectedBaseProjects: projects.filter(p => {
                        return p.type === 'folder' && this.props.projectListLoadingStatus.subBaseProjectsStatus !== 1 ? false : true;
                    }).map(project => project.id).filter(id => !isNaN(id))
                })}
            />
            <Button
                icon='times circle' className='icon button--secondary' size='mini' title={i18n.t("Annuler la sélection")}
                disabled={!selectedBaseProjects.length} onClick={() => this.setState({ selectedBaseProjects: [] })}
            />
            <Button
                className='icon button--secondary' size='mini' title={i18n.t("Déplacer la sélection")}
                disabled={!selectedBaseProjects.length || !isOnline} onClick={() => this.setState({ showMoveBaseProjects: true })}>
                <FontAwesomeIcon icon={faFolderTree} />
            </Button>
        </Button.Group>);
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    addNewBaseProject = (newBaseProject) => {
        return new Promise(resolve => {
            if (newBaseProject?.baseProject) {
                const path = newBaseProject?.baseProject.path;
                if (!path) {
                    this.props.setProjects([...this.props.projects, newBaseProject.baseProject]);
                    this.props.setRootBaseProjectIds([...this.props.rootBaseProjectIds, newBaseProject.baseProject.id]);
                }
                else {
                    const reduxProjects = [...this.props.projects];
                    if (newBaseProject.addedAlertingBaseProject) {
                        const { rootFolder } = this.state;
                        const baseProjectInRedux = reduxProjects.find(bp => bp.id === (rootFolder?.id || newBaseProject.baseProject.id));
                        if (this.updateUserBaseProjects('alertingBaseProjects', newBaseProject?.baseProject, baseProjectInRedux.userBaseProjects, 'weekly') && rootFolder)
                            this.setState({ rootFolder: baseProjectInRedux });

                        const userBaseProjects = JSON.parse(JSON.stringify(this.props.userProjects || []));
                        if (this.updateUserBaseProjects('alertingBaseProjects', newBaseProject?.baseProject, userBaseProjects, 'weekly')) this.props.setUserProjects(userBaseProjects);
                    }

                    this.props.setProjects([...reduxProjects, newBaseProject.baseProject]);
                    const userId = jwtDecode(new Cookies().get('token')).id;
                    WebSocketUtil.sendProject(this.props.webSocketHubs, this.props.userProjects.map(up => up.userId).filter(id => id !== userId), newBaseProject.baseProject);
                }
                this.props.setNewProject(null);
                resolve();
            }
        });
    }

    checkProject = (project) => {
        return FormattersUtil.getNormalizedString(project.label).toLowerCase().includes(FormattersUtil.getNormalizedString(this.state.search).toLowerCase());
    };

    checkProjects = (folder = null) => {
        const baseProjectsToCheck = folder ? this.props.projects.filter(p => p.path?.includes(folder.id)) : this.props.projects;
        return baseProjectsToCheck.find(p => this.checkProject(p)) ? true : false;
    };

    resetSearchIfNeeded = (baseProjects) => {
        if (!baseProjects.some(baseProject => (
            this.checkProject(baseProject) || (baseProject.type === 'folder' && this.checkProjects(baseProject))
        )))
            this.setState({ search: '' });
    }

    getFilteredProjects = () => {
        const { rootBaseProjectIds, projects } = this.props;
        const { search, filter, sort, rootFolder, currentFolder } = this.state;
        if (!projects?.length || !rootBaseProjectIds?.length) return [];

        const userId = jwtDecode(new Cookies().get('token')).id;
        let filteredProjects = [];
        const projectsToCheck = !currentFolder ? projects.filter(p => rootBaseProjectIds.includes(p.id)) : projects.filter(p => p.parentFolderId === currentFolder.id);

        // Recherche
        if (search.trim() === '') filteredProjects = projectsToCheck || [];
        else {
            projectsToCheck.forEach(project => {
                if (this.checkProject(project) || (project.type === 'folder' && this.checkProjects(project)))
                    filteredProjects.push(project);
            });
        }
        // Accessibilité
        if (filter === 'public') filteredProjects = filteredProjects.filter(fp => fp.isPublic);
        else if (filter === 'private') filteredProjects = filteredProjects.filter(fp => !fp.isPublic);
        else if (filter === 'my_projects') filteredProjects = filteredProjects.filter(fp => fp.userBaseProjects.find(ubp => ubp.projectRole.type === 'owner' && ubp.user.id === userId));
        // Tri
        if (sort === 'recent') filteredProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
        else if (sort === 'old') filteredProjects.sort((a, b) => new Date(a.date) - new Date(b.date));
        else if (sort === 'name') filteredProjects.sort((a, b) => FormattersUtil.getNormalizedString(a.label).localeCompare(FormattersUtil.getNormalizedString(b.label)));
        else if (sort === 'lastOpening') {
            filteredProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
            const openings = rootFolder
                ? rootFolder.userBaseProjects.find(ubp => ubp.user.id === userId).openings
                : filteredProjects.reduce((prevValue, baseProject) => ({ ...prevValue, ...(baseProject.userBaseProjects.find(ubp => ubp.user.id === userId)?.openings || {}) }), {});
            if (openings)
                filteredProjects.sort((a, b) => {
                    const aResult = openings[a.id];
                    const bResult = openings[b.id];
                    return !bResult ? -1 : !aResult ? 1 : new Date(bResult) - new Date(aResult);
                });
        } else if (sort === 'favorite') {
            filteredProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
            filteredProjects.sort((a, b) => {
                const aResult = (rootFolder || a).userBaseProjects.find(ubp => ubp.user.id === userId).favoriteBaseProjects?.find(fbp => fbp.baseProjectId === a.id) ? 1 : -1;
                const bResult = (rootFolder || b).userBaseProjects.find(ubp => ubp.user.id === userId).favoriteBaseProjects?.find(fbp => fbp.baseProjectId === b.id) ? 1 : -1;
                return bResult - aResult;
            });
        } else if (sort === 'notifications') {
            filteredProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
            filteredProjects.sort((a, b) => {
                const aPathSplit = a.path?.split('/').filter(id => id).map(id => Number(id));
                const aResult = (rootFolder || a).userBaseProjects.find(ubp => ubp.user.id === userId).alertingBaseProjects
                    ?.find(fbp => fbp.baseProjectId === a.id || aPathSplit?.includes(fbp.baseProjectId)) ? 1 : -1;
                const bPathSplit = b.path?.split('/').filter(id => id).map(id => Number(id));
                const bResult = (rootFolder || b).userBaseProjects.find(ubp => ubp.user.id === userId).alertingBaseProjects
                    ?.find(fbp => fbp.baseProjectId === b.id || bPathSplit?.includes(fbp.baseProjectId)) ? 1 : -1;
                return bResult - aResult;
            });
        }
        // Projet ouvert
        if (this.props.project) {
            filteredProjects = filteredProjects.sort((a, b) => {
                const aResult = this.props.project?.id === a.id ? 1 : -1;
                const bResult = this.props.project?.id === b.id ? 1 : -1;
                return bResult - aResult;
            });
        }

        return filteredProjects;
    }

    getUserBaseProjects = (projects) => {
        const id = jwtDecode(new Cookies().get('token')).id
        return projects.filter(project => {
            const userBaseProjects = this.state.rootFolder?.userBaseProjects || project.userBaseProjects;
            const owner = userBaseProjects.find(ubp => ubp.projectRole.type === 'owner');
            return owner.user.id === id;
        });
    }

    openProject = async (project, rights) => {
        await ProjectsUtil.loadMissingEntities(project, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects);
        this.props.setViewProjectAsData(null);
        if (this.props.loginAsData?.readOnly) rights = RightsUtil.setRightsReadOnly(rights, true);
        if (this.state.currentFolder)
            this.props.setCurrentFolderState({
                ...this.state,
                userProjects: this.props.userProjects && [...this.props.userProjects],
            });
        this.props.setRights(rights);
        this.props.setProjectCollaborators(!this.state.rootFolder ? project.userBaseProjects : [...(this.props.userProjects || []), ...(project.userBaseProjects || [])]);
        this.props.showProjectMap(project);
    }

    openFolder = (folder, showOnMap = false, rights) => {
        this.props.setViewProjectAsData(null)
        if (!this.props.isOnline && !this.state.rootFolder) return;
        if (!showOnMap) {
            if (folder.userBaseProjects) {
                const userProjectsToAdd = folder.userBaseProjects.filter(ubp => !this.props.userProjects?.find(up => up.userId === ubp.userId));
                this.props.setUserProjects([...(this.props.userProjects || []), ...(userProjectsToAdd || [])]);
            }

            const subBaseProjects = this.props.projects.filter(p => p.parentFolderId === folder.id);
            this.resetSearchIfNeeded(subBaseProjects);
            this.setState(prevState => ({
                rootFolder: prevState.rootFolder || folder, currentFolder: folder, selectedBaseProjects: []
            }));
        } else {
            this.setState({ isOpeningFolder: true });
            this.showFolderOnMap(folder, rights);
        }
    }

    showFolderOnMap = (folder, rights) => { // TODO Check pourquoi y'a pas de stations/zones persos dans les droits lorsqu'on visualise un dossier
        ProjectsService.getFolderElements(folder.id).then(elements => {
            const baseProjects = this.props.projects.filter(p => p.path?.includes(folder.id));
            let properties = { baseProjects, bounds: null, tags: [], projectFormulaVersions: [], requiredFields: [], projectLabels: [] };
            ProjectsUtil.mergeProjectsProperties(properties, true);
            if (!properties.bounds) showToast('empty_folder_viewing_not_allowed');
            else {
                rights = RightsUtil.setRightsReadOnly(rights);
                this.props.setRights(rights);
                this.props.setProjectCollaborators(!this.state.rootFolder ? folder.userBaseProjects : [...(this.props.userProjects || []), ...(folder.userBaseProjects || [])]);
                this.props.showProjectMap({
                    ...folder, ...elements, surroundings: JSON.stringify(properties.bounds),
                    tags: properties.tags, projectFormulaVersions: properties.projectFormulaVersions,
                    requiredFields: properties.requiredFields, projectLabels: properties.projectLabels
                });
            }
            this.setState({ isOpeningFolder: false });
        });
    }

    updateUserBaseProjects = (propertyName, baseProject, userBaseProjects, state) => {
        const { rootFolder } = this.state;

        const userBaseProject = userBaseProjects.find(userBaseProject => userBaseProject.user.id === jwtDecode(new Cookies().get('token')).id);
        if (!userBaseProject) return false;

        if (!userBaseProject[propertyName]) userBaseProject[propertyName] = [];
        if (!state || state === 'none') userBaseProject[propertyName] = userBaseProject[propertyName].filter(x => x.baseProjectId !== baseProject.id);
        else {
            userBaseProject[propertyName].push({
                userBaseProjectUserId: jwtDecode(new Cookies().get('token')).id,
                userBaseProjectBaseProjectId: rootFolder?.id || baseProject.id,
                baseProjectId: baseProject.id,
                recurrence: state
            });
        }

        return true;
    };

    handleDisplayChange = (display) => {
        if (display === 'normal' && this.props.projects.find(p => p.type === 'project' && !p.surroundings))
            this.setState({ isLoading: true }, () =>
                ProjectsService.getUserBaseProjectsSurroundings().then(projectsSurroundings => {
                    if (projectsSurroundings) {
                        const baseProjects = [...this.props.projects];
                        projectsSurroundings.forEach(projectSurroundings => {
                            const baseProject = baseProjects.find(bp => bp.id === projectSurroundings.baseProjectId);
                            if (baseProject) baseProject.surroundings = projectSurroundings.surroundings;
                        });
                        this.props.setProjects(baseProjects);
                    }
                    this.setState({ isLoading: false, display }, () => localStorage.setItem(DISPLAY_IN_CACHE, display));
                }));
        else this.setState({ display }, () => localStorage.setItem(DISPLAY_IN_CACHE, display));
    }

    handleNotificationsChange = (baseProject, recurrence, isNotifiable) => {
        const { rootFolder } = this.state;

        if (isNotifiable) {
            this.setState(prevState => ({ updatingNotifications: [...prevState.updatingNotifications, baseProject.id] }));
            ProjectsService.updateNotifications(rootFolder, baseProject, recurrence).then(response => {
                if (response === 200 || !response) {
                    const baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                    const baseProjectInRedux = baseProjects.find(bp => bp.id === (rootFolder?.id || baseProject.id));
                    if (this.updateUserBaseProjects('alertingBaseProjects', baseProject, baseProjectInRedux.userBaseProjects, recurrence)) {
                        this.props.setProjects(baseProjects);
                        if (rootFolder) {
                            let currentFolderInRedux = baseProjectInRedux;
                            const pathSplit = baseProject.path?.split('/').filter(id => id).map(id => Number(id)) || [];
                            for (const folderId of pathSplit) {
                                // Le premier ne sera pas trouvé car reprend l'id du parentFolder lui-même
                                const childFolder = this.props.projects.find(p => p.id === folderId && p.type === 'folder');
                                if (childFolder) currentFolderInRedux = childFolder;
                            }
                            this.setState({ rootFolder: baseProjectInRedux, currentFolder: currentFolderInRedux });
                        } else this.setState({ projects: baseProjects });
                    }

                    const userBaseProjects = JSON.parse(JSON.stringify(this.props.userProjects || []));
                    if (this.updateUserBaseProjects('alertingBaseProjects', baseProject, userBaseProjects, recurrence)) this.props.setUserProjects(userBaseProjects);
                }

                this.setState(prevState => ({ updatingNotifications: prevState.updatingNotifications.filter(id => id !== baseProject.id) }));
            });
        }
    };

    toggleFavorite = (baseProject, state) => {
        const { rootFolder } = this.state;

        ProjectsService.toggleFavorite(rootFolder?.id || 0, baseProject, state).then(response => {
            if (response === 200 || !response) {
                const baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                const baseProjectInRedux = baseProjects.find(bp => bp.id === (rootFolder?.id || baseProject.id));
                if (this.updateUserBaseProjects('favoriteBaseProjects', baseProject, baseProjectInRedux.userBaseProjects, state)) {
                    this.props.setProjects(baseProjects);
                    if (rootFolder) {
                        let currentFolderInRedux = baseProjectInRedux;
                        const pathSplit = baseProject.path?.split('/').filter(id => id).map(id => Number(id)) || [];
                        for (const folderId of pathSplit) {
                            // Le premier ne sera pas trouvé car reprend l'id du parentFolder lui-même
                            const childFolder = this.props.projects.find(p => p.id === folderId && p.type === 'folder');
                            if (childFolder) currentFolderInRedux = childFolder;
                        }
                        this.setState({ rootFolder: baseProjectInRedux, currentFolder: currentFolderInRedux });
                    } else this.setState({ projects: baseProjects });
                }

                const userBaseProjects = JSON.parse(JSON.stringify(this.props.userProjects || []));
                if (this.updateUserBaseProjects('favoriteBaseProjects', baseProject, userBaseProjects, state)) this.props.setUserProjects(userBaseProjects);
            }
        });
    };

    changeModalContentType = (formType, title, project, canAccess, rights) => {
        if (canAccess)
            this.props.setProjectListState({ ...this.state }).then(() => {
                if (formType === 'ProjectFilesGallery')
                    this.props.setProjectCollaborators(!this.state.rootFolder ? project.userBaseProjects : [...(this.props.userProjects || []), ...(project.userBaseProjects || [])]).then(() => {
                        if (this.props.loginAsData?.readOnly) rights = RightsUtil.setRightsReadOnly(rights);
                        this.props.setRights(rights).then(() => this.props.showForm(project, formType, title))
                    });
                else {
                    this.props.setUserProjects([...(this.props.userProjects || []), ...(project.userBaseProjects || [])]).then(() => {
                        if (this.props.loginAsData?.readOnly) rights = RightsUtil.setRightsReadOnly(rights);
                        this.props.setRights(rights).then(() => this.props.showForm(project, formType, title));
                    });
                }
            });
    }
    createProject = () => this.props.setProjectListState({ ...this.state }).then(() => this.props.changeModalContentType('ProjectCreationForm', i18n.t("Création d'un projet")));
    createFolder = () => this.setState({ newFolder: { userId: jwtDecode(new Cookies().get('token')).id, parentFolderId: this.state.currentFolder?.id || null, path: this.state.currentFolder && `${this.state.currentFolder.path || '/'}${this.state.currentFolder.id}/` } });
    showProjectDeletion = (project, canAccess, isOwner) => {
        if (isOwner && canAccess) this.setState({ baseProjectToDelete: project });
        else if (canAccess) this.setState({ baseProjectToLeave: project });
    }

    deleteBaseProject = (baseProject, isDeletion) => {
        if (!this.props.project || (this.props.project.id !== baseProject.id)) {
            this.setState({ isDeleting: true });
            ProjectsService.deleteBaseProject(baseProject.id, baseProject.type, isDeletion).then(isDeleted => {
                if (!isDeleted) this.setState({ isDeleting: false, baseProjectToDelete: null });
                else {
                    const userId = jwtDecode(new Cookies().get('token')).id;
                    const userBaseProjects = (this.props.userProjects || baseProject.userBaseProjects).filter(up => up.userId !== userId);
                    const usersToAlert = userBaseProjects.map(up => up.userId);
                    if (isDeletion) WebSocketUtil.removeProject(this.props.webSocketHubs, usersToAlert, baseProject.id, baseProject.path);
                    else {
                        const usersToAlert = baseProject.userBaseProjects.map(up => up.userId).filter(id => id !== userId);
                        WebSocketUtil.updateUserBaseProjects(this.props.webSocketHubs, usersToAlert, userBaseProjects);
                    }

                    this.props.setProjects(this.props.projects.filter(p => p.id !== baseProject.id));
                    this.props.setRootBaseProjectIds(this.props.rootBaseProjectIds.filter(id => id !== baseProject.id));
                    this.setState(prevState => ({
                        isDeleting: false, baseProjectToDelete: null, baseProjectToLeave: null,
                        selectedBaseProjects: prevState.selectedBaseProjects.filter(id => id !== baseProject.id)
                    }));
                }
            });
        }
    }

    handleRowClick = ({ target }, id) => {
        if (target.nodeName.toLowerCase() === 'div' && !target.classList.contains('label') && !target.classList.contains('header')) {
            let { selectedBaseProjects } = this.state;
            const index = selectedBaseProjects.indexOf(id);
            if (index >= 0) selectedBaseProjects.splice(index, 1);
            else selectedBaseProjects.push(id);
            this.setState({ selectedBaseProjects });
        }
    }

    getPathFromRoot = () => {
        const { currentFolder } = this.state;
        const pathToSplit = currentFolder && `${currentFolder.path || '/'}${currentFolder.id}/`;
        const currentPath = pathToSplit?.split('/')?.filter(id => id.length);
        let path = [];
        let baseProjects = this.props.projects;

        path.push({
            icon: faHome, onClick: () => {
                this.props.setUserProjects(null);
                this.resetSearchIfNeeded(this.props.projects);
                this.setState({ projects: this.props.projects, currentFolder: null, rootFolder: null, selectedBaseProjects: [] });
            }
        });

        if (currentPath) {
            currentPath.forEach((id, index) => {
                const baseProject = baseProjects.find(bp => bp.id.toString() === id);
                if (baseProject) {
                    path.push({
                        label: baseProject.label, element: baseProject,
                        onClick: () => {
                            if (index < currentPath.length - 1) {
                                const pathSplit = baseProject.path?.split('/').filter(id => id.length);
                                if (pathSplit) this.props.setUserProjects(this.props.userProjects.filter(up => pathSplit.includes(up.baseProjectId.toString()) || up.baseProjectId === baseProject.id));
                                else this.props.setUserProjects(baseProject.userBaseProjects);
                                this.resetSearchIfNeeded([...this.props.projects]);
                                this.setState({ currentFolder: baseProject, selectedBaseProjects: [] });
                            }
                        }
                    });
                }
            });
        } else if (currentFolder) path.push({ label: currentFolder.label });

        return path;
    }

    goToPreviousFolder = () => {
        const { currentFolder } = this.state;
        const folderIds = currentFolder?.path?.split('/')?.filter(id => id.length);
        if (!folderIds?.length) {
            this.props.setUserProjects(null);
            this.resetSearchIfNeeded(this.props.projects);
            this.setState({ projects: this.props.projects, currentFolder: null, rootFolder: null, selectedBaseProjects: [] });
        } else {
            const previousFolderId = folderIds[folderIds.length - 1];
            const baseProject = ProjectsUtil.getBaseProject(previousFolderId, this.props.projects);
            this.props.setUserProjects(this.props.userProjects.filter(up => up.baseProjectId !== currentFolder.id));
            this.resetSearchIfNeeded(baseProject ? this.props.projects.filter(p => p.path?.includes(baseProject.id)) : this.props.projects);
            this.setState(prevState => ({
                currentFolder: baseProject,
                rootFolder: baseProject ? prevState.rootFolder : null, selectedBaseProjects: []
            }));
        }
    }

    renameFolder = (value) => {
        let folder = this.state.folderToRename;
        folder.label = value;
        ProjectsService.updateFolder(folder).then(() => this.setState({ folderToRename: null }));
    }

    getSelectedBaseProjects = () => this.state.selectedBaseProjects.length ? this.props.projects.filter(project => this.state.selectedBaseProjects.includes(project.id)) : [];
    setCurrentProjectList = (projects, currentFolder, rootFolder, reduxProjects) => this.setState({ projects, currentFolder, rootFolder, selectedBaseProjects: [] }, () => this.props.setProjects(reduxProjects));
    onDragStart = (e, baseProject) => {
        let baseProjectsToMove = [...this.state.selectedBaseProjects];
        if (!baseProjectsToMove.includes(baseProject.id)) baseProjectsToMove.push(baseProject.id);
        baseProjectsToMove = this.props.projects.filter(project => baseProjectsToMove.includes(project.id));

        const ghostImage = document.getElementById('ghost-image');
        ghostImage.innerHTML = `${baseProjectsToMove.length} ${baseProjectsToMove.length > 1 ? i18n.t("Éléments").toLowerCase() : i18n.t("élément")}`;
        e.dataTransfer.setDragImage(ghostImage, 0, 0);
        e.dataTransfer.effectAllowed = "copyMove";
        e.dataTransfer.setData('id', baseProject.id);

        if (this.state.selectedBaseProjects.length && !this.state.selectedBaseProjects.includes(baseProject.id))
            this.setState(prevState => ({ selectedBaseProjects: [...prevState.selectedBaseProjects, baseProject.id] }));
    }

    onDragOver = (e) => e.preventDefault();
    onDrop = (e, folder) => {
        const { isOnline, projects } = this.props;
        const { selectedBaseProjects } = this.state;

        if (!isOnline) {
            showToast('base_projects_move_not_allowed')
            return;
        }

        let baseProjectsToMove = [];
        if (selectedBaseProjects.length) {
            baseProjectsToMove = projects.filter(project => selectedBaseProjects.includes(project.id));
            const authorizedBaseProjectIds = ProjectsUtil.getAuthorizedSelectedBaseProjectIds(baseProjectsToMove, this.props.projects);
            baseProjectsToMove = baseProjectsToMove.filter(project => authorizedBaseProjectIds.includes(project.id));
        }
        else {
            const baseProjectId = Number(e.dataTransfer.getData('id'));
            baseProjectsToMove = [projects.find(project => project.id === baseProjectId)];
            const authorizedBaseProjectIds = ProjectsUtil.getAuthorizedSelectedBaseProjectIds(baseProjectsToMove, this.props.projects);
            if (!authorizedBaseProjectIds.length) baseProjectsToMove = [];
        }

        if (!baseProjectsToMove.length || baseProjectsToMove.find(baseProjectToMove => baseProjectToMove.id === folder?.id)) {
            showToast('base_projects_move_not_allowed');
            return;
        }

        const userId = jwtDecode(new Cookies().get('token')).id;
        const canMoveTo = ProjectsUtil.checkIfCanMoveTo(folder, userId, baseProjectsToMove, this.props.projects);
        if (canMoveTo.isAllowed) {
            if (!canMoveTo.ownerChanged) this.moveBaseProjects(folder, baseProjectsToMove);
            else this.setState(({ dragAndDrop: { isDropped: true, isLoading: false, source: baseProjectsToMove, target: folder } }));
        } else showToast('base_projects_move_not_allowed');
    }

    moveBaseProjects = (folder, baseProjectsToMove) => {
        const { currentFolder, rootFolder, dragAndDrop } = this.state;
        if (dragAndDrop.isDropped) {
            this.setState(prevState => ({ dragAndDrop: { ...prevState.dragAndDrop, isLoading: true } }));
            folder = dragAndDrop.target;
            baseProjectsToMove = dragAndDrop.source;
        }

        ProjectsUtil.moveBaseProjects(folder, baseProjectsToMove.map(baseProjectToMove => baseProjectToMove.id), baseProjectsToMove, this.props.projects, currentFolder, rootFolder, this.props.rootBaseProjectIds, this.props.setRootBaseProjectIds, this.props.webSocketHubs).then((response) => {
            if (dragAndDrop.isDropped) this.setState({ dragAndDrop: dragAndDropInitialState });
            this.setCurrentProjectList(response.currentProjectList, response.currentFolder, response.rootFolder, response.baseProjects);
        });
    }

    handleContextMenu = (event, data) => {
        this.contextMenuData = data;
        contextMenu.show({ event, id: 'context-menu-project-list' });
        this.forceUpdate();
    }

    getContextMenuItems = () => {
        const { activeOrganization, isOnline, loginAsData } = this.props;
        if (!this.contextMenuData) return [];
        const { baseProject, projectSubscription, userBaseProject, rights } = this.contextMenuData;
        if (!baseProject) return [];
        const userId = jwtDecode(new Cookies().get('token')).id;
        const isOpen = this.props.project?.id === baseProject.id;
        const isEditable = (rights.parameters || rights.publicFields || rights.requiredFields || rights.formulas) && this.props.isOnline;
        const isDuplicable = RightsUtil.isOwnerOrManager(rights) && isOnline && !loginAsData?.readOnly;
        const areCollaboratorsChangeable = rights.collaborators && projectSubscription.nbCollaborators && this.props.isOnline;
        const areRightsChangeable = rights.rights && projectSubscription.nbCollaborators && this.props.isOnline;
        const isImportable = RightsUtil.canImportGlobally(rights) && projectSubscription.import && activeOrganization?.subscription.import && this.props.isOnline && !loginAsData?.readOnly;
        const isExportable = RightsUtil.canExportGlobally(rights) && projectSubscription.export && activeOrganization?.subscription.export && this.props.isOnline;
        const isFileGalleryAvailable = projectSubscription.filesSharing;
        const isDeletable = (!this.props.baseProject || this.props.baseProject?.id !== baseProject.id) && this.props.project?.id !== baseProject.id && isOnline && !loginAsData?.readOnly;
        const isOwner = RightsUtil.isOwner(rights);
        const showDeletion = isOwner || baseProject.userBaseProjects?.find(ubp => ubp.userId === userId) ? true : false;
        const isFavorite = userBaseProject.favoriteBaseProjects?.find(fbp => fbp.baseProjectId === baseProject.id) ? true : false;
        const isProject = baseProject.type === 'project';

        return [
            {
                icon: !isProject ? faFolderOpen : faFileAlt,
                label: !isProject ? i18n.t("Ouvrir le dossier") : i18n.t('Ouvrir le projet'),
                onClick: () => {
                    if (!isProject) this.openFolder(baseProject)
                    else {
                        if (!isOpen) this.openProject(baseProject);
                        else this.props.hideForm();
                    }
                }
            },
            {
                icon: faEye,
                label: i18n.t('Visualiser le dossier'),
                isVisible: () => !isProject,
                isDisabled: () => !isOnline,
                onClick: () => {
                    if (!isOpen) this.openFolder(baseProject, true, rights)
                    else this.props.hideForm();
                }
            },
            {
                icon: projectSubscription.nbCollaborators ? faUsers : faLock,
                label: i18n.t("Gérer les collaborateurs"),
                isDisabled: () => !areCollaboratorsChangeable,
                onClick: () => this.changeModalContentType('CollaboratorsForm', 'Gestion des collaborateurs', baseProject, areCollaboratorsChangeable, rights)
            },
            {
                icon: projectSubscription.nbCollaborators ? faShieldPlus : faLock,
                label: i18n.t("Gérer les rôles"),
                isDisabled: () => !areRightsChangeable,
                onClick: () => this.changeModalContentType('RoleList', 'Gestion des rôles', baseProject, areRightsChangeable, rights)
            },
            {
                icon: projectSubscription.import ? faUpload : faLock,
                label: i18n.t("Importer des données"),
                isVisible: () => isProject,
                isDisabled: () => !isImportable,
                onClick: () => this.changeModalContentType('ImportForm', i18n.t("Import de données"), baseProject, isImportable, rights)
            },
            {
                icon: projectSubscription.filesSharing ? faFileAlt : faLock,
                label: i18n.t("Exporter des données"),
                isVisible: () => isProject,
                isDisabled: () => !isExportable,
                onClick: () => this.changeModalContentType('ExportForm', i18n.t("Export de données"), baseProject, isExportable, rights)
            },
            {
                icon: projectSubscription.export ? faDownload : faLock,
                label: i18n.t("Fichiers"),
                isVisible: () => isProject,
                isDisabled: () => !isFileGalleryAvailable,
                onClick: () => this.changeModalContentType('ProjectFilesGallery', i18n.t("Fichiers"), baseProject, isFileGalleryAvailable, rights)
            },
            {
                icon: faGear,
                label: i18n.t("Paramètres"),
                isVisible: () => isProject,
                isDisabled: () => !isEditable,
                onClick: () => this.openSettings(baseProject, isEditable, rights)
            },
            {
                icon: faEraser,
                label: i18n.t("Renommer"),
                isVisible: () => !isProject,
                isDisabled: () => !isEditable,
                onClick: () => this.setState({ folderToRename: baseProject })
            },
            {
                icon: faCopy,
                label: i18n.t("Dupliquer"),
                isVisible: () => isProject,
                isDisabled: () => !isDuplicable,
                onClick: () => this.duplicateProject(baseProject, true, rights)
            },
            {
                icon: faRightLeft,
                label: i18n.t("Céder la propriété"),
                isVisible: () => isOwner && ((baseProject.type === 'folder' && !baseProject.path?.length) || baseProject.type === 'project'),
                onClick: () => this.setState({ baseProjectToTransfer: baseProject })
            },
            {
                icon: faFolderTree,
                label: this.state.selectedBaseProjects.length ? i18n.t("Déplacer la sélection") : i18n.t("Déplacer"),
                isDisabled: () => !isOnline,
                onClick: () => this.setState(prevState => ({ showMoveBaseProjects: true, selectedBaseProjects: prevState.selectedBaseProjects.length ? prevState.selectedBaseProjects : [baseProject.id] }))
            },
            {
                icon: isOwner ? faTrashAlt : faSignOut,
                label: isOwner ? i18n.t("Supprimer") : i18n.t("Quitter"),
                className: 'delete',
                isVisible: () => showDeletion,
                isDisabled: () => !isDeletable,
                onClick: () => this.showProjectDeletion(baseProject, isDeletable, isOwner)
            },
            {
                icon: isFavorite ? faStar : faStarOutline,
                label: isFavorite ? i18n.t("Retirer des favoris") : i18n.t("Ajouter aux favoris"),
                isVisible: () => isMobileOnly,
                isDisabled: () => !this.props.isOnline || this.props.loginAsData?.readOnly,
                onClick: () => this.toggleFavorite(baseProject, !isFavorite)
            }
        ];
    }

    manageRoles = (baseProject, areRightsChangeable, rights) => {
        this.changeModalContentType('RoleList', 'Gestion des rôles', baseProject, areRightsChangeable, rights);
    }

    openSettings = async (baseProject, isEditable, rights) => {
        if (this.props.isOnline) {
            const response = await ProjectsUtil.loadMissingEntities(baseProject, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects, ['surroundings']);
            if (response) baseProject = { ...baseProject, ...response };
        }

        this.changeModalContentType('ProjectModificationForm', 'Paramètres du projet', baseProject, isEditable, rights)
    }

    duplicateProject = async (baseProject, isEditable, rights) => {
        if (this.props.isOnline) {
            const response = await ProjectsUtil.loadMissingEntities(baseProject, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects, ['surroundings']);
            if (response) baseProject = { ...baseProject, ...response };
        }

        this.changeModalContentType('ProjectDuplicationForm', 'Duplication du projet', baseProject, isEditable, rights);
    }
}

const mapStateToProps = (state) => {
    return {
        userInfos: state.userInfos,
        activeOrganization: state.activeOrganization,
        editedProperties: state.editedProperties,
        rootBaseProjectIds: state.rootBaseProjectIds,
        project: state.project,
        projects: state.projects,
        newProject: state.newProject,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs,
        projectListState: state.projectListState,
        projectListLoadingStatus: state.projectListLoadingStatus,
        userProjects: state.userProjects,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setRights,
    setNewProject,
    setProject,
    setProjects,
    setRootBaseProjectIds,
    setProjectListState,
    setProjectListLoadingStatus,
    setCurrentFolderState,
    setUserProjects,
    setProjectCollaborators,
    setViewProjectAsData
}

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(ProjectList));