import React, { Component } from 'react';
// Composants
import { Button, Checkbox, Form, Grid, Input, Message, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import countryList from '../../../resources/countryList';
// Services
import UsersService from '../../../services/UsersService';
import SubscriptionsService from '../../../services/SubscriptionsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import UsersUtil from '../../../utils/UsersUtil';
import OrganizationsService from '../../../services/OrganizationsService';

const initialData = {
    vat: '',
    customerName1: '',
    customerName2: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    isValidVat: false,
    isIntracomVat: false
};

const initialError = {
    hidden: true,
    messages: [],
    vat: false,
    customerName1: false,
    customerName2: false,
    street: false,
    postalCode: false,
    city: false,
    country: false
};

class BillingAddressForm extends Component {
    state = {
        isLoading: false,
        isDataLoaded: false,
        initialData: null,
        data: initialData,
        error: initialError
    }

    render() {
        let { isPro, isOnline, loginAsData } = this.props;
        const { isLoading, initialData, data, error } = this.state;
        const { isValidVat } = this.state.data;
        const submitDisabled = !initialData || (((isPro && !isValidVat) || JSON.stringify(initialData) === JSON.stringify(data))
            && ((isPro && initialData?.vat) || (!isPro && !initialData?.vat))) ? true : false;

        return (
            <Form loading={isLoading} onSubmit={this.handleSubmit} error>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field
                                name='isPro' control={Checkbox} label={i18n.t("Professionnel")}
                                checked={isPro} disabled={isLoading || loginAsData?.readOnly} onChange={this.handleCheckboxChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {this.renderForm()}
                    <Grid.Row>
                        <Grid.Column textAlign='left'>
                            <Message
                                error style={{ textAlign: 'left' }} hidden={error.hidden}
                                header={i18n.t("Erreur")} list={error.messages}
                            />
                            {!this.props.subscribing &&
                                <Button type='submit' color='green' className='form-button' disabled={submitDisabled || !isOnline || loginAsData?.readOnly}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form >
        );
    }

    componentDidMount = () => {
        const { billingAddress } = this.props;
        if (billingAddress) this.setData(billingAddress);
    }

    componentDidUpdate = () => {
        const { billingAddress } = this.props;
        if (!this.state.isDataLoaded && billingAddress) this.setData(billingAddress);
    }

    renderForm = () => {
        const { isPro, loginAsData } = this.props;
        const { isLoading, data, error } = this.state;
        const { vat, customerName1, customerName2, street, city, postalCode, country, isValidVat } = data;

        return (
            <>
                <Grid.Row columns={15}>
                    {isPro ?
                        <>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Form.Field
                                    name='vat' control={Input} label={i18n.t("Numéro de TVA") + '* : '} placeholder='Ex: BE0999999999'
                                    value={vat} error={error.vat} disabled={isLoading || loginAsData?.readOnly} onChange={this.handleChange}
                                    action={{
                                        type: 'button', icon: 'check', color: 'blue', title: i18n.t("Vérifier le numéro"), disabled: isValidVat,
                                        onClick: this.checkVat
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Form.Field
                                    name='customerName1' control={Input} label={i18n.t("Nom d'entreprise") + '* : '} placeholder='Ex: Grality'
                                    value={customerName1} error={error.customerName1} disabled={!isValidVat || loginAsData?.readOnly} onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </>
                        :
                        <>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Form.Field
                                    name='customerName1' control={Input} label={i18n.t("Nom") + '* : '} placeholder='Ex: Rabbit'
                                    value={customerName1} error={error.customerName1} disabled={loginAsData?.readOnly} onChange={this.handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Form.Field
                                    name='customerName2' control={Input} label={i18n.t("Prénom") + '* : '} placeholder='Ex: Roger'
                                    value={customerName2} error={error.customerName2} disabled={loginAsData?.readOnly} onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </>}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field
                            name='street' control={Input} label={i18n.t("Rue") + '* : '} placeholder='Ex: Rue des Ecoles 11A'
                            value={street} error={error.street} disabled={(isPro && !isValidVat) || loginAsData?.readOnly} onChange={this.handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field
                            name='postalCode' control={Input} label={i18n.t("Code postal") + '* : '} placeholder='Ex: 7911'
                            value={postalCode} error={error.postalCode} disabled={(isPro && !isValidVat) || loginAsData?.readOnly} onChange={this.handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field
                            control={Select} label={i18n.t("Pays") + '* : '} placeholder={i18n.t("Sélectionnez un pays")}
                            name='country' options={countryList.map(c => ({ ...c, text: i18n.t(c.text) }))} value={country}
                            selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            error={error.country} disabled={(isPro && isValidVat) || loginAsData?.readOnly} onChange={this.handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field
                            name='city' control={Input} label={i18n.t("Ville") + '* : '} placeholder='Ex: Frasnes-lez-Anvaing'
                            value={city} error={error.city} disabled={(isPro && !isValidVat) || loginAsData?.readOnly} onChange={this.handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }

    setData = (billingAddress) => {
        let isPro = false;
        let data = initialData;

        if (billingAddress) {
            data = { ...billingAddress };
            delete data.customerName;

            if (!billingAddress.vat) {
                const splittedCustomNamer = billingAddress.customerName.split(' ');
                const lastName = splittedCustomNamer[splittedCustomNamer.length - 1];
                const firstName = billingAddress.customerName.replace(lastName, '').trim();
                data.customerName1 = firstName;
                data.customerName2 = lastName;
            } else {
                data.customerName1 = billingAddress.customerName;
                data.customerName2 = '';
                isPro = true;
            }
            if (this.props.setIsBillingAddressValid)
                this.props.setIsBillingAddressValid(UsersUtil.checkBillingAddress(data, isPro));
        } else if (new Cookies().get('token')) {
            let decodedToken = jwtDecode(new Cookies().get('token'));
            data.customerName1 = decodedToken.lastName;
            data.customerName2 = decodedToken.firstName;
        }

        this.props.setIsPro(isPro);
        this.setState({ isDataLoaded: true, data: data, initialData: data });
    }

    checkVat = () => {
        this.setState({ isLoading: true });
        SubscriptionsService.checkVat(this.state.data.vat).then(vatInfos => {
            if (vatInfos) {
                this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        customerName1: vatInfos.customerName,
                        street: vatInfos.street,
                        postalCode: vatInfos.postalCode,
                        city: vatInfos.city,
                        country: countryList.find(country => country.flag === vatInfos.countryCode)?.value,
                        isValidVat: true
                    }
                }), () => this.updateParentBillingAddress());
            } else {
                this.setState(prevState => ({ data: { ...prevState.data, isValidVat: true }, error: { ...prevState.error, vat: true } }));
                showToast('vat_validation_failed');
            }
            this.setState({ isLoading: false });
        });
    }

    handleCheckboxChange = (_, { checked }) => {
        if (this.props.setIsBillingAddressValid) this.props.setIsBillingAddressValid(UsersUtil.checkBillingAddress(this.state.data, checked));
        this.props.setIsPro(checked);
        if (!checked && !this.state.initialData.vat) {
            let data = this.state.data;
            data.vat = '';
            this.setState({ data });
        }
    }

    handleChange = (_, { name, value }) => {
        if (name === 'vat' && this.state.data.isValidVat) this.setState(prevState => ({ data: { ...prevState.data, isValidVat: false } }));
        this.setState(prevState => (
            { data: { ...prevState.data, [name]: value }, error: { ...prevState.error, [name]: false } }
        ), () => this.updateParentBillingAddress());
    }

    handleSubmit = () => {
        let flag = true;
        let errors = { ...initialError };
        const { isPro } = this.props;
        const { vat, customerName1, customerName2, street, city, postalCode, country, isValidVat, isIntracomVat } = this.state.data;

        let fields = {
            vat: !isPro ? '' : vat.trim(),
            customerName1: customerName1.trim(),
            customerName2: !isPro ? customerName2.trim() : '',
            street: street.trim(),
            postalCode: postalCode.trim(),
            city: city.trim(),
            country: country.trim(),
            isValidVat: isValidVat,
            isIntracomVat: isIntracomVat
        }

        const addError = (property, message) => {
            errors.messages = [...(errors.messages || []), message];
            errors[property] = true;
            flag = false;
        }

        if (isPro && !isValidVat) addError('vat', i18n.t("Le numéro de TVA est invalide"));
        if (isPro && !FormattersUtil.checkName(fields.customerName1)) addError('customerName1', isPro ? i18n.t("Le nom d'entreprise est invalide") : i18n.t("Le nom est invalide"));
        if (!isPro && !FormattersUtil.checkName(fields.customerName2)) addError('customerName2', i18n.t("Le prénom est invalide"));
        if (!FormattersUtil.checkString(fields.street)) addError('street', i18n.t("La rue est invalide"));
        if (!FormattersUtil.checkString(fields.postalCode)) addError('postalCode', i18n.t("Le code postal est invalide"));
        if (!FormattersUtil.checkString(fields.city)) addError('city', i18n.t("La ville est invalide"));
        if (!FormattersUtil.checkString(fields.country)) addError('country', i18n.t("Le pays est invalide"));

        if (flag) {
            let billingAddress = { ...fields };
            if (!isPro) {
                billingAddress.vat = '';
                billingAddress.isValidVat = false;
                this.setState(prevState => ({ data: { ...prevState.data, vat: '', isValidVat: false } }));
            }

            billingAddress.customerName = isPro ? billingAddress.customerName1 : billingAddress.customerName1 + ' ' + billingAddress.customerName2;
            delete billingAddress.customerName1;
            delete billingAddress.customerName2;

            this.setState({ isLoading: true });
            OrganizationsService.updateBillingAddress(billingAddress).then(() => {
                this.props.setBillingAddress(billingAddress);
                this.setState({ isLoading: false, error: initialError }, () => this.setData(billingAddress));
                showToast('billing_address_updated');
            });
        }
        else this.setState({ error: { ...errors, hidden: false } });
    }

    updateParentBillingAddress = () => {
        const isPro = this.props.isPro;
        let billingAddress = { ...this.state.data };
        if (this.props.setIsBillingAddressValid)
            this.props.setIsBillingAddressValid(UsersUtil.checkBillingAddress(billingAddress, isPro));
        billingAddress.vat = isPro ? billingAddress.vat : '';
        billingAddress.customerName = isPro ? billingAddress.customerName1 : billingAddress.customerName1 + ' ' + billingAddress.customerName2
        this.props.setBillingAddress(billingAddress);
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        loginAsData: state.loginAsData
    };
};

export default connect(mapStateToProps, null)(BillingAddressForm);