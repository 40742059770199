import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlowerTulip, faPlus, faShareAlt, faShareSquare, faSignOutAlt, faTablePicnic, faTree, faUsers } from '@fortawesome/pro-solid-svg-icons';
import MiniButton from '../../Utils/MiniButton';
import GaugeProgressBar from '../../Utils/GaugeProgressBar';
import { Button, Divider, Form, Grid, Input, Label, Message, Segment, Select, Tab } from 'semantic-ui-react';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { setActiveOrganization } from '../../../actionCreators/usersActions';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
// Services
import OrganizationsService from '../../../services/OrganizationsService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import DatesUtil from '../../../utils/DatesUtil';
import { showToast } from '../../../utils/ToastsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';

const $ = (str) => FormattersUtil.getNormalizedString(str);

class OrganizationMembers extends Component {
    state = {
        searchQuery: '',
        linkedUsers: [],
        invitations: [],
        usersToAdd: [],
        usersOptions: [],
        notAddedUsers: null,
        isLoading: false,
        userSearch: '',
    }

    render() {
        const { activeOrganization, loginAsData, isDarkTheme } = this.props;
        const { searchQuery, linkedUsers, invitations, userSearch, usersToAdd, usersOptions, notAddedUsers, isLoading } = this.state;
        const isMaxLinkedUsersReached = linkedUsers.filter(lu => !lu.isOwner).length + 1 >= activeOrganization.subscription.nbUsers;

        const userId = jwtDecode(new Cookies().get('token')).id;
        const usersToRender = linkedUsers.filter(user => user.id !== userId && (!userSearch?.trim() || (
            $(user.email)?.includes($(userSearch)) ||
            $(user.firstName)?.includes($(userSearch)) ||
            $(user.lastName)?.includes($(userSearch))
        )));

        const isOwner = activeOrganization?.userOrganizations.find(uo => uo.isOwner && uo.userId === userId) ? true : false;
        const organizationOwner = activeOrganization?.linkedUsers.find(user => user.isOwner);

        return (
            <>
                <Segment>
                    <h3 style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faUsers} style={{ marginRight: '5px' }} /> {i18n.t("Membres de l'organisation")}
                    </h3>
                    <Divider />
                    {isOwner && activeOrganization?.subscription?.nbUsers > 1 &&
                        <Form onSubmit={() => this.handleUsersSubmit()} error style={{ marginBottom: '25px' }}>
                            <Form.Field
                                control={Select} label={`${i18n.t("Ajouter des utilisateurs")} :`} placeholder={i18n.t("Saisissez des adresses mails")}
                                name='user' options={usersOptions} value={usersToAdd || ''} disabled={isMaxLinkedUsersReached || loginAsData?.readOnly}
                                selectOnBlur={false} multiple selection search={FormattersUtil.searchList} allowAdditions open={false} // Pour en faire un input multiple
                                searchQuery={searchQuery}
                                onSearchChange={(_, { searchQuery }) => this.setState({ searchQuery: searchQuery })} // On sauvegarde la recherche dans le state
                                onChange={this.handleChange} onKeyUp={this.handleUsersChange}
                            />
                            <Button
                                className='form-button' type='submit' color='blue' style={{ marginTop: '5px' }}
                                disabled={isMaxLinkedUsersReached || isLoading || loginAsData?.readOnly} loading={isLoading}
                            >
                                <FontAwesomeIcon icon={faPlus} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Ajouter")}
                            </Button>
                            <Label pointing={isMobile ? 'above' : 'left'}>
                                {i18n.t("Attention les utilisateurs possédant déjà un abonnement ne seront pas ajoutés.")}
                            </Label>
                            {notAddedUsers &&
                                <Message error
                                    header={`${i18n.t("Les utilisateurs suivants n'ont pas pu être ajoutés")} :`}
                                    list={Object.keys(notAddedUsers).map(mail => mail + ' : ' + notAddedUsers[mail])}
                                />}
                        </Form>}
                    <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                        <Form style={{ marginTop: '5px', width: isMobileOnly ? '100%' : '250px' }}>
                            <Form.Field control={Input} placeholder={i18n.t("Rechercher...")} value={userSearch} onChange={(_, { value }) => this.setState({ userSearch: value })} />
                        </Form>
                        {!isOwner && organizationOwner &&
                            <Button
                                color='red' type='button' className='form-button' style={{ marginLeft: 'auto' }}
                                loading={isLoading} disabled={isLoading || loginAsData?.readOnly} onClick={this.leaveSubscriptionSharing}
                            >
                                <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} />
                                {i18n.t("Quitter l'organisation")}
                            </Button>}
                    </div>
                    <Grid style={{ margin: '5px 0px 0 0px', backgroundColor: isDarkTheme ? 'var(--black-90)' : 'var(--white-90)', borderRadius: '10px' }}>
                        <Grid.Row stretched style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                            {isOwner && <Grid.Column computer={2} tablet={2} mobile={3} />}
                            <Grid.Column computer={6} tablet={6} mobile={13}>{i18n.t("Adresse e-mail")}</Grid.Column>
                            {!isMobileOnly && <>
                                <Grid.Column width={3}>{i18n.t("Nom")}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t("Prénom")}</Grid.Column>
                            </>}
                        </Grid.Row>
                        <Divider style={{ margin: '10px 0' }} />
                        <Grid.Row style={{ height: '350px', maxHeight: '350px', overflow: 'auto' }}>
                            <Grid.Column>
                                {usersToRender.length > 0 || invitations.length ?
                                    <Grid>
                                        {usersToRender.length > 0 && this.renderLinkedUsers(usersToRender, isOwner)}
                                        {invitations.length > 0 &&
                                            <>
                                                <Divider style={{ width: '100%', marginTop: 0, marginBottom: 0 }} />
                                                {this.renderInvitations(isOwner)}
                                            </>}
                                    </Grid>
                                    : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                        <FontAwesomeIcon icon={faUsers} size='6x' />
                                        <p style={{ margin: '10px 0 0 0' }}>{i18n.t("Aucun utilisateur trouvé.")}</p>
                                    </div>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment >
            </>
        );
    }

    componentDidMount = () => {
        this.setState({ linkedUsers: this.props.activeOrganization.linkedUsers, invitations: this.props.activeOrganization.invitations });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.activeOrganization && this.props.activeOrganization
            && (JSON.stringify(prevProps.activeOrganization.linkedUsers) !== JSON.stringify(this.props.activeOrganization.linkedUsers)
                || JSON.stringify(prevProps.activeOrganization.invitations) !== JSON.stringify(this.props.activeOrganization.invitations))
        )
            this.setState({ linkedUsers: this.props.activeOrganization.linkedUsers, invitations: this.props.activeOrganization.invitations });
    }

    renderLinkedUsers = (usersToRender, isOwner) => (
        usersToRender.map(linkedUser => (
            <Grid.Row key={linkedUser.id} stretched>
                {isOwner &&
                    <Grid.Column computer={2} tablet={2} mobile={3}>
                        <Grid.Row>
                            <MiniButton
                                color='red' disabled={this.props.loginAsData?.readOnly} icon='trash' title={i18n.t("Retirer l'utilisateur")}
                                onClick={() => this.handleRemoveLinkedUser(linkedUser)}
                            />
                        </Grid.Row>
                    </Grid.Column>}
                <Grid.Column computer={6} tablet={6} mobile={13}>{linkedUser.email}</Grid.Column>
                {!isMobileOnly && <>
                    <Grid.Column width={3}>{linkedUser.lastName}</Grid.Column>
                    <Grid.Column width={3}>{linkedUser.firstName}</Grid.Column>
                </>}
            </Grid.Row>
        ))
    );

    renderInvitations = (isOwner) => {
        const { invitations } = this.state;
        return invitations.map(invitation => (
            <Grid.Row key={invitation.id} stretched>
                {isOwner &&
                    <Grid.Column computer={2} tablet={2} mobile={3}>
                        <Grid.Row>
                            <MiniButton
                                color='red' disabled={this.props.loginAsData?.readOnly} icon='trash' title={i18n.t("Retirer l'utilisateur")}
                                onClick={() => this.handleRemoveIvitation(invitation)}
                            />
                        </Grid.Row>
                    </Grid.Column>}
                <Grid.Column computer={6} tablet={6} mobile={13}>{invitation.email}</Grid.Column>
                {!isMobileOnly &&
                    <Grid.Column width={8} style={{ display: 'flex' }}>
                        {i18n.t("Invité, en attente d'une réponse de sa part")}
                        <p>({DatesUtil.convertUTCDateToLocaleDate(invitation.date)})</p>
                    </Grid.Column>}
            </Grid.Row>
        ));
    }

    getFormattedValue = (value, maxValue) => maxValue !== -1 ? (value / maxValue * 100) % 1 ? ((value / maxValue * 100).toFixed(2)) : (value / maxValue * 100) : 0;
    handleRemoveLinkedUser = (userToRemove) => {
        OrganizationsService.removeUserFromOrganization(userToRemove.id, false).then(() => {
            this.setState(prevState => ({
                linkedUsers: prevState.linkedUsers.filter(user => user.id !== userToRemove.id)
            }));

            this.props.setActiveOrganization({
                ...this.props.activeOrganization,
                linkedUsers: this.props.activeOrganization.linkedUsers.filter(user => user.id !== userToRemove.id)
            });

            WebSocketUtil.removeOrganization(this.props.webSocketHubs, [userToRemove.id], this.props.activeOrganization.id);
            WebSocketUtil.removeUserOrganizations(this.props.webSocketHubs, this.props.activeOrganization.id, [userToRemove.id]);
        });
    }

    handleRemoveIvitation = (invitation) => {
        OrganizationsService.removeInvitationFromOrganization(invitation.id).then((response) => {
            if (response) {
                this.setState(prevState => ({
                    invitations: prevState.invitations.filter(i => i.email !== invitation.email)
                }));

                this.props.setActiveOrganization({
                    ...this.props.activeOrganization,
                    invitations: this.props.activeOrganization.invitations.filter(i => i.email !== invitation.email)
                });

                WebSocketUtil.removeOrganizationInvitations(this.props.webSocketHubs, this.props.activeOrganization.id, [invitation.id]);
            }
        });
    }

    handleUsersSubmit = () => {
        const { searchQuery } = this.state;
        let usersToAdd = this.state.usersToAdd;
        if (FormattersUtil.checkEmail(searchQuery))
            usersToAdd = [...usersToAdd, searchQuery];
        if (usersToAdd.length > 0) {
            this.setState({ notAddedUsers: null, isLoading: true });
            OrganizationsService.addUsersToOrganization(usersToAdd, this.props.activeOrganization.id).then(response => {
                let linkedUsers = JSON.parse(JSON.stringify(this.state.linkedUsers));
                let invitations = JSON.parse(JSON.stringify(this.state.invitations));

                if (response?.addedUsers?.length) {
                    linkedUsers.push(...response.addedUsers);
                    WebSocketUtil.sendUserOrganizations(this.props.webSocketHubs, this.props.activeOrganization.id, response.addedUsers.map(au => ({ ...au, organizationId: this.props.activeOrganization.id })));
                }

                if (response?.invitations?.length) {
                    invitations = invitations.filter(i => !response.invitations.find(im => i.email === im.email))
                    invitations.push(...response.invitations);
                    WebSocketUtil.sendOrganizationInvitations(this.props.webSocketHubs, this.props.activeOrganization.id, response.invitations);
                }

                this.props.setActiveOrganization({ ...this.props.activeOrganization, linkedUsers, invitations });
                if (response?.notAddedUsers && Object.keys(response.notAddedUsers).length > 0)
                    this.setState({ notAddedUsers: response.notAddedUsers });
                this.setState({ linkedUsers, invitations, usersToAdd: [], usersOptions: [], searchQuery: '', isLoading: false });
            });
        }
    }

    handleUsersChange = (e) => {
        if (e.keyCode === 32) { // Lorsqu'on appuie sur 'espace'
            if (FormattersUtil.checkEmail(this.state.searchQuery)) {
                this.setState(prevState => {
                    const searchQuery = prevState.searchQuery.trim();
                    return {
                        searchQuery: '', // On réinitialise la recherche
                        // On ajoute la recherche dans les options et dans les valeurs sélectionnées
                        usersToAdd: prevState.usersToAdd.find(x => x === searchQuery)
                            ? prevState.usersToAdd
                            : [...prevState.usersToAdd, searchQuery],
                        usersOptions: prevState.usersOptions.find(x => x.text === searchQuery)
                            ? prevState.usersOptions
                            : [...prevState.usersOptions, { text: searchQuery, value: searchQuery }]
                    }
                });
            } else showToast('email_not_valid');
        }
    }

    handleChange = (_, { value }) => { // Permet de gérer le 'remove' de l'input d'ajout
        this.setState({
            usersToAdd: value,
            usersOptions: value.map(x => ({ text: x, value: x }))
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        loginAsData: state.loginAsData,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
}

const mapDispatchToProps = {
    setActiveOrganization
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembers);