import React, { Component, createRef } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faGear, faImage, faInfoCircle, faSave, faTimes, faTimesCircle, faTrash, faUpload, faStar as faStarSolid, faEye, faDownload, faEdit, faEraser, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import Dropzone from 'dropzone';
import Masonry from 'react-masonry-css';
import ImageEditor from '@toast-ui/react-image-editor';
import PhotosCarousel from './PhotosCarousel';
import { Button, Loader, Dimmer, Form, Input, Progress, Grid, Message, Dropdown, Popup, List, Checkbox, Ref, Header } from 'semantic-ui-react';
import DropDownWithCheckboxes from '../Utils/DropDownWithCheckboxes';
import ContextMenu from '../Utils/ContextMenu';
// Librairies
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
import i18n from '../../locales/i18n';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import tinycolor from 'tinycolor2';
import { connect } from 'react-redux';
import { setRequest } from '../../actionCreators/appActions';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { format, isToday, isYesterday, startOfDay, subDays } from 'date-fns';
import { enUS, fr, nlBE } from 'date-fns/locale';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { contextMenu } from 'react-contexify';
import imageCompression from 'browser-image-compression';
// Ressources
import lightTheme from '../../resources/tuiLight';
import darkTheme from '../../resources/tuiDark';
// Services
import FileInfosService from '../../services/FileInfosService';
// Styles
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '../../styles/image-editor.css';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import DatesUtil from '../../utils/DatesUtil';
import FilesUtil from '../../utils/FilesUtil';
import { showToast } from '../../utils/ToastsUtil';
import TasksUtil from '../../utils/TasksUtil';
import FileInfosUtil from '../../utils/FileInfosUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import RightsUtil from '../../utils/RightsUtil';

const COMPRESSION_OPTIONS = {
    initialQuality: 0.6,
    maxWidthOrHeight: 2000, // Largeur ou hauteur maximale en px
    useWebWorker: true, // Utilisation de Web Workers pour des performances optimales
    preserveExif: true,
};

const MAX_ITEMS = 50;
const initialMessage = i18n.t("Chargement en cours...");
const breakpointColumnsObj = {
    default: 12,
    1600: 8,
    1100: 6,
    700: 2,
    500: 1
}

const initialRenameState = {
    id: 0,
    isLoading: false,
    data: {
        baseName: ''
    },
    error: {
        baseName: false
    }
}

const initialState = {
    isLoading: true,
    message: initialMessage,
    search: '',
    photos: null,
    photosStatus: [],
    selectedPhotos: [],
    hoveredPhotoId: 0,
    carouselPhoto: null,
    rename: initialRenameState,
    removeIds: [],
    dragEnter: false,
    sort: 'recent'
}

const initialImageEditor = {
    show: false,
    save: {
        isSaving: false,
        isConfirming: false,
        keepOriginal: true
    },
    photo: null
}

class PhotosGallery extends Component {
    state = {
        ...initialState,
        id: this.props.layer[0].feature.id,
        category: this.props.layer[0].feature.properties.category,
        imageEditor: initialImageEditor,
        settings: {
            photosToShow: ['element', 'actions']
        }
    }

    render() {
        const { isLoading, message, search, rename, removeIds, photos, selectedPhotos, carouselPhoto, dragEnter, imageEditor, sort, settings } = this.state;
        const { isDarkTheme, isOnline, isLandscape, rights } = this.props;
        const filteredPhotos = this.getFilteredPhotos();
        const photosGrouped = this.groupPhotos(filteredPhotos);
        const photosToManage = rename.id !== 0 ? photos.filter(x => x.id === rename.id) : removeIds.length > 0 ? photos.filter(x => removeIds.includes(x.id)) : [];
        const isRenameDisabled = photosToManage.length ? rename.error.baseName || photosToManage.find(x => x.baseName === rename.data.baseName) || rename.isLoading : false;
        const isSelectAllDisabled = photos ? photos.filter(x => !isNaN(x.id)).length === selectedPhotos.length : true;
        const hasRight = this.checkIfUserHasRight();
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;
        const dropdownOptions = [
            { label: i18n.t("Élément"), isChecked: settings.photosToShow.includes('element'), onClick: () => this.togglePhotosToShow('element') },
            { label: i18n.t("Actions"), isChecked: settings.photosToShow.includes('actions'), onClick: () => this.togglePhotosToShow('actions') }
        ];

        return (<>
            {isLoading ?
                <Dimmer active inverted>
                    <Loader inverted>{message}</Loader>
                </Dimmer>
                : <>
                    <div className='modal-content'>
                        <div className='modal-content-header' style={{ display: 'flex', flexDirection: isMobileOnly && !isLandscape && 'column', marginTop: '10px', marginBottom: '20px', alignItems: 'center' }}>
                            <Form style={{ flexGrow: 1, width: isMobileOnly && '100%' }}>
                                <Form.Field
                                    control={Input} type='text' placeholder={i18n.t("Rechercher une photo...")} name='search' value={search || ''}
                                    autoComplete='off' onChange={this.handleChange} style={{ width: '100%' }}
                                />
                            </Form>
                            <Button.Group style={{ marginLeft: (!isMobileOnly || isLandscape) && '15px', marginRight: isMobile && !isLandscape && '15px', marginTop: isMobileOnly && !isLandscape && '10px' }}>
                                <Dropdown floating button icon='sort' className='icon button--secondary' title={i18n.t("Trier les photos")} onClick={() => contextMenu.hideAll()}>
                                    <Dropdown.Menu style={{ zIndex: 10000 }}>
                                        <Dropdown.Header content={i18n.t("Trier les photos")} />
                                        <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                                        <Dropdown.Item
                                            text={this.renderDropdownText(i18n.t("Le plus récent"), sort, 'recent', checkIcon, null)}
                                            onClick={() => this.setState({ sort: 'recent' })}
                                        />
                                        <Dropdown.Item
                                            text={this.renderDropdownText(i18n.t("Le plus ancien"), sort, 'old', checkIcon, null)}
                                            onClick={() => this.setState({ sort: 'old' })}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <DropDownWithCheckboxes icon={faGear} title={i18n.t("Paramètres")} options={dropdownOptions} buttonStyle={{ padding: '12px 11px', borderRadius: 0 }} menuStyle={{ zIndex: 10000 }} />
                                <Button
                                    icon='list' className='icon button--secondary' size='mini' title={i18n.t("Sélectionner toutes les photos")} disabled={isSelectAllDisabled}
                                    onClick={() => this.setState({ selectedPhotos: photos.map(fileInfo => fileInfo.id).filter(id => !isNaN(id)) })}
                                />
                                <Button
                                    icon='times circle' className='icon button--secondary' size='mini' title={i18n.t("Annuler la sélection")}
                                    disabled={!selectedPhotos.length} onClick={() => this.setState({ selectedPhotos: [] })}
                                />
                                <Button
                                    icon='download' className='icon button--secondary' size='mini' title={i18n.t("Télécharger la sélection en ZIP")}
                                    disabled={!selectedPhotos.length || !isOnline} onClick={this.downloadAndCompressPhotosToZip}
                                />
                                {hasRight && <Button
                                    icon='trash' color='red' size='mini' title={i18n.t("Supprimer la sélection")}
                                    disabled={!selectedPhotos.length} onClick={() => this.setState({ removeIds: selectedPhotos.map(id => id) })}
                                />}
                            </Button.Group>
                            {(!isMobile || isLandscape) &&
                                <div style={{ height: '100%', marginLeft: (!isMobileOnly || isLandscape) && '15px', marginRight: !isMobileOnly && hasRight && '15px', marginTop: isMobileOnly && !isLandscape && '10px', width: isMobileOnly && '100%' }}>
                                    <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{i18n.t("Nombre de photos")} ({photos.length} / {MAX_ITEMS})</div>
                                    <Progress
                                        value={photos.length} total={MAX_ITEMS} size='small' color='blue'
                                        style={{ width: !isMobileOnly ? '250px' : '100%', margin: 0 }}
                                    />
                                </div>}
                            {!isMobileOnly && hasRight && this.renderAddButton()}
                        </div>
                        <div id='dropZone' className='modal-content-body' style={dragEnter ? { backgroundColor: 'rgba(250,250,250,0.05)', borderRadius: '20px', border: isDarkTheme ? '2px dashed white' : '2px dashed black' } : { padding: '2px 3px' }}>
                            {photos.length > 0 && this.renderPhotosGroups(photosGrouped, hasRight)}
                            {(!photos?.length || dragEnter) &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                                    <FontAwesomeIcon icon={!dragEnter ? faImage : faUpload} size='6x' style={{ marginTop: 'auto' }} />
                                    <h4 style={{ marginBottom: 'auto' }}>{!dragEnter ? i18n.t("Aucun résultat trouvé") : i18n.t("Glissez-déposez les photos ici")}</h4>
                                </div>}
                        </div>
                        {isMobileOnly && <div className='modal-content-footer'>{this.renderAddButton()}</div>}
                        {photosToManage.length > 0 &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ rename: initialRenameState, removeIds: [] }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{rename.id !== 0 ? i18n.t("Renommer") : i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        {photosToManage.map((fileInfo, index) => <div key={index} style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{fileInfo.baseName}.{fileInfo.extension}</div>)}
                                                    </div>
                                                    {rename.id !== 0 ? <>
                                                        <Form>
                                                            <Ref innerRef={this.renameRef}>
                                                                <Form.Field
                                                                    control={Input} placeholder={i18n.t("Veuillez saisir un nom")} name='baseName' value={rename.data.baseName || ''}
                                                                    error={rename.error.baseName} onChange={this.handleRenameChange} style={{ marginTop: '10px', marginBottom: '10px' }}
                                                                />
                                                            </Ref>
                                                        </Form>
                                                        <Button color='grey' onClick={() => this.setState({ rename: initialRenameState, removeIds: [] })}>
                                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                        </Button>
                                                        <Button color='green' disabled={(isRenameDisabled) ? true : false} loading={rename.isLoading} onClick={this.renamePhoto}>
                                                            <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                                        </Button>
                                                    </>
                                                        : <>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {removeIds.length === 1
                                                                    ? i18n.t("Êtes-vous certain de vouloir supprimer cette photo ? Vous ne pourrez plus la récupérer par la suite.")
                                                                    : i18n.t("Êtes-vous certain de vouloir supprimer ces photos ? Vous ne pourrez plus les récupérer par la suite.")}
                                                            </div>
                                                            <Button color='grey' onClick={() => this.setState({ rename: initialRenameState, removeIds: [] })}>
                                                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                            </Button>
                                                            <Button color='red' onClick={() => this.removePhotos(removeIds)}>
                                                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                            </Button>
                                                        </>}
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                        {carouselPhoto && <div id='photo-carousel'><PhotosCarousel photos={filteredPhotos} selectedPhoto={carouselPhoto} close={() => this.setState({ carouselPhoto: null })} /></div>}
                        {imageEditor.show &&
                            <div id='image-editor'>
                                {imageEditor.save.isConfirming &&
                                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                                        <Grid style={{ height: '100%' }}>
                                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                                <Grid.Column textAlign='center'>
                                                    <Message compact className='tableConfirmation'>
                                                        <Message.Header>{i18n.t("Sauvegarde de la photo")}</Message.Header>
                                                        <Message.Content style={{ marginTop: '10px' }}>
                                                            <Grid style={{ marginBottom: '3px' }}>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <Checkbox
                                                                            label={i18n.t("Conserver la photo originale")} checked={photos.length < MAX_ITEMS ? imageEditor.save.keepOriginal : false}
                                                                            disabled={photos.length >= MAX_ITEMS} onChange={this.toggleKeepOriginal}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            <Button
                                                                color='grey' disabled={imageEditor.save.isSaving}
                                                                onClick={() => this.setState(prevState => ({ imageEditor: { ...prevState.imageEditor, save: initialImageEditor.save } }))}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                            </Button>
                                                            <Button
                                                                color='green' loading={imageEditor.save.isSaving} disabled={imageEditor.save.isSaving}
                                                                onClick={this.saveEditedPhoto}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                                            </Button>
                                                        </Message.Content>
                                                    </Message>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Dimmer>}
                                <Button
                                    className='form-button' type='button' color='grey' size='small' icon='arrow left' title='Retour'
                                    style={{ margin: '10px' }} onClick={() => this.setState(prevState => ({ imageEditor: { show: false, save: { ...prevState.imageEditor.save, isConfirming: false }, url: '' } }))}
                                />
                                <Button
                                    className='form-button' type='button' color='green'
                                    style={{ margin: '10px', float: 'right' }} onClick={() => this.setState(prevState => ({ imageEditor: { ...prevState.imageEditor, save: { ...prevState.imageEditor.save, isConfirming: true } } }))}
                                >
                                    <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                </Button>
                                <ImageEditor
                                    ref={this.editorRef}
                                    includeUI={{
                                        loadImage: {
                                            path: imageEditor.photo.url,
                                            name: imageEditor.photo.baseName,
                                        },
                                        theme: isDarkTheme ? darkTheme : lightTheme,
                                        menuBarPosition: 'right',
                                    }}
                                    cssMaxHeight={window.innerHeight - 100}
                                    cssMaxWidth={window.innerWidth - 435}
                                    selectionStyle={{
                                        cornerSize: 20,
                                        rotatingPointOffset: 70,
                                    }}
                                    usageStatistics={false}
                                />
                            </div>}
                    </div>
                    <ContextMenu id='context-menu-photos-gallery' items={this.getContextMenuItems()} />
                </>}
        </>);
    }

    componentDidMount = () => {
        if (!this.props.project || this.checkIfUserHasRight()) {
            Dropzone.autoDiscover = false;
            this.dropZone = null;
        }

        this.renameRef = createRef();
        this.editorRef = createRef();
        this.isKeyDown = false;
        window.addEventListener('resize', () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.resetImageSize, 300);
        });

        this.loadPhotos();
    }

    componentDidUpdate = (prevProps) => {
        const { layer } = this.props;
        if (layer[0].feature.id !== this.state.id) {
            this.setState({ id: layer[0].feature.id, category: layer[0].feature.properties.category }, this.loadPhotos);
        } else if (JSON.stringify(this.props.photosGalleries.filter(x => x.elementId === layer[0].feature.id)) !== JSON.stringify(prevProps.photosGalleries.filter(x => x.elementId === layer[0].feature.id)))
            this.loadPhotos();

        if (!this.dropZone && !this.state.isLoading && (!this.props.project || this.checkIfUserHasRight())) {
            this.dropZone = new Dropzone('div#dropZone', { clickable: false, previewsContainer: false, autoProcessQueue: false, url: '/' });
            this.dropZone.on('addedfiles', photos => {
                this.setState({ dragEnter: false }, () => this.uploadPhotos(photos))
            });
            this.dropZone.on('dragover', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.setState({ dragEnter: true });
            });
            this.dropZone.on('dragleave', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    this.setState({ dragEnter: false });
                }, 200);
            });
        }

        if (this.editorRef.current) setTimeout(this.resetImageSize, 50);
        if (prevProps.request && this.props.request && prevProps.request.percentage !== this.props.request.percentage) {
            const { photosStatus } = this.state;
            let status = photosStatus.find(status => status.id === this.props.request.id);
            if (status) {
                status.percentage = this.props.request.percentage;
                this.setState({ photosStatus });
            }
        }
    }

    renderAddButton = () => {
        return (<>
            <Button color='green' as='label' htmlFor='file-input' disabled={this.state.photos.length >= MAX_ITEMS} style={{ width: isMobileOnly && '100%' }}>
                <FontAwesomeIcon icon={faUpload} style={{ marginRight: '10px' }} />{i18n.t("Ajouter")}
            </Button>
            <input type='file' id='file-input' accept='.png,.jpg,.jpeg' multiple hidden onChange={({ target }) => {
                this.uploadPhotos(Array.from(target.files));
                target.value = '';
            }} />
        </>);
    }

    renderPhotosGroups = (photosGrouped, hasRight) => {
        const today = new Date();
        const backgroundColor = tinycolor(document.body.style.getPropertyValue('--red-100'));
        backgroundColor.setAlpha(.35);
        const borderColor = tinycolor(document.body.style.getPropertyValue('--red-100'));
        backgroundColor.setAlpha(.2);

        return Object.keys(photosGrouped).map((replantingDate, index) => {
            const parentGroup = photosGrouped[replantingDate];
            return (
                <div key={index} className={replantingDate !== 'none' ? 'photos--cut-down' : null} style={replantingDate !== 'none' ? { backgroundColor: backgroundColor.toPercentageRgbString(), borderColor: borderColor.toPercentageRgbString(), marginTop: '15px' } : null}>
                    {replantingDate !== 'none' && <h3>Avant replantation du {DatesUtil.getFormattedLocaleDateString(replantingDate)}</h3>}
                    {parentGroup.map((group, index) => {
                        const isCurrentYear = group.label.split(' ')[1] === today.getFullYear().toString();
                        return (
                            <div key={index}>
                                {!isCurrentYear && <Header as='h2' style={{ textTransform: 'capitalize', marginTop: '10px', marginBottom: '0px' }}>{group.label}</Header>}
                                {group.subGroups.map((subGroup, subIndex) => (
                                    <div key={subIndex}>
                                        <Header as='h3' style={{ textTransform: 'capitalize', marginTop: subIndex && '10px' }}>{subGroup.label}</Header>
                                        <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                                            {this.renderPhotos(subGroup.photos, hasRight)}
                                        </Masonry>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    getSubGroupLabel = (date) => {
        const locale = i18n.language === 'fr-BE' ? fr : i18n.language === 'en-US' ? enUS : nlBE;
        const year = date.getFullYear() !== new Date().getFullYear() ? ` ${date.getFullYear()}` : '';
        if (isToday(date)) return i18n.t("Aujourd'hui");
        else if (isYesterday(date)) return i18n.t("Hier");
        else if (startOfDay(date) >= subDays(startOfDay(new Date()), 7)) return format(date, 'iiii', { locale });
        else return format(date, i18n.language === 'fr-BE' ? 'iii dd MMM' + year : 'iii, MMM dd' + year, { locale });
    }

    getGroupLabel = (date) => {
        const locale = i18n.language === 'fr-BE' ? fr : i18n.language === 'en-US' ? enUS : nlBE;
        return format(date, 'MMMM yyyy', { locale });
    }

    renderPhotos = (photos, hasRight) => {
        const { isDarkTheme, isOnline } = this.props;
        const { previewBlobName } = this.state;
        let items = [];

        photos.forEach((photo, index) => {
            const user = this.props.projectCollaborators?.find(x => x.userId === photo.createdBy)?.user;
            const username = user ? FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName) : '';
            const status = this.state.photosStatus.find(x => x.id === photo.id);
            const isLoading = status && isOnline ? true : false;
            let loadingColor = tinycolor(document.body.style.getPropertyValue(isDarkTheme ? '--black-100' : '--white-100'));
            loadingColor.setAlpha(isDarkTheme ? .6 : .4);
            const isSelected = this.state.selectedPhotos.find(id => id === photo.id);
            let selectionColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
            selectionColor.setAlpha(isDarkTheme ? .2 : .4);
            const isAdding = !photo.url.includes('http') && isOnline && this.state.photosStatus.filter(x => x.id !== photo.id) ? true : false;

            items.push(
                <div
                    key={index} onClick={(e) => { if (!isAdding) this.handlePhotoClick(e, photo.id) }} onMouseEnter={() => this.setState({ hoveredPhotoId: photo.id })} onMouseLeave={() => this.setState({ hoveredPhotoId: 0 })}
                    style={{ position: 'relative', cursor: !isAdding ? 'pointer' : 'not-allowed', minHeight: '75px' }} onContextMenu={(event) => this.handleContextMenu(event, photo)}
                >
                    {previewBlobName === photo.blobName
                        ? <FontAwesomeIcon icon={faStarSolid} style={{ position: 'absolute', top: '5px', left: '5px', color: 'var(--yellow-100)' }} title={hasRight && i18n.t("Retirer en tant qu'aperçu")} onClick={(e) => { e.stopPropagation(); if (hasRight) this.makePhotoPreview(null); }} />
                        : <FontAwesomeIcon icon={faStar} style={{ position: 'absolute', top: '5px', left: '5px' }} title={hasRight && i18n.t("Définir en tant qu'aperçu")} onClick={(e) => { e.stopPropagation(); if (hasRight) this.makePhotoPreview(photo.blobName); }} />}
                    <Popup
                        content={this.renderPhotoInfos(photo, username)} position='bottom right'
                        trigger={<Button icon='info' circular disabled={isAdding} style={{ position: 'absolute', top: '5px', right: '5px', fontSize: '10px' }} />}
                    />
                    {(isSelected || isLoading) &&
                        <div className='selected-photo' style={{
                            position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: isLoading ? loadingColor : isSelected && selectionColor, pointerEvents: isLoading && 'none', cursor: 'pointer',
                            borderRadius: '10px'
                        }}
                        >
                            {isLoading ? <Loader active inline size='small' content={`${status.message}${status.percentage >= 0 ? `(${status.percentage}%)` : ''}`} />
                                : isSelected ? <FontAwesomeIcon icon={faCheckCircle} size='2x' style={{ color: 'var(--primary-100)' }} />
                                    : null}
                        </div>}
                    <img src={photo.url} alt='img' style={{ display: 'block', borderRadius: '10px', width: '100%', height: '100%', opacity: isAdding && '0.1' }} />
                    {(this.state.hoveredPhotoId === photo.id || isMobile) &&
                        <div style={{ position: 'absolute', bottom: '12px', right: '5px', padding: 0, margin: 0 }}>
                            <Button
                                icon='eye' color='blue' title={i18n.t("Consulter la photo")} disabled={isAdding}
                                onClick={() => this.setState({ carouselPhoto: photo })} style={{ fontSize: '10px' }}
                            />
                            <Dropdown icon='ellipsis vertical' floating button direction='left' className='icon' disabled={isAdding} style={{ fontSize: '10px', zIndex: 1001 }}>
                                <Dropdown.Menu>
                                    <Dropdown.Menu scrolling>
                                        {this.renderOptions(photo)}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                </div>);
        });

        return items;
    }

    renderOptions = (photo) => {
        const hasRight = this.checkIfUserHasRight();
        return (<>
            <Dropdown.Item icon='eye' text={i18n.t("Consulter")} onClick={() => this.setState({ carouselPhoto: photo })} />
            <Dropdown.Item icon='download' text={i18n.t("Télécharger")} disabled={!this.props.isOnline} onClick={() => this.downloadPhoto(photo)} />
            {hasRight && <Dropdown.Item icon='edit' text={i18n.t("Éditer")} onClick={() => this.editPhoto(photo)} />}
            {hasRight && <Dropdown.Item icon='erase' text={i18n.t("Renommer")} onClick={() => this.displayRenameForm(photo)} />}
            {hasRight && <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} onClick={() => this.setState({ removeIds: [photo.id] })} />}
        </>);
    }

    renderPhotoInfos = (photo, username) => {
        const editor = this.props.projectCollaborators?.find(x => x.userId === photo.editedBy)?.user;
        const editorUserName = editor ? FormattersUtil.formatLastNameAndFirstName(editor.lastName, editor.firstName) : null;
        return (<List>
            <List.Header><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '7px' }} />{i18n.t("Informations")}</List.Header>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Nom")} :</span> {photo.baseName}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Date de création")} :</span> {DatesUtil.getFormattedLocaleDateString(photo.createdAt)}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Heure de création")} :</span> {DatesUtil.getFormattedLocaleTimeString(photo.createdAt)}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Taille")} :</span> {FormattersUtil.numberPrettyBytesSI(photo.size)}</List.Item>
            {username && <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Ajoutée par")}</span> {username}</List.Item>}
            {editorUserName && <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Modifiée par")}</span> {editorUserName}</List.Item>}
            {editorUserName && <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Date de modification")} :</span> {DatesUtil.getFormattedLocaleDateString(photo.editedAt)}</List.Item>}
        </List>);
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    loadPhotos = async () => {
        const { photosGalleries, layer } = this.props;
        const id = this.state.id || '';
        let photos = photosGalleries.filter(photo => photo.elementId === id);
        this.setState({ photos, photosStatus: [], previewBlobName: layer[0].feature.properties.previewBlobName, isLoading: false, });
    }

    uploadPhotos = (photosToUpload) => {
        let { photos } = this.state;
        let { photosGalleries } = this.props;

        const nbPhotosOverflowing = MAX_ITEMS - photos.length - photosToUpload.length;
        const nbPhotosToAdd = nbPhotosOverflowing >= 0 ? photosToUpload.length : photosToUpload.length + nbPhotosOverflowing;
        if (photos.length + photosToUpload.length > MAX_ITEMS)
            showToast('photos_limit_reached', MAX_ITEMS);

        let nbPhotosAdded = 0;
        for (const photo of photosToUpload) {
            if (photo['type'].split('/')[0] !== 'image') showToast('file_format_not_supported');
            else {
                nbPhotosAdded++;
                setTimeout(() => this.uploadPhoto(photo, photos, photosGalleries), 50);
                if (nbPhotosAdded === nbPhotosToAdd) break;
            }
        }
    }

    uploadPhoto = async (photo, photos, photosGalleries) => {
        let { photosStatus } = this.state;

        const compressedPhoto = await imageCompression(photo, COMPRESSION_OPTIONS);
        const id = uuidv4();
        const extension = photo.name.split('.').pop();
        const blobName = `${id}.${extension}`;
        const tempPhoto = {
            id: id, projectId: this.props.project?.id || null, createdBy: jwtDecode(new Cookies().get('token')).id,
            blobName, baseName: photo.name.replace(`.${extension}`, ''), extension: extension, elementId: this.state.id, category: this.state.category, recurrenceId: null,
            size: photo.size, type: 'photo', url: URL.createObjectURL(compressedPhoto), createdAt: (new Date(photo.lastModified)).toDateString(), originalCreatedAt: photo.lastModified,
        };
        photos.push(tempPhoto);
        photosStatus.push({ id: tempPhoto.id, message: i18n.t("Ajout de la photo en cours..."), percentage: 0 });
        photosGalleries.push(tempPhoto);
        this.props.setPhotosGalleries(photosGalleries);
        this.setState({ photos, photosStatus });

        const customProps = { data: { tempPhoto, photo: compressedPhoto }, percentage: 0 };
        this.props.setRequest(TasksUtil.createRequest({
            id, functionName: 'uploadPhoto', toastId: 'photo_adding', customProps,
            processMessageId: i18n.t("Ajout de la photo en cours..."),
            successMessageId: i18n.t("La photo a été ajoutée"),
            errorMessageId: i18n.t("L'ajout de la photo a échoué")
        }));
    }

    downloadAndCompressPhotosToZip = () => {
        const { photos, selectedPhotos } = this.state;
        FilesUtil.downloadAndCompressFilesToZip('photo', this.props.project?.label || this.state.id, photos.filter(x => selectedPhotos.includes(x.id)));
        this.setState({ selectedPhotos: [] });
    }

    downloadPhoto = (photo) => {
        this.setState(prevState => ({ photosStatus: [...prevState.photosStatus, { id: photo.id, message: i18n.t("Téléchargement de la photo en cours...") }] }));
        setTimeout(() => {
            Axios.get(photo.url, { responseType: 'blob' }).then(response => {
                if (response?.data) {
                    const blob = new Blob([response.data]);
                    const fileUrl = URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.href = fileUrl;
                    tempLink.setAttribute('download', `${photo.baseName}.${photo.extension}`);
                    tempLink.click();
                    tempLink.remove();
                    let photosStatus = this.state.photosStatus;
                    photosStatus = photosStatus.filter(x => x.id !== photo.id);
                    this.setState({ photosStatus })
                }
            });
        }, 250);
    }

    removePhotos = (ids) => {
        let { photos, photosStatus } = this.state;

        ids.forEach(id => photosStatus.push({ id, message: i18n.t("Suppression du fichier en cours...") }));
        this.setState({ photosStatus, removeIds: [] });
        setTimeout(() => {
            FileInfosService.deleteFileInfos(ids).then((response) => {
                if (response) photos = photos.filter(x => !ids.includes(x.id));
                let photosStatus = this.state.photosStatus;
                photosStatus = photosStatus.filter(x => !ids.includes(x.id));
                let selectedPhotos = this.state.selectedPhotos;
                ids.forEach(id => {
                    const index = selectedPhotos.indexOf(id);
                    if (index >= 0) selectedPhotos.splice(index, 1);
                });
                this.setState({ photos, photosStatus, selectedPhotos }, () => {
                    if (this.props.project && response) WebSocketUtil.removeFileInfos(this.props.webSocketHubs, this.props.project.id, ids);
                    this.props.setPhotosGalleries(this.props.photosGalleries.filter(x => !ids.includes(x.id)));
                });
            });
        }, 250);
    }

    displayRenameForm = (photo) => {
        this.setState({ rename: { ...initialRenameState, id: photo.id, data: { baseName: photo.baseName } } }, () => {
            if (this.renameRef.current) {
                const input = this.renameRef.current.querySelector('input');
                input.focus();
                input.setSelectionRange(0, input.value.length);
            }
        });
    }

    renamePhoto = () => {
        const { photos } = this.state;
        this.setState(prevState => ({ rename: { ...prevState.rename, isLoading: true } }));
        let { rename } = this.state;
        let photo = photos.find(x => x.id === rename.id);
        photo.baseName = rename.data.baseName;

        FileInfosService.updateFileInfo(photo).then(response => {
            if (response?.data) {
                let updatedPhoto = response.data;
                const index = photos.findIndex(x => x.id === updatedPhoto.id);
                if (index !== -1) {
                    updatedPhoto.url = photo.url;
                    photos[index] = updatedPhoto;
                }
                if (this.props.project) WebSocketUtil.updateFileInfos(this.props.webSocketHubs, this.props.project.id, [updatedPhoto]);
                this.setState({ photos, rename: initialRenameState });
            } else this.setState({ rename: initialRenameState });
        });
    }

    getFilteredPhotos = () => {
        const { search, sort, settings } = this.state;
        let filteredPhotos = [];

        // Type (élément/actions)
        const photos = this.state.photos?.filter(photo => (settings.photosToShow.includes('element') && !photo.recurrenceId) || (settings.photosToShow.includes('actions') && photo.recurrenceId)) || [];
        // Recherche
        if (search.trim() === '') filteredPhotos = photos || [];
        else {
            photos.forEach(photo => {
                if (FormattersUtil.getNormalizedString(photo.baseName).toLowerCase().includes(FormattersUtil.getNormalizedString(search).toLowerCase()))
                    filteredPhotos.push(photo);
            });
            return filteredPhotos;
        }
        // Tri
        if (sort === 'recent') filteredPhotos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        else if (sort === 'old') filteredPhotos.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        else if (sort === 'name') filteredPhotos.sort((a, b) => FormattersUtil.getNormalizedString(a.baseName).localeCompare(FormattersUtil.getNormalizedString(b.baseName)));
        return filteredPhotos;
    }

    groupPhotos = (photos) => {
        const groupedPhotos = photos.reduce((previousValue, photo) => {
            (previousValue[photo.replantingDate || 'none'] = previousValue[photo.replantingDate || 'none'] || []).push(photo);
            return previousValue;
        }, {});

        const groups = {};
        Object.keys(groupedPhotos).sort((a, b) => a === 'none' ? -1 : b === 'none' ? 1 : new Date(b) - new Date(a)).forEach(replantingDate => {
            groups[replantingDate] = [];
            groupedPhotos[replantingDate].forEach(photo => {
                const label = this.getGroupLabel(startOfDay(new Date(photo.createdAt)));
                const subLabel = this.getSubGroupLabel(new Date(photo.createdAt));
                const group = groups[replantingDate].find(g => g.label === label);
                if (!group) groups[replantingDate].push({ label, subGroups: [{ label: subLabel, photos: [photo] }] });
                else {
                    const subGroup = group.subGroups.find(g => g.label === subLabel)
                    if (subGroup) subGroup.photos.push(photo);
                    else group.subGroups.push({ label: subLabel, photos: [photo] });
                }
            });
        });

        return groups;
    }

    handleChange = (_, { name, value }) => this.setState({ [name]: value });
    handleRenameChange = (_, { name, value }) => {
        const error = !value?.length;
        this.setState(prevState => ({
            rename: {
                ...prevState.rename,
                data: { ...prevState.rename.data, [name]: value },
                error: { ...prevState.rename.error, [name]: error }
            }
        }));
    }

    handlePhotoClick = ({ target }, id) => {
        if (target.nodeName.toLowerCase() === 'img' || target.classList.contains('selected-photo') || ['svg', 'path'].includes(target.nodeName)) {
            let { selectedPhotos } = this.state;
            const index = selectedPhotos.indexOf(id);
            if (index >= 0) selectedPhotos.splice(index, 1);
            else selectedPhotos.push(id);
            this.setState({ selectedPhotos });
        }
    }

    editPhoto = (photo) => this.setState(prevState => ({ imageEditor: { ...prevState.imageEditor, show: true, photo } }));
    saveEditedPhoto = () => {
        const { imageEditor } = this.state;
        let editedPhoto = imageEditor.photo;
        const dataURL = this.editorRef.current.imageEditorInst.toDataURL();

        this.setState(prevState => ({ imageEditor: { ...prevState.imageEditor, save: { ...prevState.imageEditor.save, isSaving: true } } }));
        fetch(dataURL).then(r => {
            r.blob().then(blobFile => {
                if (imageEditor.save.keepOriginal && this.props.photosGalleries.length < MAX_ITEMS) {
                    editedPhoto = { ...editedPhoto };
                    editedPhoto.id = uuidv4();
                    editedPhoto.blobName = editedPhoto.id + '.' + editedPhoto.extension;
                }
                const file = new File([blobFile], editedPhoto.baseName + '.' + editedPhoto.extension, { type: blobFile.type });
                editedPhoto.size = file.size;

                const formData = new FormData();
                formData.append('type', 'photo');
                formData.append('id', editedPhoto.id);
                formData.append('projectId', this.props.project?.id);
                formData.append('elementId', this.state.id);
                formData.append('category', this.state.category);
                formData.append('recurrenceId', editedPhoto.recurrenceId);
                formData.append('blobName', editedPhoto.blobName);
                formData.append('file', file);

                FileInfosService.addFileInfo(formData).then((fileInfo) => {
                    let photo = fileInfo;
                    if (!photo) {
                        photo = editedPhoto;
                        photo.url = dataURL;
                    } else photo.url = FileInfosUtil.getUrl(photo);

                    let photos = [...this.props.photosGalleries];
                    photo.url = FileInfosUtil.getUrl(photo);
                    if (!imageEditor.save.keepOriginal || photos.length >= MAX_ITEMS) {
                        const index = photos.findIndex(x => x.id === editedPhoto.id);
                        if (index !== -1) photos[index] = photo;
                        if (this.props.project && fileInfo) WebSocketUtil.updateFileInfos(this.props.webSocketHubs, this.props.project.id, [photo]);
                    } else if (imageEditor.save.keepOriginal) {
                        photos.push(photo);
                        if (this.props.project && fileInfo) WebSocketUtil.sendFileInfos(this.props.webSocketHubs, this.props.project.id, [photo]);
                    }

                    this.props.setPhotosGalleries(photos);
                    showToast('photo_edited');
                    this.setState({ photos: photos.filter(p => p.elementId === this.state.id), imageEditor: initialImageEditor });
                });
            });
        });
    }

    resetPhotoSize = () => {
        if (this.editorRef.current)
            this.editorRef.current.imageEditorInst.resizeCanvasDimension({
                height: window.innerHeight - 150,
                width: window.innerWidth - 435
            });
    }

    toggleKeepOriginal = () => {
        this.setState(prevState => ({
            imageEditor: {
                ...prevState.imageEditor,
                save: {
                    ...prevState.imageEditor.save,
                    keepOriginal: !prevState.imageEditor.save.keepOriginal
                }
            }
        }));
    }

    checkIfUserHasRight = () => {
        const token = new Cookies().get('token');
        if (!token) return false;
        const userId = jwtDecode(token).id;
        if (!userId) return false;
        if (!this.props.project) return true;
        const userBaseProject = this.props.projectCollaborators?.find(x => x.userId === userId);
        if (!userBaseProject) return false;
        if (!this.props.layer) return false;
        const { category } = this.props.layer[0].feature.properties;
        const right = category === 'Arbre' ? 'trees' : category === 'Espace vert' ? 'greenSpaces' : category === 'Mobilier' ? 'furnitures' : 'markers';
        return RightsUtil.canWrite(this.props.rights[right]);
    }

    resetImageSize = () => {
        if (this.editorRef.current)
            this.editorRef.current.imageEditorInst.resizeCanvasDimension({
                height: window.innerHeight - 150,
                width: window.innerWidth - 435
            });
    }

    togglePhotosToShow = (type) => {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                photosToShow: prevState.settings.photosToShow.includes(type)
                    ? prevState.settings.photosToShow.filter(pts => pts !== type)
                    : [...prevState.settings.photosToShow, type]
            }
        }));
    }

    makePhotoPreview = (blobName) => {
        const { layer } = this.props;
        layer.forEach(layer => layer.feature.properties.previewBlobName = blobName);
        this.setState({ previewBlobName: blobName });
        FileInfosService.updatePhotoPreview(layer[0].feature.properties.category, layer[0].feature.id, blobName);
    }

    handleContextMenu = (event, photo) => {
        this.contextMenuElement = photo;
        contextMenu.show({ event, id: 'context-menu-photos-gallery' });
        this.forceUpdate();
    }

    getContextMenuItems = () => {
        const photo = this.contextMenuElement;
        if (!photo) return [];
        const hasRight = this.checkIfUserHasRight();
        return [
            {
                icon: faEye,
                label: i18n.t("Consulter"),
                onClick: () => this.setState({ carouselPhoto: photo })
            },
            {
                icon: faDownload,
                label: i18n.t("Télécharger"),
                isDisabled: () => !this.props.isOnline,
                onClick: () => this.downloadPhoto(photo)
            },
            {
                icon: faEdit,
                label: i18n.t("Éditer"),
                isVisible: () => hasRight,
                onClick: () => this.editPhoto(photo)
            },
            {
                icon: faEraser,
                label: i18n.t("Renommer"),
                isVisible: () => hasRight,
                onClick: () => this.displayRenameForm(photo)
            },
            {
                icon: faTrashAlt,
                label: i18n.t("Supprimer"),
                className: 'delete',
                isVisible: () => hasRight,
                onClick: () => this.setState({ removeIds: [photo.id] })
            }
        ];
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        rights: state.rights,
        projectCollaborators: state.projectCollaborators,
        request: state.request,
        photosGalleries: state.photosGalleries,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setRequest,
    setPhotosGalleries
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(PhotosGallery));