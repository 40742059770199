import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Form, Button, Label, Select, Segment, Checkbox, Dropdown, Message, Dimmer, Loader, Popup } from 'semantic-ui-react';
import DatePicker from '../../Utils/DatePicker';
// Librairies
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { setActiveOrganization } from '../../../actionCreators/usersActions';
import { setProjects, setProject, setUserProjects, setProjectCollaborators, setProjectInvitations } from '../../../actionCreators/projectsActions';
import { setLockedElements } from '../../../actionCreators/elementsActions';
import { faCheck, faClipboard, faDollarCircle, faEnvelopeCircleCheck, faPlus, faRotateRight, faTimes, faTimesCircle, faTrash, faTrashAlt, faWarning } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../../locales/i18n';
import { contextMenu } from 'react-contexify';
// Services
import ProjectsService from '../../../services/ProjectsService';
import OrganizationsService from '../../../services/OrganizationsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import DatesUtil from '../../../utils/DatesUtil';
import StylesUtil from '../../../utils/StylesUtil';
import ContextMenu from '../../Utils/ContextMenu';

class CollaboratorsForm extends Component {
    state = {
        isChangingRoles: false,
        isAddingCollaborator: false,
        project: null,
        searchQuery: '',
        collaboratorsToAdd: [],
        collaboratorsOptions: [],
        userBaseProjectsToModify: [],
        ubpToRemove: null,
        invitationToRemove: null,
        invitedUsers: [],
        mailsNotAddedToOrganization: [],
        addToOrganization: false,
        projectRoleId: null,
        projectRoleExpirationDate: null
    }

    render() {
        const { loginAsData } = this.props;
        const { isChangingRoles, isAddingCollaborator, invitedUsers, userBaseProjectsToModify, ubpToRemove, isDeleting, addToOrganization, mailsNotAddedToOrganization, projectRoleId, projectRoleExpirationDate } = this.state;
        const owner = this.props.userProjects?.find(up => up.projectRole.type === 'owner').user;
        const subscription = this.state.project?.organization.subscription;
        const nbCollaborators = this.props.userProjects?.length;
        const isMaxCollaboratorsReached = FormattersUtil.checkIfNbElementsReached(nbCollaborators - 1, subscription?.nbCollaborators);
        const submitDisabled = userBaseProjectsToModify.length > 0 && !ubpToRemove && this.props.isOnline ? false : true;
        const invitations = this.props.projectInvitations || [];
        const organizationName = this.state.project?.organization.label;
        const isOwner = owner?.id === jwtDecode(new Cookies().get('token')).id;
        const canAddToOrganization = isOwner && subscription && (subscription.nbUsers === -1 || subscription.nbUsers > 1);
        const defaultRole = this.state.project?.projectRoles.find(pr => pr.type === 'default');
        const roleOptions = this.state.project?.projectRoles
            .filter(projectRole => projectRole.type !== 'owner')
            .map(projectRole => ({ text: <span><FontAwesomeIcon icon={projectRole.icon} style={{ marginRight: '5px' }} />{projectRole.label}</span>, value: projectRole.id }));

        return (
            <>
                <div className='modal-content'>
                    <div className='modal-content-body'>
                        <Dimmer active={ubpToRemove && !isDeleting} style={StylesUtil.getMapStyles().dimmerStyle}>
                            <Message compact className='tableConfirmation'>
                                <Message.Header>{i18n.t("Êtes-vous certain de vouloir supprimer ce collaborateur ?")}</Message.Header>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Button color='grey' onClick={() => this.setState({ ubpToRemove: null })}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button color='red' onClick={() => this.handleRemoveCollaborator(ubpToRemove)}>
                                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Dimmer>
                        <Segment>
                            <Form onSubmit={() => !isMaxCollaboratorsReached && this.handleCollaboratorsSubmit()}>
                                <Form.Field
                                    control={Select} label={`${i18n.t("Ajouter des collaborateurs")} :`} placeholder={i18n.t("Saisissez des adresses mails")}
                                    name='member' options={this.state.collaboratorsOptions} value={this.state.collaboratorsToAdd || ''}
                                    selectOnBlur={false} multiple selection search={FormattersUtil.searchList} allowAdditions open={false} // Pour en faire un input multiple
                                    searchQuery={this.state.searchQuery} disabled={isMaxCollaboratorsReached || loginAsData?.readOnly}
                                    onSearchChange={(_, { searchQuery }) => this.setState({ searchQuery: searchQuery })} // On sauvegarde la recherche dans le state
                                    onChange={this.handleChange} onKeyUp={this.handleCollaboratorsChange}
                                />
                                <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', alignItems: 'center', gap: '10px', margin: '10px 0' }}>
                                    <Form.Field
                                        control={Select} width={3} label={i18n.t("Rôle") + ' :'} selectOnBlur={false} options={roleOptions} value={projectRoleId} disabled={loginAsData?.readOnly}
                                        onChange={(_, { value }) => this.setState({ projectRoleId: value, projectRoleExpirationDate: defaultRole?.id !== value ? projectRoleExpirationDate : null })}
                                    />
                                    <div style={{ paddingTop: '2px' }}>
                                        <DatePicker
                                            label={`${i18n.t("Date d'expiration")} :`} name='projectRoleExpirationDate' value={projectRoleExpirationDate} minDate={new Date()}
                                            disabled={defaultRole?.id === projectRoleId} onChange={(_, { value }) => this.setState({ projectRoleExpirationDate: value })}
                                        />
                                    </div>
                                </div>
                                {isOwner &&
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Ajouter les utilisateurs à votre organisation '{{organizationName}}'", { organizationName })}
                                        disabled={!canAddToOrganization || loginAsData?.readOnly} checked={addToOrganization} onChange={() => this.setState({ addToOrganization: canAddToOrganization && !addToOrganization })} style={{ marginTop: '5px' }}
                                    />}
                                <Button
                                    className='form-button' type='submit' color='blue' id='wGL6bOsX' loading={isAddingCollaborator}
                                    disabled={isMaxCollaboratorsReached || isAddingCollaborator || !this.props.isOnline || loginAsData?.readOnly} style={{ marginTop: '5px' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Ajouter")}
                                </Button>
                                <Label pointing={isMobile ? 'above' : 'left'} style={isMobile ? { textAlign: 'center', width: '100%' } : null}>
                                    {i18n.t("Les utilisateurs possédant déjà un compte seront automatiquement ajoutés, les autres recevront une invitation par mail.")}
                                </Label>
                                {invitedUsers.length > 0 &&
                                    <Message info
                                        onDismiss={() => this.setState({ invitedUsers: [] })}
                                        header={`${i18n.t("Les utilisateurs suivants ne possèdent pas de compte Grality et ont donc été invités par email")} :`}
                                        list={invitedUsers}
                                    />}
                            </Form>
                            {mailsNotAddedToOrganization.length > 0 && <Message warning header={i18n.t("Échec de l'ajout à la licence")} list={mailsNotAddedToOrganization} onDismiss={() => this.setState({ mailsNotAddedToOrganization: [] })} />}
                        </Segment>
                        {invitations.length > 0 &&
                            <div style={{ marginTop: '20px' }}>
                                <h3>{i18n.t("Utilisateurs invités")}</h3>
                                <Segment>
                                    {this.state.project && this.renderInvitedUsers()}
                                </Segment>
                            </div>}
                        {this.props.userProjects?.filter(x => x.projectRole.type !== 'owner' && x.user.id !== jwtDecode(new Cookies().get('token')).id).length > 0 &&
                            <div style={{ marginTop: '20px' }}>
                                <h3>{i18n.t("Collaborateurs")}</h3>
                                <Segment>
                                    <Dimmer active={isChangingRoles} style={StylesUtil.getMapStyles().dimmerStyle}>
                                        <Loader />
                                    </Dimmer>
                                    {this.state.project && this.renderCollaborators(roleOptions, canAddToOrganization, defaultRole)}
                                    <Button
                                        className='form-button' color='green' id='S68xMrcq' disabled={submitDisabled || loginAsData?.readOnly}
                                        onClick={() => this.handleChangeRoleSubmit()}
                                    >
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </Segment>
                            </div>}
                    </div>
                </div>
                <ContextMenu
                    id='collaborators-context-menu'
                    items={[
                        {
                            icon: faClipboard,
                            label: i18n.t("Copier l'email"),
                            onClick: () => this.copyEmailToClipboard(this.contextMenuElement.user.email)
                        },
                        {
                            icon: faTrashAlt,
                            label: i18n.t("Supprimer"),
                            className: 'delete',
                            onClick: () => this.setState({ ubpToRemove: this.contextMenuElement.userId })
                        }
                    ]}
                />
                <ContextMenu
                    id='invited-users-context-menu'
                    items={this.getInvitedUsersContextMenuItems()}
                />
            </>
        );
    }

    componentDidMount = () => {
        this.setState({ project: this.props.projectToEdit, projectRoleId: this.props.projectToEdit.projectRoles?.find(role => role.type === 'default')?.id }, () => {
            if (this.props.isOnline) ProjectsUtil.loadMissingEntities(this.state.project, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects, ['projectInvitations']);
        });
    }

    componentDidUpdate = (prevPops) => {
        const projectToEdit = this.props.projectToEdit;
        if (projectToEdit && prevPops.projectToEdit && JSON.stringify(projectToEdit?.userBaseProjects) !== JSON.stringify(prevPops.projectToEdit?.userBaseProjects)) {
            const baseProject = ProjectsUtil.getBaseProject(projectToEdit.id, this.props.projects);
            if (baseProject?.path) {
                const ids = baseProject.path.split('/').filter(id => id.length).map(id => parseInt(id));
                let userBaseProjects = [];
                let path;
                ids.forEach(id => {
                    const bp = ProjectsUtil.getBaseProject(id, this.props.projects);
                    if (bp?.userBaseProjects?.length)
                        bp.userBaseProjects.forEach(userBaseProject => {
                            if (!userBaseProjects.find(ubp => ubp.userId === userBaseProject.userId && ubp.baseProjectId === userBaseProject.baseProjectId))
                                userBaseProjects.push(userBaseProject);
                        });
                    path = path ? `${path}${id}/` : `/${id}/`;
                });
                this.props.setUserProjects(userBaseProjects);
            } else if (JSON.stringify(projectToEdit?.userBaseProjects) !== JSON.stringify(baseProject?.userBaseProjects)) {
                projectToEdit.userBaseProjects = baseProject.userBaseProjects;
                this.props.setUserProjects(baseProject.userBaseProjects);
            }
        }
    }

    componentWillUnmount = () => {
        const { project } = this.state;
        const userProject = this.props.userProjects?.find(up => up.userId === jwtDecode(new Cookies().get('token')).id);
        if (!project.parentFolderId || this.props.projectShortcut || userProject?.baseProjectId === project.id) {
            this.props.setUserProjects(null);
            this.props.setProjectInvitations(null);
        }
        else {
            if (this.props.userProjects) this.props.setUserProjects(this.props.userProjects.filter(up => up.baseProjectId !== project.id));
            if (this.props.projectInvitations) this.props.setProjectInvitations(this.props.projectInvitations.filter(pi => pi.baseProjectId !== project.id));
        }
    }

    renderCollaborators = (roleOptions, canAddToOrganization, defaultRole) => {
        const { loginAsData, isDarkTheme } = this.props;
        // const userBaseProjectToModify = this.state.userBaseProjectsToModify.find(x => x.userId === userProject.user.id); // En cours de modif

        const { isDeleting, ubpToRemove, project, userBaseProjectsToModify } = this.state;
        const userProjects = this.props.userProjects.filter(up => !up.deletionDate && up.user.id !== jwtDecode(new Cookies().get('token')).id && up.projectRole.type !== 'owner');
        return (
            <Grid divided='vertically' inverted={isDarkTheme} className='custom-grid' style={{ marginBottom: '14px' }}>
                <Grid.Row className='headers' columns={isMobileOnly ? 1 : 5}>
                    <Grid.Column>{i18n.t("Collaborateurs ({{nbCollaborators}})", { nbCollaborators: userProjects.length })}</Grid.Column>
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Email")}</Grid.Column>}
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Rôle")}</Grid.Column>}
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Expiration du rôle")}</Grid.Column>}
                </Grid.Row>
                {userProjects.map(userProject => {
                    const { user } = userProject;
                    const userBaseProjectToModify = userBaseProjectsToModify.find(up => up.userId === user.id);
                    const projectRole = userBaseProjectToModify?.projectRole || userProject.projectRole;
                    const projectRoleExpirationDate = userBaseProjectToModify ? userBaseProjectToModify.projectRoleExpirationDate : userProject.projectRoleExpirationDate ? DatesUtil.convertUTCDateToDate(userProject.projectRoleExpirationDate) : null;
                    const isAddedToProject = project.userBaseProjects?.find(ubp => ubp.userId === userProject.userId && ubp.baseProjectId === project.id);
                    const isDisabled = !isAddedToProject || !this.props.isOnline || loginAsData?.readOnly;
                    const isAddToOrganizationEnabled = canAddToOrganization && !this.props.activeOrganization.linkedUsers.find(lu => lu.id === userProject.user.id) && this.props.isOnline && !loginAsData?.readOnly;

                    return (
                        <Grid.Row key={userProject.user.id} columns={isMobileOnly ? 1 : 5} style={{ display: isMobileOnly && 'flex' }} onContextMenu={(event) => this.handleCollaboratorContextMenu(event, userProject)}>
                            <Dimmer active={isDeleting && ubpToRemove === userProject.userId} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Loader />
                            </Dimmer>
                            <Grid.Column style={{ justifyContent: 'flex-start', textAlign: 'left', margin: '7px 0' }}>
                                <FontAwesomeIcon icon={projectRole.icon} style={{ marginLeft: '3px', marginRight: '10px', color: projectRole.color }} />
                                {FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)}
                                {isMobileOnly && this.renderCollaboratorOptions(userProject)}
                            </Grid.Column>
                            {!isMobileOnly &&
                                <Grid.Column style={{ textAlign: 'center', margin: '7px 0' }}>
                                    {user.email}
                                </Grid.Column>}
                            <Grid.Column style={{ textAlign: 'center', margin: '7px 0' }}>
                                <Form style={{ width: '100%' }}>
                                    <Form.Field
                                        control={Select} selectOnBlur={false}
                                        options={isAddedToProject ? roleOptions : [{ text: projectRole.label, value: projectRole.id }]} value={projectRole.id}
                                        onChange={(_, { value }) => this.handleRoleChange(user.id, value)} disabled={isDisabled}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'center', margin: '7px 0' }}>
                                <DatePicker
                                    name='projectRoleExpirationDate' value={projectRoleExpirationDate} disabled={defaultRole?.id === projectRole.id}
                                    minDate={new Date()} onChange={(_, e) => this.handleProjectRoleExpirationDateChange(userProject.userId, e)}
                                    inputStyle={{ border: '1px solid var(--grey-100)', borderRadius: '5px' }}
                                />
                            </Grid.Column>
                            {!isMobileOnly &&
                                <Grid.Column style={{ textAlign: 'right', justifyContent: 'flex-end', margin: '7px 0' }}>
                                    {this.renderCollaboratorOptions(userProject, isAddToOrganizationEnabled, isDisabled)}
                                </Grid.Column>}
                        </Grid.Row>
                    );
                })}
            </Grid>
        );
    }

    renderInvitedUsers = () => {
        const { isOnline, loginAsData, isDarkTheme } = this.props;
        const { isDeleting, invitationToRemove } = this.state;
        const baseProjectId = this.state.project?.id;
        const invitations = this.props.projectInvitations || [];
        const projectRoles = this.state.project?.projectRoles;

        return (
            <Grid divided='vertically' inverted={isDarkTheme} className='custom-grid'>
                <Grid.Row className='headers' columns={5}>
                    <Grid.Column>{i18n.t("Email ({{nbEmails}})", { nbEmails: invitations.length })}</Grid.Column>
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Invité le")}</Grid.Column>}
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Rôle")}</Grid.Column>}
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Ajouté à l'organisation")}</Grid.Column>}
                </Grid.Row>
                {invitations.map(invitation => {
                    const canResend = DatesUtil.daysBetweenTwoDates(new Date(), DatesUtil.convertUTCDateToDate(invitation.date)) >= 1 || !loginAsData?.readOnly;
                    const projectRole = projectRoles?.find(pr => pr.id === invitation.projectRoleId) || projectRoles?.find(pr => pr.type === 'default');

                    return (
                        <Grid.Row key={invitation.id} columns={isMobileOnly ? 2 : 5} onContextMenu={(event) => this.handleInvitedUsersContextMenu(event, invitation)}>
                            <Dimmer active={isDeleting && invitationToRemove === invitation.id} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Loader />
                            </Dimmer>
                            <Grid.Column style={{ justifyContent: 'flex-start', textAlign: 'left', margin: '7px 0' }}>
                                {invitation.email}
                            </Grid.Column>
                            {!isMobileOnly &&
                                <Grid.Column style={{ textAlign: 'center', margin: '7px 0' }}>
                                    {DatesUtil.convertUTCDateToLocaleDate(invitation.date)}
                                </Grid.Column>}
                            <Grid.Column style={{ textAlign: 'center', margin: '7px 0' }}>
                                {projectRole && <>
                                    <FontAwesomeIcon icon={projectRole.icon} style={{ marginRight: '5px' }} />
                                    {projectRole.label}
                                </>}
                            </Grid.Column>
                            {!isMobileOnly &&
                                <Grid.Column style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={invitation.organizationId ? faCheck : faTimes} color={invitation.organizationId ? 'green' : 'red'} />
                                </Grid.Column>}
                            <Grid.Column style={{ textAlign: 'right', justifyContent: 'flex-end', margin: '7px 0' }}>
                                <Dropdown icon='ellipsis vertical' floating button direction='left' className='icon' style={{ padding: '7px' }} onClick={() => contextMenu.hideAll()}>
                                    <Dropdown.Menu>
                                        <Dropdown.Menu scrolling>
                                            <Dropdown.Item disabled={!canResend || !isOnline || loginAsData?.readOnly} onClick={() => this.handleResendInvitation(invitation)}>
                                                <FontAwesomeIcon icon={!canResend ? faEnvelopeCircleCheck : faRotateRight} style={{ marginRight: '12px' }} />{i18n.t("Renvoyer l'invitation")}
                                            </Dropdown.Item>
                                            <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '0' }} />
                                            <Dropdown.Item className='delete' disabled={invitation.baseProjectId !== baseProjectId || !isOnline || loginAsData?.readOnly} onClick={() => this.handleRemoveInvitation(invitation)}>
                                                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '12px' }} />{i18n.t("Supprimer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
            </Grid>
        );
    }

    renderCollaboratorOptions = (userProject, isAddToOrganizationEnabled, isDisabled) => {
        const organizationName = this.state.project?.organization.label;

        return (
            <Dropdown icon='ellipsis vertical' floating button direction='left' className='icon' style={{ padding: '7px', marginLeft: 'auto' }} onClick={() => contextMenu.hideAll()}>
                <Dropdown.Menu>
                    <Dropdown.Menu scrolling>
                        <Dropdown.Item onClick={() => this.copyEmailToClipboard(userProject.user.email)}>
                            <FontAwesomeIcon icon={faClipboard} style={{ marginRight: '12px' }} />{i18n.t("Copier l'email")}
                        </Dropdown.Item>
                        {isAddToOrganizationEnabled &&
                            <Dropdown.Item onClick={() => this.addCollaboratorToOrgnization(userProject)}>
                                <FontAwesomeIcon icon={faDollarCircle} style={{ marginRight: '12px' }} />{i18n.t("Ajouter à l'organisation '{{organizationName}}'", { organizationName })}
                            </Dropdown.Item>}
                        {!isDisabled &&
                            <>
                                <Dropdown.Divider style={{ backgroundColor: this.props.isDarkTheme && 'var(--black-80)', margin: '0' }} />
                                <Dropdown.Item className='delete' onClick={() => this.setState({ ubpToRemove: userProject.userId })}>
                                    <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '12px' }} />{i18n.t("Supprimer")}
                                </Dropdown.Item>
                            </>}
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    handleChange = (_, { value }) => { // Permet de gérer le 'remove' de l'input d'ajout
        this.setState({
            collaboratorsToAdd: value,
            collaboratorsOptions: value.map(x => ({ text: x, value: x }))
        });
    }

    handleRoleChange = (userId, projectRoleId) => {
        let userBaseProjectsToModify = this.state.userBaseProjectsToModify;
        const index = userBaseProjectsToModify.findIndex(x => x.userId === userId);

        const defaultRole = this.state.project?.projectRoles.find(pr => pr.type === 'default');
        const userBaseProject = JSON.parse(JSON.stringify(this.props.userProjects.find(up => up.user.id === userId)));
        const projectRole = this.state.project.projectRoles.find(projectRole => projectRole.id === projectRoleId);
        if (index !== -1) {
            userBaseProjectsToModify[index] = { ...userBaseProjectsToModify[index], projectRoleId, projectRole, projectRoleExpirationDate: defaultRole?.id !== projectRoleId ? this.state.projectRoleExpirationDate : null };
            this.setState(prevState => ({
                userBaseProjectsToModify: projectRoleId === userBaseProject.projectRoleId && userBaseProjectsToModify[index].projectRoleExpirationDate === userBaseProject.projectRoleExpirationDate
                    ? prevState.userBaseProjectsToModify.filter(up => up.userId !== userId)
                    : userBaseProjectsToModify
            }));
        } else this.setState(prevState => ({
            userBaseProjectsToModify: [...prevState.userBaseProjectsToModify, { ...userBaseProject, projectRoleId, projectRole, projectRoleExpirationDate: defaultRole?.id !== projectRoleId ? this.state.projectRoleExpirationDate : null }]
        }));
    }

    handleCollaboratorsChange = (e) => {
        if (e.keyCode === 32) { // Lorsqu'on appuie sur 'espace'
            if (FormattersUtil.checkEmail(this.state.searchQuery))
                this.setState(prevState => ({
                    searchQuery: '', // On réinitialise la recherche
                    // On ajoute la recherche dans les options et dans les valeurs sélectionnées
                    collaboratorsToAdd: prevState.collaboratorsToAdd.find(x => x === prevState.searchQuery)
                        ? prevState.collaboratorsToAdd
                        : [...prevState.collaboratorsToAdd, prevState.searchQuery],
                    collaboratorsOptions: prevState.collaboratorsOptions.find(x => x.text === prevState.searchQuery)
                        ? prevState.collaboratorsOptions
                        : [...prevState.collaboratorsOptions, { text: prevState.searchQuery, value: prevState.searchQuery }]
                }));
            else showToast('email_not_valid');
        }
    }

    handleProjectRoleExpirationDateChange = (userId, { name, value }) => {
        let userBaseProjectsToModify = JSON.parse(JSON.stringify(this.state.userBaseProjectsToModify));

        if (value && value.getFullYear().toString().length !== 4) value = null;
        const index = userBaseProjectsToModify.findIndex(ubptm => ubptm.userId === userId);
        const userBaseProject = JSON.parse(JSON.stringify(this.props.userProjects.find(up => up.user.id === userId)));
        if (index !== -1) {
            if (userBaseProject.projectRoleExpirationDate === value && userBaseProject.projectRoleId === userBaseProjectsToModify[index].projectRoleId)
                userBaseProjectsToModify = userBaseProjectsToModify.filter(ubptm => ubptm.userId !== userBaseProject.userId);
            else userBaseProjectsToModify[index] = { ...userBaseProjectsToModify[index], projectRoleExpirationDate: value };
        } else userBaseProjectsToModify = [...userBaseProjectsToModify, { ...userBaseProject, projectRoleExpirationDate: value }];
        this.setState({ userBaseProjectsToModify });
    }

    handleCollaboratorsSubmit = () => {
        const { project, addToOrganization, projectRoleId, projectRoleExpirationDate, searchQuery } = this.state;
        let collaboratorsToAdd = this.state.collaboratorsToAdd, nbCollaboratorsToAdd = 0;
        if (FormattersUtil.checkEmail(this.state.searchQuery)) collaboratorsToAdd = [...collaboratorsToAdd, searchQuery];
        nbCollaboratorsToAdd = collaboratorsToAdd.length;
        collaboratorsToAdd = collaboratorsToAdd.filter(cta => !this.props.userProjects.find(up => up.user.email.toUpperCase() === cta.toUpperCase()));
        if (collaboratorsToAdd.length > 0) {
            this.setState({ isAddingCollaborator: true });
            ProjectsService.addCollaborators({ baseProjectId: project.id, mails: collaboratorsToAdd, addToOrganization, projectRoleId, projectRoleExpirationDate }).then(response => {
                const { invitedUsers, addedUserBaseProjects, removedUserBaseProjects, mailsNotAddedToOrganization } = response;
                project.invitations = project.invitations || [];
                const invitedEmails = [];
                if (invitedUsers?.length)
                    invitedUsers.forEach(invitedUser => {
                        if (!project.invitations.find(i => i.email === invitedUser.email)) {
                            project.invitations.push({ ...invitedUser, sent: true });
                            invitedEmails.push(invitedUser.email);
                        }
                    });
                project.userBaseProjects = [...(project.userBaseProjects || []), ...addedUserBaseProjects];
                addedUserBaseProjects.forEach(ubp => {
                    const userBaseProject = JSON.parse(JSON.stringify(ubp));
                    delete userBaseProject.projectRole;
                    const projectRole = project.projectRoles.find(projectRole => projectRole.id === userBaseProject.projectRoleId);
                    if (projectRole) {
                        if (projectRole.userBaseProjects) projectRole.userBaseProjects.push(userBaseProject);
                        else projectRole.userBaseProjects = [userBaseProject];
                    }
                });
                const baseProjectIds = [...new Set(removedUserBaseProjects.map(ubp => ubp.baseProjectId))];
                baseProjectIds.forEach(baseProjectId => {
                    const path = removedUserBaseProjects.find(rubp => rubp.baseProjectId === baseProjectId)?.baseProject?.path;
                    let baseProject = ProjectsUtil.getBaseProject(baseProjectId, this.props.projects);
                    baseProject.userBaseProjects = baseProject.userBaseProjects.filter(ubp => !removedUserBaseProjects.find(rubp => rubp.userId === ubp.userId));
                });

                let usersToAlert = this.props.userProjects
                    .filter(up => up.user.id !== jwtDecode(new Cookies().get('token')).id && !collaboratorsToAdd.find(cta => cta.toLowerCase() === up.user.email.toLowerCase()))
                    .map(up => up.user.id);
                WebSocketUtil.updateUserBaseProjects(this.props.webSocketHubs, usersToAlert, this.props.userProjects, project.path);
                usersToAlert = project.userBaseProjects.filter(up => collaboratorsToAdd.find(cta => cta.toLowerCase() === up.user.email.toLowerCase())).map(up => up.user.id);
                project.theme = ProjectsUtil.getBaseProject(project.id, this.props.projects)?.theme;

                let projectToSend = JSON.parse(JSON.stringify(project));
                projectToSend.userBaseProjects = [...(this.props.userProjects || []), ...projectToSend.userBaseProjects];
                WebSocketUtil.sendProject(this.props.webSocketHubs, usersToAlert, projectToSend);
                this.props.setUserProjects([...this.props.userProjects.filter(up => !removedUserBaseProjects.find(rubp => rubp.userId === up.userId)), ...addedUserBaseProjects]);
                if (this.props.projectShortcut || this.props.project?.id === project.id || this.props.project?.path.includes(project.id.toString()))
                    this.props.setProjectCollaborators([...this.props.projectCollaborators, ...addedUserBaseProjects]);
                this.props.setProjectInvitations([...(this.props.projectInvitations || []).filter(pi => !invitedUsers.find(iu => iu.email === pi.email)), ...(invitedUsers)]);

                // Ajout à l'organisation en local
                const owner = this.props.userProjects?.find(x => x.projectRole.type === 'owner').user;
                const isOwner = owner?.id === jwtDecode(new Cookies().get('token')).id;
                const organizationId = project.organization?.id;
                if (isOwner && organizationId && addToOrganization && organizationId === this.props.activeOrganization.id) {
                    let linkedUsersToAdd = [], invitationsToAdd = [];
                    if (addedUserBaseProjects.length)
                        linkedUsersToAdd = addedUserBaseProjects
                            .filter(ubp => ubp.user.userOrganizations.find(uo => uo.organizationId === organizationId)
                                && !this.props.activeOrganization.linkedUsers.find(lu => lu.id === ubp.userId))
                            .map(ubp => ({ balance: 0, email: ubp.user.email, id: ubp.userId, isOwner: false, lastName: ubp.user.lastName, firstName: ubp.user.firstName, userName: ubp.user.userName }));

                    if (invitedUsers.length)
                        invitationsToAdd = invitedUsers.filter(iu => iu.organizationId === organizationId && !this.props.activeOrganization.invitations.find(i => i.email === iu.email));

                    this.props.setActiveOrganization({
                        ...this.props.activeOrganization,
                        linkedUsers: [...this.props.activeOrganization.linkedUsers, ...linkedUsersToAdd],
                        invitations: [...this.props.activeOrganization.invitations, ...invitationsToAdd]
                    });
                }


                const mailsWithMessage = [];
                for (const [key, value] of Object.entries(mailsNotAddedToOrganization || {}))
                    mailsWithMessage.push(`${key} - ${value}`);

                this.setState({
                    project,
                    collaboratorsToAdd: [],
                    collaboratorsOptions: [],
                    searchQuery: '',
                    invitedUsers: invitedEmails,
                    mailsNotAddedToOrganization: mailsWithMessage,
                    isAddingCollaborator: false
                }, () => {
                    ProjectsUtil.updateProjectsInProps(project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
                });
            });
        } else if (nbCollaboratorsToAdd > collaboratorsToAdd.length) {
            showToast('collaborators_not_added');
            this.setState({
                collaboratorsToAdd: [],
                collaboratorsOptions: [],
                searchQuery: '',
                invitedUsers: []
            });
        }
    }

    handleRemoveCollaborator = (userId) => {
        const projectId = this.state.project.id;
        this.setState({ isDeleting: true });
        ProjectsService.removeCollaborator(projectId, userId).then(() => {
            let baseProject = this.state.project;
            baseProject.themeId = 0;
            baseProject.theme = null;
            baseProject.userBaseProjects = baseProject.userBaseProjects.filter(ubp => ubp.userId !== userId);
            if (baseProject && this.props.project?.id === baseProject?.id) this.props.setProject(baseProject);

            let usersToAlert = this.props.userProjects.map(up => up.user.id).filter(id => id !== jwtDecode(new Cookies().get('token')).id && id !== userId);
            WebSocketUtil.updateUserBaseProjects(this.props.webSocketHubs, usersToAlert, this.props.userProjects.filter(x => x.user.id !== userId), baseProject.path);
            WebSocketUtil.removeProject(this.props.webSocketHubs, [userId], projectId, baseProject.path);

            const lockedElements = JSON.parse(JSON.stringify(this.props.lockedElements));
            if (lockedElements.find(lep => lep.userId === userId)) this.props.setLockedElements(lockedElements.filter(lep => lep.userId !== userId));
            this.props.setUserProjects(this.props.userProjects.filter(up => up.userId !== userId));
            if (this.props.projectShortcut || this.props.project?.id === this.state.project.id || this.props.project?.path.includes(this.state.project.id.toString()))
                this.props.setProjectCollaborators(this.props.projectCollaborators.filter(pc => pc.userId !== userId));

            this.setState(prevState => {
                const userBaseProjects = prevState.project.userBaseProjects.filter(x => x.user.id !== userId);
                return {
                    project: { ...prevState.project, userBaseProjects: userBaseProjects },
                    ubpToRemove: null, isDeleting: false
                };
            }, () => ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject));
        });
    }

    handleRemoveInvitation = (invitation) => {
        const projectId = this.state.project.id;
        if (invitation.baseProjectId !== projectId) return;

        const email = invitation.email;
        this.setState({ isDeleting: true, invitationToRemove: invitation.id });
        ProjectsService.removeInvitation(projectId, email).then(() => {
            let baseProject = this.state.project;
            baseProject.invitations = baseProject.invitations.filter(i => i.email !== email);
            if (baseProject && this.props.project?.id === baseProject?.id) this.props.setProject(baseProject);

            const usersToAlert = this.props.userProjects.map(up => up.user.id).filter(id => id !== jwtDecode(new Cookies().get('token')).id);
            WebSocketUtil.updateProject(this.props.webSocketHubs, usersToAlert, baseProject);
            this.props.setProjectInvitations(this.props.projectInvitations.filter(pi => pi.email !== invitation.email));

            // Suppression de la licence en local
            const owner = this.props.userProjects?.find(x => x.projectRole.type === 'owner').user;
            const isOwner = owner?.id === jwtDecode(new Cookies().get('token')).id;

            if (isOwner && invitation.organizationId && this.state.project.organizationId === this.props.activeOrganization.id) {
                this.props.setActiveOrganization({
                    ...this.props.activeOrganization,
                    invitations: this.props.activeOrganization.invitations.filter(i => i.email !== invitation.email)
                });
            }

            this.setState(prevState => {
                const invitations = prevState.project.invitations.filter(i => i.email !== email);
                return {
                    project: { ...prevState.project, invitations },
                    isDeleting: false, invitationToRemove: null
                };
            }, () => ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject));
        });
    }

    handleResendInvitation = (invitation) => {
        const baseProject = this.state.project;
        ProjectsService.sendInvitation(invitation.baseProjectId, invitation.email).then((response) => {
            const projectInvitations = JSON.parse(JSON.stringify(this.props.projectInvitations || []));
            if (baseProject && this.props.project?.id === baseProject?.id) this.props.setProject(baseProject);
            let invitationToUpdate = projectInvitations.find(pi => pi.baseProjectId === invitation.baseProjectId && pi.email === invitation.email);
            if (invitationToUpdate) {
                invitationToUpdate.date = response.date;
                this.props.setProjectInvitations(projectInvitations);
            }

            this.setState({
                project: baseProject
            }, () => ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject));
        });
    }

    handleChangeRoleSubmit = () => {
        const { project } = this.state;
        let userBaseProjectsToModify = JSON.parse(JSON.stringify(this.state.userBaseProjectsToModify));

        if (userBaseProjectsToModify.length > 0) {
            const userBaseProjects = project.userBaseProjects;
            const userProjects = JSON.parse(JSON.stringify(this.props.userProjects));
            const projectCollaborators = JSON.parse(JSON.stringify(this.props.projectCollaborators));

            this.setState({ isChangingRoles: true });
            userBaseProjectsToModify = userBaseProjectsToModify.map(ubptm => ({ ...ubptm, projectRoleExpirationDate: ubptm.projectRoleExpirationDate ? DatesUtil.getUTCDate(ubptm.projectRoleExpirationDate) : null }))

            ProjectsService.changeUserRoles(project.id, userBaseProjectsToModify).then(() => {
                userBaseProjectsToModify.forEach(userBaseProjectToModify => {
                    const userBaseProject = userBaseProjects.find(ubp => ubp.userId === userBaseProjectToModify.userId);
                    if (userBaseProject) {
                        userBaseProject.projectRoleId = userBaseProjectToModify.projectRoleId;
                        userBaseProject.projectRole = userBaseProjectToModify.projectRole;
                    }

                    const userProject = userProjects?.find(up => up.userId === userBaseProjectToModify.userId);
                    if (userProject) {
                        userProject.projectRoleId = userBaseProjectToModify.projectRoleId;
                        userProject.projectRole = userBaseProjectToModify.projectRole;
                    }

                    const projectCollaborator = projectCollaborators?.find(pc => pc.userId === userBaseProjectToModify.userId);
                    if (projectCollaborator) {
                        projectCollaborator.projectRoleId = userBaseProjectToModify.projectRoleId;
                        projectCollaborator.projectRole = userBaseProjectToModify.projectRole;
                    }
                });

                const usersToAlert = this.props.userProjects.map(up => up.user.id).filter(id => id !== jwtDecode(new Cookies().get('token')).id);
                WebSocketUtil.updateProject(this.props.webSocketHubs, usersToAlert, project);

                this.props.setUserProjects(userProjects);
                if (this.props.projectShortcut || this.props.project?.id === this.state.project.id || this.props.project?.path.includes(this.state.project.id.toString()))
                    this.props.setProjectCollaborators(projectCollaborators);
                this.setState({ project: { ...this.state.project, userBaseProjects }, userBaseProjectsToModify: [], isChangingRoles: false }, () => {
                    ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject)
                });
            });
        }
    }

    addCollaboratorToOrgnization = (userProject) => {
        OrganizationsService.addUsersToOrganization([userProject.user.email], this.state.project?.organizationId).then(response => {
            if (response?.addedUsers)
                this.props.setActiveOrganization({
                    ...this.props.activeOrganization,
                    linkedUsers: [...this.props.activeOrganization.linkedUsers, ...(response.addedUsers)]
                });


            const mailsWithMessage = [];
            for (const [key, value] of Object.entries(response?.notAddedUsers || {}))
                mailsWithMessage.push(`${key} - ${value}`);
            this.setState({
                searchQuery: '',
                mailsNotAddedToOrganization: mailsWithMessage
            });
        });
    }

    copyEmailToClipboard = (email) => {
        navigator.clipboard.writeText(email);
        showToast('email_copied');
    }

    handleCollaboratorContextMenu = (event, projectRole) => {
        this.contextMenuElement = projectRole;
        contextMenu.show({ event, id: 'collaborators-context-menu' });
    }

    handleInvitedUsersContextMenu = (event, invitation) => {
        this.contextMenuElement = invitation;
        contextMenu.show({ event, id: 'invited-users-context-menu' });
        this.forceUpdate();
    }

    getInvitedUsersContextMenuItems = () => {
        const { isOnline, loginAsData } = this.props;
        const invitation = this.contextMenuElement;
        if (!invitation) return [];
        const canResend = DatesUtil.daysBetweenTwoDates(new Date(), DatesUtil.convertUTCDateToDate(invitation.date)) >= 1 || !loginAsData?.readOnly;
        return [
            {
                icon: !canResend ? faEnvelopeCircleCheck : faRotateRight,
                label: i18n.t("Renvoyer l'invitation"),
                isDisabled: () => !canResend || !isOnline || loginAsData?.readOnly,
                onClick: () => this.handleResendInvitation(invitation)
            },
            {
                icon: faTrashAlt,
                label: i18n.t("Supprimer"),
                className: 'delete',
                onClick: () => this.handleRemoveInvitation(invitation)
            }
        ];
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        project: state.project,
        projects: state.projects,
        isDarkTheme: state.isDarkTheme,
        formulas: state.formulas,
        lockedElements: state.lockedElements,
        userProjects: state.userProjects,
        projectCollaborators: state.projectCollaborators,
        projectShortcut: state.projectShortcut,
        projectInvitations: state.projectInvitations,
        activeOrganization: state.activeOrganization,
        isOnline: state.isOnline,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setProjects,
    setProject,
    setLockedElements,
    setUserProjects,
    setProjectCollaborators,
    setProjectInvitations,
    setActiveOrganization
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsForm);