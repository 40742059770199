import React, { Component } from 'react';
// Composants
import { Bar, Line } from 'react-chartjs-2';
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';
import DatePickerWithPeriod from '../../Utils/DatePickerWithPeriod';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
import axios from 'axios';
import { addDays, addMonths, addWeeks, addYears, endOfDay, endOfMonth, endOfWeek, endOfYear, format, startOfDay, startOfMonth, startOfWeek, startOfYear, subWeeks } from 'date-fns';
import tinycolor from 'tinycolor2';
// Services
import StatisticsService from '../../../services/StatisticsService';
import SubscriptionsService from '../../../services/SubscriptionsService';
// Utils
import DatesUtil from '../../../utils/DatesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';

class ElementsStatistics extends Component {
    state = {
        isLoading: true,
        subscriptions: [],
        histories: [],
        data: null,
        options: null
    }

    render() {
        const { isLoading, data, options } = this.state;
        const { period, startDate, endDate, settings } = this.props;
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;

        return (
            <div className='modal-content'>
                <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePickerWithPeriod hideLabel={true} period={period} startDate={startDate} endDate={endDate} maxDate={new Date()} forceTime={true} setDates={this.setDates} setPeriod={this.setPeriod} />
                    <Dropdown floating button icon='tv' title={i18n.t("Affichage")} className='icon button--secondary'>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Global"), settings.display, 'global', checkIcon, null)}
                                onClick={() => this.setState({ isLoading: true }, () => this.props.updateSettings('display', 'global').then(this.loadData))}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Détaillé"), settings.display, 'detail', checkIcon, null)}
                                onClick={() => this.setState({ isLoading: true }, () => this.props.updateSettings('display', 'detail').then(this.loadData))}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='modal-content-body'>
                    {isLoading &&
                        <Dimmer active style={{ position: 'absolute', top: 0, left: 0 }}>
                            <Loader inverted active>{i18n.t("Chargement en cours...")}</Loader>
                        </Dimmer>}
                    {data && options ?
                        <>
                            {settings.display !== 'global'
                                ? <Bar data={data} options={options} />
                                : <Line data={data} options={{ ...options }} />}
                        </>
                        : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const { startDate, endDate, period } = this.props;
        this.arrowClick = false;
        this.props.setDatesAndPeriod(null, null, period).then(() => {
            this.setState({ options: this.getOptions() }, () => this.updateChart([], startDate, endDate, period, true));
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((!prevState.data && this.state.data) || prevProps.category !== this.props.category)
            this.loadData();
        if (this.props.category === 'subscriptions' && !this.state.subscriptions.length)
            SubscriptionsService.getAllSubscriptions().then(subscriptions => this.setState({ subscriptions: subscriptions.sort((a, b) => (a.yearlyPrice - b.yearlyPrice)) }));
    }

    componentWillUnmount = () => {
        if (this.cancelTokenSource) this.cancelTokenSource.cancel();
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    setDates = (startDate, endDate, arrowClick = false) => {
        this.arrowClick = arrowClick;
        if (!startDate || !endDate) this.setState({ isLoading: false }, () => this.props.setDatesAndPeriod(startDate, endDate, this.props.period));
        else this.props.setDatesAndPeriod(startDate, endDate, this.props.period).then(() => this.setState({ isLoading: true }, this.loadData));
    }

    setPeriod = (period) => {
        const { startDate, endDate } = this.props;
        this.props.setDatesAndPeriod(startDate, endDate, period).then(() => this.setState({ isLoading: startDate && endDate }, () => {
            if (startDate && endDate) this.loadData();
        }));
    }

    loadData = () => {
        const { startDate, endDate, period, settings } = this.props;

        this.cancelTokenSource = axios.CancelToken.source();
        this.setState({ isLoading: true });
        StatisticsService.getHistories(startDate, endDate, period, settings.display === 'global', this.cancelTokenSource).then(histories => {
            this.cancelTokenSource = null;
            this.setState({ histories, isLoading: false }, () => this.updateChart(histories, startDate, endDate, period, false));
        });
    }

    updateChart = (histories, startDate, endDate, period = 'week', isLoading = false) => {
        let periods = [];

        endDate = endOfDay(endDate || new Date());
        if (!startDate) startDate = startOfWeek(subWeeks(endDate, 5), { weekStartsOn: 1 });
        startDate = startOfDay(startDate);

        // Définition des périodes
        switch (period) {
            case 'day':
                periods.push({ label: format(startDate, 'dd/MM/yy'), startPeriod: startDate, endPeriod: endOfDay(startDate) });
                const nbDays = DatesUtil.daysBetweenTwoDates(endDate, startDate);
                for (let i = 1; i <= nbDays; i++) {
                    const startPeriod = startOfDay(addDays(startDate, i));
                    const endPeriod = i < nbDays ? endOfDay(startPeriod) : endDate;
                    if (startPeriod.getTime() <= endPeriod.getTime())
                        periods.push({ label: format(startPeriod, 'dd/MM/yy'), startPeriod, endPeriod });
                }
                break;
            case 'week':
                const nbWeeks = DatesUtil.weeksBetweenTwoDates(endDate, startDate);
                periods.push({ label: `${format(startDate, 'dd/MM/yy')} au ${format(nbWeeks ? endOfWeek(startDate, { weekStartsOn: 1 }) : endDate, 'dd/MM/yy')}`, startPeriod: startDate, endPeriod: nbWeeks ? endOfWeek(startDate, { weekStartsOn: 1 }) : endDate });
                for (let i = 1; i <= nbWeeks; i++) {
                    const startPeriod = startOfWeek(addWeeks(startDate, i), { weekStartsOn: 1 });
                    const endPeriod = i < nbWeeks ? endOfWeek(startPeriod, { weekStartsOn: 1 }) : endDate;
                    if (startPeriod.getTime() <= endPeriod.getTime())
                        periods.push({ label: `${format(startPeriod, 'dd/MM/yy')} au ${format(endPeriod, 'dd/MM/yy')}`, startPeriod, endPeriod });
                }
                break;
            case 'month':
                const nbMonths = DatesUtil.monthsBetweenTwoDates(endDate, startDate);
                periods.push({ label: format(startDate, 'MM/yyyy'), startPeriod: startDate, endPeriod: nbMonths ? endOfMonth(startDate) : endDate });
                for (let i = 1; i <= nbMonths; i++) {
                    const startPeriod = startOfMonth(addMonths(startDate, i));
                    const endPeriod = i < nbMonths ? endOfMonth(startPeriod) : endDate;
                    if (startPeriod.getTime() <= endPeriod.getTime())
                        periods.push({ label: format(startPeriod, 'MM/yyyy'), startPeriod, endPeriod });
                }
                break;
            case 'year':
                const nbYears = DatesUtil.yearsBetweenTwoDates(endDate, startDate);
                periods.push({ label: format(startDate, 'yyyy'), startPeriod: startDate, endPeriod: nbYears ? endOfYear(startDate) : endDate });
                for (let i = 1; i <= nbYears; i++) {
                    const startPeriod = startOfYear(addYears(startDate, i));
                    const endPeriod = i < nbYears ? endOfYear(startPeriod) : endDate;
                    if (startPeriod.getTime() <= endPeriod.getTime())
                        periods.push({ label: format(startPeriod, 'yyyy'), startPeriod, endPeriod });
                }
                break;
            default:
                break;
        }

        const datasets = this.getDatasets(periods, histories);
        const labels = periods.map(period => period.label);
        const data = { labels, datasets };
        let options = this.getOptions();
        options.tooltips = { filter: (tooltipItem, data) => data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? true : false };

        this.props.setDatesAndPeriod(startDate, endDate, this.props.period).then(() => this.setState({ isLoading, data, options }));
    }

    getDatasets = (periods = [], histories = []) => {
        const { showNbAdded, showNbModified, showNbDeleted, showSurface, display } = this.props.settings;
        const accumulate = display === 'global';
        const containsDeletion = ['trees', 'greenSpaces', 'furnitures'].includes(this.props.category);
        const datasets = [];

        let fields = {};
        switch (this.props.category) {
            case 'trees': fields = { nbAdded: 'nbTreesAdded', nbModified: 'nbTreesModified', nbDeleted: 'nbTreesDeleted' }; break;
            case 'greenSpaces': fields = { nbAdded: 'nbGreenSpacesAdded', nbModified: 'nbGreenSpacesModified', nbDeleted: 'nbGreenSpacesDeleted', surface: 'greenSpacesSurface' }; break;
            case 'furnitures': fields = { nbAdded: 'nbFurnituresAdded', nbModified: 'nbFurnituresModified', nbDeleted: 'nbFurnituresDeleted' }; break;
            case 'users': fields = { nbAdded: 'nbUsers' }; break;
            case 'projects': fields = { nbAdded: 'nbProjects' }; break;
            case 'errors': fields = { nbAdded: 'nbErrors' }; break;
            default: break;
        }

        let counters;
        for (const history of histories) {
            if (!counters) {
                counters = {};
                for (const key in fields)
                    counters[key] = periods.map(_ => 0);
            }

            const index = periods.findIndex(period => period.label === history.period);
            for (const key in counters) {
                if (key.includes('Added'))
                    counters[key][index] = !accumulate ? history[fields[key]] : history[fields[key]] - (history[fields.nbDeleted] || 0);
                else if (key.includes('surface')) counters[key][index] = Math.round(history.greenSpacesSurface / 10000) / 100;
                else counters[key][index] = history[fields[key]];
            }
        }

        if (!counters) return [];
        if (this.props.category !== 'subscriptions') {
            if (showNbAdded) {
                const addedBackgroundColor = tinycolor(document.body.style.getPropertyValue('--primary-100')).toRgbString();
                datasets.push({
                    label: !accumulate ? i18n.t("Ajoutés") : i18n.t("Total"),
                    data: counters.nbAdded,
                    backgroundColor: periods.map(_ => addedBackgroundColor),
                    borderColor: periods.map(_ => 'rgba(0, 0, 0, 1)'),
                    borderWidth: 1,
                    lineTension: 0
                });
            }
            if (showNbModified && !accumulate) {
                const modifiedBackgroundColor = tinycolor(document.body.style.getPropertyValue('--orange-100')).toRgbString();
                datasets.push({
                    label: !accumulate ? i18n.t("Modifiés") : i18n.t("Total"),
                    data: counters.nbModified,
                    backgroundColor: periods.map(_ => modifiedBackgroundColor),
                    borderColor: periods.map(_ => 'rgba(0, 0, 0, 1)'),
                    borderWidth: 1,
                    lineTension: 0
                });
            }
            if (containsDeletion && showNbDeleted && !accumulate) {
                const deletedBackgroundColor = tinycolor(document.body.style.getPropertyValue('--red-100')).toRgbString();
                datasets.push({
                    label: i18n.t("Supprimés"),
                    data: counters.nbDeleted,
                    backgroundColor: periods.map(_ => deletedBackgroundColor),
                    borderColor: periods.map(_ => 'rgba(0, 0, 0, 1)'),
                    borderWidth: 1,
                    lineTension: 0
                });
            }
            if (this.props.category === 'greenSpaces' && showSurface) {
                const surfaceBackgroundColor = tinycolor(document.body.style.getPropertyValue('--blue-100')).toRgbString();
                datasets.push({
                    label: `${i18n.t("Surface")} (km²)`,
                    data: counters.surface,
                    backgroundColor: periods.map(_ => surfaceBackgroundColor),
                    borderColor: periods.map(_ => 'rgba(0, 0, 0, 1)'),
                    borderWidth: 1,
                    lineTension: 0
                });
            }
        } else {
            // TODO Supprimer ?
            this.state.subscriptions.filter(subscription => subscription.isPublic).forEach(subscription => {
                let subscriptionName = FormattersUtil.getNormalizedString(subscription.name);
                subscriptionName = subscriptionName.charAt(0).toUpperCase() + subscriptionName.slice(1);
                datasets.push({
                    label: subscription.name,
                    data: counters[`nb${subscriptionName}Users`],
                    backgroundColor: periods.map(_ => tinycolor(subscription.color).toRgbString()),
                    borderColor: periods.map(_ => 'rgba(0, 0, 0, 1)'),
                    borderWidth: 1,
                    lineTension: 0
                });
            });
        }

        if (accumulate)
            for (let i = 0; i < datasets.length; i++)
                datasets[i] = {
                    ...datasets[i],
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    borderWidth: 3,
                    borderColor: datasets[i].backgroundColor[0],
                    fill: false
                };

        return datasets;
    }

    getOptions = () => {
        const themeColor = this.props.isDarkTheme ? 'white' : 'black';
        const zLabel = this.state.period === 'day' ? i18n.t("Jours")
            : this.state.period === 'week' ? i18n.t("Semaines")
                : this.state.period === 'month' ? i18n.t("Mois")
                    : i18n.t("Années");
        const yLabel = this.props.category === 'trees' ? i18n.t("Nombre d'arbres")
            : this.props.category === 'greenSpaces' ? i18n.t("Nombre d'espaces verts")
                : this.props.category === 'furnitures' ? i18n.t("Nombre de mobiliers")
                    : this.props.category === 'users' ? i18n.t("Nombre d'utilisateurs")
                        : i18n.t("Nombre de projets");
        return {
            animation: { duration: this.arrowClick ? 0 : 1000 },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: { beginAtZero: true, min: 0 },
                    scaleLabel: { display: true, labelString: zLabel }
                }],
                yAxes: [{
                    ticks: { beginAtZero: true, min: 0 },
                    scaleLabel: { display: true, labelString: yLabel }
                }]
            },
            plugins: {
                labels: {
                    render: 'value',
                    showZero: false,
                    fontColor: themeColor
                }
            },
            legend: {
                labels: {
                    fontColor: themeColor,
                }
            }
        };
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(ElementsStatistics);