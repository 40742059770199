import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import X2JS from 'x2js';
// Utils
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class WmsService {
    static getCapabilities(url, isWMTS) {
        return Axios.get(url + `request=GetCapabilities&service=${isWMTS ? 'WMTS' : 'WMS'}`).then(response => {
            if (response.data) return new X2JS().xml2js(response.data);
            else return;
        }, () => {
            showToast('wms_service_addition_failed'); // TODO Remplacer
            return;
        });
    }

    static getDefaultWmsServices() {
        return Axios.get(BACKEND_ENDPOINT + 'wmsServices/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('wms_service_loading_failed'); // TODO Ajouter toast
            return;
        });
    }

    static addWmsService(wmsService) {
        return Axios.post(BACKEND_ENDPOINT + 'wmsServices/', wmsService, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('wms_service_added');
            return response.data;
        }, () => {
            showToast('wms_service_addition_failed');
            return;
        });
    }

    static updateWmsService(wmsService) {
        return Axios.put(BACKEND_ENDPOINT + 'wmsServices/', wmsService, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('wms_service_updated');
            return response.data;
        }, () => {
            showToast('wms_service_update_failed');
            return;
        });
    }

    static removeWmsServices(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'wmsServices/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            if (ids.length <= 1) showToast('wms_service_deleted');
            else showToast('wms_services_deleted');
            return response.status;
        }, () => {
            showToast('wms_service_deletion_failed');
            return;
        });
    }
}