import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Input, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { setUserInfos } from '../../../actionCreators/usersActions';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
// Services
import UsersService from '../../../services/UsersService';
import FormattersUtil from '../../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    lastName: false,
    firstName: false
}

class UserInfosForm extends Component {
    state = {
        isLoading: false,
        data: {
            lastName: '',
            firstName: '',
            email: ''
        },
        error: initialError
    }

    render() {
        const { loginAsData } = this.props;
        const { isLoading } = this.state;
        const { lastName, firstName, email } = this.state.data;
        const error = this.state.error;
        let userInfos;

        if (new Cookies().get('token')) {
            let decodedToken = jwtDecode(new Cookies().get('token'));
            userInfos = {
                firstName: decodedToken.firstName,
                lastName: decodedToken.lastName
            }
        }
        const isValidBtnDisabled = !this.props.isOnline || !userInfos || lastName.length === 0 || firstName.length === 0 || (lastName === userInfos.lastName && firstName === userInfos.firstName) || loginAsData;

        return (
            <Form loading={isLoading} onSubmit={this.handleSubmit} error>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Form.Field
                                name='lastName' control={Input} label={i18n.t("Nom") + '* : '} placeholder={i18n.t("Saisissez votre nom")}
                                value={lastName} error={error.lastName} disabled={loginAsData} onChange={this.handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Form.Field
                                name='firstName' control={Input} label={i18n.t("Prénom") + '* : '} placeholder={i18n.t("Saisissez votre prénom")}
                                value={firstName} error={error.firstName} disabled={loginAsData} onChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field
                                name='email' control={Input} label={i18n.t("Adresse e-mail") + '* : '} placeholder={i18n.t("Saisissez votre email")}
                                value={email} disabled
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Message
                                error style={{ textAlign: 'left' }} hidden={error.hidden}
                                header={i18n.t("Erreur")} list={error.messages}
                            />
                            <Button type='submit' color='green' className='form-button' disabled={isValidBtnDisabled}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => {
        if (new Cookies().get('token')) {
            const decodedToken = jwtDecode(new Cookies().get('token'));
            this.setState({
                data: {
                    lastName: decodedToken.lastName,
                    firstName: decodedToken.firstName,
                    email: decodedToken.email
                }
            });
        }
    }

    handleSubmit = () => {
        let flag = true;
        let fields = {
            firstName: this.state.data.firstName,
            lastName: this.state.data.lastName
        }
        let errors = {
            firstName: this.state.error.firstName,
            lastName: this.state.error.lastName
        }
        let messages = [];

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            flag = false;
        }

        if (!FormattersUtil.checkName(fields.firstName))
            addError('firstName', i18n.t("Le prénom est invalide"))

        if (!FormattersUtil.checkName(fields.lastName))
            addError('lastName', i18n.t("Le nom est invalide"))

        if (flag) {
            const userInfos = {
                firstName: fields.firstName.trim(),
                lastName: fields.lastName.trim()
            };

            this.setState({ isLoading: true });
            UsersService.updateUserInfos(userInfos).then(response => {
                this.setState({ isLoading: false });
                if (response?.token) {
                    new Cookies().set('token', response.token, { maxAge: 31536000, path: '/' });
                    this.props.setUserInfos(userInfos);
                    this.setState({ error: initialError });
                }
                else { // Sinon on affiche la liste des messages d'erreurs
                    this.setState({
                        error: {
                            hidden: false,
                            messages: [i18n.t("Une erreur est survenue")],
                            firstName: errors.firstName,
                            lastName: errors.lastName
                        }
                    });
                }
            });
        }
        else { // Si les vérifications ne sont pas bonnes
            this.setState({ // On affiche les messages d'erreurs correspondants
                error: {
                    hidden: false,
                    messages: messages,
                    firstName: errors.firstName,
                    lastName: errors.lastName
                }
            });
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState(prevState => ({
            data: { ...prevState.data, [name]: value },
            error: { ...prevState.error, [name]: false }
        }));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setUserInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfosForm);