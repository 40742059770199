import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';
// Utils
import OfflineUtil from '../utils/OfflineUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class PriceListsService {
    static getCurrencies() {
        return Axios.get(BACKEND_ENDPOINT + 'priceLists/currencies/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static getPriceLists() {
        return Axios.get(BACKEND_ENDPOINT + 'priceLists/' + jwtDecode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static addPriceList(priceList) {
        return Axios.post(BACKEND_ENDPOINT + 'priceLists/', priceList, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'priceLists/' + jwtDecode(new Cookies().get('token')).id, (body) => {
                body.push(response.data);
                return body;
            }).then(() => {
                if (navigator.onLine) showToast('price_list_added');
                return response?.data;
            });
        }, () => {
            showToast('price_list_addition_failed');
            return;
        });
    }

    static updatePriceList(priceList) {
        return Axios.put(BACKEND_ENDPOINT + 'priceLists/', priceList, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'priceLists/' + jwtDecode(new Cookies().get('token')).id, (body) => {
                const index = body.findIndex(el => el.id === priceList.id);
                body[index] = response?.data;
                return body;
            }).then(() => {
                if (navigator.onLine) showToast('price_list_updated');
                return response?.data;
            });
        }, () => {
            showToast('price_list_update_failed');
            return;
        });
    }

    static deletePriceList(id) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'priceLists/' + jwtDecode(new Cookies().get('token')).id, (body) => {
            return body.filter(priceList => priceList.id !== id);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'priceLists/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('price_list_deleted');
            return response;
        }, () => {
            showToast('price_list_deletion_failed');
            return;
        });
    }
}