import React, { Component } from 'react';
// Composants
import { Button, Card, Checkbox, Dimmer, Divider, Grid, Loader, Statistic } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadialGauge, RadialGaugeArc, StackedRadialGaugeValueLabel, StackedRadialGaugeSeries } from 'reaviz';
import CountUp from 'react-countup';
import GaugeProgressBar from '../Utils/GaugeProgressBar';
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { faChartLine, faCircleDollar, faClapperboardPlay, faFileAlt, faFilterList, faFlowerTulip, faFolderOpen, faLayerGroup, faLock, faMessageLines, faPlay, faPlus, faQuestionCircle, faSackDollar, faSync, faTablePicnic, faTachometerAlt, faTree, faTriangleExclamation, faUpRightFromSquare, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';
import { isTablet, isMobileOnly, isMobile } from 'react-device-detect';
import { setProject, setRights, setViewProjectAsData } from '../../actionCreators/projectsActions';
import { setHomeInfos } from '../../actionCreators/usersActions';
import { setCurrentAction } from '../../actionCreators/appActions';
import { setLayer } from '../../actionCreators/elementsActions';
import { setFilterFormState } from '../../actionCreators/componentsActions';
import tinycolor from 'tinycolor2';
// Services
import StatisticsService from '../../services/StatisticsService';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import DatesUtil from '../../utils/DatesUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import StylesUtil from '../../utils/StylesUtil';

const GAP = '15px';

class Home extends Component {
    state = {
        infos: null,
        isLoading: true,
        maintenanceLabel: '',
        bombActivated: false,
        showTotal: true
    };

    render() {
        const { isDarkTheme, activeOrganization, userInfos, loginAsData } = this.props;
        const { infos, isLoading, showTotal } = this.state;
        const expirationDate = activeOrganization?.subscription.expirationDate;
        const subscriptionDate = infos?.subscriptionDate;
        const greenSpacesString = FormattersUtil.formatSquareMeter(infos?.greenSpacesSurface);
        const greenSpacesStringSplitted = greenSpacesString?.length && greenSpacesString.split(' ');
        const greenSpacesValue = greenSpacesStringSplitted?.length && greenSpacesString.replace(greenSpacesStringSplitted[greenSpacesStringSplitted.length - 1], '').trim();
        const greenSpacesUnit = greenSpacesStringSplitted?.length && greenSpacesStringSplitted[greenSpacesStringSplitted.length - 1];

        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                {isLoading &&
                    <Dimmer active={isLoading} style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader active content={i18n.t("Chargement en cours...")} />
                    </Dimmer>}
                <div style={{ display: 'flex', flexDirection: 'column', gap: GAP, flex: 1, padding: '15px', width: isMobile && '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'stretch', gap: GAP, height: isMobile && '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: GAP, height: isMobile && '100%', width: isMobile && '100%', flex: 1 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: GAP, flexWrap: 'wrap', padding: 0, margin: 0 }}>
                                <Card id='welcome-card' style={{ position: 'relative', margin: 0, flex: !isMobile && 1, width: isMobileOnly ? '100%' : isTablet && '48.9%' }}>
                                    <Card.Content style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Card.Header>{i18n.t("Bonjour")} {userInfos?.firstName},</Card.Header>
                                        <span>{i18n.t("Bienvenue sur votre espace Grality !")}</span>
                                    </Card.Content>
                                </Card>
                                {[
                                    { label: i18n.t("Arbres"), value: infos?.trees, icon: faTree },
                                    { label: i18n.t("Espaces verts"), value: greenSpacesValue, unit: greenSpacesUnit, title: infos?.greenSpaces, icon: faFlowerTulip },
                                    { label: i18n.t("Mobiliers"), value: infos?.furnitures, icon: faTablePicnic },
                                ].map(({ label, value, unit, title, icon }) => this.renderStatisticCard(label, value, unit, title, icon))}
                            </div>
                            {isMobile && this.renderStatisticCards()}
                            <div style={{ display: 'flex', flexDirection: !isMobile ? 'row' : 'column', gap: GAP, flex: 1, width: isMobile && '100%' }}>
                                <Card
                                    title={i18n.t("Voir plus d'informations")}
                                    style={{ minWidth: !isMobile && '300px', height: !isMobile && '100%', width: isMobile && '100%', flex: !isMobile && 1, margin: 0 }}
                                    onClick={() => this.props.history.push('/organizationPanel/subscription')}
                                >
                                    <Card.Content>
                                        <Card.Header>
                                            <FontAwesomeIcon icon={faCircleDollar} style={{ marginRight: '10px' }} />
                                            {i18n.t("Abonnement")}
                                        </Card.Header>
                                        <Divider style={{ marginTop: '7px' }} />
                                        <Card.Meta>
                                            {activeOrganization && <>
                                                {subscriptionDate && <h4 style={{ margin: 0, marginBottom: '10px' }}>{i18n.t("Date d'abonnement")} : <span style={{ fontWeight: 'normal' }}>{DatesUtil.getFormattedLocaleDateString(subscriptionDate)}</span></h4>}
                                                {expirationDate && <h4 style={{ margin: 0, marginBottom: '10px' }}>{i18n.t("Date d'expiration")} : <span style={{ fontWeight: 'normal' }}>{DatesUtil.getFormattedLocaleDateString(expirationDate)}</span></h4>}
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                    {this.renderGauge(i18n.t("Projets"), faFileAlt, 'nbProjects')}
                                                    {this.renderGauge(i18n.t("Arbres"), faTree, 'nbTrees')}
                                                    {this.renderGauge(i18n.t("Espaces verts"), faFlowerTulip, 'nbGreenSpaces')}
                                                    {this.renderGauge(i18n.t("Mobiliers"), faTablePicnic, 'nbFurnitures')}
                                                </div>
                                            </>}
                                        </Card.Meta>
                                    </Card.Content>
                                </Card>
                                <Card
                                    title={i18n.t("Accéder à la liste des projets")}
                                    style={{ minWidth: !isMobile && '300px', height: !isMobile && '100%', width: isMobile && '100%', flex: !isMobile && 1, marginTop: 0 }}
                                    onClick={this.openProjectList}
                                >
                                    <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                        <Card.Header>
                                            <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />
                                            {`${i18n.t("Projets")} (${infos?.projects || 0})`}
                                        </Card.Header>
                                        <Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='no-themed' style={{ color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)', fontStyle: 'italic' }}>{i18n.t("Les 5 derniers ouverts")}</span>
                                            <Button
                                                id='481a0fbb' color='green' size='tiny' title={i18n.t("Nouveau projet")} style={{ marginLeft: 'auto', padding: '8px 12px' }}
                                                disabled={loginAsData?.readOnly} onClick={() => this.props.showProjectList(true, { openProjectCreation: true })}
                                            >
                                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '7px' }} />
                                                {i18n.t("Nouveau")}
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', marginTop: '5px' }}>
                                            {infos?.latestProjectsOpened?.map(project => (
                                                <div
                                                    className='dashboard-project' title={i18n.t("Ouvrir le projet")} key={project.id}
                                                    onClick={() => this.props.history.push(`/projects/${project.id}`)}
                                                >
                                                    <span title={project.label} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{project.label}</span>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '7px' }} />
                                                        {i18n.t("Ouvrir")}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Card.Content>
                                </Card>
                            </div>
                        </div>
                        {!isMobile && this.renderStatisticCards()}
                    </div>
                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'stretch', gap: GAP, height: isMobile && '100%', flex: 1 }}>
                        <Card style={{ flex: !isMobile && 1, height: '100%', minHeight: isMobile && '290px', width: isMobile && '100%', margin: 0 }}>
                            <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                <Card.Header>
                                    <FontAwesomeIcon icon={faTachometerAlt} style={{ marginRight: '10px' }} />
                                    {showTotal ? i18n.t("Indicateurs totaux") : i18n.t("Indicateurs moyens / projet")}
                                </Card.Header>
                                <Divider style={{ marginTop: '7px' }} />
                                <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', alignItems: 'center', height: '100%', overflow: 'hidden' }}>
                                    <Checkbox
                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                        toggle label={i18n.t("Total")} checked={showTotal || false} onChange={this.toggleShowTotal}
                                    />
                                    {activeOrganization && infos && [
                                        { label: i18n.t("Valeur d'agrément"), value: infos?.averageAmenityValue, unit: '€', color: '#daa817', formatFn: (value) => FormattersUtil.formatEuro(value), disabled: !activeOrganization.subscription.amenityFormula },
                                        { label: i18n.t("Stockage annuel CO2"), value: infos?.averageCarbonStock, unit: `${i18n.t("kg")}/${i18n.t("an")}`, color: '#7250bc', formatFn: (value) => `${FormattersUtil.formatKilogram(value, { defaultUnit: 'kg' })}/${i18n.t("an")}`, disabled: !activeOrganization.subscription.carbonStockFormula },
                                        { label: i18n.t("Production d'oxygène"), value: FormulasUtil.getOxygenProduction(infos?.averageCarbonStock), unit: `${i18n.t("kg")}/${i18n.t("an")}`, color: '#c83030', formatFn: (value) => `${FormattersUtil.formatKilogram(value, { defaultUnit: 'kg' })}/${i18n.t("an")}`, disabled: !activeOrganization.subscription.carbonStockFormula },
                                        { label: i18n.t("Rafraîchissement (énergie)"), value: infos?.averageCoolingIndicator, unit: `${i18n.t("kWh")}/${i18n.t("an")}`, color: '#2185d0', formatFn: (value) => `${FormattersUtil.formatKilowattHour(value)}/${i18n.t("an")}`, disabled: !activeOrganization.subscription.coolingFormula },
                                        { label: i18n.t("Rafraîchissement (valeur)"), value: infos?.averageCoolingEnergyIndicator, unit: `€/${i18n.t("an")}`, color: '#daa817', formatFn: (value) => `${FormattersUtil.formatEuro(value)}/${i18n.t("an")}`, disabled: !activeOrganization.subscription.coolingFormula }
                                    ].map(({ label, value, unit, color, formatFn, disabled }) => {
                                        if (isNaN(value)) value = 0;
                                        if (showTotal) value = value * infos?.projects;
                                        return this.renderRadialGauge(label, value, formatFn(value || 0), value, unit, color, disabled)
                                    })}
                                </div>
                                <Button color='blue' style={{ width: 'fit-content', position: 'absolute', top: '54px', right: '10px' }} onClick={this.refreshInfos}>
                                    <FontAwesomeIcon icon={faSync} style={{ marginRight: '5px' }} />{i18n.t("Actualiser")}
                                </Button>
                            </Card.Content>
                        </Card>
                        <Card style={{ height: !isMobile && '100%', minHeight: isMobile && '205px', width: isMobile && '100%', margin: 0 }}>
                            <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                <Card.Header>
                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ marginRight: '10px' }} />
                                    {i18n.t("Aide Grality")}
                                </Card.Header>
                                <Divider style={{ marginTop: '7px' }} />
                                <div className='dashboard-support' style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
                                    <a href='https://grality.atlassian.net/servicedesk/customer/portal/3/topic/86097878-1cfc-48db-af57-b5e70641a3e9' target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faPlay} style={{ marginRight: '7px' }} />
                                        {i18n.t("Pour démarrer")}
                                        <FontAwesomeIcon icon={faUpRightFromSquare} style={{ marginLeft: 'auto' }} />
                                    </a>
                                    <a href='https://grality.atlassian.net/servicedesk/customer/portals' target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faClapperboardPlay} style={{ marginRight: '7px' }} />
                                        {i18n.t("Tous les tutoriels")}
                                        <FontAwesomeIcon icon={faUpRightFromSquare} style={{ marginLeft: 'auto' }} />
                                    </a>
                                    <a href='https://grality.atlassian.net/servicedesk/customer/portal/3/group/-1' target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faMessageLines} style={{ marginRight: '7px' }} />
                                        {i18n.t("Nous contacter")}
                                        <FontAwesomeIcon icon={faUpRightFromSquare} style={{ marginLeft: 'auto' }} />
                                    </a>
                                </div>
                            </Card.Content>
                        </Card>
                    </div>
                </div>
            </Grid>
        );
    }

    componentDidMount = () => {
        // Réinitialisation state Redux
        this.props.changeActiveItem('home');
        this.props.setProject(null);
        this.props.setCurrentAction('');
        this.props.setRights(null);
        this.props.setFilterFormState(null);
        this.props.setLayer(null); // Layer qu'on ajoute ou modifie
        this.props.setViewProjectAsData(null);

        if (!this.props.logged && !new Cookies().get('token')) this.props.history.push('/login');
        else {
            if (this.props.homeInfos) this.setState({ infos: this.props.homeInfos, isLoading: false });
            else StatisticsService.getUserDashboardInfos().then(infos => {
                this.setState({ infos, isLoading: false });
                this.props.setHomeInfos(infos);
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.loginAsData !== this.props.loginAsData) {
            this.setState({ isLoading: true });
            setTimeout(() => StatisticsService.getUserDashboardInfos().then(infos => this.setState({ infos, isLoading: false })), 250);
        }
    }

    refreshInfos = () => {
        this.setState({ isLoading: true });
        StatisticsService.getUserDashboardInfos().then(infos => {
            this.setState({ infos, isLoading: false });
            this.props.setHomeInfos(infos);
        });
    }

    renderStatisticCards = () => {
        const { activeOrganization } = this.props;
        const { infos } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: !isMobile ? 'column' : 'row', flexWrap: 'wrap', gap: GAP, maxWidth: !isMobile ? '250px' : '100%' }}>
                {[
                    { label: i18n.t("Actions"), value: infos?.actions, icon: faUserHelmetSafety, disabled: !activeOrganization?.subscription.actions },
                    { label: i18n.t("Graphiques"), value: infos?.customCharts, icon: faChartLine, disabled: !activeOrganization?.subscription.customCharts },
                    { label: i18n.t("Filtres"), value: infos?.filters, icon: faFilterList, disabled: !activeOrganization?.subscription.filters },
                    { label: i18n.t("Cartes thématiques"), value: infos?.thematicMaps, icon: faLayerGroup, disabled: !activeOrganization?.subscription.thematicMaps },
                    { label: i18n.t("Listes de prix"), value: infos?.priceLists, icon: faSackDollar, disabled: !activeOrganization?.subscription.costEstimation, onClick: activeOrganization?.subscription.costEstimation ? () => this.props.history.push('/accountPanel/priceLists') : null }
                ].map(({ label, value, title, unit, icon, disabled, onClick }) => this.renderStatisticCard(label, value || 0, unit, title, icon, { backgroundColor: 'var(--purple-100)', disabled, onClick }))}
            </div>
        );
    }

    renderStatisticCard = (label, value = 0, unit = '', title, icon, { backgroundColor = 'var(--primary-100)', disabled = false, onClick = null } = {}) => {
        const { homeInfos } = this.props;
        const blackColor = tinycolor(document.body.style.getPropertyValue('--black-100'));
        blackColor.setAlpha(0.7);

        if (unit.length) unit = ` ${unit}`;
        return (
            <Card
                key={label} title={disabled ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité") : onClick && i18n.t("Gérer")}
                style={{ position: 'relative', margin: 0, flex: !isMobile && 1, width: isMobileOnly ? '100%' : isTablet && '48.9%' }} onClick={onClick}
            >
                <Card.Content style={{ padding: '10px' }}>
                    {disabled &&
                        <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                            <FontAwesomeIcon icon={faLock} style={{ margin: 'auto', height: '50%', minHeight: '40px', color: 'white', zIndex: 1 }} />
                            <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', background: blackColor.toRgbString(), borderRadius: '5px' }}></div>
                        </div>}
                    <Card.Meta>
                        <Grid.Row style={{ display: 'flex' }}>
                            <Statistic size='tiny' style={{ margin: 0 }}>
                                <Statistic.Label style={{ textAlign: 'left' }}>{label} </Statistic.Label>
                                <Statistic.Value title={title} style={{ textAlign: 'left' }}>
                                    {!disabled ? (homeInfos ? `${value}${unit}` :
                                        <>
                                            <CountUp end={value} decimals={value % 1 !== 0 ? 2 : 0} separator=' ' decimal='.' duration={1} />
                                            {unit}
                                        </>) : '?'}
                                </Statistic.Value>
                            </Statistic>
                            {icon && <FontAwesomeIcon icon={icon} style={{ marginLeft: 'auto', borderRadius: '50%', padding: '7px', aspectRatio: '1/1', backgroundColor, alignSelf: 'center', color: 'white' }} size='2x' />}
                        </Grid.Row>
                    </Card.Meta>
                </Card.Content>
            </Card>
        );
    }

    renderRadialGauge = (label, value = 0, formattedValue, maxValue, unit, innerColor, disabled) => {
        const { homeInfos } = this.props;
        const data = [{ key: label, data: value > maxValue ? maxValue : value }];
        const roundedValue = !isNaN(value) && value % 1 !== 0 ? value.toFixed(2) : value || 0;
        if (!isNaN(maxValue)) maxValue = value || 0;

        return (
            <div
                key={label} title={disabled ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité") : roundedValue + unit}
                style={{ display: 'flex', flexDirection: 'column', width: isMobileOnly ? '100%' : '20%', height: '100%' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    {disabled ? <FontAwesomeIcon icon={faLock} size='6x' />
                        :
                        <RadialGauge
                            className='reaviz-gauge' data={data} height={200} style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            maxValue={maxValue !== undefined ? maxValue || 1 : value || 1} startAngle={-(Math.PI / 5 * 4)} endAngle={Math.PI / 5 * 4}
                            series={
                                <StackedRadialGaugeSeries
                                    innerArc={<RadialGaugeArc disabled={true} animated={!homeInfos} cornerRadius={12.5} />}
                                    outerArc={<RadialGaugeArc disabled={true} animated={!homeInfos} cornerRadius={12.5} />}
                                    label={<StackedRadialGaugeValueLabel label={formattedValue} />} colorScheme={innerColor}
                                />
                            }
                        />}
                    <p style={{ fontWeight: 'bold', fontSize: '10pt', padding: 0, margin: 0, textAlign: 'center', marginTop: !disabled ? '-20px' : '10px' }}>{label}</p>
                </div>
            </div>
        );
    }

    toggleShowTotal = (_, { checked }) => this.setState({ showTotal: checked });
    renderGauge = (label, icon, propertyName) => {
        const { activeOrganization, isDarkTheme, homeInfos } = this.props;
        const userSubscriptionConsumptions = this.state.infos?.userSubscriptionConsumptions;

        const total = userSubscriptionConsumptions?.length ? userSubscriptionConsumptions.reduce((accumulator, value) => accumulator += value[propertyName], 0) : 0;
        const subscriptionMaxValue = activeOrganization.subscription[propertyName];
        const isUnlimited = subscriptionMaxValue === -1;
        const isExceeding = total > subscriptionMaxValue;
        let maxValue = subscriptionMaxValue;
        if (isUnlimited || isExceeding) maxValue = userSubscriptionConsumptions?.length ? total : 0;
        const users = userSubscriptionConsumptions?.length ? activeOrganization.linkedUsers.filter(user => userSubscriptionConsumptions.find(usc => usc.userId === user.id && usc[propertyName])) : [];
        const isCloseToLimit = !isUnlimited && total >= (maxValue * 0.95);
        return (
            <div style={{ flex: 1 }}>
                <h4 style={{ margin: '0 0 5px 0' }}>
                    <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
                    {label} ({homeInfos ? total : <CountUp end={total} decimals={total % 1 !== 0 ? 2 : 0} decimal='.' duration={1} />}{`/${isUnlimited ? i18n.t("Illimité") : !isExceeding ? maxValue : subscriptionMaxValue}`})
                    {isCloseToLimit && <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginLeft: '10px', color: total >= subscriptionMaxValue ? 'var(--red-100)' : 'var(--yellow-100)' }} />}
                </h4>
                <GaugeProgressBar
                    className={homeInfos ? 'not-animated' : null}
                    values={users.map((user) => {
                        const value = userSubscriptionConsumptions?.length
                            ? userSubscriptionConsumptions.find(usc => usc.userId === user.id)[propertyName]
                            : 0;
                        const formattedPercent = (value / maxValue * 100) % 1 ? ((value / maxValue * 100).toFixed(2)) : (value / maxValue * 100);
                        return ({
                            value,
                            maxValue,
                            popupContent: (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>{i18n.t("Utilisateur")} : {FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)}</div>
                                    <div>{i18n.t("Quantité")} : {value}{!isUnlimited && ` (${formattedPercent}%)`}</div>
                                </div>
                            )
                        });
                    })}
                />
            </div>
        );
    }

    openProjectList = ({ target }) => {
        const newProjectButton = document.getElementById('481a0fbb');
        if (target.id !== newProjectButton.id && !newProjectButton.contains(target))
            this.props.showProjectList(true);
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        isDarkTheme: state.isDarkTheme,
        homeInfos: state.homeInfos,
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setProject,
    setCurrentAction,
    setRights,
    setHomeInfos,
    setFilterFormState,
    setLayer,
    setViewProjectAsData
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);