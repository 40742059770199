import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Input, Message, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { setNewProject } from '../../../actionCreators/projectsActions';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
// Services
import ProjectsService from '../../../services/ProjectsService';

const initialError = {
    hidden: true,
    messages: [],
    label: false
};

class FolderCreationForm extends Component {
    state = {
        newFolder: {
            userId: jwtDecode(new Cookies().get('token')).id
        },
        error: initialError,
        isCreating: false
    }

    render() {
        const { newFolder, error, isCreating } = this.state;

        return (
            <div className='modal-content'>
                <div>
                    <Button
                        className='form-button' type='button' color='grey' size='small' icon='arrow left' title={i18n.t("Retour")}
                        style={{ marginBottom: '10px' }} onClick={this.handleCancelClick}
                    />
                </div>
                <div className='modal-content-body'>
                    <Segment style={{ display: 'flex', flexDirection: 'column', paddingBottom: isMobileOnly && '20px', height: '100%', overflowY: 'hidden' }}>
                        <Form error>
                            <h3>{i18n.t("Informations générales")}</h3>
                            <Grid>
                                <Grid.Row style={{ paddingBottom: 0 }}>
                                    <Grid.Column computer={4} tablet={8} mobile={16}>
                                        <Form.Field
                                            control={Input} label={i18n.t("Nom du dossier") + ' :'} placeholder={`Ex: ${i18n.t("Aménagement des parcs")}`}
                                            name='label' value={newFolder.label || ''} autoComplete='off'
                                            error={error.label} onChange={(e, { value }) => this.handleChange('label', value)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                    <Message
                        error hidden={error.hidden} style={{ textAlign: 'left' }}
                        header={i18n.t("Erreur")} list={error.messages}
                    />
                </div>
                <div className='modal-content-footer' style={{ marginTop: '10px' }}>
                    <Button
                        type='button' className='form-button' color='green' id='EjSQiXpE'
                        disabled={isCreating} loading={isCreating} onClick={this.handleSubmit}
                    >
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const currentFolder = this.props.projectListState?.currentFolder;
        const parentFolderId = currentFolder?.id || null;
        const path = currentFolder && `${currentFolder.path || '/'}${currentFolder.id}/`;
        this.setState(prevState => ({ newFolder: { ...prevState.newFolder, parentFolderId, path } }));
    }

    handleSubmit = () => {
        let isValid = true, messages = [];
        let errors = { label: false };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            isValid = false;
        }

        if (!this.state.newFolder.label?.trim()) addError('label', i18n.t("Le nom de projet ne peut être vide"));
        if (isValid) {
            this.setState({ isCreating: true });
            ProjectsService.addFolder(this.state.newFolder).then(({ folder, addedAlertingBaseProject }) => {
                this.props.setNewProject({ baseProject: folder, addedAlertingBaseProject });
                this.setState({ isCreating: false });
                this.props.changeModalContentType('ProjectList', i18n.t("Mes projets"));
            });
        } else {
            this.setState({ // On affiche les messages d'erreurs correspondants
                error: {
                    ...errors,
                    hidden: false,
                    messages: messages
                }
            });
        }
    }

    handleChange = (name, value) => {
        this.setState(prevState => ({ newFolder: { ...prevState.newFolder, [name]: value } }));
    }

    handleCancelClick = () => {
        this.props.setEditedProperties(null);
        this.props.changeModalContentType('ProjectList', i18n.t("Mes projets"));
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        editedProperties: state.editedProperties,
        projectListState: state.projectListState
    };
};

const mapDispatchToProps = {
    setNewProject
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderCreationForm);