import AppSettings from '../AppSettings';
// Librairies
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import Axios from 'axios';
// Services
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class NotificationsService {
    static getNotifications() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/notifications/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('notifications_loading_failed');
            return;
        });
    }

    static getLatestNotifications() {
        return Axios.get(BACKEND_ENDPOINT + 'notifications/latest/' + jwtDecode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let notifications = response.data;
            // Gestion du local storage
            let notificationsLS = localStorage.getItem('notifications');
            if (!notificationsLS) {
                notificationsLS = JSON.stringify([]);
                localStorage.setItem('notifications', notificationsLS);
            }
            notificationsLS = JSON.parse(notificationsLS);
            // Par défaut elles sont non lues
            notifications.forEach(notification => {
                notification['read'] = false;
            });
            // On vérifie dans le cookie lesquelles ont été lues
            notificationsLS.forEach(notificationCookie => {
                notifications.forEach(notification => {
                    if (notificationCookie === notification.id) notification['read'] = true;
                });
            });
            // On les tries par date et on garde les notifications qui ont 30 jours ou moins
            return notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .filter(x => (new Date().getTime() - new Date(x.date).getTime()) / (1000 * 60 * 60 * 24) <= 30);
        }, () => {
            if (navigator.onLine) showToast('notifications_loading_failed');
            return;
        });
    }

    static addNotification(notification) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/notifications/', notification, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('notification_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('notification_addition_failed');
            return;
        });
    }

    static updateNotifications(notifications) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/notifications/', notifications, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('notifications_updated');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('notifications_update_failed');
            return;
        });
    }

    static deleteNotifications(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/notifications/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'notifications_deletion_failed' : 'notification_deletion_failed');
            return;
        });
    }
}