import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faFlowerTulip, faTablePicnic, faTimes, faAxe, faXmarkLarge, faMagnifyingGlass, faLasso, faFilterList, faFilter } from '@fortawesome/pro-solid-svg-icons';
import MapPreview from '../Utils/MapPreview';
import FormMemberList from '../Lists/FormMemberList';
/*     Editors     */
import TextEditor from './Editors/TextEditor';
import SmartTextEditor from './Editors/SmartTextEditor';
import NumberEditor from './Editors/NumberEditor';
import BooleanEditor from './Editors/BooleanEditor';
import DropDownEditor from './Editors/DropDownEditor';
import EssenceEditor from './Editors/EssenceEditor';
import DateEditor from '../Administration/Editors/DateEditor';
/*     Filters     */
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import BooleanFilter from './Filters/BooleanFilter';
import DropDownFilter from './Filters/DropDownFilter';
import EssenceFilter from './Filters/EssenceFilter';
import DropDownNumberFilter from './Filters/DropDownNumberFilter';
// Librairies
import DataGrid, { Row as GridRow, SortableHeaderCell } from 'react-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../locales/i18n';
import { format } from 'date-fns';
// Redux
import { connect } from 'react-redux';
import { setTableState } from '../../actionCreators/componentsActions';
import { setProject, setProjects } from '../../actionCreators/projectsActions';
// Semantic UI
import { Form, Button, Menu, Input, Label, Grid, Message, Dimmer, Popup, Transition, Loader } from 'semantic-ui-react';
// Services
import TreesService from '../../services/TreesService';
import FilesService from '../../services/FilesService';
import ProjectsService from '../../services/ProjectsService';
import StationsService from '../../services/StationsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import StylesUtil from '../../utils/StylesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import TreesUtil from '../../utils/TreesUtil';
import FieldsUtil from '../../utils/FieldsUtil';

const initialFilters = {
    global: '',
    vernacularName: '',
    gender: '',
    species: '',
    cultivar: '',
    coverType: '',
    vigor: '',
    healthReview: '',
    ontogenicStage: '',
    risk: '',
    tippingRisk: '',
    organCaliber: '',
    target: '',
    plantationType: '',
    plantationCoefficient: '',
    situationCoefficient: '',
    patrimonialCoefficient: '',
    isEmpty: '',
    isDead: '',
    isStump: '',
    isIndexed: '',
    isRemarkable: '',
    customReference: '',
    place: '',
    interactions: '',
    microHabitats: '',
    rootSymptoms: '',
    rootPathogens: '',
    rootPests: '',
    collarSymptoms: '',
    collarPathogens: '',
    collarPests: '',
    trunkSymptoms: '',
    trunkPathogens: '',
    trunkPests: '',
    trunkEpiphytes: '',
    branchSymptoms: '',
    branchPathogens: '',
    branchPests: '',
    branchEpiphytes: '',
    leafSymptoms: '',
    leafPathogens: '',
    leafPests: '',
    tags: '',
    observation: '',
    projectReference: '',
    numberOfTrunks: '',
    trunkHeight: '',
    height: '',
    circumference: '',
    crownDiameter: '',
    plantingDate: '',
    age: '',
    fruitProduction: '',
    treeCanopy: '',
    carbonStock: '',
    totalCarbonStock: '',
    coolingIndicator: '',
    coolingEnergyIndicator: '',
    coolingEconomicValue: '',
    oxygenProduction: '',
    biodiversityIndex: '',
    amenityValue: '',
    creationDate: '',
    modificationDate: '',
    projectLabel: ''
};

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

class TreeTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        treesToModify: [],
        treesToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: JSON.parse(JSON.stringify(initialFilters)),
        tableToShow: null,
        vernacularNames: [],
        genders: [],
        species: [],
        cultivars: [],
        treePorts: [],
        coverTypes: [],
        vigors: [],
        healthReviews: [],
        ontogenicStages: [],
        risks: [],
        tippingRisks: [],
        organCalibers: [],
        targets: [],
        plantationTypes: [],
        plantationCoefficients: [],
        situationCoefficients: [],
        patrimonialCoefficients: [],
        interactions: [],
        microHabitats: [],
        rootSymptoms: [],
        collarSymptoms: [],
        trunkSymptoms: [],
        branchSymptoms: [],
        leafSymptoms: [],
        pathogens: [],
        pests: [],
        epiphytes: [],
        tags: [],
        treesStations: null,
        showExports: false,
        featureToShow: null
    }

    render() {
        const { rights, selectedElements, mainFilters } = this.props;
        const {
            data, treesToModify, treesToDelete, modificationsHistory, modificationsHistoryIndex, featureToShow,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, tableToShow, showExports
        } = this.state;
        const mainPF = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators).main;
        const rows = this.getFilteredRows();
        const id = `trees-table-${this.props.project.id}`;

        return (
            <div className={'scrollable-container'}>
                {(this.props.closingTable || tableToShow) &&
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column textAlign='center'>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Header>{i18n.t("Des modifications n'ont pas été sauvegardées, que faire ?")}</Message.Header>
                                        <Message.Content style={{ marginTop: '10px' }}>
                                            <Button color='green' onClick={() => {
                                                if (!tableToShow) this.props.cancelTableClose();
                                                this.handleSubmit(true);
                                            }}>
                                                <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                            </Button>
                                            <Button color='red' onClick={() => {
                                                tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Ne pas sauvegarder")}
                                            </Button>
                                            <Button color='grey' onClick={() => {
                                                tableToShow ? this.setState({ tableToShow: null }) : this.props.cancelTableClose();
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                            </Button>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer>}
                {data && data.columns &&
                    <>
                        <Menu attached='top' tabular style={{ marginTop: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button className='button--secondary' icon='tree' disabled />
                                    {mainPF.greenSpaces &&
                                        <Button
                                            title={i18n.t("Voir les espaces verts")} className='button--secondary' style={{ padding: '11px' }}
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'GreenSpaceTable' });
                                                else this.props.changeModalContentType('GreenSpaceTable', i18n.t("Tableau de données"), true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFlowerTulip} style={{ height: '12px' }} />
                                        </Button>}
                                    {mainPF.furnitures &&
                                        <Button
                                            title={i18n.t("Voir le mobilier urbain")} className='button--secondary' style={{ padding: '11px' }}
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'FurnitureTable' });
                                                else this.props.changeModalContentType('FurnitureTable', i18n.t("Tableau de données"), true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTablePicnic} style={{ height: '12px' }} />
                                        </Button>}
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Visualiser les données")} className='button--secondary' icon='eye'
                                        onClick={this.showFilteredElements}
                                    />
                                    {RightsUtil.canExport(rights?.trees) && <>
                                        <Button
                                            title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                            disabled={!this.props.isOnline} onClick={() => this.setState(prevState => ({ showExports: !prevState.showExports }))}
                                        />
                                        {showExports &&
                                            <Button.Group style={{ position: 'absolute', top: '85%', left: '136px', zIndex: 1000 }}>
                                                <Button title='Excel' className='button--secondary' icon='file excel' disabled={!this.props.isOnline} onClick={this.exportXLSX} />
                                                <Button title='Shapefiles' className='button--secondary' icon='file' disabled={!this.props.isOnline} onClick={this.exportSHP} />
                                                <Button title='Fiches PDF' className='button--secondary' icon='file pdf' disabled={!this.props.isOnline} onClick={this.exportPDF} />
                                                <Button title='Photos' className='button--secondary' icon='picture' disabled={!this.props.isOnline} onClick={this.exportPhotos} />
                                            </Button.Group>}
                                    </>}
                                    {RightsUtil.canWrite(rights?.trees) &&
                                        <>
                                            <Button
                                                title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                                onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                            />
                                            <Button
                                                title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                                disabled={modificationsHistoryIndex === modificationsHistory.length}
                                                onClick={this.restoreNextModification}
                                            />
                                            <Button
                                                title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                                onClick={() => this.handleSubmit(false)} disabled={treesToModify.length < 1 && treesToDelete.length < 1}
                                            />
                                        </>}
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item position='right' style={{ paddingBottom: 0, paddingTop: 26 }}>
                                <div style={{ position: 'absolute', right: '20px', top: '3px' }}>
                                    <FormMemberList
                                        id={id} stateToSend={{ rows: this.state.data.rows, treesToModify: this.state.treesToModify }}
                                        setIsLoading={(isLoading) => this.setState({ isLoading })} updateForm={this.updateForm}
                                    />
                                </div>
                                {treesToDelete.length > 0 &&
                                    <>
                                        <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + treesToDelete.length} />
                                        <span style={{ marginLeft: '10px' }}>|</span>
                                    </>}
                                <Label color='grey' content={i18n.t("Les cases foncées ne sont pas éditables (référence automatique, arbre mort ou vide, ...)")} />
                            </Menu.Item>
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item element-input'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => {
                                            if (commitChanges) {
                                                this.handleRowChange(selectedRow);
                                                WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: [selectedRow], treesToModify: this.state.treesToModify });
                                            }
                                        }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <Input placeholder={i18n.t("Rechercher...")} onChange={(_, { value }) => this.setState(prevState => ({ filters: { ...prevState.filters, global: value } }))} />
                                <div style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', position: 'absolute', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <div style={{ borderRight: 'solid 1px var(--grey-100)', paddingRight: '6px', marginRight: '5px' }}>
                                        {mainFilters &&
                                            <FontAwesomeIcon
                                                icon={faFilterList} title={i18n.t("Outil filtres")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {selectedElements?.length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faLasso} title={i18n.t("Sélection d'éléments")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {filters.global?.trim().length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass} title={i18n.t("Recherche globable")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {enableFilterRow &&
                                            <FontAwesomeIcon
                                                icon={faFilter} title={i18n.t("Filtres du tableau")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                    </div>
                                    <span style={{ fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <div className='scrollable reset-height'>
                            <DataGrid
                                ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                columns={data.columns.filter(column => column.visible)}
                                rows={rows} rowRenderer={this.rowRenderer}
                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                cellNavigationMode='LOOP_OVER_ROW'
                                sortColumn={sortColumn} sortDirection={sortDirection}
                                onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                                filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}><span>{i18n.t("Aucun résultat trouvé")}</span></div>)}
                                onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                onRowsChange={(newRows, _) => {
                                    let rowsToSend = [];
                                    this.state.data.rows.forEach(row => {
                                        const index = newRows.findIndex(newRow => newRow.elementId === row.elementId);
                                        if (index !== -1 && JSON.stringify(row) !== JSON.stringify(newRows[index]))
                                            rowsToSend.push(newRows[index]);
                                    });

                                    this.handleRowsChange(newRows);
                                    if (rowsToSend.length) WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: rowsToSend, treesToModify: this.state.treesToModify });
                                }}
                            />
                        </div>
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.showRowElement}>{i18n.t("Voir (carte)")}</MenuItem>
                            <MenuItem onClick={this.showRowElementOnMinimap}>{i18n.t("Voir (aperçu)")}</MenuItem>
                            {RightsUtil.canWrite(rights?.trees) && <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>}
                            {RightsUtil.canWrite(rights?.trees) && <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>}
                        </ContextMenu>
                        <Transition.Group animation='scale' duration='1000'>
                            {featureToShow &&
                                <div style={{ height: '200px', width: '300px', position: 'absolute', bottom: '15px', right: '15px' }}>
                                    <MapPreview
                                        id={featureToShow.id} style={{ borderRadius: '10px', height: '100%', width: '100%' }} dragging={true} zooming={true}
                                        features={[featureToShow]} elementStyle={{ tree: StylesUtil.getTreeStyle() }}
                                    />
                                    <FontAwesomeIcon icon={faXmarkLarge} size='1x' style={{ position: 'absolute', top: '7px', right: '7px', zIndex: 2, cursor: 'pointer' }} onClick={() => this.setState({ featureToShow: null })} />
                                </div>}
                        </Transition.Group>
                    </>}
            </div>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        if (this.props.tableState) {
            const { trees, data: oldData, timestamp, ...state } = this.props.tableState;
            this.trees = trees;
            const data = this.loadData(state.tags, oldData.rows);

            const modifiedTrees = this.trees.filter(layer => layer.feature.properties.modificationDate && layer.feature.properties.modificationDate > timestamp);
            modifiedTrees.forEach(layer => {
                const index = data.rows.findIndex(row => row.elementId === layer.feature.id);
                if (index !== -1) data.rows[index] = this.getRowValue(layer.feature, data.rows[index].id, state.tags);
            });
            if (modifiedTrees.length > 0) {
                state.treesToModify = state.treesToModify.filter(feature => !modifiedTrees.find(layer => layer.feature.id === feature.id));
                data.rows = [...data.rows];
            }

            this.setState({ data, ...state });
            this.props.setTableState(null);
        } else {
            if (this.props.nbStations) this.loadTreesStations();
            let projectTags = [];
            if (this.props.project?.tags)
                projectTags = this.props.project.tags
                    .filter(x => x.category === 'Arbre')
                    .map(projectTag => { return { label: projectTag.label, id: projectTag.id, category: projectTag.category } });

            const data = this.loadData(projectTags);

            let propertyName;
            const formula = this.props.formulas.find(formula => formula.id === 4);
            if (formula?.formulaVersions) {
                const formulaVersion = formula.formulaVersions.find(fv => this.props.project.projectFormulaVersions.find(pfv => pfv.formulaVersionId === fv.id));
                if (formulaVersion) {
                    const formulaLabel = formulaVersion.label.toLowerCase();
                    propertyName = formulaLabel.includes('wallonie') ? 'descriptionWln'
                        : formulaLabel.includes('bruxelles') ? 'descriptionBxl'
                            : 'descriptionFr';
                }
            }

            const plantationCoefficients = this.props.plantationCoefficients.filter(pc => pc[propertyName]);
            const situationCoefficients = this.props.situationCoefficients.filter(sc => sc[propertyName]);

            this.setState({
                data: data,
                vernacularNames: [...new Set(this.props.essences
                    .map(x => x.vernacularName))]
                    .filter(x => x)
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                genders: [...new Set(this.props.essences
                    .map(x => x.gender))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                species: [...new Set(this.props.essences
                    .filter(x => x.species)
                    .map(x => x.species))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                cultivars: [...new Set(this.props.essences
                    .filter(x => x.cultivar)
                    .map(x => x.cultivar))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                treePorts: this.props.treePorts.map(x => { return { label: x.label, id: x.id } }),
                coverTypes: this.props.coverTypes.map(x => { return { label: x.label, id: x.id } }),
                vigors: this.props.vigors.map(x => { return { label: x.label, id: x.id } }),
                healthReviews: this.props.healthReviews.map(x => { return { label: x.value, id: x.id } }),
                ontogenicStages: this.props.ontogenicStages.map(x => { return { label: x.value, id: x.id } }),
                risks: this.props.risks.map(x => { return { label: x.label, id: x.id } }),
                tippingRisks: this.props.tippingRisks.map(x => { return { label: x.label, id: x.id } }),
                organCalibers: this.props.organCalibers.map(x => { return { label: x.label, id: x.id } }),
                targets: this.props.targets.map(x => { return { label: x.label, id: x.id } }),
                plantationTypes: this.props.plantationTypes.map(x => { return { label: x.label, id: x.id } }),
                plantationCoefficients: plantationCoefficients.map(x => { return { label: x.value, id: x.id } }),
                situationCoefficients: situationCoefficients.map(x => { return { label: x.value, id: x.id } }),
                patrimonialCoefficients: this.props.patrimonialCoefficients.map(x => { return { label: x.value, id: x.id } }),
                interactions: this.props.interactions.map(x => { return { label: x.label, id: x.id } }),
                microHabitats: this.props.microHabitats.map(x => { return { label: x.label, id: x.id } }),
                rootSymptoms: this.props.rootSymptoms.map(x => { return { label: x.label, id: x.id } }),
                collarSymptoms: this.props.collarSymptoms.map(x => { return { label: x.label, id: x.id } }),
                trunkSymptoms: this.props.trunkSymptoms.map(x => { return { label: x.label, id: x.id } }),
                branchSymptoms: this.props.branchSymptoms.map(x => { return { label: x.label, id: x.id } }),
                leafSymptoms: this.props.leafSymptoms.map(x => { return { label: x.label, id: x.id } }),
                pathogens: this.props.pathogens.map(x => { return { label: x.label, id: x.id } }),
                pests: this.props.pests.map(x => { return { label: x.label, id: x.id } }),
                epiphytes: this.props.epiphytes.map(x => { return { label: x.label, id: x.id } }),
                tags: projectTags
            });
        }
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('click', ({ target }) => {
            const isExportButton = target.classList?.contains('download') || target.firstChild?.classList?.contains('download');
            if (!isExportButton && this.state.showExports) this.setState({ showExports: false });
        });

        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', (elements) => {
                let areColumnsInitialized = false;
                while (!areColumnsInitialized)
                    if (this.state.data.columns) {
                        const elementsParsed = JSON.parse(elements);
                        const type = elementsParsed[0].properties.category;
                        if (type !== 'Arbre') return;
                        setTimeout(() => {
                            let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                            const layers = this.props.treesLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                            if (layers.length) {
                                this.trees = [...this.trees || [], ...layers];
                                for (const key in layers)
                                    if (layers.hasOwnProperty(key))
                                        rows.push(this.getRowValue(layers[key].feature, key, this.state.tags));
                                this.setState(prevState => ({ data: { ...prevState.data, rows } }));
                            }
                        }, 500);
                        areColumnsInitialized = true;
                    }
            });

            this.props.webSocketHubs.elementsHub.on('UpdateElements', (elements) => {
                const elementsParsed = JSON.parse(elements);
                const type = elementsParsed[0].properties.category;
                if (type !== 'Arbre') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let treesToModify = JSON.parse(JSON.stringify(this.state.treesToModify));
                const layers = this.props.treesLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                for (const key in layers)
                    if (layers.hasOwnProperty(key)) {
                        // Mise à jour de la row
                        let index = rows.findIndex(r => r.elementId === layers[key].feature.id);
                        if (index !== -1)
                            rows[index] = { ...rows[index], lat: layers[key].feature.geometry.coordinates[1], long: layers[key].feature.geometry.coordinates[0] };
                        // Mise à jour des modifications
                        index = treesToModify.findIndex(ttm => ttm.id === layers[key].feature.id);
                        if (index !== -1) treesToModify[index].geometry = layers[key].feature.geometry;
                    }
                this.setState(prevState => ({ data: { ...prevState.data, rows }, treesToModify }));
            });

            this.props.webSocketHubs.elementsHub.on('RemoveElements', (type, ids) => {
                if (type !== 'Arbre') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let treesToModify = JSON.parse(JSON.stringify(this.state.treesToModify));
                let modificationsHistory = JSON.parse(JSON.stringify(this.state.modificationsHistory));
                const idsParsed = JSON.parse(ids);
                this.trees = this.trees.filter(t => !idsParsed.includes(t.feature.id));
                rows = rows.filter(r => !idsParsed.includes(r.elementId));
                treesToModify = treesToModify.filter(ttm => !idsParsed.includes(ttm.id));
                for (let i = 0; i < modificationsHistory.length; i++)
                    modificationsHistory[i] = modificationsHistory[i].filter(m => !idsParsed.includes(m.elementId))
                modificationsHistory = modificationsHistory.filter(mh => mh.length);
                let modificationsHistoryIndex = modificationsHistory.length;
                this.setState(prevState => ({ data: { ...prevState.data, rows }, treesToModify, modificationsHistory, modificationsHistoryIndex }));
            });
        }
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);
    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.elementId === newRow.elementId);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getEditor = (type, row, column, onRowChange, onClose, propertyOptions = null) => {
        const props = {
            elements: this.trees, elementsToModify: this.state.treesToModify, propertyOptions: propertyOptions,
            row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
            pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeTreesToModify,
            updateElementCustomFields: this.updateElementCustomFields
        };
        switch (type) {
            case 'dropdown': return <DropDownEditor {...props} />;
            case 'essence': return <EssenceEditor {...props} propertyName='essenceId' />;
            case 'smart': return <SmartTextEditor {...props} />;
            case 'number': return <NumberEditor {...props} />;
            case 'url': case 'text': return <TextEditor {...props} />;
            case 'boolean': return <BooleanEditor {...props} />;
            case 'date': return <DateEditor {...props} />;
            default: return;
        }
    }

    getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, color) => (
        <div className={color ? 'headerCellOverride' : null} ref={(node) => { if (color) node?.style?.setProperty('background-color', color, 'important'); }}>
            <SortableHeaderCell
                column={column}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            >
                {column.name}
            </SortableHeaderCell>
        </div>
    );

    loadData = (projectTags, rows) => {
        let data = {
            columns: [],
            rows: rows || []
        };

        if (this.props.linkedTrees && this.props.linkedTrees.length > 0) {
            const trunkCircumferenceUnit = this.props.project?.trunkCircumferenceUnit;
            if (!rows) {
                this.trees = [];
                this.props.linkedTrees.forEach(treesArray => {
                    if (this.props.treesLayer.hasLayer(treesArray[0]))
                        this.trees.push(treesArray[0]);
                });
            }
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).trees;
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            const mainPF = publicFields.main;
            const treesPF = publicFields.trees;
            const subscription = this.props.project?.organization.subscription;
            const activeOrganization = this.props.activeOrganization;
            let amenityFormulaType;
            const formula = this.props.formulas.find(formula => formula.id === 4);
            if (formula?.formulaVersions) {
                const formulaVersion = formula.formulaVersions.find(fv => this.props.project.projectFormulaVersions.find(pfv => pfv.formulaVersionId === fv.id));
                if (formulaVersion) {
                    const formulaLabel = formulaVersion.label.toLowerCase();
                    amenityFormulaType = formulaLabel.includes('wallonie') ? 'Wallonie'
                        : formulaLabel.includes('bruxelles') ? 'Bruxelles'
                            : 'France';
                }
            }
            const projections = this.props.projections;
            const projectionId = this.props.project?.projectionId;

            // Définition des colonnes
            this.customFieldFilters = [];
            data.columns = [
                {
                    name: i18n.t("Référence"), key: 'projectReference', width: 100,
                    sortable: true, editable: false, frozen: true, visible: mainPF.references,
                    formatter: (props) => props.row.cutDown
                        ? <Popup trigger={
                            <div className='cut-down'>
                                {props.row.projectReference}<FontAwesomeIcon icon={faAxe} style={{ marginLeft: 'auto' }} />
                            </div>}
                            content={i18n.t("L'arbre a été abattu")}
                        />
                        : <div className='disabled'>{props.row.projectReference}</div>,
                    filterRenderer: p => <NumberFilter p={p} step='1' />
                },
                {
                    name: i18n.t("Référence personnalisée"), key: 'customReference', width: 195,
                    sortable: true, frozen: true, visible: requiredFields.customReference && mainPF.references,
                    formatter: (props) => props.row.cutDown
                        ? <div className='disabled'>{props.row.customReference}</div>
                        : props.row.customReference || '',
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Lieu"), key: 'place', width: 250, category: 'Emplacement',
                    sortable: true, visible: requiredFields.place && treesPF.place,
                    formatter: (props) => props.row.cutDown
                        ? <div className='disabled'>{props.row.place}</div>
                        : props.row.place || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Stations"), key: 'stations', width: 250, category: 'Emplacement',
                    sortable: true, visible: this.props.nbStations > 0,
                    formatter: (props) => <div className='disabled'>
                        {this.state.treesStations ? props.row.stations : <div>
                            <Loader active inline size='mini' style={{ marginRight: '5px' }} />
                            <small>{i18n.t("Chargement en cours...")}</small>
                        </div>}
                    </div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                },
                {
                    name: i18n.t("Tags"), key: 'tags', width: 200, category: 'Emplacement',
                    sortable: false, visible: requiredFields.tags && treesPF.tags,
                    formatter: (props) => {
                        if (props.row.tags === null) return '';
                        const lastIndex = props.row.tags.lastIndexOf(',');
                        const tags = props.row.tags.substring(lastIndex + 1, lastIndex + 3).trim() === '-'
                            ? props.row.tags.slice(0, lastIndex + 1) + props.row.tags.slice(lastIndex + 3, props.row.tags.length)
                            : props.row.tags || '';
                        return props.row.cutDown
                            ? (<div className='disabled'>{tags}</div>)
                            : tags;
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.tags)
                },
                {
                    name: i18n.t("Classé"), key: 'isIndexed', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isIndexed && treesPF.isIndexed,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.isIndexed : i18n.t("Non")}</div>)
                            : props.row.isIndexed || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Remarquable"), key: 'isRemarkable', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isRemarkable && treesPF.isRemarkable,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.isRemarkable : i18n.t("Non")}</div>)
                            : props.row.isRemarkable || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Vide"), key: 'isEmpty', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isEmpty && treesPF.isEmpty,
                    formatter: (props) => {
                        return [props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (<div className='disabled'>{props.row.isEmpty}</div>)
                            : props.row.isEmpty || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isDead, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Mort"), key: 'isDead', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isDead && treesPF.isDead,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (<div className='disabled'>{props.row.isDead}</div>)
                            : props.row.isDead || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Souche"), key: 'isStump', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isStump && treesPF.isStump,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isDead].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? i18n.t("Oui") : i18n.t("Non")}</div>)
                            : props.row.isStump || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isDead].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement')),
                {
                    name: i18n.t("Nom vernaculaire"), key: 'vernacularName', width: 150, category: 'Description',
                    sortable: true, visible: requiredFields.vernacularName && treesPF.vernacularName,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.vernacularName : ''}</div>)
                            : props.row.vernacularName || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.vernacularNames} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.vernacularNames),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Genre"), key: 'gender', width: 150, category: 'Description',
                    sortable: true, visible: requiredFields.gender && treesPF.gender,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.gender : ''}</div>)
                            : props.row.gender || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.genders} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.genders),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Espèce"), key: 'species', width: 150, category: 'Description',
                    sortable: true, visible: requiredFields.species && treesPF.species,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || !props.row.gender || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.species : ''}</div>)
                            : props.row.species || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.species} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown && (props.gender ? true : false),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.species),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Cultivar"), key: 'cultivar', width: 150, category: 'Description',
                    sortable: true, visible: requiredFields.cultivar && treesPF.cultivar,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || !props.row.gender || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.cultivar : ''}</div>)
                            : props.row.cultivar || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.cultivars} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown && (props.gender ? true : false),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.cultivars),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Port de l'arbre"), key: 'treePort', width: 180, category: 'Description',
                    sortable: true, visible: requiredFields.treePort && treesPF.treePort,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isStump === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.treePort}</div>)
                            : props.row.treePort || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.treePorts} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isStump !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.treePorts),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Date de plantation"), key: 'plantingDate', width: 155, category: 'Description',
                    sortable: true, visible: requiredFields.plantingDate && treesPF.plantingDate,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isDead === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.plantingDate : ''}</div>)
                            : (props.row.plantingDate || props.row.plantingDate === 0 ? props.row.plantingDate : '')
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: props => <TextFilter p={props} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isDead !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('date', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Âge"), key: 'age', width: 100, category: 'Description',
                    sortable: true, visible: requiredFields.age && treesPF.age,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isDead === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.age : ''}</div>)
                            : (props.row.age || props.row.age === 0 ? props.row.age : '')
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isDead !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Stade ontogénique"), key: 'ontogenicStage', width: 160, category: 'Description',
                    sortable: true, visible: requiredFields.ontogenicStage && treesPF.ontogenicStage,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.ontogenicStage : ''}</div>)
                            : props.row.ontogenicStage || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.ontogenicStages} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isDead, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.ontogenicStages),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Description')),
                {
                    name: i18n.t("Nombre d'axes"), key: 'numberOfTrunks', width: 150, category: 'Dimensions',
                    sortable: true, visible: requiredFields.numberOfTrunks && treesPF.numberOfTrunks,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? '1' : props.row.cutDown ? props.row.numberOfTrunks : ''}
                                </div>
                            ) : props.row.numberOfTrunks || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Hauteur totale axe") + ' (m)', key: 'height', width: 200, category: 'Dimensions',
                    sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? '0' : (props.row.cutDown ? props.row.height : '')}
                                </div>
                            ) : props.row.height || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isStump !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: (trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe")) + ' (cm)', key: 'circumference', width: 215, category: 'Dimensions',
                    sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.circumference || '' : ''}</div>)
                            : props.row.circumference || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Diamètre couronne axe") + ' (m)', key: 'crownDiameter', width: 215, category: 'Dimensions',
                    sortable: true, visible: requiredFields.trunks && treesPF.trunks,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.crownDiameter || '' : ''}</div>)
                            : props.row.crownDiameter || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Hauteur du tronc") + ' (m)', key: 'trunkHeight', width: 200, category: 'Dimensions',
                    sortable: true, visible: requiredFields.trunkHeight && treesPF.trunkHeight,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isStump === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.trunkHeight || '' : ''}</div>)
                            : props.row.trunkHeight || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isStump !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions')),
                {
                    name: i18n.t("Type de plantation"), key: 'plantationType', width: 150, category: 'Environnement',
                    sortable: true, visible: requiredFields.plantationType && treesPF.plantationType,
                    formatter: (props) => {
                        return props.row.isDead === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.plantationType : ''}</div>)
                            : props.row.plantationType || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.plantationTypes} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isDead !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.plantationTypes),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Type de couverture au sol"), key: 'coverType', width: 180, category: 'Environnement',
                    sortable: true, visible: requiredFields.coverType && treesPF.coverType,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isDead === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.coverType : ''}</div>)
                            : props.row.coverType || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.coverTypes} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isDead !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.coverTypes),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Interactions"), key: 'interactions', width: 200, category: 'Environnement',
                    sortable: false, visible: requiredFields.interactions && treesPF.interactions,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.isDead === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.interactions : ''}</div>)
                            : props.row.interactions || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && props.isDead !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.interactions)
                },
                {
                    name: i18n.t("Dendro-microhabitats"), key: 'microHabitats', width: 200, category: 'Environnement',
                    sortable: false, visible: requiredFields.microHabitats && treesPF.microHabitats,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.microHabitats : ''}</div>)
                            : props.row.microHabitats || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.microHabitats)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Environnement')),
                {
                    name: i18n.t("Vigueur"), key: 'vigor', width: 110, category: 'État',
                    sortable: true, visible: requiredFields.vigor && treesPF.vigor,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {[props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) ? i18n.t("Mort") : props.row.cutDown ? props.row.vigor : ''}
                                </div>
                            ) : props.row.vigor || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.vigors} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isDead, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.vigors),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Cote sanitaire"), key: 'healthReview', width: 115, category: 'État',
                    sortable: true, visible: requiredFields.healthReview && treesPF.healthReview,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {[props.row.isDead, props.row.isStump].includes(i18n.t("Oui")) ? '0' : props.row.cutDown ? props.row.healthReview : ''}
                                </div>
                            ) : (props.row.healthReview || props.row.healthReview === 0 ? props.row.healthReview : '')
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.healthReviews} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isDead, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.healthReviews),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Risque"), key: 'risk', width: 110, category: 'État',
                    sortable: true, visible: requiredFields.risk && treesPF.risk,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? i18n.t("Faible") : props.row.cutDown ? props.row.risk : ''}
                                </div>
                            ) : props.row.risk || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.risks} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.risks),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Risque de basculement/rupture"), key: 'tippingRisk', width: 240, category: 'État',
                    sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? i18n.t("Faible") : props.row.cutDown ? props.row.tippingRisk : ''}
                                </div>
                            ) : props.row.tippingRisk || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.tippingRisks} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.tippingRisks),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Calibre de l'organe instable"), key: 'organCaliber', width: 210, category: 'État',
                    sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? '<150mm' : props.row.cutDown ? props.row.organCaliber : ''}
                                </div>
                            ) : props.row.organCaliber || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.organCalibers} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.organCalibers),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Cible"), key: 'target', width: 110, category: 'État',
                    sortable: true, visible: requiredFields.accurateRisk && treesPF.risk,
                    formatter: (props) => {
                        return [props.row.isEmpty, props.row.isStump].includes(i18n.t("Oui")) || props.row.cutDown
                            ? (
                                <div className='disabled'>
                                    {props.row.isStump === i18n.t("Oui") ? i18n.t("Occasionnelle") : props.row.cutDown ? props.row.target : ''}
                                </div>
                            ) : props.row.target || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.targets} isNullable />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && ![props.isEmpty, props.isStump].includes(i18n.t("Oui")) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.targets),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État')),
                {
                    name: i18n.t("Observation"), key: 'observation', width: 500, category: 'Observation',
                    sortable: true, visible: requiredFields.observation && treesPF.observation,
                    formatter: (props) => {
                        return props.row.cutDown
                            ? (<div className='disabled'>{props.row.observation}</div>)
                            : props.row.observation || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Observation')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Observation')),
                {
                    name: i18n.t("Coefficient de plantation"), key: 'plantationCoefficient', width: 195, category: 'Coefficients',
                    sortable: true, visible: requiredFields.plantationCoefficient && treesPF.plantationCoefficient,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.plantationCoefficient : ''}</div>)
                            : props.row.plantationCoefficient || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.plantationCoefficients} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.plantationCoefficients),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Coefficient de situation"), key: 'situationCoefficient', width: 180, category: 'Coefficients',
                    sortable: true, visible: requiredFields.situationCoefficient && treesPF.situationCoefficient,
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.situationCoefficient : ''}</div>)
                            : props.row.situationCoefficient || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.situationCoefficients} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.situationCoefficients),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Coefficient patrimonial"), key: 'patrimonialCoefficient', width: 180, category: 'Coefficients',
                    sortable: true, visible: requiredFields.patrimonialCoefficient && treesPF.patrimonialCoefficient && amenityFormulaType === 'Wallonie',
                    formatter: (props) => {
                        return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                            ? (<div className='disabled'>{props.row.cutDown ? props.row.patrimonialCoefficient : ''}</div>)
                            : props.row.patrimonialCoefficient || ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.patrimonialCoefficients} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.patrimonialCoefficients),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients')),
                ...treeOrgans.flatMap(({ organ, label }) => [
                    {
                        name: `${i18n.t("Symptômes")} ${label}`, key: `${organ}Symptoms`, width: 200, category: `VTA ${label}`,
                        sortable: false, visible: requiredFields[`${organ}Symptoms`] && treesPF[`${organ}Symptoms`],
                        formatter: (props) => {
                            return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                                ? (<div className='disabled'>{props.row.cutDown ? props.row[`${organ}Symptoms`] : ''}</div>)
                                : props.row[`${organ}Symptoms`] || ''
                        },
                        headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                        filterRenderer: p => <TextFilter p={p} />,
                        editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                        editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state[`${organ}Symptoms`])
                    },
                    {
                        name: `${i18n.t("Pathogènes")} ${label}`, key: `${organ}Pathogens`, width: 200, category: `VTA ${label}`,
                        sortable: false, visible: requiredFields[`${organ}Pathogens`] && treesPF[`${organ}Pathogens`],
                        formatter: (props) => {
                            return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                                ? (<div className='disabled'>{props.row.cutDown ? props.row[`${organ}Pathogens`] : ''}</div>)
                                : props.row[`${organ}Pathogens`] || ''
                        },
                        headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                        filterRenderer: p => <TextFilter p={p} />,
                        editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                        editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.pathogens)
                    },
                    {
                        name: `${i18n.t("Ravageurs")} ${label}`, key: `${organ}Pests`, width: 200, category: `VTA ${label}`,
                        sortable: false, visible: requiredFields[`${organ}Pests`] && treesPF[`${organ}Pests`],
                        formatter: (props) => {
                            return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                                ? (<div className='disabled'>{props.row.cutDown ? props.row[`${organ}Pests`] : ''}</div>)
                                : props.row[`${organ}Pests`] || ''
                        },
                        headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                        filterRenderer: p => <TextFilter p={p} />,
                        editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                        editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.pests)
                    },
                    ['trunk', 'branch'].includes(organ) && {
                        name: `${i18n.t("Épiphytes")} ${label}`, key: `${organ}Epiphytes`, width: 200, category: `VTA ${label}`,
                        sortable: false, visible: requiredFields[`${organ}Epiphytes`] && treesPF[`${organ}Epiphytes`],
                        formatter: (props) => {
                            return props.row.isEmpty === i18n.t("Oui") || props.row.cutDown
                                ? (<div className='disabled'>{props.row.cutDown ? props.row[`${organ}Epiphytes`] : ''}</div>)
                                : props.row[`${organ}Epiphytes`] || ''
                        },
                        headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)?.color),
                        filterRenderer: p => <TextFilter p={p} />,
                        editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && props.isEmpty !== i18n.t("Oui") && !props.cutDown,
                        editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.epiphytes)
                    },
                    ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`)),
                ]),
                {
                    name: i18n.t("Stabilité"), key: 'stability', width: 80, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.stability,
                    formatter: (props) => <div className='disabled'>
                        {props.row.circumference > 0 ? Math.round((props.row.height * 100) / (props.row.circumference / Math.PI)) : ''}
                    </div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />
                },
                {
                    name: `${i18n.t("Production de fruits")} (kg/an)`, key: 'fruitProduction', width: 200, category: 'Indicateurs',
                    sortable: true, editable: false, visible: treesPF.isFruit,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.fruitProduction}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Canopée de l'arbre")} (m²)`, key: 'treeCanopy', width: 175, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.canopy && requiredFields.crownDiameter && treesPF.canopy && subscription.canopyFormula && activeOrganization.subscription.canopyFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.treeCanopy}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Stockage annuel CO2")} (kg/an)`, key: 'carbonStock', width: 210, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.carbonStock && treesPF.carbonStock && subscription.carbonStockFormula && activeOrganization.subscription.carbonStockFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.carbonStock}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Stockage total CO2")} (kg/an)`, key: 'totalCarbonStock', width: 195, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.carbonStock && treesPF.carbonStock && subscription.carbonStockFormula && activeOrganization.subscription.carbonStockFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.totalCarbonStock}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Rafraîchissement")} (m²)`, key: 'coolingIndicator', width: 160, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.coolingIndicator && treesPF.coolingIndicator && subscription.coolingFormula && activeOrganization.subscription.coolingFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.coolingIndicator}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Rafraîchissement (énergie)")} (kWh/an)`, key: 'coolingEnergyIndicator', width: 255, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.coolingIndicator && treesPF.coolingIndicator && subscription.coolingFormula && activeOrganization.subscription.coolingFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.coolingEnergyIndicator}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Rafraîchissement (valeur)")} (€/an)`, key: 'coolingEconomicValue', width: 230, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.coolingIndicator && treesPF.coolingIndicator && subscription.coolingFormula && activeOrganization.subscription.coolingFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.coolingEconomicValue}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Production d'oxygène")} (kg/an)`, key: 'oxygenProduction', width: 210, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.oxygenProduction && treesPF.carbonStock,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.oxygenProduction}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Potentiel de biodiversité")} (/100)`, key: 'biodiversityIndex', width: 250, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.biodiversityIndex && treesPF.biodiversityIndex,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.biodiversityIndex * 100}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Valeur d'agrément")} (€)`, key: 'amenityValue', width: 190, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.amenityValue && treesPF.amenityValue && subscription.amenityFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.amenityValue}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Indicateurs')),
                ...this.getCustomCategoriesColumns(),
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Autres')),
                {
                    name: i18n.t("Date de création"), key: 'creationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.creationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Dernière modification"), key: 'modificationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.modificationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Latitude (y)"), key: 'lat', width: 160,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => {
                        const coordinates = GeoJsonUtil.projectMarkerCoordinates([props.row.long, props.row.lat], projections, projectionId);
                        return <div className='disabled'>{coordinates[1]}</div>
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props)
                },
                {
                    name: i18n.t("Longitude (x)"), key: 'long', width: 160,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => {
                        const coordinates = GeoJsonUtil.projectMarkerCoordinates([props.row.long, props.row.lat], projections, projectionId);
                        return <div className='disabled'>{coordinates[0]}</div>
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props)
                },
                {
                    name: i18n.t("Projet"), key: 'projectLabel', width: 170,
                    sortable: true, editable: false, visible: this.props.project?.type === 'folder',
                    formatter: (props) => <div className='disabled'>{props.row.projectLabel}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                }
            ];

            if (this.props.project.orderConfig)
                data.columns.sort((a, b) => {
                    const aOrder = FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Arbre', a.category)
                    const bOrder = FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Arbre', b.category)
                    return aOrder - bOrder;
                });

            this.setState(({ filters }) => {
                this.customFieldFilters.forEach(customFieldId => filters[customFieldId] = '');
                return { filters };
            });

            // Ajout des données
            if (!rows) {
                const layers = this.trees;
                for (const key in layers)
                    if (layers.hasOwnProperty(key))
                        data.rows.push(this.getRowValue(layers[key].feature, key, projectTags));

                data.rows.sort((a, b) => a.projectReference - b.projectReference);
                let initialOrder = [];
                data.rows.forEach(row => initialOrder.push(row.elementId));
                this.setState({ initialOrder: initialOrder });
            }
        }
        return data;
    }

    loadTreesStations = () => {
        return new Promise((resolve, reject) => {
            StationsService.getElementStations('Trees', this.props.project.id).then((treesStations) => {
                if (treesStations) {
                    this.setState(prevState => ({
                        treesStations,
                        data: {
                            ...prevState.data,
                            rows: prevState.data.rows.map(row => {
                                row.stations = treesStations[row.elementId];
                                return row;
                            })
                        }
                    }), () => resolve);
                } else if (this.props.isOnline) {
                    this.setState({ treesStations: {} });
                    reject();
                }
                else {
                    this.setState({ treesStations: {} });
                    resolve();
                }
            });
        });
    }

    getCustomColumns = (category, isDefaultCategory = true) => {
        const { activeOrganization, customFields, project } = this.props;
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;
        if (!areCustomFieldsAvailable) return [];

        const projectCustomFields = [...(project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map((projectCustomField) => {
            const customField = customFields.find(cf => cf.id === projectCustomField.customFieldId);
            if (customField) this.customFieldFilters.push(String(customField.id));

            return customField?.category === 'Arbre' ? {
                name: customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : ''), key: customField.id, width: 180, category: isDefaultCategory ? category.label : `${category.id}`,
                sortable: true, visible: true, customField,
                formatter: (props) => (
                    (props.row.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                        (props.row.isDead === i18n.t("Oui") && !customField.forDead) ||
                        (props.row.isStump === i18n.t("Oui") && !customField.forStump)
                        ? <div className='disabled'></div>
                        : props.row[String(customField.id)] ? (
                            customField.type === 'url'
                                ? <a href={props.row[String(customField.id)]?.includes('http') ? props.row[String(customField.id)] : '//' + props.row[String(customField.id)]} target='_blank' rel='noreferrer'>{props.row[String(customField.id)]}</a>
                                : customField.type === 'formula'
                                    ? FormattersUtil.formatFormulaCustomField(customField, props.row[String(customField.id)])
                                    : props.row[String(customField.id)] + (customField.type === 'number' && customField.unit?.trim() ? customField.unit : '')
                        ) : ''
                ),
                headerRenderer: (props) => this.getHeaderRenderer(props, category.color),
                filterRenderer: p => (
                    customField.type === 'boolean' ? <BooleanFilter p={p} />
                        : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? <TextFilter p={p} />
                            : customField.type === 'number' ? <NumberFilter p={p} step={customField.step} />
                                : customField.type === 'list' ? <DropDownFilter p={p} propertyOptions={customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))} isNullable />
                                    : null
                ),
                editable: (props) => RightsUtil.canWrite(this.props.rights?.trees) && !(
                    (props.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                    (props.isDead === i18n.t("Oui") && !customField.forDead) ||
                    (props.isStump === i18n.t("Oui") && !customField.forStump)
                ),
                editor: ({ row, column, onRowChange, onClose }) => this.getEditor(
                    customField.type === 'list' ? customField.isMultiple ? 'smart' : 'dropdown' : customField.type,
                    row, column, onRowChange, onClose, customField.dropdownCustomFieldValues?.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))
                ),
                editorOptions: { editOnClick: ['boolean', 'list'].includes(customField.type) }
            } : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    getCustomCategoriesColumns = () => {
        const { project } = this.props;
        const fieldCategories = project?.fieldCategories || [];
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === 'Arbre' && (project?.projectCustomFields || []).find(pcf => pcf.fieldCategoryId === fieldCategory.id));
        return fieldCategoriesToRender.flatMap(fieldCategory => this.getCustomColumns(fieldCategory, false));
    }

    getFilteredRows = () => {
        const { selectedElements } = this.props;
        const { filters, data, enableFilterRow } = this.state;
        let rows = [...data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!selectedElements?.length || selectedElements.find(el => el.feature.id === r.elementId))
                && (!filters.global?.trim().length || Object.keys(r).filter(property => !['id', 'elementId'].includes(property)).some(property => (r[property] || r[property] === 0) && $(`${r[property]}`).includes($(filters.global))))
                && (!enableFilterRow || (
                    (filters.vernacularName ? (r.vernacularName === filters.vernacularName || (filters.vernacularName === 'empty' && !r.vernacularName)) : true)
                    && (filters.gender ? (r.gender === filters.gender || (filters.gender === 'empty' && !r.gender)) : true)
                    && (filters.species ? (r.species === filters.species || (filters.species === 'empty' && !r.species)) : true)
                    && (filters.cultivar ? (r.cultivar === filters.cultivar || (filters.cultivar === 'empty' && !r.cultivar)) : true)
                    && (filters.treePort ? (r.treePort === filters.treePort || (filters.treePort === 'empty' && !r.treePort)) : true)
                    && (filters.coverType ? (r.coverType === filters.coverType || (filters.coverType === 'empty' && !r.coverType)) : true)
                    && (filters.vigor ? (r.vigor === filters.vigor || (filters.vigor === 'empty' && !r.vigor)) : true)
                    && (filters.healthReview || filters.healthReview === 0 ? (r.healthReview === filters.healthReview || (filters.healthReview === 'empty' && !r.healthReview && r.healthReview !== 0)) : true)
                    && (filters.ontogenicStage ? (r.ontogenicStage === filters.ontogenicStage || (filters.ontogenicStage === 'empty' && !r.ontogenicStage)) : true)
                    && (filters.risk ? (r.risk === filters.risk || (filters.risk === 'empty' && !r.risk)) : true)
                    && (filters.tippingRisk ? (r.tippingRisk === filters.tippingRisk || (filters.tippingRisk === 'empty' && !r.tippingRisk)) : true)
                    && (filters.organCaliber ? (r.organCaliber === filters.organCaliber || (filters.organCaliber === 'empty' && !r.organCaliber)) : true)
                    && (filters.target ? (r.target === filters.target || (filters.target === 'empty' && !r.target)) : true)
                    && (filters.plantationType ? (r.plantationType === filters.plantationType || (filters.plantationType === 'empty' && !r.plantationType)) : true)
                    && (filters.trunks ? r.trunks === Number(filters.trunks) : true)
                    && (filters.plantationCoefficient ? (r.plantationCoefficient === filters.plantationCoefficient || (filters.plantationCoefficient === 'empty' && !r.plantationCoefficient)) : true)
                    && (filters.situationCoefficient ? (r.situationCoefficient === filters.situationCoefficient || (filters.situationCoefficient === 'empty' && !r.situationCoefficient)) : true)
                    && (filters.patrimonialCoefficient ? (r.patrimonialCoefficient === filters.patrimonialCoefficient || (filters.patrimonialCoefficient === 'empty' && !r.patrimonialCoefficient)) : true)
                    && (filters.isEmpty ? r.isEmpty === filters.isEmpty : true)
                    && (filters.isDead ? r.isDead === filters.isDead : true)
                    && (filters.isStump ? r.isStump === filters.isStump : true)
                    && (filters.isIndexed ? r.isIndexed === filters.isIndexed : true)
                    && (filters.isRemarkable ? r.isRemarkable === filters.isRemarkable : true)
                    && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                    && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                    && (filters.interactions ? $(r.interactions)?.includes($(filters.interactions)) : true)
                    && (filters.microHabitats ? $(r.microHabitats)?.includes($(filters.microHabitats)) : true)
                    && (filters.rootSymptoms ? $(r.rootSymptoms)?.includes($(filters.rootSymptoms)) : true)
                    && (filters.rootPathogens ? $(r.rootPathogens)?.includes($(filters.rootPathogens)) : true)
                    && (filters.rootPests ? $(r.rootPests)?.includes($(filters.rootPests)) : true)
                    && (filters.collarSymptoms ? $(r.collarSymptoms)?.includes($(filters.collarSymptoms)) : true)
                    && (filters.collarPathogens ? $(r.collarPathogens)?.includes($(filters.collarPathogens)) : true)
                    && (filters.collarPests ? $(r.collarPests)?.includes($(filters.collarPests)) : true)
                    && (filters.trunkSymptoms ? $(r.trunkSymptoms)?.includes($(filters.trunkSymptoms)) : true)
                    && (filters.trunkPathogens ? $(r.trunkPathogens)?.includes($(filters.trunkPathogens)) : true)
                    && (filters.trunkPests ? $(r.trunkPests)?.includes($(filters.trunkPests)) : true)
                    && (filters.trunkEpiphytes ? $(r.trunkEpiphytes)?.includes($(filters.trunkEpiphytes)) : true)
                    && (filters.branchSymptoms ? $(r.branchSymptoms)?.includes($(filters.branchSymptoms)) : true)
                    && (filters.branchPathogens ? $(r.branchPathogens)?.includes($(filters.branchPathogens)) : true)
                    && (filters.branchPests ? $(r.branchPests)?.includes($(filters.branchPests)) : true)
                    && (filters.branchEpiphytes ? $(r.branchEpiphytes)?.includes($(filters.branchEpiphytes)) : true)
                    && (filters.leafSymptoms ? $(r.leafSymptoms)?.includes($(filters.leafSymptoms)) : true)
                    && (filters.leafPathogens ? $(r.leafPathogens)?.includes($(filters.leafPathogens)) : true)
                    && (filters.leafPests ? $(r.leafPests)?.includes($(filters.leafPests)) : true)
                    && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                    && (filters.observation ? $(r.observation)?.includes($(filters.observation)) : true)
                    && (filters.creationDate ? $(r.creationDate)?.includes($(filters.creationDate)) : true)
                    && (filters.modificationDate ? $(r.modificationDate)?.includes($(filters.modificationDate)) : true)
                    && (filters.projectLabel ? $(r.projectLabel)?.includes($(filters.projectLabel)) : true)
                    && (filters.projectReference ? r.projectReference === Number(filters.projectReference) : true)
                    && (filters.numberOfTrunks ? r.numberOfTrunks === Number(filters.numberOfTrunks) : true)
                    && (filters.trunkHeight ? r.trunkHeight === Number(filters.trunkHeight) : true)
                    && (filters.height ? r.height === Number(filters.height) : true)
                    && (filters.circumference ? r.circumference === Number(filters.circumference) : true)
                    && (filters.crownDiameter ? r.crownDiameter === Number(filters.crownDiameter) : true)
                    && (filters.plantingDate ? $(r.plantingDate)?.includes($(filters.plantingDate)) : true)
                    && (filters.age ? r.age === Number(filters.age) : true)
                    && (filters.fruitProduction ? Number(r.fruitProduction) === Number(filters.fruitProduction) : true)
                    && (filters.treeCanopy ? Number(r.treeCanopy) === Number(filters.treeCanopy) : true)
                    && (filters.carbonStock ? Number(r.carbonStock) === Number(filters.carbonStock) : true)
                    && (filters.totalCarbonStock ? Number(r.totalCarbonStock) === Number(filters.totalCarbonStock) : true)
                    && (filters.coolingIndicator ? Number(r.coolingIndicator) === Number(filters.coolingIndicator) : true)
                    && (filters.coolingEnergyIndicator ? Number(r.coolingEnergyIndicator) === Number(filters.coolingEnergyIndicator) : true)
                    && (filters.coolingEconomicValue ? Number(r.coolingEconomicValue) === Number(filters.coolingEconomicValue) : true)
                    && (filters.oxygenProduction ? Number(r.oxygenProduction) === Number(filters.oxygenProduction) : true)
                    && (filters.biodiversityIndex ? Number(r.biodiversityIndex) === Number(filters.biodiversityIndex) : true)
                    && (filters.amenityValue ? Number(r.amenityValue) === Number(filters.amenityValue) : true)
                    && data.columns.filter(column => column.customField).every(({ key, customField }) => (
                        filters[key] ? (
                            customField.type === 'boolean' ? r[key] === filters[key]
                                : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? $(r[key])?.includes($(filters[key]))
                                    : (r[key] === filters[key] || (filters[key] === 'empty' && !r[key]))
                        ) : true
                    )))
                );
        });
    }

    getHistoryPropertyValue = (properties) => {
        if (properties.length < 1) return;
        if (properties.find(x => ['vernacularName', 'gender', 'species', 'cultivar'].includes(x.property))) {
            const vernacularName = properties.find(x => x.property === 'vernacularName').value;
            const gender = properties.find(x => x.property === 'gender').value;
            const species = properties.find(x => x.property === 'species').value;
            const cultivar = properties.find(x => x.property === 'cultivar').value;

            const essence = this.props.essences.find(x => // On recherche l'essence correspondante aux données saisies
                x.vernacularName === (vernacularName || null)
                && x.gender === gender
                && x.species === (species || null)
                && x.cultivar === (cultivar || null)
            );

            return essence?.id || 0;
        } else return this.searchForPropertyOptions(properties[0].property, properties[0].value, properties[0].customField);
    }

    getRowValue = (feature, key, projectTags) => {
        const properties = feature.properties;
        const projectReference = properties.projectReference;
        const essence = this.props.essences.find(x => x.id === properties.essenceId);
        const vernacularName = essence?.vernacularName;
        const gender = essence?.gender;
        const species = essence?.species;
        const cultivar = essence?.cultivar;
        const lat = feature.geometry.coordinates[1];
        const long = feature.geometry.coordinates[0];
        const place = properties.place;
        const numberOfTrunks = properties.numberOfTrunks;
        const trunkHeight = Math.round((properties.dimensions.trunkHeight / 100) * 100) / 100;
        const biggestTrunk = TreesUtil.getBiggestTrunk(properties.trunks);
        const height = biggestTrunk ? Math.round((biggestTrunk.height / 100) * 100) / 100 : 0;
        const circumference = FormattersUtil.getTrunkCircumference(biggestTrunk ? biggestTrunk.circumference : 0, this.props.project?.trunkCircumferenceUnit);
        const crownDiameter = biggestTrunk ? Math.round((biggestTrunk.crownDiameter / 100) * 100) / 100 : 0;
        const treePort = this.props.treePorts.find(x => x.id === properties.treePortId)?.label;
        const coverType = this.props.coverTypes.find(x => x.id === properties.coverTypeId)?.label;
        const plantingDate = properties.plantingDate ? format(properties.plantingDate, 'dd/MM/yyyy') : '';
        const age = properties.age || '';
        const interactions = properties.interactionId.map(id => this.props.interactions.find(x => x.id === id).label).join(', ');
        const microHabitats = properties.microHabitatId.map(id => this.props.microHabitats.find(x => x.id === id).label).join(', ');
        const vigor = this.props.vigors.find(x => x.id === properties.vigorId)?.label;
        const healthReview = this.props.healthReviews.find(x => x.id === properties.healthReviewId)?.value;
        const ontogenicStage = this.props.ontogenicStages.find(x => x.id === properties.ontogenicStageId)?.value;
        const risk = this.props.risks.find(x => x.id === properties.riskId)?.label;
        const tippingRisk = this.props.tippingRisks.find(x => x.id === properties.tippingRiskId)?.label;
        const organCaliber = this.props.organCalibers.find(x => x.id === properties.organCaliberId)?.label;
        const target = this.props.targets.find(x => x.id === properties.targetId)?.label;
        const plantationType = this.props.plantationTypes.find(x => x.id === properties.plantationTypeId)?.label;
        const plantationCoefficient = this.props.plantationCoefficients.find(x => x.id === properties.plantationCoefficientId)?.value;
        const situationCoefficient = this.props.situationCoefficients.find(x => x.id === properties.situationCoefficientId)?.value;
        const patrimonialCoefficient = this.props.patrimonialCoefficients.find(x => x.id === properties.patrimonialCoefficientId)?.value;
        const fruitProduction = essence?.fruitProduction;
        const treeCanopy = (Math.round((Math.pow(crownDiameter / 2, 2) * Math.PI) * 100) / 100)?.toFixed(2);
        const carbonStock = properties.carbonStock?.toFixed(2);
        const trunks = properties.trunks?.length;
        const trunksWithCircumference = properties.trunks?.filter(t => t.circumference !== null) || [];
        const totalCarbonStock = (trunksWithCircumference.reduce((accumulator, trunk) => accumulator + FormulasUtil.getTreeTotalCarbonStock(carbonStock, trunk.circumference), 0))?.toFixed(2);
        const coolingIndicator = properties.coolingIndicator?.toFixed(2);
        const coolingEnergyIndicator = properties.coolingEnergyIndicator?.toFixed(2);
        const coolingEconomicValue = FormulasUtil.getCoolingEconomicValue(properties.coolingEnergyIndicator)?.toFixed(2);
        const oxygenProduction = FormulasUtil.getOxygenProduction(properties.carbonStock)?.toFixed(2);
        const biodiversityIndex = properties.biodiversityIndex?.toFixed(2);
        const amenityValue = properties.amenityValue?.toFixed(2);
        const isEmpty = properties.isEmpty ? i18n.t("Oui") : i18n.t("Non");
        const isDead = properties.isDead ? i18n.t("Oui") : i18n.t("Non");
        const isStump = properties.isStump ? i18n.t("Oui") : i18n.t("Non");
        const isIndexed = properties.isIndexed ? i18n.t("Oui") : i18n.t("Non");
        const isRemarkable = properties.isRemarkable ? i18n.t("Oui") : i18n.t("Non");
        let tags = '';

        if (properties.tagId)
            for (let i = 0; i < properties.tagId.length; i++) {
                const tag = projectTags.find(x => x.id === properties.tagId[i]);
                if (tag) tags += !tags ? tag.label : ', ' + tag.label
            }
        const observation = properties.observation;
        const customReference = properties.customReference;
        const creationDate = `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`;
        const modificationDate = `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}`;
        const projectLabel = this.props.project?.projectLabels?.find(pl => pl.id === feature.projectId)?.label;
        const stations = this.state.treesStations ? this.state.treesStations[feature.id] : null;

        const row = {
            id: key, elementId: feature.id, projectReference, vernacularName, gender, species, cultivar, lat, long, place, trunkHeight, height, circumference, crownDiameter,
            numberOfTrunks, treePort, coverType, plantationType, plantingDate, age, trunks, interactions, microHabitats, vigor, risk, tippingRisk, organCaliber, target, healthReview,
            ontogenicStage, fruitProduction, treeCanopy, carbonStock, totalCarbonStock, coolingIndicator, coolingEnergyIndicator, coolingEconomicValue, oxygenProduction, biodiversityIndex,
            amenityValue, plantationCoefficient, situationCoefficient, patrimonialCoefficient, isEmpty, isDead, isStump, isIndexed, isRemarkable, tags, observation, customReference,
            cutDown: properties.toCutDown === 4, creationDate, modificationDate, projectLabel, stations,
            ...treeOrgans.reduce((prevValue, { organ }) => ({
                ...prevValue,
                [`${organ}Symptoms`]: properties[`${organ}SymptomId`].map(id => this.props[`${organ}Symptoms`].find(x => x.id === id).label).join(', '),
                [`${organ}Pathogens`]: properties[`${organ}PathogenId`].map(id => this.props.pathogens.find(x => x.id === id).label).join(', '),
                [`${organ}Pests`]: properties[`${organ}PestId`].map(id => this.props.pests.find(x => x.id === id).label).join(', '),
                [`${organ}Epiphytes`]: ['trunk', 'branch'].includes(organ) && properties[`${organ}EpiphyteId`].map(id => this.props.epiphytes.find(x => x.id === id).label).join(', ')
            }), {})
        };

        if (properties.customFields) {
            Object.keys(properties.customFields).forEach(key => {
                const customField = this.props.customFields.find(customField => customField.id === Number(key));
                if (customField) {
                    row[key] = customField.type === 'boolean' ? properties.customFields[key] === 'true' ? i18n.t("Oui") : i18n.t("Non")
                        : customField.type === 'date'
                            ? DatesUtil.getFormattedLocaleDateString(properties.customFields[key])
                            : customField.type === 'list'
                                ? customField.isMultiple
                                    ? properties.customFields[key].split(',').map(id => customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(id))?.label).join(', ')
                                    : customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(properties.customFields[key]))?.label
                                : properties.customFields[key];
                }
            });
        }

        return row;
    }

    getProperties = (properties) => {
        let newProperties = { ...properties };

        const removeUnallowedProperties = (properties, checkProperty) => {
            if (properties.customFields)
                Object.keys(properties.customFields).forEach(customFieldId => {
                    const customField = this.props.customFields.find(cf => cf.id === Number(customFieldId));
                    if (customField && !customField[checkProperty]) delete properties.customFields[customFieldId];
                });
        }

        if (newProperties.trunks?.length) {
            const biggestTrunk = TreesUtil.getBiggestTrunk(newProperties.trunks);
            if (biggestTrunk) {
                biggestTrunk.height = newProperties.height ? newProperties.height * 100 : biggestTrunk.height;
                biggestTrunk.circumference = newProperties.circumference || biggestTrunk.circumference;
                biggestTrunk.crownDiameter = newProperties.crownDiameter ? newProperties.crownDiameter * 100 : biggestTrunk.crownDiameter;
            }
        }

        if (properties.isEmpty || properties.isDead) {
            newProperties = {
                ...newProperties,
                coverTypeId: 0,
                interactionId: [],
                ontogenicStageId: 0,
                treePortId: 0,
                isStump: false
            };
        }

        if (properties.isEmpty) {
            newProperties = {
                ...newProperties,
                dimensions: {
                    height: 0,
                    circumference: 0,
                    crownDiameter: 0,
                    trunkHeight: 0
                },
                essenceId: 0,
                vigorId: 0,
                healthReviewId: 0,
                riskId: 0,
                tippingRiskId: 0,
                organCaliberId: 0,
                targetId: 0,
                plantationCoefficientId: 0,
                situationCoefficientId: 0,
                patrimonialCoefficientId: 0,
                numberOfTrunks: 0,
                trunks: [],
                isIndexed: false,
                isRemarkable: false,
                ...treeOrgans.reduce((prevValue, { organ }) => ({ ...prevValue, [`${organ}SymptomId`]: [], [`${organ}PathogenId`]: [], [`${organ}PestId`]: [], [`${organ}EpiphyteId`]: [] }), {})
            };
            removeUnallowedProperties(newProperties, 'forEmpty');
        } else if (properties.isDead) {
            newProperties = {
                ...newProperties,
                plantationTypeId: 0,
                vigorId: 1,
                healthReviewId: 1
            };
            removeUnallowedProperties(newProperties, 'forDead');
        } else if (properties.isStump) {
            newProperties = {
                ...newProperties,
                treePortId: 0,
                numberOfTrunks: 1,
                dimensions: {
                    ...newProperties.dimensions,
                    height: 0,
                    crownDiameter: 0,
                    trunkHeight: 0
                },
                trunks: [{ id: uuidv4(), propertiesId: properties.treeId, projectId: properties.projectId, height: null, circumference: null, crownDiameter: null, order: 0 }],
                ontogenicStageId: 0,
                vigorId: 1,
                riskId: 1,
                tippingRiskId: 1,
                organCaliberId: 1,
                targetId: 1,
                healthReviewId: 1,
            };
            removeUnallowedProperties(newProperties, 'forStump');
        }

        return newProperties;
    }

    getPropertyName = (columnKey) => {
        let property = columnKey;
        if (['vernacularName', 'gender', 'species', 'cultivar'].includes(columnKey)) property = 'essenceId';
        else if (['treePort', 'coverType', 'plantationType', 'vigor', 'risk', 'tippingRisk', 'organCaliber', 'target',
            'healthReview', 'ontogenicStage', 'plantationCoefficient', 'situationCoefficient', 'patrimonialCoefficient'].includes(columnKey))
            property = property + 'Id';
        else if (['interactions', 'microHabitats', 'tags', ...treeOrgans.flatMap(({ organ }) => [`${organ}Symptoms`, `${organ}Pathogens`, `${organ}Pests`, `${organ}Epiphytes`])].includes(columnKey))
            property = property.substring(0, property.length - 1) + 'Id'
        return property;
    }

    getTargetRowPropertyValue = (columnKey, sourceRow, targetRow, customField) => {
        if (!sourceRow[columnKey]) return;
        if (['vernacularName', 'gender', 'species', 'cultivar'].includes(columnKey)) {
            const vernacularName = columnKey !== 'vernacularName' ? targetRow.vernacularName : sourceRow.vernacularName;
            const gender = columnKey !== 'gender' && !sourceRow[columnKey] ? targetRow.gender : sourceRow.gender;
            const species = columnKey === 'cultivar' && !sourceRow.cultivar
                ? targetRow.species
                : (['species', 'cultivar'].includes(columnKey) ? sourceRow?.species : null);
            const cultivar = columnKey === 'cultivar' ? sourceRow?.cultivar : null;
            const essence = this.props.essences.find(x => // On recherche l'essence correspondante aux données saisies
                columnKey === 'vernacularName'
                    ? x.vernacularName === (vernacularName || null)
                    : (
                        x.gender === (gender || null)
                        && x.species === (species || null)
                        && x.cultivar === (cultivar || null)
                    )
            );
            return essence?.id;
        }
        return this.searchForPropertyOptions(columnKey, sourceRow[columnKey], customField);
    }

    updateElementCustomFields = (feature, property, value) => {
        if (value?.toISOString) value = value.toISOString();
        if (value) {
            if (!feature.properties.customFields) feature.properties.customFields = {};
            feature.properties.customFields[property] = value;
        } else if (feature.properties.customFields)
            delete feature.properties.customFields[property];
    }

    changeTreesToModify = (treesToModify) => {
        if (!this.props.elementsHaveBeenModified) this.props.setElementsHaveBeenModified(true);
        this.setState({ treesToModify: treesToModify });
    }

    changeTreesToModifyLocally = (elementId, property, value, treesToModify, customField) => {
        const index = treesToModify.findIndex(x => x.id === elementId);
        if (customField) this.updateElementCustomFields(treesToModify[index], property, value)
        else treesToModify[index].properties[property] = value;

        return treesToModify;
    }

    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    searchForPropertyOptions = (property, value, customField) => {
        let propertyOptions;
        switch (property) {
            case 'treePort': propertyOptions = this.state.treePorts; break;
            case 'coverType': propertyOptions = this.state.coverTypes; break;
            case 'vigor': propertyOptions = this.state.vigors; break;
            case 'risk': propertyOptions = this.state.risks; break;
            case 'tippingRisk': propertyOptions = this.state.tippingRisks; break;
            case 'organCaliber': propertyOptions = this.state.organCalibers; break;
            case 'target': propertyOptions = this.state.targets; break;
            case 'plantationType': propertyOptions = this.state.plantationTypes; break;
            case 'healthReview': propertyOptions = this.state.healthReviews; break;
            case 'ontogenicStage': propertyOptions = this.state.ontogenicStages; break;
            case 'plantationCoefficient': propertyOptions = this.state.plantationCoefficients; break;
            case 'situationCoefficient': propertyOptions = this.state.situationCoefficients; break;
            case 'patrimonialCoefficient': propertyOptions = this.state.patrimonialCoefficients; break;
            case 'interactions': propertyOptions = this.state.interactions; break;
            case 'microHabitats': propertyOptions = this.state.microHabitats; break;
            case 'rootSymptoms': propertyOptions = this.state.rootSymptoms; break;
            case 'collarSymptoms': propertyOptions = this.state.collarSymptoms; break;
            case 'trunkSymptoms': propertyOptions = this.state.trunkSymptoms; break;
            case 'branchSymptoms': propertyOptions = this.state.branchSymptoms; break;
            case 'leafSymptoms': propertyOptions = this.state.leafSymptoms; break;
            case 'rootPathogens': case 'collarPathogens': case 'trunkPathogens': case 'branchPathogens': case 'leafPathogens':
                propertyOptions = this.state.pathogens; break;
            case 'rootPests': case 'collarPests': case 'trunkPests': case 'branchPests': case 'leafPests':
                propertyOptions = this.state.pests; break;
            case 'trunkEpiphytes': case 'branchEpiphytes':
                propertyOptions = this.state.epiphytes; break;
            case 'tags': propertyOptions = this.state.tags; break;
            case 'isEmpty': case 'isDead': case 'isStump': case 'isIndexed': case 'isRemarkable':
                propertyOptions = [{ label: i18n.t("Oui"), id: true }, { label: i18n.t("Non"), id: false }]; break;
            default:
                if (customField?.type === 'boolean')
                    propertyOptions = [{ label: i18n.t("Oui"), id: 'true' }, { label: i18n.t("Non"), id: 'false' }];
                else if (customField?.dropdownCustomFieldValues)
                    propertyOptions = customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }));
                break;
        }
        if (!propertyOptions) return value;
        else {
            if (customField?.isMultiple || ['interactions', 'microHabitats', ...treeOrgans.flatMap(({ organ }) => [`${organ}Symptoms`, `${organ}Pathogens`, `${organ}Pests`, `${organ}Epiphytes`]), 'tags'].includes(property)) {
                const values = value?.split(',') || [];
                let ids = [];
                values.forEach(value => {
                    let linkedValue;
                    if (value.trim() !== '') linkedValue = propertyOptions.find(x => x.label === value.trim());
                    if (linkedValue) ids.push(linkedValue.id);
                    else if (property === 'tags') {
                        let trueValue = value.substring(0, 2).trim() === '-' ? value.substring(2).trim() : value.trim();
                        if (trueValue !== '')
                            ids.push(trueValue);
                    }
                });
                return customField ? ids.join(',') : ids;
            }

            let option = propertyOptions.find(x => x.label === value);
            return customField ? (option?.id ? String(option.id) : '') : option?.id || 0;
        };
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];
        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { treesToModify, treesToDelete } = this.state;

            let modificationsToCreate = [];
            let previousElementsId = [];
            let previousElementsProperties = [];
            let essences = [];
            let rowsToSend = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(x => x.elementId === modification.elementId);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    if (property !== 'essenceId') {
                        let value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                        treesToModify = this.changeTreesToModifyLocally(modification.elementId, property, value, treesToModify, modification.customField);
                    } else {
                        const essenceIndex = essences.findIndex(essence => essence.elementId === modification.elementId);
                        if (essenceIndex === -1) essences.push({ elementId: modification.elementId, properties: [{ property: modification.property, value: modification.oldValue }] });
                        else essences[essenceIndex].properties = [...essences[essenceIndex].properties, { property: modification.property, value: modification.oldValue }];
                    }
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    treesToDelete = treesToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }
            });

            essences.forEach(essence => {
                const essenceId = treesToModify.find(x => x.id === essence.elementId).properties.essenceId;
                const treeToModifyEssence = this.props.essences.find(x => x.id === essenceId);
                const pushIfNotFound = (property) => {
                    if (!essence.properties.find(x => x.property === property))
                        essence.properties.push({
                            property: property,
                            value: treeToModifyEssence[property]
                        });
                }
                pushIfNotFound('vernacularName');
                pushIfNotFound('gender');
                pushIfNotFound('species');
                pushIfNotFound('cultivar');

                const value = this.getHistoryPropertyValue(essence.properties);
                treesToModify = this.changeTreesToModifyLocally(essence.elementId, 'essenceId', value, treesToModify);
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [];
                let actualElementsProperties = [];
                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data, treesToModify, treesToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: rowsToSend, treesToModify }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];
        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { treesToModify, treesToDelete } = this.state;

            let modificationsToCreate = [];
            let nextElementsId = [];
            let nextElementsProperties = [];
            let essences = [];
            let rowsToSend = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(x => x.elementId === modification.elementId);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    if (property !== 'essenceId') {
                        let value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                        treesToModify = this.changeTreesToModifyLocally(modification.elementId, property, value, treesToModify, modification.customField);
                    } else {
                        const essenceIndex = essences.findIndex(essence => essence.elementId === modification.elementId);
                        if (essenceIndex === -1) essences.push({ elementId: modification.elementId, properties: [{ property: modification.property, value: modification.oldValue }] });
                        else essences[essenceIndex].properties = [...essences[essenceIndex].properties, { property: modification.property, value: modification.oldValue }];
                    }
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    treesToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            essences.forEach(essence => {
                const essenceId = treesToModify.find(x => x.id === essence.elementId).properties.essenceId;
                const treeToModifyEssence = this.props.essences.find(x => x.id === essenceId);
                const pushIfNotFound = (property) => {
                    if (!essence.properties.find(x => x.property === property))
                        essence.properties.push({
                            property: property,
                            value: treeToModifyEssence[property]
                        });
                }
                pushIfNotFound('vernacularName');
                pushIfNotFound('gender');
                pushIfNotFound('species');
                pushIfNotFound('cultivar');

                let value = this.getHistoryPropertyValue(essence.properties);
                treesToModify = this.changeTreesToModifyLocally(essence.elementId, 'essenceId', value, treesToModify);
            });

            let modificationsHistory;
            let actualElementsId = [];
            let actualElementsProperties = [];
            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data, treesToModify, treesToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: rowsToSend, treesToModify }));
        }
    }

    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);

    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        const { treesToModify, data } = this.state;
        let property = this.getPropertyName(columnKey);
        const customField = data.columns.find(column => column.key === columnKey)?.customField;

        const shouldUpdate = (row, columnKey) => {
            if ((row.isEmpty === i18n.t("Oui") || row.isDead === i18n.t("Oui"))
                && ['coverType', 'interactions', 'microHabitats', ...treeOrgans.flatMap(({ organ }) => [`${organ}Symptoms`, `${organ}Pathogens`, `${organ}Pests`, `${organ}Epiphytes`]),
                    'observation', 'age', 'ontogenicStage', 'risk', 'tippingRisk', 'organCaliber', 'target',
                    'numberOfTrunks', 'trunks', 'isStump', 'isIndexed', 'isRemarkable', 'vigor', 'healthReview'].includes(columnKey))
                return false;
            if (row.isEmpty === i18n.t("Oui") && (
                ['vernacularName', 'gender', 'species', 'cultivar', 'treePort', 'trunkHeight', 'height', 'circumference', 'crownDiameter', 'plantationCoefficient', 'situationCoefficient', 'patrimonialCoefficient'].includes(columnKey)
                || (customField && !customField.forEmpty)
            ))
                return false;
            if (row.isDead === i18n.t("Oui") && (columnKey === 'plantationType' || (customField && !customField.forDead)))
                return false;
            if (row.isStump === i18n.t("Oui") && (customField && !customField.forStump))
                return false;

            return true;
        }

        let rowsUpdated = false;
        targetRows.forEach(row => {
            if (shouldUpdate(row, columnKey)) {
                rowsUpdated = true;
                const value = this.getTargetRowPropertyValue(columnKey, sourceRow, row, customField);
                const index = treesToModify.findIndex(x => x.id === row.elementId);
                const feature = JSON.parse(JSON.stringify(this.trees[row.id].feature));
                if (index === -1) { // On modifie les propriétés de l'arbre
                    if ('trunkHeight' === property) feature.properties.dimensions[property] = Number(value * 100);
                    else if (customField) this.updateElementCustomFields(feature, property, value);
                    else feature.properties[property] = value;
                    treesToModify.push(feature);
                } else {
                    if ('trunkHeight' === property) feature.properties.dimensions[property] = Number(value * 100);
                    else if (customField) this.updateElementCustomFields(treesToModify[index], property, value);
                    else treesToModify[index].properties[property] = value;
                }
            }
        });
        if (rowsUpdated) this.changeTreesToModify(treesToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            if (!shouldUpdate(row, columnKey)) return row;
            if (['vernacularName', 'gender', 'species', 'cultivar'].includes(columnKey)) {
                modificationsToCreate.push({ property: 'vernacularName', elementId: row.elementId, oldValue: row.vernacularName });
                modificationsToCreate.push({ property: 'gender', elementId: row.elementId, oldValue: row.gender });
                modificationsToCreate.push({ property: 'species', elementId: row.elementId, oldValue: row.species });
                modificationsToCreate.push({ property: 'cultivar', elementId: row.elementId, oldValue: row.cultivar });

                let newRow;
                if (columnKey === 'gender') newRow = { ...row, gender: sourceRow.gender, species: '', cultivar: '' };
                if (columnKey === 'species') newRow = sourceRow.species
                    ? { ...row, gender: sourceRow.gender, species: sourceRow.species, cultivar: '' }
                    : { ...row, species: sourceRow.species, cultivar: '' };
                if (columnKey === 'cultivar') newRow = sourceRow.cultivar
                    ? { ...row, gender: sourceRow.gender, species: sourceRow.species, cultivar: sourceRow.cultivar }
                    : { ...row, cultivar: sourceRow.cultivar };
                if (columnKey === 'vernacularName') newRow = sourceRow.vernacularName
                    ? { ...row, vernacularName: sourceRow.vernacularName, gender: sourceRow.gender, species: sourceRow.species, cultivar: sourceRow.cultivar }
                    : { ...row, vernacularName: sourceRow.vernacularName, gender: '', species: '', cultivar: '' };
                else {
                    const vernacularName = this.props.essences.find(
                        essence => essence.gender === (newRow.gender || null) && essence.species === (newRow.species || null) && essence.cultivar === (newRow.cultivar || null)
                    )?.vernacularName || '';
                    newRow = { ...newRow, vernacularName };
                };
                return newRow;
            }

            modificationsToCreate.push({ property: columnKey, elementId: row.elementId, oldValue: row[columnKey], customField });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });

        WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: newRows, treesToModify });
        this.pushToModificationsHistory(modificationsToCreate);
        return newRows;
    }

    handleSubmit = (shouldClose) => {
        const { project, projects, formulas } = this.props;
        const tableToShow = this.state.tableToShow;
        if (tableToShow) this.setState({ tableToShow: null });

        const guidRegExp = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}');
        let tagsToAdd = [];
        let { treesToModify, treesToDelete } = this.state;
        let layersToModify = [];
        let treesNotToModifyAnymore = [];
        treesToModify.forEach(treeToModify => {
            const properties = this.getProperties(treeToModify.properties);
            if (JSON.stringify(properties) === JSON.stringify(this.trees.find(x => x.feature.id === treeToModify.id).feature.properties)
                || treesToDelete.find(tree => tree.id === treeToModify.id))
                treesNotToModifyAnymore.push(treesToModify);
            else treeToModify.properties = properties;
        });
        treesToModify = treesToModify.filter(tree => !treesNotToModifyAnymore.includes(tree));
        for (let i = 0; i < treesToModify.length; i++) { // Pour chaque arbre à modifier
            layersToModify.push(this.props.linkedTrees.find(x => x[0].feature.id === treesToModify[i].id));
            let tagId = treesToModify[i].properties.tagId;
            if (tagId) {
                for (let j = 0; j < tagId.length; j++) { // Pour chaque tag de l'arbre
                    let tag = tagId[j];
                    // Si le tag n'est pas un GUID et n'est pas encore dans la liste d es tags à ajouter
                    if (!guidRegExp.test(tag)) {
                        if (!tagsToAdd.find(x => x.label === tag))
                            tagsToAdd.push({ id: uuidv4(), label: tag, projectId: this.props.project.id, category: 'Arbre' });
                        tagId[j] = tagsToAdd.find(x => x.label === tag).id;
                    }
                }
            }
        }

        if (tagsToAdd.length > 0) {
            ProjectsService.addProjectTags(tagsToAdd).then(response => {
                if (response) {
                    let project = this.props.project;
                    project.tags = response;
                    this.props.setProject(project);
                }
            });
        }

        if (treesToModify.length > 0 && treesToModify.length === layersToModify.length) {
            this.props.showLoader(true, 'modal', i18n.t("Mise à jour des arbres en cours..."));
            UpdatesUtil.bulkUpdateTrees(layersToModify, treesToModify, this.props.fieldList, this.props.project.id, this.props.treesLayer, 'updating', this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps }).finally(() => {
                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Arbres"));
                this.props.updateHeatmaps({ updateBaseRadius: true });
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });
        } else {
            if (treesToModify.length > 0) showToast('trees_updated');
            if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
        }

        if (treesToDelete.length > 0) {
            const layersToDelete = this.trees.filter(tree => treesToDelete.find(treeToDelete => treeToDelete.elementId === tree.feature.id));

            this.props.showLoader(true, 'modal', i18n.t("Suppression des arbres en cours..."));
            TreesService.removeTrees(layersToDelete.map(tree => tree.feature), this.props.webSocketHubs).finally(() => {
                // Suppression des arbres sur la carte
                this.props.treesLayer.eachLayer(layer => {
                    if (layersToDelete.find(l => l.feature.id === layer.feature?.id))
                        this.props.treesLayer.removeLayer(layer); // On supprime l'élément du layer
                });

                this.props.treesLayerNotClustered.eachLayer(layer => {
                    if (layersToDelete.find(l => l.feature.id === layer.feature?.id))
                        this.props.treesLayerNotClustered.removeLayer(layer); // On supprime l'élément du layer
                });

                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Arbres"));
                this.props.updateHeatmaps({ updateBaseRadius: true });
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });

            // Suppression des liens
            const ids = treesToDelete.map(ttd => ttd.elementId);
            if (ids.length && project?.linkedElements?.length) {
                let updateProject = JSON.parse(JSON.stringify(project));
                updateProject.linkedElements = updateProject.linkedElements.filter(le => !ids.includes(le.elementId) && !ids.includes(le.linkedElementId));
                if (updateProject.linkedElements.length !== project.linkedElements.length)
                    ProjectsUtil.updateProjectsInProps(updateProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
            }
        } else if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);

        this.props.setElementsHaveBeenModified(false);
        this.setState({ treesToModify: [], treesToDelete: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));

    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (let property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    clearFilters = () => {
        const filters = JSON.parse(JSON.stringify(initialFilters));
        this.state.data.columns.filter(column => column.customField).forEach(column => filters[String(column.customField.id)] = '');
        this.setState({ filters });
    }

    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(x => x.elementId === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            const customField = this.state.data.columns.find(column => column.key === sortColumn)?.customField;
            if (['projectReference', 'lat', 'long', 'trunkHeight', 'height', 'circumference', 'crownDiameter', 'age', 'healthReview', 'trunks', 'numberOfTrunks',
                'ontogenicStage', 'fruitProduction', 'treeCanopy', 'carbonStock', 'totalCarbonStock', 'coolingIndicator', 'coolingEnergyIndicator', 'coolingEconomicValue',
                'oxygenProduction', 'biodiversityIndex', 'amenityValue', 'plantationCoefficient', 'situationCoefficient', 'patrimonialCoefficient'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (['creationDate', 'modificationDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else if (sortColumn === 'customReference')
                rows = rows.sort(({ customReference: a }, { customReference: b }) => {
                    if (!a && b) return sortDirection === 'DESC' ? -1 : 1;
                    const aIsNumber = a && !isNaN(a) ? true : false, bIsNumber = b && !isNaN(b) ? true : false;
                    return aIsNumber && bIsNumber ? (parseInt(a) - parseInt(b)) : (
                        aIsNumber ? -1 : (bIsNumber ? 1 : (a || '').localeCompare((b || '')))
                    );
                });
            else if (customField?.type === 'number')
                rows = rows.sort((a, b) => {
                    if (!a[sortColumn]) return -1;
                    if (!b[sortColumn]) return 1;
                    return Number(a[sortColumn]) - Number(b[sortColumn]);
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    resetRow = (_, { rowIdx }) => {
        let treesToModify = this.state.treesToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        const layers = this.trees;
        let filteredRows = this.getFilteredRows();
        let rowToSend;
        for (const key in layers) {
            if (layers[key].feature.id === filteredRows[rowIdx].elementId) {
                const index = treesToModify.findIndex(x => x.id === filteredRows[rowIdx].elementId);
                if (index !== -1) {
                    treesToModify[index] = JSON.parse(JSON.stringify(layers[key].feature));
                    const newDisplayedData = this.getRowValue(layers[key].feature, key, this.state.tags);
                    let modificationsToCreate = [];
                    let rowIndex = data.rows.findIndex(x => x.elementId === filteredRows[rowIdx].elementId);
                    const properties = [...new Set([...Object.keys(newDisplayedData), ...Object.keys(data.rows[rowIndex])])];
                    for (const property of properties)
                        if (data.rows[rowIndex][property] !== newDisplayedData[property]) {
                            const customField = data.columns.find(column => column.key === Number(property))?.customField;
                            modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].elementId, oldValue: data.rows[rowIndex][property], customField });
                        }
                    if (modificationsToCreate.length > 0)
                        this.pushToModificationsHistory(modificationsToCreate);
                    data.rows[rowIndex] = newDisplayedData;
                    rowToSend = data.rows[rowIndex];
                    this.updateSelectedRow(newDisplayedData);
                }
            }
        }

        this.setState({ data, treesToModify }, () => {
            if (rowToSend) WebSocketUtil.updateForm(this.props.webSocketHubs, 'TreeTable' + this.props.project.id, { rows: [rowToSend], treesToModify })
        });
    }

    deleteRow = (_, { rowIdx }) => {
        let treesToDelete = this.state.treesToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.data.rows.find(element => filteredRows[rowIdx].id === element.id);
        treesToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: initialElement.elementId, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, treesToDelete, selectedRow: null, selectedColumn: null });
    }

    exportXLSX = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        TreesService.exportTreesFromProjectAsExcel(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportSHP = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        TreesService.exportTreesAsSHP(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportPDF = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        TreesService.exportTreesAsPDF(this.props.project.id, elementsToExport, { projection });
        showToast('pdfs_exporting');
    }

    exportPhotos = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        FilesService.exportProjectPhotos(this.props.project.id, ['trees'], elementsToExport);
        showToast('photos_exporting');
    }

    showRowElement = (_, { rowIdx }) => {
        this.props.setTableState({ ...this.state, trees: this.trees, timestamp: new Date().toISOString(), selectedRow: null, selectedColumn: null }).then(() => {
            const layers = this.trees;
            for (const key in layers) {
                if (layers[key].feature.id === this.getFilteredRows()[rowIdx].elementId)
                    this.props.showElement('Arbres', layers[key].feature, 'TreeTable');
            }
        });
    }

    showRowElementOnMinimap = (_, { rowIdx }) => {
        const layers = this.trees;
        for (const key in layers)
            if (layers[key].feature.id === this.getFilteredRows()[rowIdx].elementId)
                this.setState({ featureToShow: layers[key].feature });
    }

    showFilteredElements = () => {
        this.props.setTableState({ ...this.state, trees: this.trees, timestamp: new Date().toISOString(), selectedRow: null, selectedColumn: null }).then(() => {
            this.props.showElements('Arbres', this.getFilteredRows())
        });
    }

    updateForm = (state) => {
        let rows = JSON.parse(JSON.stringify(this.state.data.rows));
        let treesToModify = JSON.parse(JSON.stringify(this.state.treesToModify));
        let selectedRow = this.state.selectedRow ? JSON.parse(JSON.stringify(this.state.selectedRow)) : null;
        const stateParsed = JSON.parse(state);
        const newRows = stateParsed.rows;
        newRows.forEach(newRow => {
            const index = rows.findIndex(r => r.elementId === newRow.elementId);
            if (index !== -1) rows[index] = newRow;
            if (selectedRow?.elementId === newRow.elementId) selectedRow = newRow;
        });
        const newTreesToModify = stateParsed.treesToModify;
        newTreesToModify.forEach(newTreeToModify => {
            const index = treesToModify.findIndex(ttm => ttm.id === newTreeToModify.id);
            if (index !== -1) treesToModify[index] = newTreeToModify;
            else treesToModify.push(newTreeToModify);
        });

        this.setState(prevState => ({ isLoading: false, data: { ...prevState.data, rows }, treesToModify, selectedRow }));
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        project: state.project,
        projects: state.projects,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        tableState: state.tableState,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        projections: state.projections,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs,
        formulas: state.formulas,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setTableState,
    setProject,
    setProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeTable);