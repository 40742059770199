import React, { Component } from 'react';
// Composants
import SharingManagement from '../Utils/SharingManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faChartLine, faPenToSquare, faShareFromSquare, faSignOutAlt, faTimesCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Form, Grid, Select, Checkbox, Input, Button, Dimmer, Loader, Popup, Message } from 'semantic-ui-react';
import { Slider } from 'react-semantic-ui-range';
// Librairies
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Bar, Doughnut, HorizontalBar, Line, Pie, Polar, Radar } from 'react-chartjs-2';
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setCustomCharts } from '../../actionCreators/componentsActions';
// Services
import CustomChartsService from '../../services/CustomChartsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import GreenSpacesUtil from '../../utils/GreenSpacesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import RightsUtil from '../../utils/RightsUtil';
import TreesUtil from '../../utils/TreesUtil';

const initialState = {
    displayObject: null,
    selectedCustomChart: 0,
    chartToModify: 0,
    chartToShare: 0,
    sharingValues: [],
    isCreating: false,
    newCustomChartName: null,
    property: null,
    secondProperty: 'number',
    chartType: null,
    initialData: null,
    data: null,
    options: null,
    config: {
        title: '',
        saveFilters: false,
        filters: null,
        showZero: false,
        numberStep: null,
        secondNumberStep: [],
        orientation: 'vertical',
        displayAxesLabel: false,
        xLabel: null,
        yLabel: null,
        displayLegend: false,
        legendPosition: 'top',
        displayLabels: true,
        valueType: 'value',
        steppedLine: false,
        lineTension: 0.4,
        pointStyle: 'rect',
        pointRadius: 10,
        showLine: false,
        fill: false,
        backgroundColors: null,
        secondBackgroundColors: {},
        pointColor: 'rgba(0, 0, 0, 0.7)',
        lineColor: 'rgba(0, 0, 0, 0.7)',
        fillColor: 'rgba(104, 189, 70, 0.7)'
    }
}

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

class CustomCharts extends Component {
    state = {
        ...initialState,
        linkToProject: true,
        category: 'trees',
        customCharts: null,
        interactions: null,
        microHabitats: null,
        rootSymptoms: null,
        collarSymptoms: null,
        trunkSymptoms: null,
        branchSymptoms: null,
        leafSymptoms: null,
        pathogens: null,
        pests: null,
        epiphytes: null,
        tags: null,
        step: 1,
        areFiltersApplied: false,
        isLoading: true,
        isSaving: false,
        isDeleting: false,
        customChartToRemove: 0
    }

    render() {
        const { category, chartType, data, options, step, property, secondProperty, renderChart, isLoading, isSaving, isCreating, chartToModify, newCustomChartName, customChartToRemove, isDeleting } = this.state;
        const { isDarkTheme, isOnline } = this.props;
        const { title, filters, saveFilters, showZero, numberStep, secondNumberStep, displayLabels } = this.state.config || {};
        const { steppedLine, lineTension, pointRadius, showLine, fill } = data?.datasets?.[0] || {};
        const { elements, legend, plugins, scales } = options || {};
        const mainPF = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators).main;

        return (
            <>
                {isLoading ?
                    <div className='modal-content'>
                        <div className='modal-content-body' style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                            <Dimmer active inverted>
                                <Loader inverted>{i18n.t("Chargement en cours...")}</Loader>
                            </Dimmer>
                        </div>
                    </div>
                    :
                    <div className='modal-content' style={{ padding: '5px' }}>
                        <div className='modal-content-header'>
                            <Button.Group style={{ width: '100%' }}>
                                {mainPF.trees &&
                                    <Button
                                        id='q9cY7sHq' type='button' content={i18n.t("Arbres")} active={category === 'trees'}
                                        className={category === 'trees' ? 'button--secondary' : null} color={category !== 'trees' ? 'grey' : null}
                                        inverted={!isDarkTheme} style={{ width: '50%', color: isDarkTheme ? null : 'rgba(0, 0, 0, 0.6)' }}
                                        onClick={() => this.handleCategoryChange('trees')}
                                    />}
                                {mainPF.greenSpaces &&
                                    <Button
                                        id='3OLfYALM' type='button' content='Espaces verts' active={category === 'greenSpaces'}
                                        className={category === 'greenSpaces' ? 'button--secondary' : null} color={category !== 'greenSpaces' ? 'grey' : null}
                                        inverted={!isDarkTheme} style={{ width: '50%', color: isDarkTheme ? null : 'rgba(0, 0, 0, 0.6)' }}
                                        onClick={() => this.handleCategoryChange('greenSpaces')}
                                    />}
                            </Button.Group>
                            <div>{this.renderCustomCharts()}</div>
                        </div>
                        {!isMobileOnly &&
                            <div className='modal-content-body' style={{ overflowY: 'hidden' }}>
                                {customChartToRemove > 0 &&
                                    <Dimmer active onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ customChartToRemove: 0 }); }}>
                                        <Grid>
                                            <Grid.Row verticalAlign='middle'>
                                                <Grid.Column textAlign='center'>
                                                    <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                        <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                        <Message.Content style={{ marginTop: '10px' }}>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {i18n.t("Êtes-vous certain de vouloir supprimer ce filtre ?")}
                                                            </div>
                                                            <Button color='grey' disabled={isDeleting} onClick={() => this.setState({ customChartToRemove: 0 })}>
                                                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                            </Button>
                                                            <Button color='red' disabled={!isOnline || isDeleting} loading={isDeleting} onClick={() => this.deleteCustomChart(customChartToRemove)}>
                                                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                            </Button>
                                                        </Message.Content>
                                                    </Message>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Dimmer>}
                                {isCreating && this.props.project.type === 'project' ?
                                    <Grid style={{ height: '100%' }}>
                                        <Grid.Column width={6} style={{ height: '100%' }}>
                                            <Grid.Row style={{ height: '95%', overflowX: 'hidden', overflowY: 'auto', paddingRight: '5px' }}>
                                                {step === 1 &&
                                                    <Form>
                                                        {/* Configuration de base (Titre, Propriété, Type de graphique & Masquage des zéros) */}
                                                        <Form.Field
                                                            control={Input} label='Titre : ' placeholder={i18n.t("Entrez un titre")}
                                                            value={title || ''}
                                                            onChange={this.handleTitleChange}
                                                            onBlur={() => this.setState({ renderChart: false }, () => this.setState({ renderChart: true }))}
                                                        />
                                                        <Form.Field
                                                            control={Select} label={`${i18n.t("Propriété primaire")} :`} placeholder={i18n.t("Sélectionnez une propriété primaire")}
                                                            selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")} id='e5w1mCKG'
                                                            options={this.getOptions()} value={property || ''}
                                                            onChange={this.handlePropertyChange}
                                                        />
                                                        {property &&
                                                            <Form.Field
                                                                control={Select} label={`${i18n.t("Type de graphique")} :`} placeholder={i18n.t("Sélectionnez un type de graphique")}
                                                                selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                                                options={[
                                                                    chartType === 'horizontalBar'
                                                                        ? { text: i18n.t("Bâton"), value: 'horizontalBar' }
                                                                        : { text: i18n.t("Bâton"), value: 'bar' },
                                                                    { text: i18n.t("Camembert"), value: 'pie' },
                                                                    { text: i18n.t("Donut"), value: 'doughnut' },
                                                                    { text: i18n.t("Polaire"), value: 'polar' },
                                                                    { text: i18n.t("Ligne"), value: 'line' },
                                                                    { text: i18n.t("Nuage de points"), value: 'points' },
                                                                    { text: i18n.t("Radar"), value: 'radar' }
                                                                ]}
                                                                value={chartType || ''}
                                                                onChange={this.handleChartTypeChange}
                                                            />}
                                                        {['bar', 'horizontalBar'].includes(chartType) &&
                                                            <Form.Field
                                                                control={Select} label={`${i18n.t("Propriété secondaire")} :`} placeholder={i18n.t("Sélectionnez une propriété secondaire")}
                                                                selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                                                options={[{ text: category === 'trees' ? i18n.t("Nombre d'arbres") : i18n.t("Nombre d'espaces verts"), value: 'number' }, ...this.getOptions()]
                                                                    .filter(option => option.value !== property)}
                                                                value={secondProperty || ''}
                                                                onChange={this.handleSecondPropertyChange}
                                                            />}
                                                        <div>
                                                            {chartType &&
                                                                <>
                                                                    {this.props.mainFilters && !filters &&
                                                                        <Form.Field>
                                                                            <label>{i18n.t("Conserver les filtres appliqués")} : </label>
                                                                            {this.renderBooleanCheckboxes(saveFilters,
                                                                                (e, { value }) => this.setState(prevState => ({ config: { ...prevState.config, saveFilters: value === 'true' } }))
                                                                            )}
                                                                        </Form.Field>}
                                                                    {filters &&
                                                                        <>
                                                                            <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Des filtres ont été appliqués")} :</b></label><br />
                                                                            <Button.Group>
                                                                                {this.props.mainFilters &&
                                                                                    <Button
                                                                                        color='green' type='button' content={i18n.t("Mettre à jour")} icon='sync' title={i18n.t("Mettre à jour les filtres sauvegardés")}
                                                                                        onClick={this.handleUpdateFilters}
                                                                                    />}
                                                                                <Button
                                                                                    color='red' type='button' content={i18n.t("Supprimer")} icon='trash' title={i18n.t("Supprimer les filtres sauvegardés")}
                                                                                    onClick={this.handleDeleteFilters}
                                                                                />
                                                                            </Button.Group>
                                                                        </>}
                                                                    {(!['gender', 'species', 'cultivar', 'vernacularName'].includes(property)
                                                                        || !['number', 'gender', 'species', 'cultivar', 'vernacularName'].includes(secondProperty)) &&
                                                                        <Form.Field>
                                                                            <label>{i18n.t("Masquer les zéros")} : </label>
                                                                            {this.renderBooleanCheckboxes(showZero,
                                                                                (e, { value }) => this.setState(prevState => ({ config: { ...prevState.config, showZero: value === 'true' } }))
                                                                            )}
                                                                        </Form.Field>}
                                                                    {['age', 'height', 'trunkHeight', 'circumference', 'crownDiameter', 'carbonStock', 'surface', 'annualMaintenanceFrequency'].includes(property) &&
                                                                        <Form.Field
                                                                            control={Input} type='number' label={`${i18n.t("Intervalle primaire")} :`}
                                                                            name='numberStep' value={numberStep || ''}
                                                                            onChange={this.handleNumberStepChange}
                                                                            onBlur={() => this.handlePropertyChange(null, { options: this.getOptions(), value: property })}
                                                                        />}
                                                                    {['age', 'height', 'trunkHeight', 'circumference', 'crownDiameter', 'carbonStock', 'surface', 'annualMaintenanceFrequency'].includes(secondProperty) &&
                                                                        <Form.Field
                                                                            control={Input} label={`${i18n.t("Intervalle secondaire")} :`} type='number'
                                                                            name='secondNumberStep' value={secondNumberStep[secondProperty] || ''}
                                                                            onChange={this.handleNumberStepChange}
                                                                            onBlur={() => this.handleSecondPropertyChange(null, { options: this.getOptions(), value: secondProperty })}
                                                                        />}
                                                                </>}
                                                            {/* Paramètres graphique 'Bar' & 'HorizontalBar' */}
                                                            {['bar', 'horizontalBar'].includes(chartType) &&
                                                                <Form.Field>
                                                                    <label>{i18n.t("Orientation")} : </label>
                                                                    <Checkbox
                                                                        radio label={i18n.t("Vertical")} value='bar' checked={chartType === 'bar'}
                                                                        onChange={() => this.setState(prevState => ({ chartType: 'bar', config: { ...prevState.config, orientation: 'vertical' } }))}
                                                                    />
                                                                    <Checkbox
                                                                        radio label={i18n.t("Horizontal")} value='horizontalBar' style={{ marginLeft: '10px' }} checked={chartType === 'horizontalBar'}
                                                                        onChange={() => this.setState(prevState => ({ chartType: 'horizontalBar', config: { ...prevState.config, orientation: 'horizontal' } }))}
                                                                    />
                                                                </Form.Field>}
                                                            {/* Paramètres graphique 'Ligne' */}
                                                            {chartType === 'line' &&
                                                                <>
                                                                    <Form.Field>
                                                                        <label>{i18n.t("Étape")} : </label>
                                                                        <Checkbox
                                                                            radio label={i18n.t("Aucune")} style={{ marginRight: '10px' }} value='false' checked={!steppedLine}
                                                                            onChange={this.handleSteppedChange}
                                                                        />
                                                                        {this.renderCheckboxes([
                                                                            { label: i18n.t("Avant"), value: 'before' },
                                                                            { label: i18n.t("Au milieu"), value: 'middle' },
                                                                            { label: i18n.t("Après"), value: 'after' }
                                                                        ], steppedLine, this.handleSteppedChange)}
                                                                    </Form.Field>
                                                                    {!steppedLine &&
                                                                        <Form.Field>
                                                                            <label>{i18n.t("Tension")} : </label>
                                                                            <Slider
                                                                                discrete color='blue'
                                                                                settings={{
                                                                                    min: 0, max: 0.8, step: 0.2,
                                                                                    start: lineTension,
                                                                                    onChange: this.handleTensionChange
                                                                                }}
                                                                            />
                                                                        </Form.Field>}
                                                                </>}
                                                            {/* Paramètres graphique 'Points' */}
                                                            {chartType === 'points' &&
                                                                <>
                                                                    <Form.Field
                                                                        control={Select} label={`${i18n.t("Type de points")} :`} placeholder={i18n.t("Sélectionnez un type de points")}
                                                                        value={elements.point.pointStyle || ''}
                                                                        selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                                                        options={[
                                                                            { text: i18n.t("Rond"), value: 'circle' },
                                                                            { text: i18n.t("Triangle"), value: 'triangle' },
                                                                            { text: i18n.t("Carré"), value: 'rect' },
                                                                            { text: i18n.t("Carré arrondi"), value: 'rectRounded' },
                                                                            { text: i18n.t("Carré incliné"), value: 'rectRot' },
                                                                            { text: i18n.t("Croix"), value: 'cross' },
                                                                            { text: i18n.t("Croix inclinée"), value: 'crossRot' },
                                                                            { text: i18n.t("Étoile"), value: 'star' },
                                                                            { text: i18n.t("Ligne"), value: 'line' }
                                                                        ]}
                                                                        onChange={this.handlePointStyleChange}
                                                                    />
                                                                    <Form.Field>
                                                                        <label>{i18n.t("Taille")} :</label>
                                                                        <Slider
                                                                            discrete color='blue'
                                                                            settings={{
                                                                                min: 5, max: 20, step: 5,
                                                                                start: pointRadius,
                                                                                onChange: this.handlePointRadiusChange
                                                                            }}
                                                                        />
                                                                    </Form.Field>
                                                                    <Form.Field>
                                                                        <label>{i18n.t("Liaison")} :</label>
                                                                        {this.renderBooleanCheckboxes(showLine, this.handleShowLineChange)}
                                                                    </Form.Field>
                                                                </>}
                                                            {(this.state.data?.datasets[0]?.showLine || chartType === 'line') &&
                                                                <Form.Field>
                                                                    <label>{i18n.t("Remplissage")} :</label>
                                                                    {this.renderBooleanCheckboxes(fill, this.handleFillChange)}
                                                                </Form.Field>}
                                                            {/* Légende */}
                                                            {(['pie', 'doughnut', 'polar'].includes(chartType) || secondProperty !== 'number') &&
                                                                <Form.Field>
                                                                    <label>{i18n.t("Légende")} :</label>
                                                                    {this.renderBooleanCheckboxes(legend.display, this.handleLegendDisplayChange)}
                                                                </Form.Field>}
                                                            {legend?.display &&
                                                                <Form.Field>
                                                                    {this.renderCheckboxes([
                                                                        { label: i18n.t("Haut"), value: 'top' }, { label: i18n.t("Droite"), value: 'right' },
                                                                        { label: i18n.t("Bas"), value: 'bottom' }, { label: i18n.t("Gauche"), value: 'left' }
                                                                    ], legend.position, this.handleLegendPositionChange)}
                                                                </Form.Field>}
                                                            {/* Libellé des valeurs */}
                                                            {['bar', 'horizontalBar', 'pie', 'doughnut', 'polar'].includes(chartType) &&
                                                                <Form.Field style={{ marginBottom: '5px' }}>
                                                                    <label>{i18n.t("Libellé des valeurs")} :</label>
                                                                    {this.renderBooleanCheckboxes(displayLabels, this.handleLabelsDisplayChange)}
                                                                </Form.Field>}
                                                            {/* Type de valeur */}
                                                            {displayLabels && ['pie', 'doughnut', 'polar'].includes(chartType) &&
                                                                <Form.Field>
                                                                    <label>{i18n.t("Type de valeur")} :</label>
                                                                    {this.renderCheckboxes([
                                                                        { label: i18n.t("Nombre"), value: 'value' },
                                                                        { label: i18n.t("Pourcentage"), value: 'percentage' },
                                                                        { label: i18n.t("Libellé"), value: 'label' }
                                                                    ], plugins.labels.render, this.handleValueDisplayChange)}
                                                                </Form.Field>}
                                                            {/* Libellé des axes */}
                                                            {['bar', 'horizontalBar', 'line', 'points'].includes(chartType) &&
                                                                <>
                                                                    <Form.Field style={{ marginBottom: '5px' }}>
                                                                        <label>{i18n.t("Libellé des axes")} :</label>
                                                                        {this.renderBooleanCheckboxes(scales.xAxes[0].scaleLabel.display, this.handleAxesNameDisplayChange)}
                                                                    </Form.Field>
                                                                    {scales.xAxes[0].scaleLabel.display &&
                                                                        <>
                                                                            <Form.Field
                                                                                control={Input}
                                                                                value={scales.xAxes[0].scaleLabel.labelString || ''}
                                                                                onChange={(_, { value }) => this.handleAxesNameChange('x', value)}
                                                                                onBlur={() => this.setState({ renderChart: false }, () => this.setState({ renderChart: true }))}
                                                                            />
                                                                            <Form.Field
                                                                                control={Input}
                                                                                value={scales.yAxes[0].scaleLabel.labelString || ''}
                                                                                onChange={(_, { value }) => this.handleAxesNameChange('y', value)}
                                                                                onBlur={() => this.setState({ renderChart: false }, () => this.setState({ renderChart: true }))}
                                                                            />
                                                                        </>}
                                                                </>}
                                                        </div>
                                                    </Form>}
                                                {/* Colorimétrie */}
                                                {chartType && step === 2 && <Form>{this.renderColorPickers()}</Form>}
                                            </Grid.Row>
                                            <Grid.Row style={{ height: '5%', paddingTop: '5px' }}>
                                                {chartType &&
                                                    <>
                                                        {chartToModify ?
                                                            <Button
                                                                type='button' title={i18n.t("Sauvegarder les modifications")} content={i18n.t("Sauvegarder")} icon='save' color='green'
                                                                disabled={isSaving || !this.props.isOnline} loading={isSaving} onClick={this.saveCustomChart}
                                                            />
                                                            : !this.props.loginAsData?.readOnly && RightsUtil.canWrite(this.props.rights?.charts) &&
                                                            <Input
                                                                placeholder={i18n.t("Nom du graphique")} value={newCustomChartName || ''} className='bordered' action
                                                                onChange={(_, { value }) => this.setState({ newCustomChartName: value.length > 30 ? value.substring(0, 30) : value })}
                                                            >
                                                                <input />
                                                                <Button
                                                                    title={i18n.t("Sauvegarder le graphique")} icon='save' color='green'
                                                                    disabled={isSaving || !this.props.isOnline} loading={isSaving} onClick={this.saveCustomChart}
                                                                />
                                                            </Input>}
                                                    </>}
                                                {/* Précédent */}
                                                {step === 2 &&
                                                    <Button type='button' size='tiny' color='grey' style={{ marginTop: '5px', paddingLeft: '5px', paddingRight: '5px', float: 'right' }}
                                                        onClick={() => this.setState({ step: 1 })}>
                                                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px' }} />{i18n.t("Configuration")}
                                                    </Button>}
                                                {/* Suivant */}
                                                {step === 1 && chartType &&
                                                    <Button
                                                        type='button' size='tiny' color='grey' style={{ marginTop: '5px', paddingLeft: '5px', paddingRight: '5px', float: 'right' }}
                                                        onClick={() => this.setState({ step: 2 })}>
                                                        {i18n.t("Colorimétrie")}<FontAwesomeIcon icon={faArrowRight} style={{ marginRight: '10px' }} />
                                                    </Button>}
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Column width={10} verticalAlign='middle'>
                                            {renderChart &&
                                                <div>
                                                    {chartType === 'bar' &&
                                                        <Bar data={this.getData()} options={options} />}
                                                    {chartType === 'horizontalBar' &&
                                                        <HorizontalBar data={this.getData()} options={this.getHorizontalBarOptions()} />}
                                                    {chartType === 'pie' &&
                                                        <Pie data={this.getData()} options={options} />}
                                                    {chartType === 'doughnut' &&
                                                        <Doughnut data={this.getData()} options={options} />}
                                                    {chartType === 'polar' &&
                                                        <Polar data={this.getData()} options={options} />}
                                                    {chartType === 'line' &&
                                                        <Line data={this.getData()} options={options} />}
                                                    {chartType === 'points' &&
                                                        <Line data={this.getData()} options={options} />}
                                                    {chartType === 'radar' &&
                                                        <Radar data={this.getData()} options={options} />}
                                                </div>}
                                        </Grid.Column>
                                    </Grid>
                                    :
                                    <>
                                        {renderChart &&
                                            <>
                                                {chartType === 'bar' &&
                                                    <Bar data={this.getData()} options={options} />}
                                                {chartType === 'horizontalBar' &&
                                                    <HorizontalBar data={this.getData()} options={this.getHorizontalBarOptions()} />}
                                                {chartType === 'pie' &&
                                                    <Pie data={this.getData()} options={options} />}
                                                {chartType === 'doughnut' &&
                                                    <Doughnut data={this.getData()} options={options} />}
                                                {chartType === 'polar' &&
                                                    <Polar data={this.getData()} options={options} />}
                                                {chartType === 'line' &&
                                                    <Line data={this.getData()} options={options} />}
                                                {chartType === 'points' &&
                                                    <Line data={this.getData()} options={options} />}
                                                {chartType === 'radar' &&
                                                    <Radar data={this.getData()} options={options} />}
                                            </>}
                                    </>}
                            </div>}
                        {(isMobileOnly || this.props.project.type !== 'project') && !this.state.customCharts?.length &&
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faChartLine} size='6x' />
                                <h4>{i18n.t("Aucun résultat trouvé")}</h4>
                            </div>}
                    </div>}
            </>
        );
    }

    componentDidMount = () => {
        const setInitialState = (customCharts) => {
            let projectTags = this.props.project?.tags || [];
            const mainPF = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators).main;
            this.setState({
                customCharts,
                tags: projectTags,
                interactions: this.props.interactions,
                microHabitats: this.props.microHabitats,
                rootSymptoms: this.props.rootSymptoms,
                collarSymptoms: this.props.collarSymptoms,
                trunkSymptoms: this.props.trunkSymptoms,
                branchSymptoms: this.props.branchSymptoms,
                leafSymptoms: this.props.leafSymptoms,
                pathogens: this.props.pathogens,
                pests: this.props.pests,
                epiphytes: this.props.epiphytes,
                isLoading: false,
                category: mainPF.trees ? 'trees' : 'greenSpaces'
            }, () => {
                let index = !this.props.match.params.path3 ? -1 : customCharts.findIndex(chart => chart.id === +this.props.match.params.path3);
                if (index !== -1) this.displayCustomChart(index);
                else {
                    index = customCharts.findIndex(chart => chart.category === 'trees');
                    if (index !== -1) this.displayCustomChart(index);
                    else {
                        index = customCharts.findIndex(chart => chart.category === 'greenSpaces');
                        if (index !== -1) this.displayCustomChart(index);
                        else this.setState({ isCreating: true });
                    }
                }
            });
        }

        const loadedCustomCharts = this.props.customCharts || {};
        if (loadedCustomCharts['0'] && loadedCustomCharts[this.props.project.id]) // Si on a déjà chargé les graphiques globaux et du projet
            setInitialState(this.sortCustomCharts([...this.props.customCharts[this.props.project.id], ...this.props.customCharts['0']]))
        else {
            let promises = [], customCharts = [];
            if (!loadedCustomCharts['0']) {
                promises.push(new Promise(resolve => {
                    CustomChartsService.getCustomCharts(0).then(charts => {
                        if (charts) customCharts.push(...charts);
                        resolve();
                    });
                }));
            } else customCharts.push(...loadedCustomCharts['0']);
            if (!loadedCustomCharts[this.props.project.id]) {
                promises.push(new Promise(resolve => {
                    CustomChartsService.getCustomCharts(this.props.project.id).then(charts => {
                        if (charts) customCharts.push(...charts);
                        resolve();
                    });
                }));
            } else customCharts.push(...loadedCustomCharts[this.props.project.id]);
            Promise.all(promises).then(() => {
                setInitialState(this.sortCustomCharts(customCharts || []));
                this.props.setCustomCharts({
                    ...loadedCustomCharts,
                    0: customCharts.filter(customChart => !customChart.projectId),
                    [this.props.project.id]: customCharts.filter(customChart => customChart.projectId === this.props.project.id),
                });
            });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.project && JSON.stringify(prevProps.customCharts) !== JSON.stringify(this.props.customCharts))
            this.setState({ customCharts: this.sortCustomCharts([...this.props.customCharts['0'], ...this.props.customCharts[this.props.project.id]]) });

        if (prevState.selectedCustomChart !== this.state.selectedCustomChart || prevState.chartToModify !== this.state.chartToModify) {
            this.props.history.push({ pathname: `/projects/${this.props.project.id}/statistics/customCharts${this.state.chartToModify || this.state.selectedCustomChart ? `/${this.state.chartToModify || this.state.selectedCustomChart}` : ''}` });
        }
    }

    componentWillUnmount = () => {
        if (this.state.areFiltersApplied)
            this.props.removeFilters(true, JSON.parse(this.state.config.filters));
    }

    renderColorPickers = () => {
        let colorPickers = [];
        const defaultStyles = {
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px'
                },
                swatch: {
                    padding: '5px',
                    marginLeft: '1px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer'
                },
                popover: {
                    position: 'fixed',
                    zIndex: '2',
                    marginLeft: '1px',
                    marginBottom: '1px',
                    top: '175px',
                    left: '10px'
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'
                }
            }
        }

        const getColorPicker = (i, datasetProperty = null, configProperty = null) => {
            let styles = { ...defaultStyles };
            styles.default.color.background = datasetProperty ? this.state.data.datasets[0][datasetProperty] :
                ['points', 'radar'].includes(this.state.chartType) ? this.state.data.datasets[0].pointBackgroundColor[i] :
                    this.state.secondProperty === 'number' ? this.state.config.backgroundColors[i] :
                        this.state.config.secondBackgroundColors[this.state.secondProperty][i];
            styles = reactCSS(styles);
            return (
                <div>
                    {this.state.colorToModify === i ?
                        <div style={styles.popover}>
                            <div style={styles.cover} onClick={() => this.setState({ colorToModify: null })} />
                            <SketchPicker color={configProperty ? this.state.config[configProperty] :
                                this.state.secondProperty === 'number' ? this.state.config.backgroundColors[i] :
                                    this.state.config.secondBackgroundColors[this.state.secondProperty][i]}
                                onChange={color => {
                                    color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')'
                                    this.setState(prevState => {
                                        let data = prevState.data;
                                        let config = prevState.config;
                                        if (this.state.secondProperty === 'number') {
                                            if (i < this.state.data.labels.length) {
                                                if (['points', 'radar'].includes(prevState.chartType))
                                                    data.datasets[0].pointBackgroundColor[i] = color;
                                                else data.datasets[0].backgroundColor[i] = color;
                                                config.backgroundColors[i] = color;
                                            } else {
                                                data.datasets[0][datasetProperty] = color;
                                                config[configProperty] = color;
                                            }
                                        } else {
                                            data.datasets[i].backgroundColor = color;
                                            config.secondBackgroundColors[this.state.secondProperty][i] = color;
                                        }
                                        return { data: data, config: config };
                                    });
                                }}
                                onChangeComplete={() => this.setState({ renderChart: false }, () => this.setState({ renderChart: true }))}
                            />
                        </div> : null}
                    <div style={styles.swatch} onClick={() => this.setState({ colorToModify: i })}>
                        <div style={styles.color} />
                    </div>
                    {this.state.secondProperty === 'number' ?
                        <>
                            {i < this.state.data.labels.length &&
                                <label style={{ marginLeft: '10px' }}>{this.state.data.labels[i]} </label>}
                        </>
                        :
                        <>
                            {i < this.state.data.datasets.length &&
                                <label style={{ marginLeft: '10px' }}>{this.state.data.datasets[i].label} </label>}
                        </>}
                </div>
            );
        }

        if (this.state.secondProperty === 'number')
            for (let i = 0; i < this.state.data.labels.length; i += 2) {
                colorPickers.push(
                    <Grid.Row key={i / 2} style={{ padding: 0 }}>
                        <Grid.Column width={8}>{getColorPicker(i)}</Grid.Column>
                        {this.state.data.labels[i + 1] && <Grid.Column width={8}>{getColorPicker(i + 1)}</Grid.Column>}
                    </Grid.Row>
                );
            }
        else
            for (let i = 0; i < this.state.data.datasets.length; i += 2) {
                colorPickers.push(
                    <Grid.Row key={i / 2} style={{ padding: 0 }}>
                        <Grid.Column width={8}>{getColorPicker(i)}</Grid.Column>
                        {this.state.data.datasets[i + 1] && <Grid.Column width={8}>{getColorPicker(i + 1)}</Grid.Column>}
                    </Grid.Row>
                );
            }

        return (
            <>
                {this.state.chartType !== 'line' &&
                    <>
                        <Form.Field style={{ marginBottom: '5px' }}><label>Couleurs : </label></Form.Field>
                        <Grid style={{ marginTop: '5px' }}>{colorPickers}</Grid>
                    </>}
                {(this.state.data.datasets[0].fill || ['radar', 'line', 'points'].includes(this.state.chartType)) &&
                    <Grid>
                        <Grid.Row>
                            {['radar', 'line'].includes(this.state.chartType) &&
                                <Grid.Column width={8}>
                                    <Form.Field style={{ marginBottom: '5px' }}><label>{i18n.t("Trait")} : </label></Form.Field>
                                    {getColorPicker(this.state.data.labels.length, 'borderColor', 'lineColor')}
                                </Grid.Column>}
                            {((this.state.data.datasets[0].fill && (this.state.data.datasets[0].showLine || this.state.chartType === 'line'))
                                || this.state.chartType === 'radar') &&
                                <Grid.Column width={8}>
                                    <Form.Field style={{ marginBottom: '5px' }}><label>{i18n.t("Remplissage")} : </label></Form.Field>
                                    {getColorPicker(this.state.data.labels.length + 1, 'backgroundColor', 'fillColor')}
                                </Grid.Column>}
                        </Grid.Row>
                        {this.state.chartType === 'line' &&
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Grid.Column width={8}>
                                    <Form.Field style={{ marginBottom: '5px' }}><label>{i18n.t("Points")} : </label></Form.Field>
                                    {getColorPicker(this.state.data.labels.length + 2, 'pointBackgroundColor', 'pointColor')}
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>}
            </>
        );
    }

    renderBooleanCheckboxes = (checkedValue, onChange) => {
        return (
            <>
                <Checkbox radio label={i18n.t("Oui")} value='true' checked={checkedValue} onChange={onChange} />
                <Checkbox radio label={i18n.t("Non")} value='false' style={{ marginLeft: '10px' }} checked={!checkedValue} onChange={onChange} />
            </>
        );
    }

    renderCheckboxes = (checkboxes, checkedValue, onChange) => {
        return checkboxes.map(checkbox => (
            <Checkbox
                radio label={checkbox.label} key={uuidv4()} style={{ marginRight: '10px' }} value={checkbox.value}
                checked={checkedValue === checkbox.value} onChange={onChange}
            />
        ));
    }

    renderCustomCharts = () => {
        let customChartsButtons = [];
        if (this.state.customCharts) {
            for (var i = 0; i < this.state.customCharts.length; i++) { //! var utile
                const customChart = this.state.customCharts[i];
                if (this.state.category === customChart.category) {
                    customChartsButtons.push(
                        <Button.Group key={uuidv4()} style={{ marginBottom: '5px', marginRight: '5px', flexShrink: 0 }}>
                            <Button
                                className='NUGHw5Zl button--secondary' type='button' title={i18n.t("Afficher le graphique")} style={{ padding: '11px' }}
                                name={i} key={uuidv4()} content={customChart.label}
                                disabled={this.state.selectedCustomChart === customChart.id} onClick={(e, { name }) => this.displayCustomChart(name)}
                            />
                            {!this.props.loginAsData?.readOnly && RightsUtil.canWrite(this.props.rights?.charts) && <>
                                <Button
                                    className='hLWSnIOs button--secondary' type='button' title={i18n.t("Modifier le graphique")} style={{ padding: '8px', borderLeft: 'solid 1px black' }}
                                    name={i} key={uuidv4()} content={<FontAwesomeIcon icon={faPenToSquare} />}
                                    disabled={this.state.chartToModify === customChart.id} onClick={(_, { name }) => this.displayCustomChart(name, true)}
                                />
                                <Button
                                    className='L7434QE1' type='button' title={i18n.t("Supprimer le graphique")} color='red' style={{ padding: '8px', borderLeft: 'solid 1px black' }}
                                    name={i} key={uuidv4()} content={<FontAwesomeIcon icon={faTrash} style={{ padding: '0 2px' }} />}
                                    disabled={this.state.chartToModify || !this.props.isOnline ? true : false}
                                    onClick={() => this.setState({ customChartToRemove: customChart.id })}
                                />
                            </>}
                        </Button.Group>
                    );
                }
            }
        }

        customChartsButtons = [<div className='left' key={uuidv4()}>{customChartsButtons}</div>];

        if ((!this.state.isCreating || this.state.chartToModify) && this.state.interactions) // On vérifie le chargement des interactions pour ne pas afficher le bouton si on a pas encore chargé le reste
            customChartsButtons.push(
                <div className='right' key={uuidv4()}>
                    <Button
                        type='button' title={i18n.t("Créer un graphique personnalisé")} color='green' style={{ padding: '11px', marginBottom: 'auto' }}
                        name={i} key={uuidv4()} content={i18n.t("Créer")}
                        onClick={this.handleCreationClick}
                    />
                </div>
            );

        return (<div className='modalNavbar'>{customChartsButtons}</div>);
    }

    sortCustomCharts = (customCharts) => {
        return customCharts.sort((a, b) => {
            const isOwnerOfA = a === jwtDecode(new Cookies().get('token')).id;
            const isOwnerOfB = b === jwtDecode(new Cookies().get('token')).id;
            return isOwnerOfA && isOwnerOfB ? b.projectId - a.projectId : (isOwnerOfA ? -1 : 1);
        });
    }

    handleCategoryChange = (category) => {
        if (this.state.category !== category)
            this.setState({ category, ...initialState }, () => {
                let index = this.state.customCharts.findIndex(chart => chart.category === category);
                if (index !== -1) this.displayCustomChart(index);
                else this.setState({ isCreating: true });
            });
    }

    handleCreationClick = () => {
        if (this.state.areFiltersApplied) {
            this.props.removeFilters(true, JSON.parse(this.state.config.filters));
            this.setState({ areFiltersApplied: false });
        }
        this.setState({ ...initialState, isCreating: true, displayObject: null });
    }

    handleTitleChange = (_, { value }) => this.setState(prevState => {
        let options = prevState.options;
        if (options) {
            options.title.text = value.trim();
            options.title.display = value.trim().length > 0;
        }
        return { options: options, config: { ...prevState.config, title: value } };
    });

    getRandomColor = () => {
        const color = 'rgba('
            + Math.floor(Math.random() * 256) + ', '
            + Math.floor(Math.random() * 256) + ', '
            + Math.floor(Math.random() * 256) + ', 0.7)';
        return color;
    }

    rgbToRgba = (rgb) => rgb.replace(')', ', 0.7)').replace('rgb', 'rgba');

    handlePropertyChange = (_, { options, value }) => {
        let labels = [], backgroundColors = [], values = [], layers = [];
        const layerContainer = this.state.category === 'trees' ? this.props.treesLayer : this.props.greenSpacesLayer;
        const originalLayers = layerContainer.getLayers(); // Layers présents sur la carte
        const trunkCircumferenceUnit = this.props.project.trunkCircumferenceUnit;

        const countValues = (getPropertyValue) => {
            for (const layerName in originalLayers) {
                const layer = originalLayers[layerName];
                const propertyValue = getPropertyValue(layer);
                if (propertyValue || propertyValue === 0) {
                    const index = labels.indexOf(propertyValue)
                    if (index >= 0) {
                        values[index]++;
                        layers[index].push(layer);
                    }
                }
            }
        }

        let step;
        const setNumberLabels = (property) => { // Permet de définir les catégories des types numbers
            let max = -1;
            for (const layerName in originalLayers) { // On récupère le maximum pour la propriété sélectionnée
                let value = ['height', 'circumference', 'crownDiameter'].includes(property)
                    ? FormattersUtil.getTrunkCircumference(TreesUtil.getBiggestTrunkValue(originalLayers[layerName].feature.properties.trunks, property) || 0, trunkCircumferenceUnit)
                    : originalLayers[layerName].feature.properties[property];
                if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(property)) value = value / 100;
                if (property === 'carbonStock' && this.state.category === 'greenSpaces') value = value / 1000;
                else if (value) {
                    value = Math.ceil(value);
                    if (value > max) max = value;
                }
            }

            if (this.state.config.numberStep && Number(this.state.config.numberStep) > 0 && (this.state.property === value || this.state.displayObject))
                step = Number(this.state.config.numberStep); // Si l'utilisateur a changé manuelle ma tranche pour la propriété sélectionnée, on la récupère
            else { // Sinon on calcule automatiquement une tranche appropriée en fonction du maximum obtenu
                const roundToDozen = (n) => {
                    const r = n % 10;
                    if (r > 4) return Math.ceil(n / 10) * 10;
                    else return Math.floor(n / 10) * 10;
                }
                step = roundToDozen(max) / 10;
                if (max > 50) step = roundToDozen(step);
            }

            if (step < 1) step = 1;

            for (let i = 0; i < max; i += step) { // On génère les labels des catégories
                labels[i / step] = i + 1 + ' - ' + (i + step);
                values[i / step] = 0;
                layers[i / step] = []
                backgroundColors[i / step] = (this.state.property === value || this.state.displayObject ?
                    this.state.config.backgroundColors[i / step] : null) || this.getRandomColor();
            }
        }

        const countNumberValues = (property) => {
            for (const layerName in originalLayers) {
                const layer = originalLayers[layerName];
                let propertyValue = ['height', 'circumference', 'crownDiameter'].includes(property)
                    ? FormattersUtil.getTrunkCircumference(TreesUtil.getBiggestTrunkValue(originalLayers[layerName].feature.properties.trunks, property) || 0, trunkCircumferenceUnit)
                    : layer.feature.properties[property];
                if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(property)) propertyValue = propertyValue / 100;
                if (property === 'carbonStock' && this.state.category === 'greenSpaces') propertyValue = propertyValue / 1000;
                if (propertyValue) {
                    let index = -1;
                    for (let i = 0; i < labels.length; i++) { // Pour chaque valeur, on recherche la tranche qui lui correspond
                        let bounds = typeof labels[i] === 'string' ? labels[i].split('-') : labels[0];
                        // Le nombre est arrondi à la valeur supérieure pour déterminer sa tranche (ex : 0,5 -> tranche 1 - 20)
                        if (Number(bounds[0]) <= Math.ceil(propertyValue) && Math.ceil(propertyValue) <= Number(bounds[1]))
                            index = i;
                    }
                    if (index >= 0) {
                        values[index]++;
                        layers[index].push(layer);
                    }
                }
            }
        }

        const generateChartData = (list, property, valuePath, colorFunction) => {
            list.forEach(listItem => {
                labels.push(listItem[valuePath]);
                backgroundColors.push(colorFunction(listItem[valuePath]));
                values[values.length] = 0;
                layers[layers.length] = [];
            });
            countValues(layer => { return list.find(listItem => listItem.id === layer.feature.properties[property])?.[valuePath]; });
        }

        switch (value) { // En fonction de la propriété sélectionnée
            case 'healthReview': case 'averageHealthReview': generateChartData(this.props.healthReviews, value + 'Id', 'value', StylesUtil.getHealthReviewColor); break;
            case 'vigor': generateChartData(this.props.vigors, 'vigorId', 'label', StylesUtil.getVigorColor); break;
            case 'risk': generateChartData(this.props.risks, 'riskId', 'label', StylesUtil.getRiskColor); break;
            case 'tippingRisk': generateChartData(this.props.tippingRisks, 'tippingRiskId', 'label', this.getRandomColor); break;
            case 'organCaliber': generateChartData(this.props.organCalibers, 'organCaliberId', 'label', this.getRandomColor); break;
            case 'target': generateChartData(this.props.targets, 'targetId', 'label', this.getRandomColor); break;
            case 'ontogenicStage': generateChartData(this.props.ontogenicStages, 'ontogenicStageId', 'value', StylesUtil.getOntogenicStageColor); break;
            case 'managementClass': generateChartData(this.props.managementClasses, 'managementClassId', 'value', this.getRandomColor); break;
            case 'plantationType': case 'treePort': case 'coverType': case 'spaceFunction': case 'spaceType': case 'dominantComposition':
                generateChartData(this.props[value + 's'], value + 'Id', 'label', this.getRandomColor); break;
            case 'age': case 'height': case 'circumference': case 'crownDiameter': case 'trunkHeight': case 'carbonStock': case 'surface': case 'annualMaintenanceFrequency':
            case 'nbTrees': case 'density': case 'numberOfTrunks': case 'distanceBetweenTrunks': case 'averageHeight': case 'averageCircumference': case 'averageCrownDiameter':
                setNumberLabels(value); countNumberValues(value); break;
            case 'isStump': case 'isDead': case 'isEmpty': case 'isIndexed': case 'isRemarkable': case 'isTreeBase':
                labels = [i18n.t("Oui"), i18n.t("Non")]; values = [0, 0]; layers = [[], []];
                backgroundColors = ['rgba(0, 255, 0, 0.7)', 'rgba(255, 0, 0, 0.7)'];
                countValues(layer => { return layer.feature.properties[value] ? i18n.t("Oui") : i18n.t("Non") });
                break;
            case 'gender': case 'species': case 'cultivar': case 'vernacularName':
            case 'dominantGender': case 'dominantSpecies': case 'dominantCultivar': case 'dominantVernacularName':
                let property = value.replace('dominant', '');
                property = property.charAt(0).toLowerCase() + property.slice(1);
                countValues(layer => {
                    let essence = this.props.essences.find(x => x.id === layer.feature.properties[value.includes('dominant') ? 'dominantEssenceId' : 'essenceId']);
                    if (essence && essence[property] && !labels.includes(essence[property])) {
                        labels.push(essence[property]);
                        backgroundColors.push(this.getRandomColor());
                        values[values.length] = 0;
                        layers[layers.length] = [];
                    }
                    return essence ? essence[property] : essence;
                });
                break;
            case 'plantationCoefficient': case 'situationCoefficient': case 'patrimonialCoefficient':
                const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
                const propertyName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
                    : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                        : 'descriptionFr';
                let coefficients;
                if (value === 'plantationCoefficient') coefficients = this.props.plantationCoefficients.filter(x => x[propertyName]);
                else if (value === 'situationCoefficient') coefficients = this.props.situationCoefficients.filter(x => x[propertyName]);
                else if (value === 'patrimonialCoefficient') coefficients = this.props.patrimonialCoefficients;

                generateChartData(coefficients, value + 'Id', 'value', this.getRandomColor); break;
            case 'runoffCoefficient':
                this.props.runoffCoefficients.forEach(runoffCoefficient => {
                    labels.push(GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient));
                    backgroundColors.push(this.getRandomColor());
                    values[values.length] = 0;
                    layers[layers.length] = [];
                });
                countValues(layer => {
                    const dominantComposition = this.props.dominantCompositions.find(dominantComposition => dominantComposition.id === layer.feature.properties.dominantCompositionId);
                    const runoffCoefficient = this.props.runoffCoefficients.find(runoffCoefficient => runoffCoefficient.id === dominantComposition?.runoffCoefficientId);
                    return GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient);
                });
                break;
            case 'toCutDown':
                labels = [i18n.t("Aucun abattage"), i18n.t("Abattage 1 - 3 ans"), i18n.t("Abattage < 1 an"), i18n.t("Abattage d'urgence"), i18n.t("Abattu")];
                values = [0, 0, 0, 0, 0];
                layers = [[], [], [], [], []];
                backgroundColors = [null, 1, 2, 3, 4].map(toCutDown => { return this.rgbToRgba(StylesUtil.getCutDownColor(toCutDown)) });
                countValues(layer => {
                    return layer.feature.properties.toCutDown === 1 ? i18n.t("Abattage 1 - 3 ans") :
                        layer.feature.properties.toCutDown === 2 ? i18n.t("Abattage < 1 an") :
                            layer.feature.properties.toCutDown === 3 ? i18n.t("Abattage d'urgence") :
                                layer.feature.properties.toCutDown === 4 ? i18n.t("Abattu") :
                                    i18n.t("Aucun abattage");
                });
                break;
            case 'interaction': case 'microHabitat': case 'tag':
            case 'rootSymptom': case 'collarSymptom': case 'trunkSymptom': case 'branchSymptom': case 'leafSymptom':
            case 'rootPathogen': case 'collarPathogen': case 'trunkPathogen': case 'branchPathogen': case 'leafPathogen':
            case 'rootPest': case 'collarPest': case 'trunkPest': case 'branchPest': case 'leafPest':
            case 'trunkEpiphyte': case 'branchEpiphyte':
                let list = this.state[value + 's'];
                if (value === 'tag') list = list.filter(tag => tag.category === (this.state.category === 'trees' ? 'Arbre' : 'Espace vert'));
                list.forEach(property => {
                    labels.push(property.label);
                    backgroundColors.push(this.getRandomColor());
                    values[values.length] = 0;
                    layers[layers.length] = [];
                });
                for (const key in originalLayers) {
                    const layer = originalLayers[key];
                    if (layer.feature.properties[value + 'Id']) {
                        for (let i = 0; i < layer.feature.properties[value + 'Id'].length; i++) {
                            const propertyValue = list.find(x => x.id === layer.feature.properties[value + 'Id'][i])?.label;
                            if (propertyValue) {
                                const index = labels.indexOf(propertyValue)
                                if (index >= 0) {
                                    values[index]++;
                                    layers[index].push(layer);
                                }
                            }
                        }
                    }
                }
                break;
            default: break;
        }

        const xLabel = this.state.displayObject ? this.state.config.xLabel : options.find(x => x.value === value).text + (
            value === 'carbonStock' ? (this.state.category === 'trees' ? ' (kg)' : ' (t)')
                : ['trunkHeight', 'crownDiameter', 'height', 'crownDiameter', 'averageHeight', 'averageCrownDiameter', 'distanceBetweenTrunks'].includes(value) ? ' (m)'
                    : ['circumference', 'averageCircumference'].includes(value) ? ' (cm)'
                        : value === 'density' ? ' (/ha)'
                            : value === 'surface' ? ' (m²)'
                                : value === 'age' ? ` (${i18n.t("Années").toLowerCase()})`
                                    : ''
        );

        this.setState(prevState => ({
            property: value,
            initialData: {
                labels: labels,
                datasets: [{
                    data: values,
                    borderColor: 'rgba(0, 0, 0, 1)',
                    borderWidth: 1
                }]
            },
            config: {
                ...prevState.config,
                numberStep: step,
                xLabel: xLabel,
                yLabel: prevState.displayObject ? prevState.config.yLabel : (prevState.category === 'trees' ? i18n.t("Nombre d'arbres") : i18n.t("Nombre d'espaces verts")),
                backgroundColors: prevState.displayObject
                    ? ([
                        'age', 'height', 'circumference', 'crownDiameter', 'trunkHeight', 'carbonStock', 'nbTrees', 'density', 'distanceBetweenTrunks',
                        'averageHeight', 'averageCircumference', 'averageCrownDiameter'
                    ].includes(value) ? backgroundColors : prevState.config.backgroundColors)
                    : backgroundColors
            },
            layers: layers,
            renderChart: false
        }), () => {
            if (this.state.displayObject) this.handleChartTypeChange(null, { options: this.getOptions(), value: this.state.displayObject.chartType });
            else this.handleChartTypeChange(null, { options: this.getOptions(), value: this.state.chartType });
        });
    }

    handleSecondPropertyChange = (e, { options, value }) => {
        if (value === 'number' && !this.state.displayObject)
            this.setState({ secondProperty: value, renderChart: false }, () => this.handleChartTypeChange(null, { value: this.state.chartType }));
        else {
            let labels = [], backgroundColors = [], values = [];
            const layerContainer = this.state.category === 'trees' ? this.props.treesLayer : this.props.greenSpacesLayer;
            const originalLayers = layerContainer.getLayers(); // Layers présents sur la carte
            const trunkCircumferenceUnit = this.props.project.trunkCircumferenceUnit;

            const countValues = (i, getPropertyValue) => {
                for (const layerName in this.state.layers[i]) {
                    const layer = this.state.layers[i][layerName];
                    const propertyValue = getPropertyValue(layer);
                    if (propertyValue && propertyValue) {
                        const index = labels.indexOf(propertyValue)
                        if (index >= 0) values[index][i]++;
                    }
                }
            }

            let step;
            const setNumberLabels = (property) => { // Permet de définir les catégories des types numbers
                let max = -1;
                for (const layerName in originalLayers) { // On récupère le maximum pour la propriété sélectionnée
                    let value = ['height', 'circumference', 'crownDiameter'].includes(property)
                        ? FormattersUtil.getTrunkCircumference(TreesUtil.getBiggestTrunkValue(originalLayers[layerName].feature.properties.trunks, property) || 0, trunkCircumferenceUnit)
                        : originalLayers[layerName].feature.properties[property];
                    if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(property)) value = value / 100;
                    if (property === 'carbonStock' && this.state.category === 'greenSpaces') value = value / 1000;
                    if (value) {
                        value = Math.ceil(value);
                        if (value > max)
                            max = value;
                    }
                }

                if (this.state.config.secondNumberStep[value] && Number(this.state.config.secondNumberStep[value]))
                    // Si l'utilisateur a changé manuellement la tranche pour la propriété sélectionnée, on la récupère
                    step = Number(this.state.config.secondNumberStep[value]);
                else { // Sinon on calcule automatiquement une tranche appropriée en fonction du maximum obtenu
                    const roundToDozen = (n) => {
                        const r = n % 10;
                        if (r > 4) return Math.ceil(n / 10) * 10;
                        else return Math.floor(n / 10) * 10;
                    }
                    step = roundToDozen(max) / 10;
                    if (max > 50) step = roundToDozen(step);
                }

                for (let i = 0; i < max; i += step) { // On génère les labels des catégories
                    labels[i / step] = i + 1 + ' - ' + (i + step);
                    backgroundColors[i / step] = (this.state.secondProperty === value || this.state.displayObject ?
                        (this.state.config.secondBackgroundColors[value] ?
                            this.state.config.secondBackgroundColors[value][i / step] : null
                        ) : null) || this.getRandomColor();
                }
                labels.forEach(() => values.push(this.state.initialData.labels.map(() => 0)));
            }

            const countNumberValues = (i, property) => {
                for (const layerName in this.state.layers[i]) {
                    const layer = this.state.layers[i][layerName];
                    let propertyValue = ['height', 'circumference', 'crownDiameter'].includes(property)
                        ? FormattersUtil.getTrunkCircumference(TreesUtil.getBiggestTrunkValue(originalLayers[layerName].feature.properties.trunks, property) || 0, trunkCircumferenceUnit)
                        : layer.feature.properties[property];
                    if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(property)) propertyValue = propertyValue / 100;
                    if (property === 'carbonStock' && this.state.category === 'greenSpaces') propertyValue = propertyValue / 1000;
                    if (propertyValue) {
                        let index = -1;
                        for (let i = 0; i < labels.length; i++) { // Pour chaque valeur, on recherche la tranche qui lui correspond
                            let bounds = labels[i].split('-');
                            // Le nombre est arrondi à la valeur supérieure pour déterminer sa tranche (ex : 0,5 -> tranche 1 - 20)
                            if (Number(bounds[0]) <= Math.ceil(propertyValue) && Math.ceil(propertyValue) <= Number(bounds[1]))
                                index = i;
                        }
                        if (index >= 0) values[index][i]++;
                    }
                }
            }

            const generateChartData = (list, property, valuePath, colorFunction) => {
                list.forEach(listItem => {
                    labels.push(listItem[valuePath]);
                    backgroundColors.push(colorFunction(listItem[valuePath]));
                    values.push(this.state.initialData.labels.map(() => 0));
                });
                for (let i = 0; i < this.state.layers.length; i++)
                    countValues(i, layer => { return list.find(listItem => listItem.id === layer.feature.properties[property])?.[valuePath] });
            }

            switch (value) { // En fonction de la seconde propriété sélectionnée
                case 'healthReview': case 'averageHealthReview': generateChartData(this.props.healthReviews, value + 'Id', 'value', StylesUtil.getHealthReviewColor); break;
                case 'vigor': generateChartData(this.props.vigors, 'vigorId', 'label', StylesUtil.getVigorColor); break;
                case 'risk': generateChartData(this.props.risks, 'riskId', 'label', StylesUtil.getRiskColor); break;
                case 'tippingRisk': generateChartData(this.props.tippingRisks, 'tippingRiskId', 'label', this.getRandomColor); break;
                case 'organCaliber': generateChartData(this.props.organCalibers, 'organCaliberId', 'label', this.getRandomColor); break;
                case 'target': generateChartData(this.props.targets, 'targetId', 'label', this.getRandomColor); break;
                case 'ontogenicStage': generateChartData(this.props.ontogenicStages, 'ontogenicStageId', 'value', StylesUtil.getOntogenicStageColor); break;
                case 'managementClass': generateChartData(this.props.managementClasses, 'managementClassId', 'value', this.getRandomColor); break;
                case 'plantationType': case 'treePort': case 'coverType': case 'spaceFunction': case 'spaceType': case 'dominantComposition':
                    generateChartData(this.props[value + 's'], value + 'Id', 'label', this.getRandomColor); break;
                case 'age': case 'height': case 'circumference': case 'crownDiameter': case 'trunkHeight': case 'carbonStock': case 'surface': case 'annualMaintenanceFrequency':
                case 'nbTrees': case 'density': case 'numberOfTrunks': case 'distanceBetweenTrunks': case 'averageHeight': case 'averageCircumference': case 'averageCrownDiameter':
                    setNumberLabels(value);
                    for (let i = 0; i < this.state.layers.length; i++)
                        countNumberValues(i, value);
                    break;
                case 'isStump': case 'isDead': case 'isEmpty': case 'isIndexed': case 'isRemarkable': case 'isTreeBase':
                    labels = ['Oui', 'Non']; values = [];
                    labels.forEach(() => values.push(this.state.initialData.labels.map(() => 0)));
                    backgroundColors = ['rgba(0, 255, 0, 0.7)', 'rgba(255, 0, 0, 0.7)'];
                    for (let i = 0; i < this.state.layers.length; i++)
                        countValues(i, layer => { return layer.feature.properties[value] ? 'Oui' : 'Non' });
                    break;
                case 'gender': case 'species': case 'cultivar': case 'vernacularName':
                case 'dominantGender': case 'dominantSpecies': case 'dominantCultivar': case 'dominantVernacularName':
                    let property = value.replace('dominant', '');
                    property = property.charAt(0).toLowerCase() + property.slice(1);
                    const getPropertyFunction = layer => {
                        const essence = this.props.essences.find(x => x.id === layer.feature.properties[value.includes('dominant') ? 'dominantEssenceId' : 'essenceId']);
                        if (essence && essence[property] && !labels.includes(essence[property])) {
                            labels.push(essence[property]);
                            backgroundColors.push(this.getRandomColor());
                            values.push(this.state.initialData.labels.map(() => 0));
                        }
                        return essence ? essence[property] : essence;
                    }
                    for (let i = 0; i < this.state.layers.length; i++)
                        countValues(i, getPropertyFunction);
                    break;
                case 'plantationCoefficient': case 'situationCoefficient': case 'patrimonialCoefficient':
                    const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
                    const propertyName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
                        : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                            : 'descriptionFr';
                    let coefficients;
                    if (value === 'plantationCoefficient') coefficients = this.props.plantationCoefficients.filter(x => x[propertyName]);
                    else if (value === 'situationCoefficient') coefficients = this.props.situationCoefficients.filter(x => x[propertyName]);
                    else if (value === 'patrimonialCoefficient') coefficients = this.props.patrimonialCoefficients;

                    coefficients.forEach(coefficient => {
                        labels.push(coefficient.value);
                        backgroundColors.push(this.getRandomColor());
                        values.push(this.state.initialData.labels.map(() => 0));
                    });
                    for (let i = 0; i < this.state.layers.length; i++)
                        countValues(i, layer => { return coefficients.find(x => x.id === layer.feature.properties[value + 'Id'])?.value });
                    break;

                case 'runoffCoefficient':
                    this.props.runoffCoefficients.forEach(runoffCoefficient => {
                        labels.push(GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient));
                        backgroundColors.push(this.getRandomColor());
                        values.push(this.state.initialData.labels.map(() => 0));
                    });
                    for (let i = 0; i < this.state.layers.length; i++)
                        countValues(i, layer => {
                            const dominantComposition = this.props.dominantCompositions.find(dominantComposition => dominantComposition.id === layer.feature.properties.dominantCompositionId);
                            const runoffCoefficient = this.props.runoffCoefficients.find(runoffCoefficient => runoffCoefficient.id === dominantComposition?.runoffCoefficientId);
                            return GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient);
                        });
                    break;
                case 'toCutDown':
                    labels = [i18n.t("Aucun abattage"), i18n.t("Abattage 1 - 3 ans"), i18n.t("Abattage < 1 an"), i18n.t("Abattage d'urgence"), i18n.t("Abattu")]; values = [];
                    labels.forEach(() => values.push(this.state.initialData.labels.map(() => 0)));
                    backgroundColors = [null, 1, 2, 3, 4].map(toCutDown => { return this.rgbToRgba(StylesUtil.getCutDownColor(toCutDown)) });
                    for (let i = 0; i < this.state.layers.length; i++)
                        countValues(i, layer => {
                            return layer.feature.properties.toCutDown === 1 ? i18n.t("Abattage 1 - 3 ans") :
                                layer.feature.properties.toCutDown === 2 ? i18n.t("Abattage < 1 an") :
                                    layer.feature.properties.toCutDown === 3 ? i18n.t("Abattage d'urgence") :
                                        layer.feature.properties.toCutDown === 4 ? i18n.t("Abattu") :
                                            i18n.t("Aucun abattage");
                        });
                    break;
                case 'interaction': case 'microHabitat': case 'tag':
                case 'rootSymptom': case 'collarSymptom': case 'trunkSymptom': case 'branchSymptom': case 'leafSymptom':
                case 'rootPathogen': case 'collarPathogen': case 'trunkPathogen': case 'branchPathogen': case 'leafPathogen':
                case 'rootPest': case 'collarPest': case 'trunkPest': case 'branchPest': case 'leafPest':
                case 'trunkEpiphyte': case 'branchEpiphyte':
                    let list = this.state[value + 's'];
                    if (value === 'tag') list = list.filter(tag => tag.category === (this.state.category === 'trees' ? 'Arbre' : 'Espace vert'));
                    list.forEach(property => {
                        labels.push(property.label);
                        backgroundColors.push(this.getRandomColor());
                        values.push(this.state.initialData.labels.map(() => 0));
                    });
                    for (let i = 0; i < this.state.layers.length; i++)
                        for (const layerName in this.state.layers[i]) {
                            const layer = this.state.layers[i][layerName];
                            if (layer.feature.properties[value + 'Id']) {
                                for (let j = 0; j < layer.feature.properties[value + 'Id'].length; j++) {
                                    const propertyValue = list.find(x => x.id === layer.feature.properties[value + 'Id'][j])?.label;
                                    if (propertyValue) {
                                        const index = labels.indexOf(propertyValue)
                                        if (index >= 0) values[index][i]++;
                                    }
                                }
                            }
                        }
                    break;
                default: break;
            }

            let datasets = [];
            for (let i = 0; i < labels.length; i++) {
                datasets.push({
                    label: labels[i],
                    backgroundColor: (this.state.config.secondBackgroundColors[value]
                        ? this.state.config.secondBackgroundColors[value][i]
                        : null) || backgroundColors[i],
                    data: values[i],
                    borderColor: 'rgba(0, 0, 0, 1)',
                    borderWidth: 1
                });
            }

            const yLabel = this.state.displayObject ? this.state.config.yLabel : (options.find(x => x.value === value)?.text || 'Nombre d\'arbres') + (
                value === 'carbonStock' ? (this.state.category === 'trees' ? ' (kg)' : ' (t)')
                    : ['crownDiameter', 'height', 'trunkHeight', 'averageHeight', 'averageCrownDiameter', 'distanceBetweenTrunks'].includes(value) ? ' (m)'
                        : ['circumference', 'averageCircumference'].includes(value) ? ' (cm)'
                            : value === 'density' ? ' (/ha)'
                                : value === 'surface' ? ' (m²)'
                                    : value === 'age' ? ` (${i18n.t("Années").toLowerCase()})`
                                        : ''
            );

            this.setState(prevState => {
                let chartOptions = prevState.options;
                chartOptions.scales.yAxes[0].scaleLabel.labelString = yLabel;
                chartOptions.legend.display = prevState.config.displayLegend;
                chartOptions.legend.position = prevState.config.legendPosition;
                chartOptions.tooltips = {
                    mode: 'label',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel < 1 ? null :
                                data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                }
                let secondBackgroundColors = this.state.config.secondBackgroundColors;
                secondBackgroundColors[value] = [
                    'age', 'height', 'circumference', 'crownDiameter', 'trunkHeight', 'carbonStock', 'nbTrees', 'density', 'distanceBetweenTrunks',
                    'averageHeight', 'averageCircumference', 'averageCrownDiameter'
                ].includes(value) ? backgroundColors : secondBackgroundColors[value] || backgroundColors;
                let secondNumberStep = this.state.config.secondNumberStep;
                secondNumberStep[value] = step;
                return {
                    secondProperty: value,
                    data: {
                        ...prevState.data,
                        labels: prevState.initialData.labels,
                        datasets: datasets
                    },
                    options: chartOptions,
                    config: {
                        ...prevState.config,
                        secondNumberStep: secondNumberStep,
                        secondBackgroundColors: secondBackgroundColors
                    },
                    renderChart: false
                }
            }, () => this.setState({ renderChart: true }));
        }
    }

    handleChartTypeChange = (e, { value }) => {
        let data, options;
        let config = this.state.config;
        const themeColor = this.props.isDarkTheme ? 'white' : 'black';
        const linesColor = this.props.isDarkTheme ? '#666' : '#DDD';

        switch (value) { // En fonction du type de graphique sélectionné
            case 'bar': case 'horizontalBar':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = { ...data.datasets[0], backgroundColor: config.backgroundColors };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    scales: {
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.yLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }],
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.xLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }]
                    },
                    layout: { padding: { top: 20 } },
                    plugins: {
                        labels: {
                            render: config.displayLabels ? (args => args.value > 0 ? args.value : '') : () => '',
                            fontColor: themeColor
                        }
                    },
                    legend: { display: false }
                };
                break;
            case 'pie': case 'doughnut':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = { ...data.datasets[0], backgroundColor: config.backgroundColors };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    plugins: {
                        labels: {
                            render: config.displayLabels ? config.valueType : () => '',
                            position: 'outside',
                            textMargin: 8,
                            fontColor: themeColor
                        }
                    },
                    legend: {
                        display: config.displayLegend,
                        position: config.legendPosition,
                        labels: {
                            fontColor: themeColor
                        }
                    }
                };
                break;
            case 'polar':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = { ...data.datasets[0], backgroundColor: config.backgroundColors };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    scale: {
                        ticks: {
                            fontColor: themeColor,
                            backdropColor: 'transparent'
                        },
                        gridLines: { color: linesColor },
                        angleLines: { color: linesColor }
                    },
                    plugins: {
                        labels: {
                            render: config.displayLabels ? config.valueType : () => '',
                            position: 'outside',
                            textMargin: 8,
                            fontColor: themeColor
                        }
                    },
                    layout: { padding: { bottom: 20 } },
                    legend: {
                        display: config.displayLegend,
                        position: config.legendPosition,
                        labels: {
                            fontColor: themeColor
                        }
                    }
                };
                break;
            case 'line':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = {
                    ...data.datasets[0],
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    steppedLine: config.steppedLine,
                    lineTension: config.lineTension,
                    borderWidth: 2,
                    borderColor: config.lineColor,
                    backgroundColor: config.fillColor,
                    pointBackgroundColor: config.pointColor,
                    fill: config.fill,
                };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.yLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.xLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }]
                    },
                    layout: { padding: { top: 20, right: 20 } },
                    legend: { display: false }
                };
                break;
            case 'points':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = {
                    ...data.datasets[0],
                    pointRadius: config.pointRadius,
                    pointHoverRadius: config.pointRadius + 5,
                    backgroundColor: config.fillColor,
                    pointBackgroundColor: config.backgroundColors,
                    showLine: config.showLine,
                    fill: config.fill
                };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.yLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: config.displayAxesLabel,
                                labelString: config.xLabel || '',
                                fontColor: themeColor
                            },
                            gridLines: { color: linesColor }
                        }]
                    },
                    layout: { padding: { top: 20, right: 20 } },
                    legend: { display: false },
                    elements: {
                        point: { pointStyle: config.pointStyle }
                    }
                };
                break;
            case 'radar':
                data = JSON.parse(JSON.stringify(this.state.initialData));
                data.datasets[0] = {
                    ...data.datasets[0],
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    borderColor: config.lineColor,
                    backgroundColor: config.fillColor,
                    pointBackgroundColor: config.backgroundColors
                };
                options = {
                    maintainAspectRatio: !this.state.displayObject || this.state.chartToModify,
                    title: {
                        display: config.title.trim().length > 0,
                        text: config.title,
                        fontColor: themeColor
                    },
                    scale: {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            fontColor: themeColor,
                            backdropColor: 'transparent'
                        },
                        gridLines: { color: linesColor },
                        angleLines: { color: linesColor }
                    },
                    legend: { display: false },
                    tooltips: {
                        callbacks: { title: (tooltipItem, data) => data.labels[tooltipItem[0].index] }
                    }
                };
                break;
            default: break;
        }

        if (options && this.state.isCreating)
            options.animation = { duration: 0 };

        this.setState(prevState => ({
            chartType: !['bar', 'horizontalBar'].includes(value) ? value : (config.orientation === 'vertical' ? 'bar' : 'horizontalBar'),
            secondProperty: prevState.displayObject ? prevState.secondProperty : 'number',
            data: data,
            options: options,
            renderChart: true
        }), () => {
            if (this.state.displayObject && this.state.displayObject.secondProperty && this.state.displayObject.secondProperty !== 'number')
                this.handleSecondPropertyChange(null, { options: this.getOptions(), value: this.state.displayObject.secondProperty });
        });
    }

    handleUpdateFilters = () => {
        this.props.removeFilters(true, JSON.parse(this.state.config.filters));
        this.setState(prevState => ({
            config: { ...prevState.config, filters: null }
        }), () => this.handlePropertyChange(null, { options: this.getOptions(), value: this.state.property }));
    }

    handleDeleteFilters = () => {
        this.props.removeFilters(true, JSON.parse(this.state.config.filters));
        this.setState(prevState => ({
            config: { ...prevState.config, saveFilters: false, filters: null }
        }), () => this.handlePropertyChange(null, { options: this.getOptions(), value: this.state.property }));
    }

    handleNumberStepChange = (e, { name, value }) => {
        if ((value.charCodeAt(value.length - 1) >= 48 && value.charCodeAt(value.length - 1) <= 57) || value.length < 1) {
            if (value === '0') value = '1';
            if (value) value = String(Math.floor(Number(value)));
            this.setState(prevState => {
                let config = prevState.config;
                if (name === 'numberStep') config.numberStep = value;
                else if (name === 'secondNumberStep') config.secondNumberStep[prevState.secondProperty] = value;
                return config;
            });
        }
    }

    handleSteppedChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let data = prevState.data;
            data.datasets[0].steppedLine = value !== 'false' ? value : false;
            return { data: data, config: { ...prevState.config, steppedLine: value !== 'false' ? value : false }, renderChart: true };
        })
    );

    handleTensionChange = (value) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let data = prevState.data;
            data.datasets[0].lineTension = Number(value);
            return { data: data, config: { ...prevState.config, lineTension: value }, renderChart: true };
        })
    );

    handlePointStyleChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.elements.point.pointStyle = value;
            return { options: options, config: { ...prevState.config, pointStyle: value }, renderChart: true };
        })
    );

    handlePointRadiusChange = (value) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let data = prevState.data;
            data.datasets[0].pointRadius = Number(value);
            data.datasets[0].pointHoverRadius = Number(value) + 5;
            return { data: data, config: { ...prevState.config, pointRadius: value }, renderChart: true };
        })
    );

    handleShowLineChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let data = prevState.data;
            data.datasets[0].showLine = value === 'true';
            return { data: data, config: { ...prevState.config, showLine: value === 'true' }, renderChart: true };
        })
    );

    handleFillChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let data = prevState.data;
            data.datasets[0].fill = value === 'true';
            return { data: data, config: { ...prevState.config, fill: value === 'true' }, renderChart: true };
        })
    );

    handleLegendDisplayChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.legend.display = value === 'true';
            return { options: options, config: { ...prevState.config, displayLegend: value === 'true' }, renderChart: true };
        })
    );

    handleLegendPositionChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.legend.position = value;
            return { options: options, config: { ...prevState.config, legendPosition: value }, renderChart: true };
        })
    );

    handleAxesNameDisplayChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.scales.xAxes[0].scaleLabel.display = value === 'true';
            options.scales.yAxes[0].scaleLabel.display = value === 'true';
            return { options: options, config: { ...prevState.config, displayAxesLabel: value === 'true' }, renderChart: true };
        })
    );

    handleAxesNameChange = (axe, value) => this.setState(prevState => { // Axe = x ou y
        let options = prevState.options;
        options.scales[axe + 'Axes'][0].scaleLabel.labelString = value;
        return { options: options, config: { ...prevState.config, [axe + 'Label']: value } };
    });

    handleLabelsDisplayChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.plugins.labels.render = value === 'false' ? (() => '') :
                (['bar', 'horizontalBar'].includes(this.state.chartType) ? (args => args.value > 0 ? args.value : '') : this.state.config.valueType);
            return { options: options, config: { ...prevState.config, displayLabels: value === 'true' }, renderChart: true };
        })
    );

    handleValueDisplayChange = (e, { value }) => this.setState({ renderChart: false },
        () => this.setState(prevState => {
            let options = prevState.options;
            options.plugins.labels.render = value;
            return { options: options, config: { ...prevState.config, valueType: value }, renderChart: true };
        })
    );

    saveCustomChart = () => {
        this.setState({ isSaving: true });
        if (this.state.chartToModify) { // En cas de modification
            const index = this.state.customCharts.findIndex(customChart => customChart.id === this.state.chartToModify);
            if (index !== -1) {
                let customChart = {
                    ...this.state.customCharts[index],
                    property: this.state.property,
                    secondProperty: this.state.secondProperty,
                    chartType: this.state.chartType,
                    config: JSON.stringify(this.state.config.saveFilters && !this.state.config.filteredCategory
                        ? { ...this.state.config, filters: JSON.stringify(this.props.mainFilters) }
                        : this.state.config)
                }

                const { userCustomCharts, ...customChartToSend } = customChart;
                CustomChartsService.updateCustomChart(customChartToSend).then(() => {
                    this.setState(prevState => {
                        let customCharts = prevState.customCharts;
                        customCharts[index] = customChart;
                        return {
                            customCharts: customCharts,
                            chartToModify: 0,
                            step: 1,
                            isSaving: false
                        }
                    }, () => {
                        if (this.props.customCharts) {
                            let customCharts = this.props.customCharts[customChart.projectId || 0];
                            const indexInRedux = customCharts.findIndex(cc => cc.id === customChart.id);
                            customCharts[indexInRedux] = customChart;
                            this.props.setCustomCharts({
                                ...this.props.customCharts,
                                [customChart.projectId || 0]: customCharts
                            });
                        }
                        this.displayCustomChart(index);
                    });
                });
            }

        } else { // En cas d'ajout
            let customChart = {
                userId: jwtDecode(new Cookies().get('token')).id,
                projectId: this.props.project.id,
                category: this.state.category,
                label: this.state.newCustomChartName?.trim() !== '' && this.state.newCustomChartName?.length < 31 ?
                    this.state.newCustomChartName : null,
                property: this.state.property,
                secondProperty: this.state.secondProperty,
                chartType: this.state.chartType,
                config: JSON.stringify(this.state.config.saveFilters && !this.state.config.filteredCategory
                    ? { ...this.state.config, filters: JSON.stringify(this.props.mainFilters) }
                    : this.state.config)
            };

            CustomChartsService.addCustomChart(customChart).then(customChart => {
                if (customChart) {
                    this.setState(prevState => ({
                        customCharts: prevState.customCharts ? this.sortCustomCharts([...prevState.customCharts, customChart]) : [customChart]
                    }), () => this.displayCustomChart(this.state.customCharts.findIndex(cc => cc.id === customChart.id)));

                    if (this.props.customCharts)
                        this.props.setCustomCharts({
                            ...this.props.customCharts,
                            [customChart.projectId]: [...this.props.customCharts[customChart.projectId], customChart]
                        });
                }

                this.setState({
                    newCustomChartName: null,
                    step: 1,
                    isSaving: false
                });
            });
        }
    }

    deleteCustomChart = (id) => {
        const userId = jwtDecode(new Cookies().get('token')).id;
        const index = this.state.customCharts.findIndex(cc => cc.id === id);
        const customChart = this.state.customCharts[index];
        this.setState({ isDeleting: true });
        CustomChartsService.removeCustomChart(customChart).then(() => {
            // TODO
            // const usersToAlert = customChart.userCustomCharts.filter(ucc => ucc.userId !== userId).map(up => up.userId);
            // WebSocketUtil.removeCustomChart(this.props.webSocketHubs, usersToAlert, this.props.project.id, userId, id);
            this.setState(prevState => ({ isDeleting: false, customChartToRemove: 0, customCharts: prevState.customCharts.filter(cc => cc.id !== id) }), () => {
                if (this.props.customCharts)
                    this.props.setCustomCharts({
                        ...this.props.customCharts,
                        [customChart.projectId || 0]: this.props.customCharts[customChart.projectId || 0].filter(cc => cc.id !== customChart.id)
                    });
                if (this.state.selectedCustomChart === id) {
                    if (this.state.customCharts[index]) this.displayCustomChart(index);
                    else this.handleCreationClick();
                }
            });
        });
    }

    displayCustomChart = (index, isModifying = false) => { // Passer isCreating permet de modifier le graphique
        let customChart = this.state.customCharts[index];
        let config = JSON.parse(customChart.config);

        if (config.saveFilters && config.filters) {
            this.props.removeFilters(true, JSON.parse(config.filters));
            this.props.filterLayers(JSON.parse(config.filters));
            this.setState({ areFiltersApplied: true });
        } else if (this.state.areFiltersApplied) {
            this.props.removeFilters(true, JSON.parse(config.filters));
            this.setState({ areFiltersApplied: false });
        }

        this.setState({
            category: customChart.category,
            selectedCustomChart: !isModifying ? customChart.id : 0,
            chartToModify: isModifying ? customChart.id : 0,
            config: config,
            displayObject: {
                property: customChart.property,
                secondProperty: customChart.secondProperty,
                chartType: customChart.chartType
            },
            isCreating: isModifying
        }, () => this.handlePropertyChange(null, { options: this.getOptions(), value: customChart.property }));
    }

    getHorizontalBarOptions = () => { // Pour le graphique horizontalBar, on inverse les noms d'axes
        let options = JSON.parse(JSON.stringify(this.state.options));
        let tmpString;
        tmpString = options.scales.xAxes[0].scaleLabel.labelString;
        options.scales.xAxes[0].scaleLabel.labelString = options.scales.yAxes[0].scaleLabel.labelString;
        options.scales.yAxes[0].scaleLabel.labelString = tmpString;
        options.scales.xAxes[0].ticks = options.scales.yAxes[0].ticks;
        delete options.scales.yAxes[0].ticks;
        if (this.state.secondProperty !== 'number')
            options.tooltips = {
                mode: 'label',
                callbacks: {
                    label: function (tooltipItem, data) {
                        return tooltipItem.xLabel < 1 ? null :
                            data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.xLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                }
            }
        return options;
    }

    getOptions = () => {
        // Required fields
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project)[this.state.category];
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators)[this.state.category];
        const subscription = this.props.project?.organization.subscription;
        const trunkCircumferenceUnit = this.props.project.trunkCircumferenceUnit;

        let options = [];
        switch (this.state.category) {
            case 'trees':
                if (requiredFields.gender && publicFields.gender) options.push({ text: i18n.t("Genre"), value: 'gender' });
                if (requiredFields.species && publicFields.species) options.push({ text: i18n.t("Espèce"), value: 'species' });
                if (requiredFields.cultivar && publicFields.cultivar) options.push({ text: i18n.t("Cultivar"), value: 'cultivar' });
                if (requiredFields.vernacularName && publicFields.vernacularName) options.push({ text: i18n.t("Nom vernaculaire"), value: 'vernacularName' });
                if (requiredFields.trunkHeight && publicFields.trunkHeight) options.push({ text: i18n.t("Hauteur du tronc"), value: 'trunkHeight' });
                if (requiredFields.trunks && publicFields.trunks) options.push({ text: i18n.t("Hauteur totale axe"), value: 'height' });
                if (requiredFields.trunks && publicFields.trunks) options.push({ text: trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe"), value: 'circumference' });
                if (requiredFields.trunks && publicFields.trunks) options.push({ text: i18n.t("Diamètre couronne axe"), value: 'crownDiameter' });
                if (requiredFields.treePort && publicFields.treePort) options.push({ text: i18n.t("Port de l'arbre"), value: 'treePort' });
                if (requiredFields.coverType && publicFields.coverType) options.push({ text: i18n.t("Type de couverture au sol"), value: 'coverType' });
                if (requiredFields.numberOfTrunks && publicFields.numberOfTrunks) options.push({ text: i18n.t("Nombre d'axes"), value: 'numberOfTrunks' });
                if (requiredFields.vigor && publicFields.vigor) options.push({ text: i18n.t("Vigueur"), value: 'vigor' });
                if (requiredFields.risk && publicFields.risk) options.push({ text: i18n.t("Risque"), value: 'risk' });
                if (requiredFields.accurateRisk && publicFields.risk) {
                    options.push({ text: i18n.t("Risque de basculement/rupture"), value: 'tippingRisk' });
                    options.push({ text: i18n.t("Calibre de l'organe instable"), value: 'organCaliber' });
                    options.push({ text: i18n.t("Cible"), value: 'target' });
                }
                if (requiredFields.plantationType && publicFields.plantationType) options.push({ text: i18n.t("Type de plantation"), value: 'plantationType' });
                if (requiredFields.healthReview && publicFields.healthReview) options.push({ text: i18n.t("Cote sanitaire"), value: 'healthReview' });
                if (requiredFields.ontogenicStage && publicFields.ontogenicStage) options.push({ text: i18n.t("Stade ontogénique"), value: 'ontogenicStage' });
                if (requiredFields.plantationCoefficient && publicFields.plantationCoefficient) options.push({ text: i18n.t("Coefficient de plantation"), value: 'plantationCoefficient' });
                if (requiredFields.situationCoefficient && publicFields.situationCoefficient) options.push({ text: i18n.t("Coefficient de situation"), value: 'situationCoefficient' });
                const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
                if (amenityFormulaType === 'Wallonie' && requiredFields.patrimonialCoefficient && publicFields.patrimonialCoefficient)
                    options.push({ text: i18n.t("Coefficient patrimonial"), value: 'patrimonialCoefficient' });
                if (requiredFields.isEmpty && publicFields.isEmpty) options.push({ text: i18n.t("Vide"), value: 'isEmpty' });
                if (requiredFields.isDead && publicFields.isDead) options.push({ text: i18n.t("Mort"), value: 'isDead' });
                if (requiredFields.isStump && publicFields.isStump) options.push({ text: i18n.t("Souche"), value: 'isStump' });
                if (requiredFields.isIndexed && publicFields.isIndexed) options.push({ text: i18n.t("Classé"), value: 'isIndexed' });
                if (requiredFields.isRemarkable && publicFields.isRemarkable) options.push({ text: i18n.t("Remarquable"), value: 'isRemarkable' });
                if (publicFields.toCutDown) options.push({ text: i18n.t("À abattre"), value: 'toCutDown' });
                if (requiredFields.interactions && publicFields.interactions) options.push({ text: i18n.t("Interactions"), value: 'interaction' });
                if (requiredFields.microHabitats && publicFields.microHabitats) options.push({ text: i18n.t("Dendro-microhabitats"), value: 'microHabitat' });

                treeOrgans.forEach(({ organ, label }) => {
                    if (requiredFields[`${organ}Symptoms`] && publicFields[`${organ}Symptoms`]) options.push({ text: `${i18n.t("Symptômes")} ${label}`, value: `${organ}Symptom` });
                    if (requiredFields[`${organ}Pathogens`] && publicFields[`${organ}Pathogens`]) options.push({ text: `${i18n.t("Pathogènes")} ${label}`, value: `${organ}Pathogen` });
                    if (requiredFields[`${organ}Pests`] && publicFields[`${organ}Pests`]) options.push({ text: `${i18n.t("Ravageurs")} ${label}`, value: `${organ}Pest` });
                    if (['trunk', 'branch'].includes(organ) && requiredFields[`${organ}Epiphytes`] && publicFields[`${organ}Epiphytes`]) options.push({ text: `${i18n.t("Épiphytes")} ${label}`, value: `${organ}Epiphyte` });
                });

                if (requiredFields.tags && publicFields.tags && this.state.tags.filter(tag => (
                    tag.category === (this.state.category === 'trees' ? 'Arbre' : 'Espace vert')
                )).length > 0)
                    options.push({ text: i18n.t("Tags"), value: 'tag' });
                if (requiredFields.carbonStock && publicFields.carbonStock && subscription.carbonStockFormula)
                    options.push({ text: i18n.t("Stock carbone"), value: 'carbonStock' });
                options = options.sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.text.toUpperCase();
                    const textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                if (requiredFields.age && publicFields.age) options.unshift({ text: i18n.t("Âge"), value: 'age' });
                return options;
            case 'greenSpaces':
                if (requiredFields.tags && publicFields.tags && this.state.tags.filter(tag => (
                    tag.category === (this.state.category === 'trees' ? 'Arbre' : 'Espace vert')
                )).length > 0)
                    options.push({ text: i18n.t("Tags"), value: 'tag' });
                if (requiredFields.carbonStock && publicFields.carbonStock && subscription.carbonStockFormula) options.push({ text: i18n.t("Stock carbone"), value: 'carbonStock' });
                if (requiredFields.surface && publicFields.surface) options.push({ text: i18n.t("Surface"), value: 'surface' });
                if (requiredFields.spaceFunction && publicFields.spaceFunction) options.push({ text: i18n.t("Fonction de l'espace"), value: 'spaceFunction' });
                if (requiredFields.spaceType && publicFields.spaceType) options.push({ text: i18n.t("Type de surface"), value: 'spaceType' });
                if (requiredFields.dominantComposition && publicFields.dominantComposition) options.push({ text: i18n.t("Composition dominante"), value: 'dominantComposition' });
                if (requiredFields.managementClass && publicFields.managementClass) options.push({ text: i18n.t("Classe de gestion"), value: 'managementClass' });
                if (requiredFields.dominantComposition && publicFields.dominantComposition) options.push({ text: i18n.t("Coefficient de ruissellement"), value: 'runoffCoefficient' });
                if (requiredFields.annualMaintenanceFrequency && publicFields.annualMaintenanceFrequency) options.push({ text: i18n.t("Fréquence annuelle d'entretien"), value: 'annualMaintenanceFrequency' });
                if (requiredFields.isTreeBase && publicFields.isTreeBase) options.push({ text: i18n.t("Pied d'arbre"), value: 'isTreeBase' });
                if (requiredFields.density && publicFields.density) {
                    options.push({ text: i18n.t("Nombre d'arbres"), value: 'nbTrees' });
                    options.push({ text: i18n.t("Densité"), value: 'density' });
                    options.push({ text: i18n.t("Distance moyenne entre les troncs"), value: 'distanceBetweenTrunks' });
                }
                if (requiredFields.dominantEssence && publicFields.dominantEssence) options.push({ text: i18n.t("Nom vernaculaire dominant"), value: 'dominantVernacularName' });
                if (requiredFields.dominantEssence && publicFields.dominantEssence) options.push({ text: i18n.t("Genre dominant"), value: 'dominantGender' });
                if (requiredFields.dominantEssence && publicFields.dominantEssence) options.push({ text: i18n.t("Espèce dominante"), value: 'dominantSpecies' });
                if (requiredFields.dominantEssence && publicFields.dominantEssence) options.push({ text: i18n.t("Cultivar dominant"), value: 'dominantCultivar' });
                if (requiredFields.averageHealthReview && publicFields.averageHealthReview) options.push({ text: i18n.t("Cote sanitaire moyenne"), value: 'averageHealthReview' });
                if (requiredFields.averageHeight && publicFields.averageHeight) options.push({ text: i18n.t("Hauteur moyenne"), value: 'averageHeight' });
                if (requiredFields.averageCircumference && publicFields.averageCircumference) options.push({ text: i18n.t("Circonférence moyenne des troncs"), value: 'averageCircumference' });
                if (requiredFields.averageCrownDiameter && publicFields.averageCrownDiameter) options.push({ text: i18n.t("Diamètre moyen des couronnes"), value: 'averageCrownDiameter' });
                options = options.sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.text.toUpperCase();
                    const textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                return options;
            default: return;
        }
    }

    getData = () => { // Permet retirer les données insignifiantes lorsque l'option 'masquer les zéros' est sélectionnée
        let displayedData = JSON.parse(JSON.stringify(this.state.data));
        if (this.state.config.showZero && displayedData.datasets[0]) {
            if (this.state.secondProperty === 'number') {
                for (let i = displayedData.datasets[0].data.length - 1; i >= 0; i--)
                    if (displayedData.datasets[0].data[i] === 0) {
                        displayedData.labels.splice(i, 1);
                        displayedData.datasets[0].data.splice(i, 1);
                        if (['radar', 'points'].includes(this.state.chartType) && Array.isArray(displayedData.datasets[0].pointBackgroundColor))
                            displayedData.datasets[0].pointBackgroundColor.splice(i, 1);
                        else if (this.state.chartType !== 'line' && Array.isArray(displayedData.datasets[0].backgroundColor))
                            displayedData.datasets[0].backgroundColor.splice(i, 1);
                    }
            } else {
                for (let i = displayedData.datasets[0].data.length - 1; i >= 0; i--) {
                    let total = 0;
                    for (let j = 0; j < displayedData.datasets.length; j++)
                        total += displayedData.datasets[j].data[i];
                    if (total < 1) {
                        displayedData.labels.splice(i, 1);
                        for (let j = 0; j < displayedData.datasets.length; j++)
                            displayedData.datasets[j].data.splice(i, 1);
                    }
                }

                for (let i = displayedData.datasets.length - 1; i >= 0; i--)
                    if (displayedData.datasets[i].data.reduce((a, b) => a + b, 0) < 1)
                        displayedData.datasets.splice(i, 1);
            }
        }

        if (['bar', 'horizontalBar', 'line', 'points'].includes(this.state.chartType) && [
            'plantationType', 'treePort', 'coverType', 'spaceFunction', 'spaceType', 'dominantComposition', 'gender', 'species', 'cultivar', 'vernacularName',
            'interaction', 'microHabitat', 'dominantGender', 'dominantSpecies', 'dominantCultivar', 'dominantVernacularName', 'tag',
            ...treeOrgans.flatMap(({ organ }) => [`${organ}Symptom`, `${organ}Pathogen`, `${organ}Pest`, `${organ}Epiphyte`])
        ].includes(this.state.property)) {
            const dataToSort = displayedData.labels
                .map((label, index) => ({ label, value: displayedData.datasets.reduce((previousValue, dataset) => previousValue + dataset.data[index], 0) }))
                .sort((a, b) => b.value - a.value);
            for (let i = 0; i < dataToSort.length; i++) {
                const oldIndex = displayedData.labels.indexOf(dataToSort[i].label);
                const tmpLabel = displayedData.labels[i];
                displayedData.labels[i] = displayedData.labels[oldIndex];
                displayedData.labels[oldIndex] = tmpLabel;

                for (let j = 0; j < displayedData.datasets.length; j++) {
                    const tmpValue = displayedData.datasets[j].data[i];
                    displayedData.datasets[j].data[i] = displayedData.datasets[j].data[oldIndex];
                    displayedData.datasets[j].data[oldIndex] = tmpValue;
                    if (j === 0 && Array.isArray(displayedData.datasets[j].backgroundColor)) {
                        const tmpColor = displayedData.datasets[j].backgroundColor[i];
                        displayedData.datasets[j].backgroundColor[i] = displayedData.datasets[j].backgroundColor[oldIndex];
                        displayedData.datasets[j].backgroundColor[oldIndex] = tmpColor;
                    }
                }
            }
        }

        return displayedData;
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        runoffCoefficients: state.runoffCoefficients,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        customCharts: state.customCharts,
        rights: state.rights,
        webSocketHubs: state.webSocketHubs,
        isOnline: state.isOnline,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setCustomCharts
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCharts);