import React, { Component } from 'react';
import AppSettings from '../../AppSettings';
// Composants
import { Card, Checkbox, Divider, Grid, Image, Label, Loader, Segment, Statistic } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadialGauge, RadialGaugeArc, StackedRadialGaugeValueLabel, StackedRadialGaugeSeries } from 'reaviz';
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setDashboardInfos } from '../../actionCreators/adminActions';
import { faArrowTrendDown, faArrowTrendUp, faBuilding, faChartLine, faCode, faFilterList, faFlowerTulip, faFolderOpen, faLayerGroup, faSackDollar, faTablePicnic, faTachometerAlt, faTree, faUser, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import { faHexagon } from '@fortawesome/pro-regular-svg-icons';
import { enUS, fr, nlBE } from 'date-fns/locale';
import { format, subMonths } from 'date-fns';
// Ressources
import AvatarB from '../../resources/images/avatar-b.png';
import AvatarC from '../../resources/images/avatar-c.png';
// Services
import ParametersService from '../../services/ParametersService';
import StatisticsService from '../../services/StatisticsService';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import DatesUtil from '../../utils/DatesUtil';
import FormulasUtil from '../../utils/FormulasUtil';

const LABELS = [
    'Extinction de masse',
    'Bombe nucléaire',
    'Catastrophe naturelle',
    'Pandémie mondiale',
    'Éruption volcanique',
    'Chute de météorites',
    'Raz-de-marée',
    'Tremblement de terre'
];

class AdminDashboard extends Component {
    state = {
        infos: null,
        isLoading: true,
        maintenanceLabel: '',
        bombActivated: false,
        showTotal: true
    };

    render() {
        const { isDarkTheme } = this.props;
        const { infos, isLoading, maintenanceLabel, bombActivated, showTotal } = this.state;
        const maxUsers = infos && (infos.users - infos.administrators - infos.developers - infos.demos);
        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%', overflowY: 'overlay' }}>
                {this.props.activeOrganization?.subscription.shortName === 'Dev' &&
                    <div style={{ position: 'relative', marginTop: '14px' }}>
                        <h3 style={{ textAlign: 'center', marginBottom: 0 }}>{i18n.t("Dashboard")}</h3>
                        <p id='app-version' style={{ textAlign: 'center', margin: 0 }}>{i18n.t("Version")} {AppSettings.getAppVersion()}</p>
                        {bombActivated ?
                            <Checkbox
                                className='maintenance-checkbox' style={{ position: 'absolute', top: '0px', right: '10px', backgroundColor: 'red', padding: '4px 8px', borderRadius: '15px' }}
                                toggle label={maintenanceLabel} checked={infos?.isUnderMaintenance || false} onChange={this.toggleMaintenanceStatus}
                            />
                            :
                            <Checkbox
                                style={{ position: 'absolute', top: '0px', right: '10px' }}
                                toggle label={infos?.isUnderMaintenance ? i18n.t("Maintenance en cours...") : i18n.t("Activer la maintenance")}
                                checked={infos?.isUnderMaintenance || false} onChange={this.toggleMaintenanceStatus}
                            />}
                    </div>}
                <Divider />
                {isLoading && <Loader active content={i18n.t("Chargement en cours...")} />}
                {infos &&
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', gap: '5px', padding: '0 10px', marginBottom: '5px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginRight: '5px' }}>
                                <div itemsPerRow={4} style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: 0, margin: 0 }}>
                                    {[
                                        { label: i18n.t("Arbres"), value: infos.trees, icon: faTree, grow: infos.treesGrow },
                                        { label: i18n.t("Espaces verts"), value: infos.greenSpacesSurface, additionalValue: infos.greenSpaces, icon: faFlowerTulip, grow: infos.greenSpacesGrow },
                                        { label: i18n.t("Mobiliers"), value: infos.furnitures, icon: faTablePicnic, grow: infos.furnituresGrow },
                                        { label: i18n.t("Stations"), value: infos.stations, icon: faHexagon }
                                    ].map(({ label, value, additionalValue, icon, grow }) => this.renderStatisticCard(label, value, icon, { additionalValue, grow }))}
                                </div>
                                <div style={{ display: 'flex', gap: '10px', maxHeight: '335px', marginTop: '5px', marginBottom: '5px' }}>
                                    <Card style={{ minWidth: '300px', height: '100%', flex: 1, marginTop: '5px' }} onClick={() => this.props.changePanelContentType('ManageUsers')}>
                                        <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                            <Card.Header style={{ paddingLeft: '10px' }}>
                                                <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} />
                                                {`${i18n.t("Utilisateurs")} (${infos.users || 0})`}
                                            </Card.Header>
                                            <Divider style={{ marginTop: '7px' }} />
                                            <span className='no-themed' style={{ color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)', fontStyle: 'italic' }}>{i18n.t("Les 10 derniers inscrits")}</span>
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
                                                {infos.lastUsers?.map(user => {
                                                    const organization = (user.userOrganizations.find(uo => !uo.isOwner) || user.userOrganizations[0])?.organization;
                                                    return (
                                                        <>
                                                            <div style={{ display: 'flex', textAlign: 'left', margin: '7px' }}>
                                                                <span title={FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)} style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                    {FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)}
                                                                </span>
                                                                <span className='no-themed' style={{ marginLeft: '5px', color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)' }}>{`(${user.email})`}</span>
                                                                {organization &&
                                                                    <Label style={{ fontSize: '10px', backgroundColor: 'var(--grey-100)', color: 'var(--white-100)', marginLeft: '10px' }}>
                                                                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />{organization.label}
                                                                    </Label>}
                                                                <span className='no-themed' style={{ marginLeft: 'auto', color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)' }}>
                                                                    {DatesUtil.getFormattedLocaleDateString(user.registrationDate)}
                                                                </span>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                    <Card style={{ minWidth: '300px', height: '100%', flex: 1, marginTop: '5px' }} onClick={() => this.props.changePanelContentType('ManageProjects')}>
                                        <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                            <Card.Header style={{ paddingLeft: '10px' }}>
                                                <FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '10px' }} />
                                                {`${i18n.t("Projets")} (${infos.projects})`}
                                            </Card.Header>
                                            <Divider style={{ marginTop: '7px' }} />
                                            <span className='no-themed' style={{ color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)', fontStyle: 'italic' }}>{i18n.t("Les 10 derniers créés")}</span>
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
                                                {infos.lastProjects?.map(project => (
                                                    <div style={{ display: 'flex', textAlign: 'left', margin: '7px' }}>
                                                        <span title={project.label} style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{project.label}</span>
                                                        <span className='no-themed' style={{ marginLeft: 'auto', color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)' }}>
                                                            {DatesUtil.getFormattedLocaleDateString(project.date)}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '250px' }}>
                                {[
                                    { label: i18n.t("Actions"), value: infos.actions, icon: faUserHelmetSafety, onClick: () => this.props.changePanelContentType('ManageActions') },
                                    { label: i18n.t("Graphiques"), value: infos.customCharts, icon: faChartLine },
                                    { label: i18n.t("Filtres"), value: infos.filters, icon: faFilterList },
                                    { label: i18n.t("Cartes thématiques"), value: infos.thematicMaps, icon: faLayerGroup },
                                    { label: i18n.t("Listes de prix"), value: infos.priceLists, icon: faSackDollar }
                                ].map(({ label, value, icon, onClick }) => this.renderStatisticCard(label, value, icon, { backgroundColor: 'var(--purple-100)', onClick }))}
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: '5px 10px 10px 10px', flex: 1, gap: '10px' }}>
                            <Card style={{ height: '100%', minWidth: '300px' }}>
                                <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    <Card.Header style={{ paddingLeft: '10px' }}>
                                        <FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '10px' }} />
                                        {`${i18n.t("Connexions du jour")} (${infos.connectedUsers?.length || 0})`}
                                    </Card.Header>
                                    <Divider style={{ marginTop: '7px' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', maxHeight: '285px' }}>
                                        {infos.connectedUsers?.map(user => (
                                            <div style={{ display: 'flex', textAlign: 'left', margin: '7px' }}>
                                                <span title={FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)} style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName)}</span>
                                                <span className='no-themed' style={{ marginLeft: 'auto', color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)' }}>
                                                    {DatesUtil.getFormattedLocaleTimeString(user.lastLoginDate)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </Card.Content>
                            </Card>
                            <Card style={{ flex: 1, height: '100%', margin: 0 }}>
                                <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    <Card.Header style={{ paddingLeft: '10px' }}>
                                        <FontAwesomeIcon icon={faTachometerAlt} style={{ marginRight: '10px' }} />
                                        {showTotal ? i18n.t("Indicateurs totaux") : i18n.t("Indicateurs moyens / projet")}
                                    </Card.Header>
                                    <Divider style={{ marginTop: '7px' }} />
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', overflow: 'hidden' }}>
                                        <Checkbox
                                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                                            toggle label={i18n.t("Total")} checked={showTotal || false} onChange={this.toggleShowTotal}
                                        />
                                        {[
                                            { label: i18n.t("Valeur d'agrément"), value: infos.averageAmenityValue, color: '#daa817', formatFn: (value) => FormattersUtil.formatEuro(Math.round(value)) },
                                            { label: i18n.t("Stockage annuel CO2"), value: infos.averageCarbonStock, color: '#7250bc', formatFn: (value) => `${FormattersUtil.formatKilogram(value, { defaultUnit: 'kg' })}/an` },
                                            { label: i18n.t("Production d'oxygène"), value: FormulasUtil.getOxygenProduction(infos.averageCarbonStock), color: '#c83030', formatFn: (value) => `${FormattersUtil.formatKilogram(value, { defaultUnit: 'kg' })}/an` },
                                            { label: i18n.t("Rafraîchissement (énergie)"), value: infos.averageCoolingIndicator, color: '#2185d0', formatFn: (value) => `${FormattersUtil.formatKilowattHour(value)}/an` },
                                            { label: i18n.t("Rafraîchissement (valeur)"), value: infos.averageCoolingEnergyIndicator, color: '#daa817', formatFn: (value) => `${FormattersUtil.formatEuro(value)}/an` }
                                        ].map(({ label, value, color, formatFn }) => {
                                            if (showTotal) value = value * infos.projects;
                                            return this.renderRadialGauge(label, value, formatFn(value), value, color)
                                        })}
                                    </div>
                                </Card.Content>
                            </Card>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Card style={{ flex: 1, width: '100%', marginBottom: '10px' }}>
                                    <Card.Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Statistic label={i18n.t("jours depuis le début du développement")} value={infos.devDays} />
                                    </Card.Content>
                                </Card>
                                <Card style={{ width: '100%', margin: 0 }}>
                                    <Card.Content style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                        <Card.Header style={{ paddingLeft: '10px' }}>
                                            <FontAwesomeIcon icon={faCode} style={{ marginRight: '10px' }} />
                                            {i18n.t("Développeurs")}
                                        </Card.Header>
                                        <Divider style={{ marginTop: '7px' }} />
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/christopher-denis/'><Image avatar src={AvatarC} style={{ height: '75.2px', width: '75.2px' }} /><span style={{ marginLeft: '10px' }}><b>Christopher</b></span></a>
                                            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/font-bastien/'><Image avatar src={AvatarB} style={{ height: '75.2px', width: '75.2px', marginLeft: '50px' }} /><span style={{ marginLeft: '10px' }}><b>Bastien</b></span></a>
                                        </div>
                                    </Card.Content>
                                </Card>
                            </div>
                        </div>
                    </div>}
            </Segment>
        );
    }

    componentDidMount = () => {
        if (this.props.dashboardInfos) this.setState({ infos: this.props.dashboardInfos, isLoading: false });
        else StatisticsService.getAdminDashboardInfos().then(infos => {
            this.setState({ infos, isLoading: false });
            this.props.setDashboardInfos(infos);
        });

        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    renderStatisticCard = (label, value, icon, { backgroundColor = 'var(--primary-100)', onClick = null, additionalValue, grow } = {}) => {
        const { isDarkTheme } = this.props;
        const isGreenSpaces = label === i18n.t("Espaces verts");
        const locale = i18n.language === 'fr-BE' ? fr : i18n.language === 'en-US' ? enUS : nlBE;
        grow = Math.round(grow * 100) / 100;

        return (
            <Card style={{ margin: 0, flex: 1 }} onClick={onClick}>
                <Card.Content>
                    <Card.Meta>
                        <Grid.Row style={{ display: 'flex' }}>
                            <Statistic size='tiny' style={{ margin: 0 }}>
                                <Statistic.Label style={{ textAlign: 'left' }}>{label} </Statistic.Label>
                                <Statistic.Value title={additionalValue} style={{ textAlign: 'left' }}>{isGreenSpaces ? FormattersUtil.formatSquareMeter(value) : value}</Statistic.Value>
                            </Statistic>
                            {icon && <FontAwesomeIcon icon={icon} style={{ marginLeft: 'auto', borderRadius: '50%', padding: '7px', aspectRatio: '1/1', backgroundColor, alignSelf: 'center', color: 'white' }} size='2x' />}
                        </Grid.Row>
                        {((grow && !isNaN(grow)) ? true : false) &&
                            <Grid.Row style={{ textAlign: 'left', display: 'flex', marginTop: '7px' }}>
                                <span className='no-themed' style={{ color: grow >= 0 ? 'var(--green-100)' : 'var(--red-100)' }}>
                                    <FontAwesomeIcon icon={grow >= 0 ? faArrowTrendUp : faArrowTrendDown} style={{ marginRight: '5px' }} />
                                    {grow >= 0 && '+'}{grow}%
                                </span>
                                <span className='no-themed' style={{ color: isDarkTheme ? 'var(--grey-50)' : 'var(--grey-80)' }}>{i18n.t("en {{month}}", { month: format(subMonths(new Date(), 1), 'LLLL', { locale }) })}</span>
                            </Grid.Row>
                        }
                    </Card.Meta>
                </Card.Content>
            </Card>
        );
    }

    renderRadialGauge = (label, value, formattedValue, maxValue, innerColor) => {
        const data = [{ key: label, data: value > maxValue ? maxValue : value }];
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%', height: '100%' }}>
                <RadialGauge
                    className='reaviz-gauge' data={data} height={200} style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    maxValue={maxValue !== undefined ? maxValue || 1 : value || 1} startAngle={-(Math.PI / 5 * 4)} endAngle={Math.PI / 5 * 4}
                    series={
                        <StackedRadialGaugeSeries
                            innerArc={<RadialGaugeArc disabled={true} animated={true} cornerRadius={12.5} />}
                            outerArc={<RadialGaugeArc disabled={true} animated={true} cornerRadius={12.5} />}
                            label={<StackedRadialGaugeValueLabel label={formattedValue} />} colorScheme={innerColor}
                        />
                    }
                />
                <p style={{ fontWeight: 'bold', fontSize: '10pt', padding: 0, margin: 0, marginTop: '-20px' }}>{label}</p>
            </div>
        );
    }

    handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'Control' && !this.state.bombActivated)
            this.setState({ bombActivated: true, maintenanceLabel: LABELS[Math.floor(Math.random() * LABELS.length)] });
    }

    handleKeyUp = (event) => {
        if (!event.ctrlKey && event.key === 'Control' && this.state.bombActivated)
            this.setState({ bombActivated: false });
    }

    toggleMaintenanceStatus = (_, { checked }) => {
        if (checked && this.state.bombActivated) {
            const body = document.getElementsByTagName("BODY")[0];
            body.classList.add('animation-shake');
            setTimeout(() => body.classList.remove('animation-shake'), 2000);
        }
        this.setState(prevState => ({ infos: { ...prevState.infos, isUnderMaintenance: checked } }));
        this.props.setDashboardInfos({ ...this.props.dashboardInfos, isUnderMaintenance: checked });
        ParametersService.toggleMaintenanceStatus(checked);
    }

    toggleShowTotal = (_, { checked }) => this.setState({ showTotal: checked });
}

const mapStateToProps = (state) => {
    return {
        dashboardInfos: state.dashboardInfos,
        activeOrganization: state.activeOrganization,
        isDarkTheme: state.isDarkTheme
    };
};

const mapDispatchToProps = {
    setDashboardInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);