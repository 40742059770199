import React, { Component } from 'react';
// Composants
import { Grid, Header, Card, Button, Divider, Statistic, Dropdown, Dimmer, Message, Checkbox, Select, Loader, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { RadialGauge, StackedRadialGaugeSeries, StackedRadialGaugeValueLabel, RadialGaugeArc } from 'reaviz';
import CompareProjectsTree from '../Utils/CompareProjectsTree';
import DropDownWithCheckboxes from '../Utils/DropDownWithCheckboxes';
import InfoIcon from '../Utils/InfoIcon';
// Librairies
import { connect } from 'react-redux';
import { setIsDarkTheme } from '../../actionCreators/appActions';
import i18n from '../../locales/i18n';
import {
    faAxe, faBenchTree, faBoxOpenFull, faLightbulbOn, faTreeDeciduous, faTreeCity, faAppleAlt, faCircleDot,
    faLock, faSeedling, faTachometerAlt, faGear, faCheck, faTimesCircle, faDownload, faTree, faFlowerTulip, faTablePicnic, faFileAlt,
    faWaveSquare, faTrees, faWater, faCloud, faSickle, faSquare, faFilterList, faLasso
} from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faCircleSmall, faHexagon } from '@fortawesome/pro-regular-svg-icons';
import { faAxe as faAxeLight } from '@fortawesome/pro-light-svg-icons';
import { faLineColumns } from '@fortawesome/pro-duotone-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ScrollSyncPane } from 'react-scroll-sync';
// Ressources
import fruitsPNG from '../../resources/pngs/fruits.png';
import treeMoneyPNG from '../../resources/pngs/tree-money.png';
import earthPNG from '../../resources/pngs/earth.png';
import desertPNG from '../../resources/pngs/desert.png';
import forestPNG from '../../resources/pngs/forest.png';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import StylesUtil from '../../utils/StylesUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';
import StatisticsService from '../../services/StatisticsService';
import TreesUtil from '../../utils/TreesUtil';
import UrlsUtil from '../../utils/UrlsUtil';

const initialExportConfig = {
    inventory: {
        global: {
            nbTrees: true,
            nbGreenSpaces: true,
            nbFurnitures: true
        },
        trees: {
            count: true,
            tallest: true,
            smallest: true,
            biggest: true,
            oldest: true,
            youngest: true,
            toCutDown: true,
            cutDown: true,
            fruitTrees: true
        },
        greenSpaces: {
            count: true,
            surface: true,
            tallest: true,
            smallest: true,
            lawnsSurface: true,
            hayfieldSurface: true,
            shrubMassSurface: true,
            woodedMassifSurface: true,
            wetAreaSurface: true,
            totalHedgedLength: true
        },
        furnitures: {
            count: true,
            predominantType: true
        }
    },
    performance: {
        global: {
            canopy: true,
            annualCoolingIndicator: true,
            annualCoolingEconomicValue: true,
            carbonStock: true,
            totalCarbonStock: true,
            oxygenProduction: true,
            fruitProduction: true,
            amenityValue: true
        },
        trees: {
            canopy: true,
            annualCoolingIndicator: true,
            annualCoolingEconomicValue: true,
            carbonStock: true,
            totalCarbonStock: true,
            oxygenProduction: true,
            fruitProduction: true,
            biodiversityIndex: true,
            amenityValue: true
        },
        greenSpaces: {
            annualCoolingIndicator: true,
            annualCoolingEconomicValue: true,
            carbonStock: true,
            oxygenProduction: true
        }
    },
    punchlines: {
        carbonStock: true,
        cooling: true,
        oxygen: true
    }
};

const initialTreesState = {
    count: 0,
    tallest: null,
    smallest: null,
    biggest: null,
    oldest: null,
    youngest: null,
    carbonStock: 0,
    totalCarbonStock: 0,
    biodiversityIndex: 0,
    amenityValue: 0,
    toCutDown: 0,
    cutDown: 0,
    canopyIndicator: 0,
    canopyIndicatorWithoutOverlap: 0,
    canopyIndicatorWithWoodedMassifs: -1, // -1 = pas encore calculé car est calculé en différé
    annualCoolingIndicator: 0,
    annualCoolingEconomicValue: 0,
    oxygenProduction: 0,
    fruitProduction: 0,
    fruitTrees: 0
};

const initialWoodedMassifsState = {
    nbTrees: 0,
    carbonStock: 0,
    totalCarbonStock: 0,
    canopyIndicator: 0,
    canopyIndicatorWithoutOverlap: 0,
    annualCoolingIndicator: 0,
    annualCoolingEconomicValue: 0,
    oxygenProduction: 0,
    fruitProduction: 0,
    fruitTrees: 0
};

const initialGreenSpacesState = {
    count: 0,
    surface: 0,
    tallest: null,
    smallest: null,
    lawnsSurface: 0,
    hayfieldSurface: 0,
    shrubMassSurface: 0,
    woodedMassifSurface: 0,
    wetAreaSurface: 0,
    totalHedgedLength: 0,
    carbonStock: 0,
    annualCoolingIndicator: 0,
    annualCoolingEconomicValue: 0,
    oxygenProduction: 0,
    ecopotentialIndex: 0
};

const initialFurnitureState = {
    count: 0,
    predominantType: null
};

class ProjectDetail extends Component {
    state = {
        display: 'detail',
        showExportForm: false,
        isExporting: false,
        isExportReady: false,
        exportConfig: initialExportConfig,
        showComparisonForm: false,
        treesPerformanceOptions: {
            showTrees: true,
            showWoodedMassifs: true
        },
        woodedMassifs: initialWoodedMassifsState,
        trees: initialTreesState,
        greenSpaces: initialGreenSpacesState,
        furnitures: initialFurnitureState,
        stationList: [],
        stationOptions: [],
        stationId: 'none',
        withOverlap: false,
        search: null
    };

    render() {
        const { activeOrganization, isDarkTheme, isOnline, furnitureTypes, isComparison, project, selectedElements, mainFilters } = this.props;
        const {
            showExportForm, exportConfig, trees, woodedMassifs, greenSpaces, furnitures, showComparisonForm,
            display, isExporting, isExportReady, treesPerformanceOptions, stationOptions, stationId, withOverlap
        } = this.state;
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.projectToCompareWith || this.props.project);
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.projectToCompareWith || this.props.project, this.props.projectCollaborators);
        const projectSubscription = this.props.projectToCompareWith
            ? this.props.projectToCompareWith.organization.subscription
            : project.organization.subscription;
        const backgroundColor = `var(--${isDarkTheme ? 'black' : 'white'}-${isExporting ? '100' : '90'})`;
        const treesPerformance = treesPerformanceOptions.showTrees && treesPerformanceOptions.showWoodedMassifs
            ? this.mergeTreesPerformance()
            : treesPerformanceOptions.showTrees ? trees
                : treesPerformanceOptions.showWoodedMassifs
                    ? { ...woodedMassifs, amenityValue: 0 } : initialTreesState;
        const globalPerformance = this.getGlobalPerformance(requiredFields);
        const numberOfTurns = Math.round((((globalPerformance.carbonStock) * 1000) / 97 / 40000) * 100) / 100;
        const nbPersons = Math.round((0.72727 * (globalPerformance.carbonStock)) / 255.5);
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;
        // Export
        const isExportable = projectSubscription.export && activeOrganization?.subscription.export;
        const exportTreesInventory = Object.values(exportConfig.inventory.trees).includes(true);
        const exportGreenSpacesInventory = Object.values(exportConfig.inventory.greenSpaces).includes(true);
        const exportFurnituresInventory = Object.values(exportConfig.inventory.furnitures).includes(true);
        const exportTreesPerformance = Object.values(exportConfig.performance.trees).includes(true);
        const exportGreenSpacesPerformance = Object.values(exportConfig.performance.greenSpaces).includes(true);

        return (
            <>
                <div className='modal-content'>
                    <div className='modal-content-header' style={{ display: 'flex', alignItems: 'right', marginBottom: '5px', justifyContent: 'flex-end' }}>
                        {!isComparison ? <>
                            {(mainFilters || selectedElements?.length > 0 || stationId !== 'none') &&
                                <div style={{ borderRight: 'solid 1px var(--grey-100)', paddingRight: '6px', marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                    {mainFilters &&
                                        <FontAwesomeIcon
                                            icon={faFilterList} title={i18n.t("Outil filtres")}
                                            style={{ borderRadius: '5px', padding: '6px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                        />}
                                    {selectedElements?.length > 0 &&
                                        <FontAwesomeIcon
                                            icon={faLasso} title={i18n.t("Sélection d'éléments")}
                                            style={{ borderRadius: '5px', padding: '6px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                        />}
                                    {stationId !== 'none' &&
                                        <FontAwesomeIcon
                                            icon={faHexagon} title={i18n.t("Station")}
                                            style={{ borderRadius: '5px', padding: '6px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                        />}
                                </div>}
                            <Select
                                style={{ marginRight: '5px' }}
                                options={stationOptions.map(({ text, value }) => ({ text, value }))} value={stationId}
                                onChange={(_, { value }) => this.setState(prevState => ({ stationId: value, search: UrlsUtil.adjustSearch(prevState.search, { stationId: value !== 0 ? value : null }) }), () => this.loadData())}
                            />
                            <Dropdown floating button icon='tv' className='icon button--secondary' title={i18n.t("Affichage")} style={{ display: 'block' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        text={this.renderDropdownText(i18n.t("Global"), display, 'global', checkIcon, null)}
                                        onClick={() => this.setState(prevState => ({ display: 'global', search: UrlsUtil.adjustSearch(prevState.search, { display: 'global' }) }))}
                                    />
                                    <Dropdown.Item
                                        text={this.renderDropdownText(i18n.t("Détail"), display, 'detail', checkIcon, null)}
                                        onClick={() => this.setState(prevState => ({ display: 'detail', search: UrlsUtil.adjustSearch(prevState.search, { display: null }) }))}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button className='button--secondary' onClick={() => this.setState({ showComparisonForm: true })}>
                                <FontAwesomeIcon icon={faLineColumns} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Comparer avec")}
                            </Button>
                            {isExportable &&
                                <Button
                                    content={!isMobileOnly && i18n.t("Exporter")} className='button--secondary' icon='download' style={{ display: 'block', marginRight: !isMobileOnly && '10px' }}
                                    disabled={isExporting || !isExportReady || !isOnline} onClick={() => this.setState({ showExportForm: true })}
                                />}
                        </>
                            : isComparison &&
                            <Header as='h1' style={{ width: '100%', textAlign: 'center' }}>
                                <FontAwesomeIcon icon={faFileAlt} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{this.props.projectToCompareWith?.label || this.props.project.label}
                            </Header>}
                    </div>
                    <ScrollSyncPane>
                        <div className='modal-content-body' style={{ overflowY: isExporting ? 'hidden' : 'auto' }}>
                            <Grid id='project-details'>
                                {((display === 'detail' && (exportTreesInventory || exportGreenSpacesInventory || exportFurnituresInventory))
                                    || (display === 'global' && Object.values(exportConfig.inventory.global).includes(true)) || !isExporting) &&
                                    <>
                                        <Grid.Row style={{ paddingBottom: 0 }}>
                                            <Grid.Column width={14}>
                                                <Header as='h1'><FontAwesomeIcon icon={faBoxOpenFull} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Inventaire")}</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Divider style={{ margin: 0 }} />
                                        <Grid.Row>
                                            {display === 'detail' &&
                                                <>
                                                    {(exportTreesInventory || !isExporting) &&
                                                        <Grid.Column computer={!isComparison ? 8 : 16} tablet={16} mobile={16} stretched>
                                                            <Grid.Row style={{ padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                                <Grid.Column>
                                                                    <Header as='h3'>{i18n.t("Arbres")}</Header>
                                                                    <Card.Group itemsPerRow={isExporting ? 2 : isMobileOnly ? 1 : isMobile ? 2 : !isComparison ? 3 : 2} style={{ width: 'calc(100% + 5em)' }}>
                                                                        {this.renderInventoryCard(faTree, i18n.t("Enregistrés"), trees.count || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.trees.count, extraValue: woodedMassifs.nbTrees })}
                                                                        {requiredFields.trees.trunks && publicFields.trees.trunks &&
                                                                            <>
                                                                                {this.renderInventoryCard(
                                                                                    faTreeCity, i18n.t("Plus grand"), trees.tallest ? FormattersUtil.formatMeter(trees.tallest.value) : i18n.t("Aucun"),
                                                                                    { id: 'Tnxky8bs', onClick: trees.tallest ? () => this.props.showElement('Arbres', trees.tallest.tree, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.trees.tallest },
                                                                                )}
                                                                                {this.renderInventoryCard(
                                                                                    faBenchTree, i18n.t("Plus petit"), trees.smallest ? FormattersUtil.formatMeter(trees.smallest.value) : i18n.t("Aucun"),
                                                                                    { id: 'n1ShmKJj', onClick: trees.smallest ? () => this.props.showElement('Arbres', trees.smallest.tree, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.trees.smallest }
                                                                                )}
                                                                            </>}
                                                                        {requiredFields.trees.trunks && publicFields.trees.trunks &&
                                                                            <>
                                                                                {this.renderInventoryCard(
                                                                                    faCircleDot, i18n.t("Plus gros"), trees.biggest ? FormattersUtil.formatMeter(FormattersUtil.getTrunkCircumference(Math.max(...trees.biggest.properties.trunks.map(t => t.circumference)), project.trunkCircumferenceUnit)) : i18n.t("Aucun"),
                                                                                    { id: 'hsDM2U3O', onClick: trees.biggest ? () => this.props.showElement('Arbres', trees.biggest, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.trees.biggest }
                                                                                )}
                                                                            </>}
                                                                        {requiredFields.trees.age && publicFields.trees.age &&
                                                                            <>
                                                                                {this.renderInventoryCard(
                                                                                    faTreeDeciduous, i18n.t("Plus vieux"), trees.oldest ? trees.oldest.properties.age + ` (${i18n.t("Ans").toLowerCase()})` : i18n.t("Aucun"),
                                                                                    { id: '8evPTMSZ', onClick: trees.oldest ? () => this.props.showElement('Arbres', trees.oldest, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.trees.oldest }
                                                                                )}
                                                                                {this.renderInventoryCard(
                                                                                    faSeedling, i18n.t("Plus jeune"), trees.youngest ? trees.youngest.properties.age + ` (${i18n.t("Ans").toLowerCase()})` : i18n.t("Aucun"),
                                                                                    { id: 'SqO7W5Re', onClick: trees.youngest ? () => this.props.showElement('Arbres', trees.youngest, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.trees.youngest }
                                                                                )}
                                                                            </>}
                                                                        {publicFields.trees.toCutDown && <>
                                                                            {this.renderInventoryCard(faAxe, i18n.t("À abattre"), trees.toCutDown || i18n.t("Aucun"), { id: 'gK5UIx34', shouldExport: exportConfig.inventory.trees.toCutDown })}
                                                                        </>}
                                                                        {publicFields.trees.toCutDown && <>
                                                                            {this.renderInventoryCard(faAxeLight, trees.cutDown > 1 ? i18n.t("Abattus") : i18n.t("Abattu"), trees.cutDown || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.trees.cutDown })}
                                                                        </>}
                                                                        {publicFields.trees.isFruit && <>
                                                                            {this.renderInventoryCard(faAppleAlt, i18n.t("Arbres fruitiers"), trees.fruitTrees !== 0 ? trees.fruitTrees : i18n.t("Aucun"), { id: 'y8SSuHEH', shouldExport: exportConfig.inventory.trees.fruitTrees })}
                                                                        </>}
                                                                    </Card.Group>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            {furnitures.count > 0 && (exportFurnituresInventory || !isExporting) &&
                                                                <Grid.Row style={{ padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                                    <Grid.Column>
                                                                        <Header as='h3'>{i18n.t("Mobilier urbain")}</Header>
                                                                        <Card.Group itemsPerRow={isExporting ? 2 : isMobileOnly ? 1 : isMobile ? 2 : !isComparison ? 3 : 2} style={{ width: 'calc(100% + 5em)' }} >
                                                                            {this.renderInventoryCard(faTablePicnic, i18n.t("Enregistrés"), furnitures.count || i18n.t("Aucun"),
                                                                                { shouldExport: exportConfig.inventory.furnitures.count })}
                                                                            {requiredFields.furnitures.type && publicFields.furnitures.type &&
                                                                                this.renderInventoryCard(StylesUtil.getFurnitureIcon(furnitureTypes, furnitures.predominantType?.id), i18n.t("Type prédominant"), furnitures.predominantType?.label || i18n.t("Aucun"),
                                                                                    { shouldExport: exportConfig.inventory.furnitures.predominantType })}
                                                                        </Card.Group>
                                                                    </Grid.Column>
                                                                </Grid.Row>}
                                                        </Grid.Column>}
                                                    <Grid.Column computer={!isComparison ? 8 : 16} tablet={16} mobile={16} stretched>
                                                        {greenSpaces.count > 0 && (exportGreenSpacesInventory || !isExporting) &&
                                                            <Grid.Row style={{ padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                                <Grid.Column>
                                                                    <Header as='h3'>{i18n.t("Espaces verts")}</Header>
                                                                    <Card.Group itemsPerRow={isExporting ? 2 : isMobileOnly ? 1 : isMobile ? 2 : !isComparison ? 3 : 2} style={{ width: 'calc(100% + 5em)' }}>
                                                                        {this.renderInventoryCard(faFlowerTulip, i18n.t("Enregistrés"), greenSpaces.count || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.greenSpaces.count })}
                                                                        {requiredFields.greenSpaces.surface && publicFields.greenSpaces.surface &&
                                                                            <>
                                                                                {this.renderInventoryCard(
                                                                                    faCircle, i18n.t("Plus grand"), greenSpaces.tallest ? FormattersUtil.formatSquareMeter(greenSpaces.tallest.properties.surface) : i18n.t("Aucun"),
                                                                                    { id: 'moFxyTWA', onClick: greenSpaces.tallest ? () => this.props.showElement('Espaces verts', greenSpaces.tallest, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.greenSpaces.tallest }
                                                                                )}
                                                                                {this.renderInventoryCard(
                                                                                    faCircleSmall, i18n.t("Plus petit"), greenSpaces.smallest ? FormattersUtil.formatSquareMeter(greenSpaces.smallest.properties.surface) : i18n.t("Aucun"),
                                                                                    { id: 'Jpu52Nfw', onClick: greenSpaces.smallest ? () => this.props.showElement('Espaces verts', greenSpaces.smallest, 'ProjectDetail') : null, shouldExport: exportConfig.inventory.greenSpaces.smallest }
                                                                                )}
                                                                                {this.renderInventoryCard(faSquare, i18n.t("Pelouses"), FormattersUtil.formatSquareMeter(greenSpaces.lawnsSurface), { shouldExport: exportConfig.inventory.greenSpaces.lawnsSurface })}
                                                                                {this.renderInventoryCard(faSickle, i18n.t("Prés de fauche"), FormattersUtil.formatSquareMeter(greenSpaces.hayfieldSurface), { shouldExport: exportConfig.inventory.greenSpaces.hayfieldSurface })}
                                                                                {this.renderInventoryCard(faCloud, i18n.t("Massifs arbustifs"), FormattersUtil.formatSquareMeter(greenSpaces.shrubMassSurface), { shouldExport: exportConfig.inventory.greenSpaces.shrubMassSurface })}
                                                                                {this.renderInventoryCard(faTrees, i18n.t("Massifs arborés"), FormattersUtil.formatSquareMeter(greenSpaces.woodedMassifSurface), { shouldExport: exportConfig.inventory.greenSpaces.woodedMassifSurface })}
                                                                                {this.renderInventoryCard(faWater, i18n.t("Zones humides"), FormattersUtil.formatSquareMeter(greenSpaces.wetAreaSurface), { shouldExport: exportConfig.inventory.greenSpaces.wetAreaSurface })}
                                                                                {this.renderInventoryCard(faWaveSquare, i18n.t("Longueur de haies"), FormattersUtil.formatSquareMeter(greenSpaces.totalHedgedLength), { shouldExport: exportConfig.inventory.greenSpaces.totalHedgedLength })}
                                                                                {this.renderInventoryCard(faHexagon, i18n.t("Surface totale"), FormattersUtil.formatSquareMeter(greenSpaces.surface), { shouldExport: exportConfig.inventory.greenSpaces.surface })}
                                                                            </>}
                                                                    </Card.Group>
                                                                </Grid.Column>
                                                            </Grid.Row>}
                                                    </Grid.Column>
                                                </>}
                                            {display === 'global' &&
                                                <Grid.Column stretched>
                                                    <div style={{ width: '100%', padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                        <Card.Group itemsPerRow={isExporting ? 5 : isMobileOnly ? 1 : isMobile ? 2 : 5}>
                                                            {this.renderInventoryCard(faTree, i18n.t("Arbres"), trees.count || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.global.nbTrees })}
                                                            {this.renderInventoryCard(faFlowerTulip, i18n.t("Espaces verts"), greenSpaces.count || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.global.nbGreenSpaces })}
                                                            {this.renderInventoryCard(faTablePicnic, i18n.t("Mobiliers"), furnitures.count || i18n.t("Aucun"), { shouldExport: exportConfig.inventory.global.nbFurnitures })}
                                                        </Card.Group>
                                                    </div>
                                                </Grid.Column>}
                                        </Grid.Row>
                                    </>}
                                {((display === 'detail' && (exportTreesPerformance || exportGreenSpacesPerformance))
                                    || (display === 'global' && Object.values(exportConfig.performance.global).includes(true)) || !isExporting) &&
                                    <>
                                        <Grid.Row style={{ paddingBottom: 0 }}>
                                            <Grid.Column>
                                                <Header as='h1'><FontAwesomeIcon icon={faTachometerAlt} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Performances")}</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Divider style={{ margin: 0 }} />
                                        <Grid.Row>
                                            {display === 'detail' && (exportTreesPerformance || exportGreenSpacesPerformance || !isExporting) &&
                                                <>
                                                    {(exportTreesPerformance || !isExporting) &&
                                                        <Grid.Column computer={!isComparison ? 8 : 16} tablet={16} mobile={16} stretched>
                                                            <div style={{ width: '100%', padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                                <div style={{ width: '100%', display: 'flex' }}>
                                                                    <Header as='h3'>{greenSpaces.count > 0 ? i18n.t("Arbres et massifs arborés") : i18n.t("Arbres")}</Header>
                                                                    {greenSpaces.count > 0 && <DropDownWithCheckboxes
                                                                        icon={faGear} options={[
                                                                            { label: i18n.t("Arbres"), isChecked: treesPerformanceOptions.showTrees, onClick: () => this.toggleTreesPerformanceElements('trees') },
                                                                            { label: i18n.t("Massifs arborés"), isChecked: treesPerformanceOptions.showWoodedMassifs, onClick: () => this.toggleTreesPerformanceElements('woodedMassifs') },
                                                                        ]} style={{ position: 'absolute', right: '25px', top: '14px' }} buttonStyle={{ padding: '7px 5px' }} menuStyle={{ left: '-125px' }}
                                                                    />}
                                                                </div>
                                                                <Card.Group itemsPerRow={isExporting ? 2 : isMobileOnly ? 1 : isMobile ? 2 : !isComparison ? 3 : 2}>
                                                                    {requiredFields.trees.canopy && requiredFields.trees.trunks && publicFields.trees.canopy && <>
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Canopée"), treesPerformance[`canopyIndicator${!withOverlap ? 'WithoutOverlap' : ''}`], 'm²', FormattersUtil.formatSquareMeter(treesPerformance[`canopyIndicator${!withOverlap ? 'WithoutOverlap' : ''}`]), {
                                                                            id: 'hWrZtT71', maxValue: treesPerformance[`canopyIndicator${!withOverlap ? 'WithoutOverlap' : ''}`] * 2.7, tooltip: i18n.t("Surface totale de la canopée, projection sur le sol"),
                                                                            disabled: !projectSubscription.canopyFormula || !activeOrganization.subscription.canopyFormula, shouldExport: exportConfig.performance.trees.canopy,
                                                                            toggle: { name: 'withOverlap', label: i18n.t("Overlap") }
                                                                        })}
                                                                    </>}
                                                                    {requiredFields.trees.coolingIndicator && publicFields.trees.coolingIndicator && <>
                                                                        {this.renderPerformanceRadialGauge(`${i18n.t("Rafraîchissement")} (${i18n.t("énergie")})`, treesPerformance.annualCoolingIndicator, 'kWh/an', FormattersUtil.formatKilowattHour(treesPerformance.annualCoolingIndicator) + '/an', {
                                                                            id: '7vi83Suo', maxValue: treesPerformance.annualCoolingIndicator * (4 / 3), innerColor: '#2185d0', tooltip: i18n.t("Quantité de chaleur absorbée par le projet par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards"),
                                                                            disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.trees.annualCoolingIndicator
                                                                        })}
                                                                        {this.renderPerformanceRadialGauge(`${i18n.t("Rafraîchissement")} (${i18n.t("valeur").toLowerCase()})`, treesPerformance.annualCoolingEconomicValue, '€/an', FormattersUtil.formatEuro(treesPerformance.annualCoolingEconomicValue) + '/an', {
                                                                            maxValue: treesPerformance.annualCoolingEconomicValue * (4 / 3), innerColor: '#daa817', tooltip: i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)"),
                                                                            disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.trees.annualCoolingEconomicValue
                                                                        })}
                                                                    </>}
                                                                    {requiredFields.trees.carbonStock && publicFields.trees.carbonStock && <>
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Stockage annuel CO2"), treesPerformance.carbonStock, 'kg/an', `${FormattersUtil.formatKilogram(treesPerformance.carbonStock, { defaultUnit: 'kg' })}/an`, {
                                                                            id: 'L4XPuITc', innerColor: '#7250bc', tooltip: i18n.t("Quantité de CO2 actuellement stockée dans votre projet"),
                                                                            disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.trees.carbonStock
                                                                        })}
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Stockage total CO2"), treesPerformance.totalCarbonStock, 'kg', `${FormattersUtil.formatKilogram(treesPerformance.totalCarbonStock, { defaultUnit: 'kg' })}`, {
                                                                            innerColor: '#7250bc', disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.trees.totalCarbonStock
                                                                        })}
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Production d'oxygène"), treesPerformance.oxygenProduction, 'kg/an', `${FormattersUtil.formatKilogram(treesPerformance.oxygenProduction, { defaultUnit: 'kg' })}/an`, {
                                                                            maxValue: treesPerformance.carbonStock, innerColor: '#c83030', tooltip: i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par votre projet"),
                                                                            disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.trees.oxygenProduction
                                                                        })}
                                                                    </>}
                                                                    {requiredFields.trees.biodiversityIndex && publicFields.trees.biodiversityIndex &&
                                                                        this.renderPerformanceRadialGauge(i18n.t("Potentiel de biodiversité"), treesPerformance.biodiversityIndex, '%', `${treesPerformance.biodiversityIndex}%`, {
                                                                            maxValue: 100, tooltip: i18n.t("Potentiel de valeur estimé pour la biodiversité de l'arbre, résultat adapté de publications scientifiques"),
                                                                            shouldExport: exportConfig.performance.trees.biodiversityIndex
                                                                        })}
                                                                    {(exportConfig.performance.trees.fruitProduction || exportConfig.performance.trees.amenityValue || !isExporting) &&
                                                                        <Card style={{ boxShadow: 'none', width: !isMobile && !isExporting && '100%' }}>
                                                                            <Card.Content style={{ display: 'flex', flexDirection: (isMobile || isExporting) && 'column', padding: 0 }}>
                                                                                {(exportConfig.performance.trees.fruitProduction || !isExporting) &&
                                                                                    <div id='NFLDoORP' className='card--tainted' style={{ flex: 1, position: 'relative', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', aspectRatio: isMobile || isExporting ? '16 / 4' : '16 / 6', overflow: 'hidden', marginBottom: (isMobile || isExporting) && '5px', marginRight: !isMobile && !isExporting && '1em' }}>
                                                                                        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 1 }}>
                                                                                            <h1 title={`${treesPerformance.fruitProduction}kg/an`} style={{ margin: 0 }}>
                                                                                                {FormattersUtil.formatKilogram(treesPerformance.fruitProduction, { defaultUnit: 'kg' }) + `/${i18n.t("an")}`}
                                                                                            </h1>
                                                                                            <h3 style={{ margin: 0 }}>{i18n.t("Production de fruits")}</h3>
                                                                                        </div>
                                                                                        <img style={{ width: '100%' }} src={fruitsPNG} alt='fruits' />
                                                                                    </div>}
                                                                                {requiredFields.trees.amenityValue && publicFields.trees.amenityValue && (exportConfig.performance.trees.amenityValue || !isExporting) &&
                                                                                    <div
                                                                                        id='mqE5I4jg' className='card--tainted' title={!projectSubscription.amenityFormula ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité") : ''}
                                                                                        style={{ flex: 1, position: 'relative', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', aspectRatio: (isMobile || isExporting) ? '16 / 4' : '16 / 6', overflow: 'hidden', marginTop: (isMobile || isExporting) && '5px', marginLeft: !isMobile && !isExporting && '1em' }}
                                                                                    >
                                                                                        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 1 }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                                                {!projectSubscription.amenityFormula
                                                                                                    ? <FontAwesomeIcon icon={faLock} size='3x' style={{ marginTop: '5px' }} />
                                                                                                    : <h1 style={{ margin: 0 }}>{FormattersUtil.formatEuro(treesPerformance.amenityValue)}</h1>}
                                                                                            </div>
                                                                                            <h3 style={{ margin: 0 }}>{i18n.t("Valeur d'agrément totale")}</h3>
                                                                                            {treesPerformanceOptions.showWoodedMassifs && !isMobileOnly &&
                                                                                                <small style={{ position: 'absolute', bottom: '5px', color: 'lightgrey', fontStyle: 'italic' }}>
                                                                                                    {i18n.t("Ne prend pas en compte les massifs arborés")}
                                                                                                </small>}
                                                                                        </div>
                                                                                        <img style={{ width: '100%', position: 'relative', bottom: '25%' }} src={treeMoneyPNG} alt='money' />
                                                                                    </div>}
                                                                            </Card.Content>
                                                                        </Card>}
                                                                </Card.Group>
                                                            </div>
                                                        </Grid.Column>}
                                                    {greenSpaces.count > 0 && (exportGreenSpacesPerformance || !isExporting) &&
                                                        <Grid.Column computer={!isComparison ? 8 : 16} tablet={16} mobile={16} stretched>
                                                            <div style={{ width: '100%', padding: '15px', borderRadius: '10px', backgroundColor, height: '100%', marginBottom: '10px' }}>
                                                                <Header as='h3'>{i18n.t("Espaces verts")} ({i18n.t("hors massifs arborés")})</Header>
                                                                <Card.Group itemsPerRow={isExporting ? 2 : isMobileOnly ? 1 : isMobile ? 2 : !isComparison ? 3 : 2}>
                                                                    {requiredFields.greenSpaces.coolingIndicator && publicFields.greenSpaces.coolingIndicator && <>
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Rafraîchissement") + ` (${i18n.t("énergie")})`, greenSpaces.annualCoolingIndicator, 'kWh/an', FormattersUtil.formatKilowattHour(greenSpaces.annualCoolingIndicator) + '/an', {
                                                                            id: '7vi83Suo', maxValue: greenSpaces.annualCoolingIndicator * (4 / 3), innerColor: '#2185d0', tooltip: i18n.t("Quantité de chaleur absorbée par le projet par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards"),
                                                                            disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.greenSpaces.annualCoolingIndicator
                                                                        })}
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Rafraîchissement") + ` (${i18n.t("Valeur").toLowerCase()})`, greenSpaces.annualCoolingEconomicValue, '€/an', FormattersUtil.formatEuro(greenSpaces.annualCoolingEconomicValue) + '/an', {
                                                                            maxValue: greenSpaces.annualCoolingEconomicValue * (4 / 3), innerColor: '#daa817', tooltip: i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)"),
                                                                            disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.greenSpaces.annualCoolingEconomicValue
                                                                        })}
                                                                    </>}
                                                                    {requiredFields.greenSpaces.carbonStock && publicFields.greenSpaces.carbonStock && <>
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Stockage annuel CO2"), greenSpaces.carbonStock, 'kg/an', `${FormattersUtil.formatKilogram(greenSpaces.carbonStock, { defaultUnit: 'kg' })}/an`, {
                                                                            id: 'oyOU7vW7', innerColor: '#7250bc', tooltip: i18n.t("Quantité de CO2 actuellement stockée dans votre projet"),
                                                                            disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.greenSpaces.carbonStock
                                                                        })}
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Production d'oxygène"), greenSpaces.oxygenProduction, 'kg/an', `${FormattersUtil.formatKilogram((greenSpaces.oxygenProduction), { defaultUnit: 'kg' })}/an`, {
                                                                            maxValue: greenSpaces.carbonStock, innerColor: '#c83030', tooltip: i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par votre projet"),
                                                                            disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.greenSpaces.oxygenProduction
                                                                        })}
                                                                    </>}
                                                                    {requiredFields.greenSpaces.spaceType && publicFields.greenSpaces.spaceType && <>
                                                                        {this.renderPerformanceRadialGauge(i18n.t("Potentiel de biodiversité"), greenSpaces.ecopotentialIndex, '%', `${greenSpaces.ecopotentialIndex}%`, {
                                                                            maxValue: 100, tooltip: i18n.t("Potentiel de développement/d'accueil de la biodiversité, ou intérêt écologique du site pour la biodiversité, selon le coefficient développé par Bruxelles Environnement"),
                                                                            shouldExport: exportConfig.performance.greenSpaces.ecopotentialIndex
                                                                        })}
                                                                    </>}
                                                                </Card.Group>
                                                            </div>
                                                        </Grid.Column>}
                                                </>}
                                            {display === 'global' &&
                                                <Grid.Column stretched>
                                                    <div style={{ width: '100%', padding: '15px', borderRadius: '10px', backgroundColor, marginBottom: '10px' }}>
                                                        <Card.Group itemsPerRow={isExporting ? 4 : isMobileOnly ? 1 : isMobile ? 2 : 4} style={{ justifyContent: 'center' }}>
                                                            {requiredFields.trees.canopy && requiredFields.trees.trunks && publicFields.trees.canopy && <>
                                                                {this.renderPerformanceRadialGauge(i18n.t("Canopée"), treesPerformance.canopyIndicator, 'm²', FormattersUtil.formatSquareMeter(treesPerformance.canopyIndicator), {
                                                                    id: 'hWrZtT71', maxValue: treesPerformance.canopyIndicator * 2.7, tooltip: i18n.t("Surface totale de la canopée, projection sur le sol"),
                                                                    disabled: !projectSubscription.canopyFormula || !activeOrganization.subscription.canopyFormula, shouldExport: exportConfig.performance.global.canopy
                                                                })}
                                                            </>}
                                                            {((requiredFields.trees.coolingIndicator && publicFields.trees.coolingIndicator)
                                                                || (requiredFields.greenSpaces.coolingIndicator && publicFields.greenSpaces.coolingIndicator)) && <>
                                                                    {this.renderPerformanceRadialGauge(i18n.t("Rafraîchissement") + ` (${i18n.t("énergie")})`, globalPerformance.annualCoolingIndicator, 'kWh/an', FormattersUtil.formatKilowattHour(globalPerformance.annualCoolingIndicator) + '/an', {
                                                                        id: '7vi83Suo', maxValue: globalPerformance.annualCoolingIndicator * (4 / 3), innerColor: '#2185d0', tooltip: i18n.t("Quantité de chaleur absorbée par le projet par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards"),
                                                                        disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.global.annualCoolingIndicator
                                                                    })}
                                                                    {this.renderPerformanceRadialGauge(i18n.t("Rafraîchissement") + ` (${i18n.t("Valeur").toLowerCase()})`, globalPerformance.annualCoolingEconomicValue, '€/an', FormattersUtil.formatEuro(globalPerformance.annualCoolingEconomicValue) + '/an', {
                                                                        maxValue: globalPerformance.annualCoolingEconomicValue * (4 / 3), innerColor: '#daa817', tooltip: i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)"),
                                                                        disabled: !projectSubscription.coolingFormula || !activeOrganization.subscription.coolingFormula, shouldExport: exportConfig.performance.global.annualCoolingEconomicValue
                                                                    })}
                                                                </>}
                                                            {((requiredFields.trees.carbonStock && publicFields.trees.carbonStock)
                                                                || (requiredFields.greenSpaces.carbonStock && publicFields.greenSpaces.carbonStock)) && <>
                                                                    {this.renderPerformanceRadialGauge(i18n.t("Stockage annuel CO2"), globalPerformance.carbonStock, 'kg/an', `${FormattersUtil.formatKilogram(globalPerformance.carbonStock, { defaultUnit: 'kg' })}/an`, {
                                                                        id: 'L4XPuITc', innerColor: '#7250bc', tooltip: i18n.t("Quantité de CO2 actuellement stockée dans votre projet"),
                                                                        disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.global.carbonStock
                                                                    })}
                                                                    {this.renderPerformanceRadialGauge(i18n.t("Stockage total CO2"), globalPerformance.totalCarbonStock, 'kg', `${FormattersUtil.formatKilogram(globalPerformance.totalCarbonStock, { defaultUnit: 'kg' })}`, {
                                                                        innerColor: '#7250bc', disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.global.totalCarbonStock
                                                                    })}
                                                                    {this.renderPerformanceRadialGauge(i18n.t("Production d'oxygène"), globalPerformance.oxygenProduction, 'kg/an', `${FormattersUtil.formatKilogram(globalPerformance.oxygenProduction, { defaultUnit: 'kg' })}/an`, {
                                                                        maxValue: globalPerformance.carbonStock, innerColor: '#c83030', tooltip: i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par votre projet"),
                                                                        disabled: !projectSubscription.carbonStockFormula || !activeOrganization.subscription.carbonStockFormula, shouldExport: exportConfig.performance.global.oxygenProduction
                                                                    })}
                                                                </>}
                                                            {exportConfig.performance.global.fruitProduction &&
                                                                <Card style={{ boxShadow: 'none' }}>
                                                                    <Card.Content style={{ display: 'flex', flexDirection: (isMobile || isExporting) && 'column', padding: 0 }}>
                                                                        <div id='N6LDoODP' className='card--tainted' style={{ flex: 1, position: 'relative', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', aspectRatio: isMobile || isExporting ? '16 / 4' : '16 / 6', overflow: 'hidden' }}>
                                                                            <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 1 }}>
                                                                                <h1 style={{ margin: 0 }}>{FormattersUtil.formatKilogram(globalPerformance.fruitProduction) + `kg/${i18n.t("an")}`}</h1>
                                                                                <h3 style={{ margin: 0 }}>{i18n.t("Production de fruits")}</h3>
                                                                            </div>
                                                                            <img style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={fruitsPNG} alt='fruits' />
                                                                        </div>
                                                                    </Card.Content>
                                                                </Card>}
                                                            {requiredFields.trees.amenityValue && publicFields.trees.amenityValue && exportConfig.performance.global.amenityValue &&
                                                                <Card style={{ boxShadow: 'none' }}>
                                                                    <Card.Content style={{ display: 'flex', flexDirection: (isMobile || isExporting) && 'column', padding: 0 }}>

                                                                        <div
                                                                            id='msE5Iejc' className='card--tainted' title={!projectSubscription.amenityFormula ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité") : ''}
                                                                            style={{ flex: 1, position: 'relative', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', aspectRatio: (isMobile || isExporting) ? '16 / 4' : '16 / 6', overflow: 'hidden' }}
                                                                        >
                                                                            <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 1 }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                                    {!projectSubscription.amenityFormula
                                                                                        ? <FontAwesomeIcon icon={faLock} size='3x' style={{ marginTop: '5px' }} />
                                                                                        : <h1 style={{ margin: 0 }}>{FormattersUtil.formatEuro(Math.round(globalPerformance.amenityValue)) + '€'}</h1>}
                                                                                </div>
                                                                                <h3 style={{ margin: 0 }}>{i18n.t("Valeur d'agrément totale")}</h3>
                                                                                {!isMobileOnly &&
                                                                                    <small style={{ position: 'absolute', bottom: '5px', color: 'lightgrey', fontStyle: 'italic' }}>
                                                                                        {i18n.t("Ne prend pas en compte les massifs arborés")}
                                                                                    </small>}
                                                                            </div>
                                                                            <img style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={treeMoneyPNG} alt='money' />
                                                                        </div>
                                                                    </Card.Content>
                                                                </Card>}
                                                        </Card.Group>
                                                    </div>
                                                </Grid.Column>}
                                        </Grid.Row>
                                    </>}
                                {(Object.values(exportConfig.punchlines).includes(true) || !isExporting) &&
                                    <>
                                        <Grid.Row style={{ paddingBottom: 0 }}>
                                            <Grid.Column>
                                                <Header as='h1'><FontAwesomeIcon icon={faLightbulbOn} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Impact")}</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Divider style={{ margin: 0 }} />
                                        <Grid.Row style={{ paddingBottom: 0 }}>
                                            <Grid.Column width={16}>
                                                <div style={{ width: '100%', padding: '15px', borderRadius: '10px', backgroundColor }}>
                                                    <Card.Group itemsPerRow={isExporting ? 3 : isMobileOnly ? 1 : isMobile ? 2 : isComparison ? 2 : 3}>
                                                        {this.renderPunchlineCard(
                                                            i18n.t("Votre projet absorbe chaque année __ de CO2, ce qui correspond aux émissions d'une voiture diesel sur __ (soit __ le tour de la Terre)"),
                                                            [
                                                                { content: FormattersUtil.formatKilogram(globalPerformance.carbonStock, { defaultUnit: 'kg' }) },
                                                                { content: FormattersUtil.formatMeter((globalPerformance.carbonStock * 1000) / 97, { defaultUnit: 'km' }) },
                                                                { content: FormattersUtil.formatNumber((numberOfTurns > 1 ? Math.floor(numberOfTurns) : numberOfTurns)) + 'x' }
                                                            ], earthPNG, { shouldExport: exportConfig.punchlines.carbonStock }
                                                        )}
                                                        {this.renderPunchlineCard(
                                                            i18n.t("Votre projet absorbe chaque année __ de chaleur, ce qui équivaut à la consommation annuelle de __"),
                                                            [{ content: FormattersUtil.formatKilowattHour(globalPerformance.annualCoolingIndicator, { targetUnit: 'MWh' }) }, { content: `${FormattersUtil.formatNumber(Math.round(globalPerformance.annualCoolingIndicator / 350))} ${i18n.t("frigos")}` }], desertPNG,
                                                            { shouldExport: exportConfig.punchlines.cooling }
                                                        )}
                                                        {this.renderPunchlineCard(
                                                            i18n.t("Votre projet produit actuellement __ d'oxygène par an ce qui correspond au besoin annuel de __ personnes"),
                                                            [
                                                                { content: FormattersUtil.formatKilogram(globalPerformance.oxygenProduction, { defaultUnit: 'kg' }) },
                                                                { content: FormattersUtil.formatNumber(nbPersons > 1 ? Math.floor(nbPersons) : nbPersons) }
                                                            ], forestPNG, { shouldExport: exportConfig.punchlines.oxygen }
                                                        )}
                                                        {/* {this.renderPunchlineCard(
                                                'Votre projet absorbe __ des précipitations, contribuant ainsi à limiter les inondations, et alimenter les nappes phréatiques',
                                                [{ content: '64%', tooltip: i18n.t("Mesure la proportion des eaux ruisselant (le reste étant absorbé par le sol). Les végétaux, en favorisant l'absorption, réduisent ce coefficient") }], floodJPG
                                            )} */}
                                                    </Card.Group>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>}
                            </Grid>
                        </div>
                    </ScrollSyncPane>
                    {showExportForm && this.renderExportForm()}
                </div>
                {showComparisonForm && <CompareProjectsTree cancel={() => this.setState({ showComparisonForm: false })} compareProjectWith={this.props.compareProjectWith} />}
            </>
        )
    }

    componentDidMount = () => {
        this.stationList = Array.isArray(this.props.stations) ? this.props.stations : this.props.stations.getLayers();
        const stationOptions = !this.props.isComparison
            ? this.stationList.map(layer => {
                const station = layer.feature || layer;
                return station.properties.label?.trim() && ({ text: station.properties.label, value: station.id });
            }).filter(st => st)
            : [];
        stationOptions.sort((a, b) => {
            const textA = a.text.toUpperCase();
            const textB = b.text.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        stationOptions.unshift({ text: i18n.t("Tout le projet"), value: 'none' });

        const { stationId, display, withOverlap, treePerformances } = UrlsUtil.getSearchParams(this.props.location?.search);

        this.setState(prevState => ({
            stationOptions,
            stationId: stationId || prevState.stationId,
            display: display || prevState.display,
            withOverlap: [true, false].includes(withOverlap) ? withOverlap : prevState.withOverlap,
            treesPerformanceOptions: !treePerformances ? prevState.treesPerformanceOptions : {
                showTrees: treePerformances === 'trees',
                showWoodedMassifs: treePerformances === 'woodedMassifs'
            }
        }), () => this.loadData());

        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    componentDidUpdate = (_, prevState) => {
        if (prevState.search?.long !== this.state.search?.long) this.props.history.push({ search: this.state.search?.long })
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    renderInventoryCard = (icon, description, value, { id, extraValue, onClick, shouldExport = true } = {}) => {
        const { isDarkTheme } = this.props;
        const unit = FormattersUtil.getUnit(value);
        if (unit) value = Number(value.replace(unit, ''));
        if (!shouldExport && this.state.isExporting) return;
        if (this.props.isComparison) onClick = null;

        const renderedExtraValue = extraValue > 1000 ? Math.round(extraValue / 100) / 10 : extraValue;
        const extraValueUnit = extraValue > 1000 ? 'K' : '';

        return (
            <Card
                id={id} className='inventory-card' style={{ boxShadow: 'none', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', margin: '6.125px 7px', padding: '7px 14px' }}
                link={onClick ? true : false} onClick={onClick} title={onClick ? i18n.t("Cliquez pour afficher sur la carte") : ''}
            >
                <Card.Content style={{ borderTop: 'none', padding: 0, display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <FontAwesomeIcon icon={icon} size='4x' style={{ maxWidth: '55px', maxHeight: '55px', marginRight: '15px' }} className='inventory-card__icon' />
                        <Statistic size='tiny' style={{ margin: 0, flexGrow: 1 }}>
                            <Statistic.Value>
                                {isNaN(value) ? value : <CountUp end={value} decimals={value % 1 !== 0 ? 2 : 0} decimal='.' duration={2} />}<span style={{ fontSize: '20px' }}>{unit}</span>
                                {extraValue > 0 &&
                                    <Popup
                                        trigger={<span className='extra-value no-themed'>{'(+'}<CountUp end={renderedExtraValue} decimals={renderedExtraValue % 1 !== 0 ? 1 : 0} decimal='.' duration={2} />{extraValueUnit}{')'}</span>}
                                        content={i18n.t("Nombre d'arbres contenus dans un massif arboré") + (renderedExtraValue !== extraValue ? ` (${extraValue})` : '')}
                                    />}
                            </Statistic.Value>
                            <Statistic.Label>{description}</Statistic.Label>
                        </Statistic>
                    </div>
                </Card.Content>
            </Card>
        );
    }

    renderPerformanceRadialGauge = (label, value = 0, unit, formattedValue, {
        id, tooltip, disabled, maxValue, innerColor = 'var(--primary-100)', outerColor, toggle, shouldExport = true
    } = {}) => {
        const { isDarkTheme } = this.props;
        const { isExporting } = this.state;
        const data = [{ key: label, data: value > maxValue ? maxValue : value }];
        const iconStyle = { position: 'absolute', top: '5px', right: '5px' };
        const roundedValue = value % 1 !== 0 ? value.toFixed(2) : value;

        if (!shouldExport && this.state.isExporting) return;
        return (
            <Card
                id={id} className='inventory-card' style={{ boxShadow: 'none', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', height: '224.98px' }}
                title={disabled ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité") : roundedValue + unit}
            >
                <Card.Content style={{ borderTop: 'none' }}>
                    {tooltip && <InfoIcon content={tooltip} iconStyle={iconStyle} />}
                    {toggle && <Checkbox
                        style={{ position: 'absolute', top: '10px', left: '10px' }}
                        toggle label={toggle.label} checked={this.state[toggle.name] || false} onChange={() => this.setState(prevState => ({ [toggle.name]: !prevState[toggle.name], search: UrlsUtil.adjustSearch(prevState.search, { [toggle.name]: prevState[toggle.name] ? null : true }) }))}
                    />}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        {label === i18n.t("Canopée") && this.state.trees.canopyIndicatorWithWoodedMassifs === -1 ?
                            <Dimmer active={true} style={{ ...StylesUtil.getMapStyles().dimmerStyle, borderRadius: '10px' }}>
                                <Loader content={i18n.t("Calcul de la canopée en cours...")} />
                            </Dimmer>
                            :
                            <>
                                {disabled ? <FontAwesomeIcon icon={faLock} size='6x' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                                    :
                                    <RadialGauge
                                        className='reaviz-gauge' data={data} height={200} width={isExporting ? 325 : undefined} style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                        maxValue={maxValue !== undefined ? maxValue || 1 : value || 1} startAngle={-(Math.PI / 5 * 4)} endAngle={Math.PI / 5 * 4}
                                        series={
                                            <StackedRadialGaugeSeries
                                                innerArc={<RadialGaugeArc disabled={true} animated={true} cornerRadius={12.5} />}
                                                outerArc={<RadialGaugeArc disabled={true} animated={true} cornerRadius={12.5} color={outerColor} />}
                                                label={<StackedRadialGaugeValueLabel label={formattedValue} />} colorScheme={innerColor}
                                            />
                                        }
                                    />}
                            </>}
                        <p style={{ padding: 0, margin: 0, marginTop: '-20px' }}>{label}</p>
                    </div>
                </Card.Content>
            </Card>
        );
    }

    renderPunchlineCard = (punchline, values, image, {
        id, disabled, shouldExport = true
    } = {}) => {
        if (!shouldExport && this.state.isExporting) return;
        const { isDarkTheme, isComparison } = this.props;
        values.forEach(value => {
            punchline = punchline.replace('__', `<b${value.tooltip ? ` title="${value.tooltip}"` : ''}>${value.content}</b>`);
        });

        const fontSize = (isComparison && !isMobile) ? '18px' : isComparison ? '14px' : isMobileOnly ? '16px' : isMobile ? '20px' : 'min(1.7vw, 22px)';
        return (
            <Card
                id={id} className='card--tainted' style={{ boxShadow: 'none', border: `solid 1px ${isDarkTheme ? '#FFFFFF22' : '#00000022'}`, borderRadius: '10px', overflow: 'hidden' }}
                title={disabled && i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité")}
            >
                <Card.Content style={{ position: 'relative', aspectRatio: '16 / 9', overflow: 'hidden', padding: 0 }}>
                    <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 1 }}>
                        {disabled
                            ? <FontAwesomeIcon icon={faLock} size='6x' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                            : <p style={{ fontSize, margin: '30px' }} dangerouslySetInnerHTML={{ __html: punchline }}></p>}
                    </div>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={image} alt='fruits' />
                </Card.Content>
            </Card>
        );
    }

    renderExportForm = () => {
        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ showExportForm: false, exportConfig: initialExportConfig }) }}
            >
                <Grid style={{ height: '100%' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                            <Message className='input-popup-form' style={{ maxWidth: !isMobile ? '700px' : '100%', height: '100vh', maxHeight: !isMobile && '90vh' }}>
                                <Message.Header>{i18n.t("Export")}</Message.Header>
                                <Message.Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    {this.renderExportConfig()}
                                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 'auto', marginBottom: '20px', paddingTop: '25px' }}>
                                        <Button color='grey' onClick={() => this.setState({ showExportForm: false, exportConfig: initialExportConfig })}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='blue' onClick={() => this.setState({ showExportForm: false }, this.exportAsPDF)}>
                                            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />
                                            {i18n.t("Exporter")}
                                        </Button>
                                    </div>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    renderExportConfig = () => {
        const { greenSpaces, furnitures } = this.state;

        const isGlobalDisplay = this.state.display === 'global';
        const exportConfig = JSON.parse(JSON.stringify(this.state.exportConfig));
        let areAllSelected = true;
        const checkIfContainsFalse = (category) => {
            if (typeof category === 'object') Object.values(category).forEach((subCategory) => checkIfContainsFalse(subCategory));
            else if ((Array.isArray(category) && category.includes(false)) || !category) {
                areAllSelected = false;
                return;
            }
        }

        const selectAll = (category, state) => {
            if (typeof category === 'object') {
                const values = Object.values(category);
                const subCategories = values.filter(value => typeof value === 'object');
                subCategories.forEach((subCategory) => selectAll(subCategory, state));
                if (subCategories.length < values.length)
                    for (const key in category)
                        if (typeof category[key] !== 'object')
                            category[key] = state;
            }
        }

        checkIfContainsFalse(exportConfig);

        return (
            <>
                <Checkbox label={i18n.t("Tout sélectionner")} checked={areAllSelected} style={{ textAlign: 'left', flex: 'none' }} onChange={() => { selectAll(exportConfig, !areAllSelected); this.setState({ exportConfig }); }} />
                <Grid style={{ textAlign: 'left', marginTop: '15px', overflowY: 'auto' }}>
                    <Grid.Row style={{ paddingTop: 0, paddingBottom: '5px' }}>
                        <Grid.Column width={14}>
                            <Header as='h4'><FontAwesomeIcon icon={faBoxOpenFull} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Inventaire")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={!isMobileOnly ? 3 : 2} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid.Column>
                            <Grid.Row><FontAwesomeIcon icon={faTree} style={{ marginLeft: '3px', marginRight: '13px' }} size='sm' />{i18n.t("Arbres")}</Grid.Row>
                            <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                                <Checkbox name={!isGlobalDisplay ? 'count' : 'nbTrees'} label={i18n.t("Enregistrés")} checked={!isGlobalDisplay ? exportConfig.inventory.trees.count : exportConfig.inventory.global.nbTrees} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                {!isGlobalDisplay &&
                                    <Grid.Row>
                                        <Checkbox name='tallest' label={i18n.t("Plus grand")} checked={exportConfig.inventory.trees.tallest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='smallest' label={i18n.t("Plus petit")} checked={exportConfig.inventory.trees.smallest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='biggest' label={i18n.t("Plus gros")} checked={exportConfig.inventory.trees.biggest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='oldest' label={i18n.t("Plus vieux")} checked={exportConfig.inventory.trees.oldest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='youngest' label={i18n.t("Plus jeune")} checked={exportConfig.inventory.trees.youngest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='toCutDown' label={i18n.t("À abattre")} checked={exportConfig.inventory.trees.toCutDown} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='cutDown' label={i18n.t("Abattus")} checked={exportConfig.inventory.trees.cutDown} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                        <Checkbox name='fruitTrees' label={i18n.t("Arbres fruitiers")} checked={exportConfig.inventory.trees.fruitTrees} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'trees', name, checked)} />
                                    </Grid.Row>}
                            </Grid.Row>
                        </Grid.Column>
                        {greenSpaces.count > 0 &&
                            <Grid.Column>
                                <Grid.Row><FontAwesomeIcon icon={faFlowerTulip} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Espaces verts")}</Grid.Row>
                                <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Checkbox name={!isGlobalDisplay ? 'count' : 'nbGreenSpaces'} label={i18n.t("Enregistrés")} checked={!isGlobalDisplay ? exportConfig.inventory.greenSpaces.count : exportConfig.inventory.global.nbGreenSpaces} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                    {!isGlobalDisplay &&
                                        <Grid.Row>
                                            <Checkbox name='tallest' label={i18n.t("Plus grand")} checked={exportConfig.inventory.greenSpaces.tallest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='smallest' label={i18n.t("Plus petit")} checked={exportConfig.inventory.greenSpaces.smallest} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='lawnsSurface' label={i18n.t("Pelouses")} checked={exportConfig.inventory.greenSpaces.lawnsSurface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='hayfieldSurface' label={i18n.t("Prés de fauche")} checked={exportConfig.inventory.greenSpaces.hayfieldSurface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='shrubMassSurface' label={i18n.t("Massifs arbustifs")} checked={exportConfig.inventory.greenSpaces.shrubMassSurface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='woodedMassifSurface' label={i18n.t("Massifs arborés")} checked={exportConfig.inventory.greenSpaces.woodedMassifSurface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='wetAreaSurface' label={i18n.t("Zones humides")} checked={exportConfig.inventory.greenSpaces.wetAreaSurface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='totalHedgedLength' label={i18n.t("Longueur de haies")} checked={exportConfig.inventory.greenSpaces.totalHedgedLength} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                            <Checkbox name='surface' label={i18n.t("Surface totale")} checked={exportConfig.inventory.greenSpaces.surface} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'greenSpaces', name, checked)} />
                                        </Grid.Row>}
                                </Grid.Row>
                            </Grid.Column>}
                        {furnitures.count > 0 &&
                            <Grid.Column>
                                <Grid.Row><FontAwesomeIcon icon={faTablePicnic} style={{ marginTop: isMobileOnly && '10px', marginLeft: '2px', marginRight: '12px' }} size='sm' />{i18n.t("Mobiliers")}</Grid.Row>
                                <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Checkbox name={!isGlobalDisplay ? 'count' : 'nbFurnitures'} label={i18n.t("Enregistrés")} checked={!isGlobalDisplay ? exportConfig.inventory.furnitures.count : exportConfig.inventory.global.nbFurnitures} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'furnitures', name, checked)} />
                                    {!isGlobalDisplay && <Checkbox name='predominantType' label={i18n.t("Type prédominant")} checked={exportConfig.inventory.furnitures.predominantType} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('inventory', 'furnitures', name, checked)} />}
                                </Grid.Row>
                            </Grid.Column>}
                    </Grid.Row>
                    <Divider />
                    <Grid.Row style={{ paddingTop: 0, paddingBottom: '5px' }}>
                        <Grid.Column width={14}>
                            <Header as='h4'><FontAwesomeIcon icon={faTachometerAlt} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Performances")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={!isMobileOnly ? 3 : 2} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid.Column>
                            {!isGlobalDisplay && <Grid.Row><FontAwesomeIcon icon={faTree} style={{ marginLeft: '3px', marginRight: '13px' }} size='sm' />{i18n.t("Arbres")}</Grid.Row>}
                            <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                                <Checkbox name='canopy' label={i18n.t("Canopée")} checked={!isGlobalDisplay ? exportConfig.performance.trees.canopy : exportConfig.performance.global.canopy} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='annualCoolingIndicator' label={`${i18n.t("Rafraîchissement")} (${i18n.t("énergie")})`} checked={!isGlobalDisplay ? exportConfig.performance.trees.annualCoolingIndicator : exportConfig.performance.global.annualCoolingIndicator} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='annualCoolingEconomicValue' label={`${i18n.t("Rafraîchissement")} (${i18n.t("valeur")})`} checked={!isGlobalDisplay ? exportConfig.performance.trees.annualCoolingEconomicValue : exportConfig.performance.global.annualCoolingEconomicValue} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='carbonStock' label={i18n.t("Stockage annuel CO2")} checked={!isGlobalDisplay ? exportConfig.performance.trees.carbonStock : exportConfig.performance.global.carbonStock} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='totalCarbonStock' label={i18n.t("Stockage total CO2")} checked={!isGlobalDisplay ? exportConfig.performance.trees.totalCarbonStock : exportConfig.performance.global.totalCarbonStock} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='oxygenProduction' label={i18n.t("Production d'oxygène")} checked={!isGlobalDisplay ? exportConfig.performance.trees.oxygenProduction : exportConfig.performance.global.oxygenProduction} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                <Checkbox name='fruitProduction' label={i18n.t("Production de fruits")} checked={!isGlobalDisplay ? exportConfig.performance.trees.fruitProduction : exportConfig.performance.global.fruitProduction} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                                {!isGlobalDisplay && <Checkbox name='biodiversityIndex' label={i18n.t("Potentiel de biodiversité")} checked={exportConfig.performance.trees.biodiversityIndex} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />}
                                <Checkbox name='amenityValue' label={i18n.t("Valeur d'agrément totale")} checked={!isGlobalDisplay ? exportConfig.performance.trees.amenityValue : exportConfig.performance.global.amenityValue} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'trees', name, checked)} />
                            </Grid.Row>
                        </Grid.Column>
                        {greenSpaces.count > 0 && !isGlobalDisplay &&
                            <Grid.Column>
                                <Grid.Row><FontAwesomeIcon icon={faFlowerTulip} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Espaces verts")}</Grid.Row>
                                <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Checkbox name='annualCoolingIndicator' label={`${i18n.t("Rafraîchissement")} (${i18n.t("énergie")})`} checked={exportConfig.performance.greenSpaces.annualCoolingIndicator} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'greenSpaces', name, checked)} />
                                    <Checkbox name='annualCoolingEconomicValue' label={`${i18n.t("Rafraîchissement")} (${i18n.t("valeur")})`} checked={exportConfig.performance.greenSpaces.annualCoolingEconomicValue} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'greenSpaces', name, checked)} />
                                    <Checkbox name='carbonStock' label={i18n.t("Stockage annuel CO2")} checked={exportConfig.performance.greenSpaces.carbonStock} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'greenSpaces', name, checked)} />
                                    <Checkbox name='oxygenProduction' label={i18n.t("Production d'oxygène")} checked={exportConfig.performance.greenSpaces.oxygenProduction} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('performance', 'greenSpaces', name, checked)} />
                                </Grid.Row>
                            </Grid.Column>}
                    </Grid.Row>
                    <Divider />
                    <Grid.Row style={{ paddingTop: 0, paddingBottom: '3px' }}>
                        <Grid.Column width={14}>
                            <Header as='h4'><FontAwesomeIcon icon={faLightbulbOn} style={{ marginLeft: '2px', marginRight: '11px' }} size='sm' />{i18n.t("Impact")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={!isMobileOnly ? 3 : 2} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid.Column>
                            <Checkbox name='carbonStock' label={i18n.t("Stockage CO2")} checked={exportConfig.punchlines.carbonStock} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('punchlines', null, name, checked)} />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox name='cooling' label={i18n.t("Rafraîchissement")} checked={exportConfig.punchlines.cooling} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('punchlines', null, name, checked)} />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox name='oxygen' label={i18n.t("Production d'oxygène")} checked={exportConfig.punchlines.oxygen} style={{ marginTop: '5px' }} onChange={(_, { name, checked }) => this.handleExportConfigChanged('punchlines', null, name, checked)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    loadData = () => {
        const { selectedElements } = this.props;
        const { stationId } = this.state;
        const subscription = this.props.project?.organization.subscription;

        this.setState({ isExportReady: false });
        if (this.exportTimeout) clearTimeout(this.exportTimeout);
        this.exportTimeout = setTimeout(() => { this.setState({ isExportReady: true }); }, 2250); // On attend la fin de l'animation pour activer le bouton 'Exporter'

        // Arbres
        const treeList = (Array.isArray(this.props.trees) ? this.props.trees : this.props.trees.getLayers()).filter(layer => (
            (!selectedElements?.length || selectedElements.find(element => element.feature.id === layer.feature.id))
            && (stationId === 'none' || GeometriesUtil.checkIfIsInsidePolygon(layer.getLatLng(), this.stationList.find(station => station.feature?.id === stationId || station.id === stationId)?.getLatLngs()[0]))
        ));
        const treeLayers = treeList.filter(layer => layer.feature?.properties.toCutDown !== 4);
        const cutDownTreeLayers = treeList.filter(layer => layer.feature?.properties.toCutDown === 4);
        let trees = { ...initialTreesState, count: treeLayers.length, cutDown: cutDownTreeLayers.length };

        for (let tree of treeLayers) {
            if (tree.feature) tree = tree.feature;
            const biggestTrunk = TreesUtil.getBiggestTrunk(tree.properties.trunks);
            const smallestTrunk = TreesUtil.getSmallestTrunk(tree.properties.trunks);


            // Plus grand
            if ((biggestTrunk?.height > trees.tallest?.value) || (!trees.tallest && biggestTrunk?.height))
                trees.tallest = { tree, value: biggestTrunk.height };
            // Plus petit
            if (!tree.properties.isStump && ((smallestTrunk?.height < trees.smallest?.value && smallestTrunk.height > 0)
                || (!trees.smallest && smallestTrunk?.height && smallestTrunk.height > 0)))
                trees.smallest = { tree, value: smallestTrunk.height };
            // Plus gros
            if ((!trees.biggest?.properties?.trunks?.length && tree.properties?.trunks?.length)
                || (Math.max(...(tree?.properties?.trunks || []).map(t => t.circumference)) > Math.max(...(trees.biggest?.properties?.trunks || []).map(t => t.circumference))))
                trees.biggest = tree;
            // Plus vieux
            if ((tree.properties.age > trees.oldest?.properties.age)
                || (!trees.oldest && tree.properties.age))
                trees.oldest = tree;
            // Plus jeune
            if ((tree.properties.age && tree.properties.age < trees.youngest?.properties.age)
                || (!trees.youngest && tree.properties.age))
                trees.youngest = tree;
            // Stock carbone
            if (subscription.carbonStockFormula) {
                trees.carbonStock += tree.properties.carbonStock;
                const trunksWithCircumference = (tree.properties.trunks || []).filter(t => t.circumference !== null);
                const totalCarbonStock = trunksWithCircumference.reduce((accumulator, trunk) => accumulator + FormulasUtil.getTreeTotalCarbonStock(tree.properties.carbonStock, trunk.circumference), 0);
                trees.totalCarbonStock += totalCarbonStock;
            }
            // Potentiel de biodiversité
            if (!tree.properties.isEmpty) trees.biodiversityIndex += (tree.properties.biodiversityIndex * 100) || 0;
            // Valeur d'agrément
            if (subscription.amenityFormula)
                trees.amenityValue += tree.properties.amenityValue;
            // Arbres à abattre
            if ([1, 2, 3].includes(tree.properties.toCutDown)) trees.toCutDown++;
            // Indicateur de canopée
            if (subscription.canopyFormula) {
                const treeCanopy = biggestTrunk ? Math.pow((biggestTrunk.crownDiameter / 100) / 2, 2) * Math.PI : 0;
                trees.canopyIndicator += treeCanopy;
            }
            // Rafraîchissement
            if (subscription.coolingFormula) {
                trees.annualCoolingIndicator += tree.properties.coolingEnergyIndicator;
                trees.annualCoolingEconomicValue += FormulasUtil.getCoolingEconomicValue(tree.properties.coolingEnergyIndicator);
            }
            // Production de fruits
            const essence = this.props.essences.find(x => x.id === tree.properties.essenceId);
            if (essence?.fruitProduction > 0) {
                trees.fruitProduction += essence.fruitProduction;
                trees.fruitTrees++;
            }
        }

        trees.biodiversityIndex /= treeLayers.filter(layer => !(layer.feature || layer).properties.isEmpty && (layer.feature || layer).properties.biodiversityIndex)?.length || 1;
        if (isNaN(trees.biodiversityIndex)) trees.biodiversityIndex = 0;

        // Espaces Verts
        const greenSpaceList = Array.isArray(this.props.greenSpaces) ? this.props.greenSpaces : this.props.greenSpaces.getLayers();
        const greenSpaceLayers = greenSpaceList.filter(layer => (
            (!selectedElements?.length || selectedElements.find(element => element.feature.id === layer.feature.id))
            && (stationId === 'none' || GeometriesUtil.checkIfIsInsidePolygon(layer.getLatLngs(), this.stationList.find(station => station.feature?.id === stationId || station.id === stationId)?.getLatLngs()[0]))
        ));
        let greenSpaces = { ...initialGreenSpacesState, count: greenSpaceLayers.length };
        let woodedMassifs = { ...initialWoodedMassifsState };

        if (greenSpaceLayers.length > 0) {
            for (let greenSpace of greenSpaceLayers) {
                if (greenSpace.feature) greenSpace = greenSpace.feature;
                // Surface totale
                greenSpaces.surface += greenSpace.properties.surface;
                // Plus grand
                if (greenSpace.properties.surface > (greenSpaces.tallest?.properties.surface || 0))
                    greenSpaces.tallest = greenSpace;
                // Plus petit
                if (!greenSpaces.smallest || greenSpace.properties.surface < greenSpaces.smallest.properties.surface)
                    greenSpaces.smallest = greenSpace;
                // Potentiel de biodiversité
                const spaceType = this.props.spaceTypes.find(spaceType => spaceType.id === greenSpace.properties.spaceTypeId);
                greenSpaces.ecopotentialIndex += (spaceType?.ecopotentialIndex * 100 || 0) * greenSpace.properties.surface;

                // Surfaces totales
                switch (greenSpace.properties.dominantCompositionId) {
                    case 1: greenSpaces.lawnsSurface += greenSpace.properties.surface; break;
                    case 2: greenSpaces.hayfieldSurface += greenSpace.properties.surface; break;
                    case 4: greenSpaces.shrubMassSurface += greenSpace.properties.surface; break;
                    case 7: greenSpaces.woodedMassifSurface += greenSpace.properties.surface; break;
                    case 14: greenSpaces.wetAreaSurface += greenSpace.properties.surface; break;
                    default: break;
                }

                // Longueur totale des haies
                greenSpaces.totalHedgedLength = greenSpace.properties.baseLine?.length || 0;

                if (greenSpace.properties.dominantCompositionId !== 7) {
                    if (subscription.carbonStockFormula)
                        greenSpaces.carbonStock += greenSpace.properties.carbonStock;

                    // Rafraîchissement
                    if (subscription.coolingFormula && greenSpace.properties.dominantCompositionId) {
                        greenSpaces.annualCoolingIndicator += greenSpace.properties.coolingEnergyIndicator;
                        greenSpaces.annualCoolingEconomicValue += FormulasUtil.getCoolingEconomicValue(greenSpace.properties.coolingEnergyIndicator);
                    }
                } else { // Massif arboré
                    woodedMassifs.nbTrees += greenSpace.properties.nbTrees;
                    // Stock carbone
                    if (subscription.carbonStockFormula) {
                        woodedMassifs.carbonStock += greenSpace.properties.carbonStock;
                        woodedMassifs.totalCarbonStock += FormulasUtil.getTreeTotalCarbonStock(greenSpace.properties.carbonStock, greenSpace.properties.averageCircumference);
                    }
                    // Indicateur de canopée
                    if (subscription.canopyFormula) {
                        const woodedMassifCanopy = Math.pow((greenSpace.properties.averageCrownDiameter / 100) / 2, 2) * Math.PI;
                        woodedMassifs.canopyIndicator += woodedMassifCanopy * greenSpace.properties.nbTrees;
                    }
                    // Rafraîchissement
                    if (subscription.coolingFormula && greenSpace.properties.dominantCompositionId) {
                        woodedMassifs.annualCoolingIndicator += greenSpace.properties.coolingEnergyIndicator;
                        woodedMassifs.annualCoolingEconomicValue += FormulasUtil.getCoolingEconomicValue(greenSpace.properties.coolingEnergyIndicator);
                    }
                    // Production de fruits
                    const essence = this.props.essences.find(x => x.id === greenSpace.properties.dominantEssenceId);
                    if (essence?.fruitProduction > 0) {
                        woodedMassifs.fruitProduction += essence.fruitProduction * greenSpace.properties.nbTrees;
                        woodedMassifs.fruitTrees += greenSpace.properties.nbTrees;
                    }
                }
            }

            greenSpaces.ecopotentialIndex /= greenSpaceLayers
                .filter(layer => (layer.feature || layer).properties.spaceTypeId)
                .reduce((sum, layer) => sum + (layer.feature || layer).properties.surface, 0);
            if (isNaN(greenSpaces.ecopotentialIndex)) greenSpaces.ecopotentialIndex = 0;
        }

        // Mobilier urbain
        const furnitureList = Array.isArray(this.props.furnitures) ? this.props.furnitures : this.props.furnitures.getLayers();
        const furnitureLayers = furnitureList.filter(layer => (
            (!selectedElements?.length || selectedElements.find(element => element.feature.id === layer.feature.id))
            && (stationId === 'none' || GeometriesUtil.checkIfIsInsidePolygon(layer.getLatLng(), this.stationList.find(station => station.feature?.id === stationId || station.id === stationId)?.getLatLngs()[0]))
        ));
        let furnitures = { ...initialFurnitureState, count: furnitureLayers.length };
        if (furnitureLayers.length > 0) {
            const furnitureTypes = this.props.furnitureTypes.map(ft => { return { id: ft.id, label: ft.label, count: 0 } });

            for (let furniture of furnitureLayers) {
                if (furniture.feature) furniture = furniture.feature;
                // Type prédominant
                if (furniture.properties.typeId) {
                    let furnitureType = furnitureTypes.find(x => x.id === furniture.properties.typeId);
                    if (furnitureType) furnitureType.count++;
                }
            }

            const predominantType = furnitureTypes.sort((a, b) => b.count - a.count)[0];
            furnitures.predominantType = predominantType.count ? predominantType : null;
        }

        if (subscription.canopyFormula) {
            trees.canopyIndicator = Math.round(trees.canopyIndicator * 100) / 100;
            woodedMassifs.canopyIndicator = Math.round(woodedMassifs.canopyIndicator * 100) / 100;

            // Calcul en back-end
            StatisticsService.getCanopiesWithoutOverlap(this.props.project.id, [...treeLayers.map(layer => layer.feature?.id), ...greenSpaceLayers.map(layer => layer.feature?.id)]).then(response => {
                this.setState(prevState => ({
                    trees: { ...prevState.trees, canopyIndicatorWithoutOverlap: response.treeCanopy, canopyIndicatorWithWoodedMassifs: response.totalCanopy },
                    woodedMassifs: { ...prevState.woodedMassifs, canopyIndicatorWithoutOverlap: response.greenSpaceCanopy }
                }));
            });
        }

        if (subscription.carbonStockFormula) {
            trees.oxygenProduction = FormulasUtil.getOxygenProduction(trees.carbonStock);
            woodedMassifs.oxygenProduction = FormulasUtil.getOxygenProduction(woodedMassifs.carbonStock);
            greenSpaces.oxygenProduction = FormulasUtil.getOxygenProduction(greenSpaces.carbonStock);
        }

        trees.biodiversityIndex = Math.round(trees.biodiversityIndex * 100) / 100;
        greenSpaces.ecopotentialIndex = Math.round(greenSpaces.ecopotentialIndex * 100) / 100;
        if (subscription.coolingFormula) {
            trees.annualCoolingIndicator = Math.round(trees.annualCoolingIndicator * 100) / 100;
            trees.annualCoolingEconomicValue = Math.round(trees.annualCoolingEconomicValue * 100) / 100;
            woodedMassifs.annualCoolingIndicator = Math.round(woodedMassifs.annualCoolingIndicator * 100) / 100;
            woodedMassifs.annualCoolingEconomicValue = Math.round(woodedMassifs.annualCoolingEconomicValue * 100) / 100;
            greenSpaces.annualCoolingIndicator = Math.round(greenSpaces.annualCoolingIndicator * 100) / 100;
            greenSpaces.annualCoolingEconomicValue = Math.round(greenSpaces.annualCoolingEconomicValue * 100) / 100;
        }

        this.setState({ trees, woodedMassifs, greenSpaces, furnitures });
    }

    toggleTreesPerformanceElements = (element) => {
        const { treesPerformanceOptions } = this.state;
        if (element === 'trees') treesPerformanceOptions.showTrees = !treesPerformanceOptions.showTrees;
        else treesPerformanceOptions.showWoodedMassifs = !treesPerformanceOptions.showWoodedMassifs;
        this.setState(prevState => ({ treesPerformanceOptions, search: UrlsUtil.adjustSearch(prevState.search, { treePerformances: treesPerformanceOptions.showTrees && treesPerformanceOptions.showWoodedMassifs ? null : (treesPerformanceOptions.showTrees ? 'trees' : (treesPerformanceOptions.showWoodedMassifs ? 'woodedMassifs' : 'none')) }) }));
    }

    mergeTreesPerformance = () => {
        const { trees, woodedMassifs } = this.state;
        const treesPerformance = { ...initialWoodedMassifsState, amenityValue: 0 };

        treesPerformance.biodiversityIndex = trees.biodiversityIndex;
        treesPerformance.amenityValue = trees.amenityValue;
        treesPerformance.annualCoolingEconomicValue = trees.annualCoolingEconomicValue + woodedMassifs.annualCoolingEconomicValue;
        treesPerformance.annualCoolingIndicator = trees.annualCoolingIndicator + woodedMassifs.annualCoolingIndicator;
        treesPerformance.canopyIndicator = trees.canopyIndicator + woodedMassifs.canopyIndicator;
        treesPerformance.canopyIndicatorWithoutOverlap = trees.canopyIndicatorWithWoodedMassifs; // Représente le cumul arbres + massifs arborés sans les overlaps
        treesPerformance.fruitProduction = trees.fruitProduction + woodedMassifs.fruitProduction;
        treesPerformance.fruitTrees = trees.fruitTrees + woodedMassifs.fruitTrees;
        treesPerformance.carbonStock = trees.carbonStock + woodedMassifs.carbonStock;
        treesPerformance.totalCarbonStock = trees.totalCarbonStock + woodedMassifs.totalCarbonStock;
        treesPerformance.oxygenProduction = trees.oxygenProduction + woodedMassifs.oxygenProduction;

        return treesPerformance;
    }

    getGlobalPerformance = (requiredFields) => {
        const { trees, woodedMassifs, greenSpaces } = this.state;
        return {
            canopyIndicator: requiredFields.trees.canopy ? trees.canopyIndicator + woodedMassifs.canopyIndicator : 0,
            canopyIndicatorWithoutOverlap: requiredFields.trees.canopy ? trees.canopyIndicatorWithWoodedMassifs : 0,
            annualCoolingIndicator: (requiredFields.trees.coolingIndicator ? trees.annualCoolingIndicator + woodedMassifs.annualCoolingIndicator : 0) + (requiredFields.greenSpaces.coolingIndicator ? greenSpaces.annualCoolingIndicator : 0),
            annualCoolingEconomicValue: (requiredFields.trees.coolingIndicator ? trees.annualCoolingEconomicValue + woodedMassifs.annualCoolingEconomicValue : 0) + (requiredFields.greenSpaces.coolingIndicator ? greenSpaces.annualCoolingEconomicValue : 0),
            carbonStock: (requiredFields.trees.carbonStock ? trees.carbonStock + woodedMassifs.carbonStock : 0) + (requiredFields.greenSpaces.carbonStock ? greenSpaces.carbonStock : 0),
            totalCarbonStock: requiredFields.trees.carbonStock ? trees.totalCarbonStock + woodedMassifs.totalCarbonStock : 0,
            oxygenProduction: (requiredFields.trees.oxygenProduction ? trees.oxygenProduction + woodedMassifs.oxygenProduction : 0) + (requiredFields.greenSpaces.oxygenProduction ? greenSpaces.oxygenProduction : 0),
            fruitProduction: trees.fruitProduction + woodedMassifs.fruitProduction,
            amenityValue: requiredFields.trees.amenityValue ? trees.amenityValue : 0
        };
    }

    handleExportConfigChanged = (category, subCategory, name, checked) => {
        if (subCategory && this.state.display === 'global') subCategory = 'global';
        this.setState(prevState => ({
            exportConfig: {
                ...prevState.exportConfig,
                [category]: subCategory ? {
                    ...prevState.exportConfig[category],
                    [subCategory]: {
                        ...prevState.exportConfig[category][subCategory],
                        [name]: checked
                    }
                } : {
                    ...prevState.exportConfig[category],
                    [name]: checked
                }
            }
        }));
    }

    exportAsPDF = () => {
        this.props.showLoader(true, 'modal', i18n.t("Export des statistiques en cours..."));
        const { isDarkTheme } = this.props;
        this.setState({ isExporting: true }, () => {
            this.props.setIsDarkTheme(false).then(() => {
                setTimeout(() => {
                    this.props.exportStatisticsAsPDF().then(() => {
                        this.props.setIsDarkTheme(isDarkTheme).then(() => {
                            this.setState({ isExporting: false, exportConfig: initialExportConfig });
                            this.props.showLoader(false);
                        });
                    });
                }, 100);
            });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        essences: state.essences,
        dominantCompositions: state.dominantCompositions,
        spaceTypes: state.spaceTypes,
        furnitureTypes: state.furnitureTypes,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isOnline: state.isOnline,
        webSocketHubs: state.webSocketHubs,
        isDarkTheme: state.isDarkTheme
    };
};

const mapDispatchToProps = {
    setIsDarkTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);