import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ErrorsService {
    static addError(data) {
        return Axios.post(BACKEND_ENDPOINT + 'errors/', data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response.data;
        }, () => { return; });
    }
}