import React, { Component } from 'react';
// Composants
import { Label, Menu, Popup, Loader, Button } from 'semantic-ui-react';
// Librairies
import i18n from '../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle, faChevronRight, faDownload, faTasks, faTimes, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setRequest, setCurrentAction } from '../../actionCreators/appActions';
import { setPhotosGalleries, setFormulasResults } from '../../actionCreators/elementsActions';
import { setUserProjects, setProjects, setProject, setRootBaseProjectIds } from '../../actionCreators/projectsActions';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// Services
import FileInfosService from '../../services/FileInfosService';
import TreesService from '../../services/TreesService';
import FurnituresService from '../../services/FurnituresService';
import GreenSpacesService from '../../services/GreenSpacesService';
import ActionsService from '../../services/ActionsService';
import ProjectsService from '../../services/ProjectsService';
import BackgroundTasksService from '../../services/BackgroundTasksService';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import { isMobile } from 'react-device-detect';
import FileInfosUtil from '../../utils/FileInfosUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import DatesUtil from '../../utils/DatesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import { setProjectListState } from '../../actionCreators/componentsActions';

class Tasks extends Component {
    state = {
        open: false,
        tasks: [],
        newTask: null
    }

    render() {
        const { open, tasks, newTask } = this.state;
        const tasksLeft = this.state.tasks.filter(task => !task.status);
        const nbTasksLeft = tasksLeft.length;

        return (<>
            <Popup
                position={!isMobile ? 'bottom center' : 'bottom left'}
                positionFixed
                content={<>
                    <div style={{ display: 'flex', marginBottom: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3 style={{ margin: 0 }}>{!open && newTask ? i18n.t("Tâche en cours...") : i18n.t("Tâches")}</h3>
                        {!open && newTask &&
                            <Button color='blue' onClick={() => this.setState({ open: false, newTask: null })} style={{ height: '25px', width: '25px', padding: 0 }}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>}
                    </div>
                    <div id='tasks-list' style={{ display: !tasks.length && 'flex', alignItems: 'center', justifyContent: 'center', height: !open && newTask && 'auto', maxWidth: '75vw' }}>
                        {tasks.length > 0 ?
                            this.renderTasks(!open && newTask ? [newTask] : null)
                            : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                                <FontAwesomeIcon icon={faTasks} size='4x' style={{ marginTop: 'auto' }} />
                                <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                            </div>}
                    </div>
                </>}
                style={{ padding: '8px', width: '300px' }}
                on='click' onOpen={() => this.setState({ open: true, newTask: null })}
                onClose={() => { if (!newTask) this.setState({ open: false, newTask: null }) }}
                popper={{ id: 'popper-container', style: { zIndex: 1000 } }}
                trigger={<Menu.Item id='custom-id-task' title={i18n.t("Tâches")} as='a'>
                    <FontAwesomeIcon icon={faTasks} />
                    {nbTasksLeft > 0 &&
                        <Label color='blue' floating size='mini' style={{ top: '.8em', left: '90%', padding: '3px 5px', borderRadius: '10px' }}>
                            {nbTasksLeft}
                        </Label>}
                </Menu.Item>}
                open={(open || newTask) ? true : false}
            />
            <div id='tasksStatus'></div>
        </>);
    }

    componentDidMount = () => {
        this.handleUsersHub();
        this.loadBackgroundTasks();
    }

    componentDidUpdate = () => {
        this.handleUsersHub();
        if (this.props.request && !this.props.request.status && !this.state.tasks.find(x => x.id === this.props.request.id)) {
            const tasks = this.state.tasks;
            const request = this.props.request;
            tasks.push(request);
            this.props.setRequest(null);

            this.setState({ tasks }, () => {
                if (this.props.isOnline && request.toastId && request.toastId !== request.processMessageId) showToast(request.toastId, ...(request.toastCustomTexts || []));
                switch (request.functionName) {
                    case 'uploadPhoto': this.uploadPhoto(request); break;
                    case 'exportTreePDF': this.exportTreePDF(request); break;
                    case 'exportFurniturePDF': this.exportFurniturePDF(request); break;
                    case 'exportGreenSpacePDF': this.exportGreenSpacePDF(request); break;
                    case 'exportActionPDF': this.exportActionPDF(request); break;
                    case 'exportBacklogPDF': this.exportBacklogPDF(request); break;
                    case 'updateProject': this.updateProject(request); break;
                    default: break;
                }
            });
        }
    }

    loadBackgroundTasks = () => {
        if (this.state.tasks.length === 0 && this.props.isOnline)
            BackgroundTasksService.getLatestBackgroundTasks().then(response => this.setState(prevState => ({ tasks: [...prevState.tasks, ...(response || [])] })));
    }

    renderTasks = (tasksToRender) => {
        const { tasks } = this.state;
        tasksToRender = tasksToRender || tasks;
        tasksToRender.sort((a, b) => new Date(b.date) - new Date(a.date));

        return tasksToRender.map((task, index) => {
            const canBeOpened = task.content ? true : false;
            const timeLeftAsString = task.timeLeft ? new Date(task.timeLeft).toISOString().slice(11, 19) : null;
            let message = task.title || i18n.t(!task.status ? task.processMessageId : task.status === 1 ? task.successMessageId : task.errorMessageId);
            if (task.toastCustomTexts?.length)
                task.toastCustomTexts.forEach(text => {
                    message = message.replace('__', text);
                });

            return (
                <div
                    key={index} id={`task-card-${task.id}`} className='task-card' onClick={() => this.toggleTaskOpening(task)}
                    style={{ marginBottom: (index < tasksToRender.length - 1) && '5px', marginRight: '5px', cursor: canBeOpened && 'pointer' }}
                >
                    <div>
                        {canBeOpened && <FontAwesomeIcon icon={faChevronRight} style={{ margin: '0 16px 0 6px' }} />}
                        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                            <h4 style={{ margin: 0, marginBottom: '5px' }}>{message}{task.percentage ? `(${task.percentage}%)` : ''}</h4>
                            <div style={{ color: 'var(--white-40)' }}>{!task.status ? DatesUtil.convertUTCDateToLocaleDate(task.date) : DatesUtil.convertUTCDateToLocaleDate(task.date)}</div>
                        </div>
                        <div>
                            {!task.status ? <Loader active inline size='small' title={i18n.t("En cours...")} />
                                : task.status === 1 ? <FontAwesomeIcon icon={faCheckCircle} size='2x' color='green' title={i18n.t("Réussite")} />
                                    : task.status === 2 ? <FontAwesomeIcon icon={faTimesCircle} size='2x' color='red' title={i18n.t("Échouée")} />
                                        : <FontAwesomeIcon icon={faBan} size='2x' color='grey' title={i18n.t("Annulée")} />}
                        </div>
                    </div>
                    <div>
                        <div>
                            {timeLeftAsString &&
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <h4 style={{ margin: 0 }}>{i18n.t("Temps restant estimé")} :</h4>
                                    <p style={{ marginTop: '2px' }}>{timeLeftAsString}</p>
                                </div>}
                            <h4 style={{ margin: 0 }}>{i18n.t("Détails")} :</h4>
                            <p style={{ margin: 0 }}>{task.content}</p>
                            {task.link &&
                                <Button color='blue' size='mini' as='a' href={task.link} target='_blank' style={{ marginTop: '5px' }}>
                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />{i18n.t("Télécharger")}
                                </Button>}
                        </div>
                    </div>
                </div>
            );
        })
    }

    clearTasks = (nbTasksLeft) => {
        if (nbTasksLeft === 0) this.setState({ tasks: [] });
    }

    uploadPhoto = (request) => {
        const formData = new FormData();
        formData.append('type', 'photo');
        formData.append('id', request.data.tempPhoto.id);
        formData.append('projectId', request.data.tempPhoto.projectId);
        formData.append('elementId', request.data.tempPhoto.elementId);
        formData.append('category', request.data.tempPhoto.category);
        formData.append('recurrenceId', request.data.tempPhoto.recurrenceId);
        formData.append('blobName', request.data.tempPhoto.blobName);
        formData.append('createdAt', request.data.tempPhoto.originalCreatedAt);
        formData.append('file', request.data.photo, request.data.tempPhoto.blobName);

        const axiosOptions = {
            onUploadProgress: (processEvent) => {
                const { loaded, total } = processEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent !== this.state.uploadPercentage) {
                    const tasks = JSON.parse(JSON.stringify(this.state.tasks));
                    let task = tasks.find(task => task.id === request.id);
                    task.percentage = percent;
                    this.props.setRequest({ ...request, percentage: percent });
                    this.setState({ tasks });
                }
            }
        }

        FileInfosService.addFileInfo(formData, axiosOptions).then(response => {
            const tasks = JSON.parse(JSON.stringify(this.state.tasks));
            const taskIndex = tasks.findIndex(task => task.id === request.id);
            if (taskIndex >= 0) {
                request.status = response ? 1 : 2;
                request.date = new Date();
                request.response = response;
                tasks[taskIndex] = request;
            }

            this.setState({ tasks }, () => {
                if (request.response) {
                    let photosGalleries = [...this.props.photosGalleries];
                    const index = photosGalleries.findIndex(x => x.id === request.data.tempPhoto.id);
                    if (index !== -1) {
                        request.response.url = FileInfosUtil.getUrl(request.response);
                        photosGalleries[index] = request.response;
                    }
                    this.props.setPhotosGalleries(photosGalleries);
                    if (request.response.projectId) WebSocketUtil.sendFileInfos(this.props.webSocketHubs, request.response.projectId, [request.response])
                }
                this.props.setRequest(request);

                if (this.props.isOnline) showToast('photo_added');
                else showToast('will_sync');
                this.setState({ tasks });
            });
        });
    }

    updateExportRequest = (request) => {
        const tasks = this.state.tasks.map(task => {
            if (task === request) {
                request.status = 1;
                request.response = null;
                request.date = new Date();
                task = request;
            }
            return task;
        });

        this.setState({ tasks }, () => this.props.setRequest(request));
    }

    exportTreePDF = (request) => {
        const { element, image, photosId, colorless } = request.data;
        TreesService.exportTreeAsPDF(element, image, photosId, colorless, request.toastCustomTexts).then(() => this.updateExportRequest(request));
    }

    exportFurniturePDF = (request) => {
        const { element, image, photosId, colorless } = request.data;
        FurnituresService.exportFurnitureAsPDF(element, image, photosId, colorless, request.toastCustomTexts).then(() => this.updateExportRequest(request));
    }

    exportGreenSpacePDF = (request) => {
        const { element, image, photosId, colorless } = request.data;
        GreenSpacesService.exportGreenSpaceAsPDF(element, image, photosId, colorless, request.toastCustomTexts).then(() => this.updateExportRequest(request));
    }

    exportActionPDF = (request) => {
        const { projectAction, image } = request.data;
        ActionsService.exportActionAsPDF(projectAction, image).then(() => {
            this.updateExportRequest(request);
            this.props.setCurrentAction('');
        });
    }

    exportBacklogPDF = (request) => {
        ActionsService.exportBacklogAsPDF(request.data, request.toastCustomTexts).then(() => {
            this.updateExportRequest(request);
            this.props.setCurrentAction('');
        });
    }

    updateProject = (request) => {
        const { project, removedArea, ownerId } = request.data;
        const userProjects = JSON.parse(JSON.stringify(this.props.userProjects));
        ProjectsService.updateProject(project, removedArea ? JSON.stringify(removedArea.geometry) : null, ownerId)
            .then((response) => {
                if (response) {
                    const { formulasResults, newOwner, newOrganization, newUserBaseProjects } = response;

                    let projects = [...this.props.projects];
                    let updatedProject = { ...project, path: null, parentFolderId: null, userBaseProjects: userProjects };
                    if (newOrganization) {
                        updatedProject.organization = newOrganization;
                        updatedProject.userBaseProjects = newUserBaseProjects;
                    }

                    if (project.path && !updatedProject.path) {
                        projects = ProjectsUtil.removeBaseProject(project.id, this.props.projects, this.props.rootBaseProjectIds, this.props.setProjects, this.props.setRootBaseProjectIds);
                        let projectListState = this.props.projectListState;
                        if (projectListState?.projects?.length)
                            this.props.setProjectListState({ ...projectListState, projects: projectListState.projects.filter(p => p.id !== project.id) });
                        projects.push(updatedProject);
                        this.props.setProjects(projects);

                        const usersToAlert = userProjects.filter(up => !newUserBaseProjects.find(nubp => nubp.userId === up.userId)).map(up => up.userId);
                        WebSocketUtil.removeProject(this.props.webSocketHubs, usersToAlert, project.id, project.path);
                    }

                    if (formulasResults?.length && this.props.project?.id === project.id)
                        this.props.setFormulasResults(formulasResults);

                    if (userProjects?.length) {
                        let oldOwner = userProjects.find(userProject => userProject.projectRole.type === 'owner');
                        if (newOwner && oldOwner.userId !== newOwner.userId) {
                            const managerRole = project.projectRoles.find(pr => pr.type === 'manager');
                            oldOwner.isOwner = false;
                            oldOwner.projectRoleId = managerRole.id;
                            oldOwner.projectRole = managerRole;
                            const newOwnerIndex = userProjects.findIndex(userProject => userProject.userId === newOwner.userId);
                            userProjects[newOwnerIndex] = newOwner;
                            if (this.props.userProjects[0].baseProjectId === newOwner.baseProjectId)
                                this.props.setUserProjects(userProjects);
                            updatedProject = { ...project, userBaseProjects: userProjects, projectRoles: project.projectRoles.map(pr => ({ ...pr, userBaseProjects: userProjects.filter(up => up.projectRoleId === pr.id) })) };

                            // WebSocketUtil.updateUserBaseProjects(this.props.webSocketHubs, usersToAlert, userProjects, project.path);
                            project.userBaseProjects = userProjects;
                        }

                        const userId = jwtDecode(new Cookies().get('token')).id;
                        const usersToAlert = userProjects.filter(up => up.user.id !== userId).map(up => up.user.id);
                        WebSocketUtil.updateProject(this.props.webSocketHubs, usersToAlert, updatedProject);
                        ProjectsUtil.updateProjectsInProps(updatedProject, projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
                    }
                }

                const tasks = this.state.tasks.map(task => {
                    if (task === request) {
                        request.status = response ? 1 : 2;
                        request.date = new Date();
                        request.response = response;
                        task = request;
                    }
                    return task;
                });
                this.setState({ tasks });
            });
    }

    handleUsersHub = () => {
        if (!this.hubHandled && this.props.webSocketHubs?.usersHub && this.props.webSocketHubs.usersHub.state === 'Connected') {
            this.hubHandled = true;
            this.props.webSocketHubs.usersHub.on('SendBackgroundTask', (backgroundTask) => this.setState(prevState => ({ tasks: [...prevState.tasks, JSON.parse(backgroundTask)], newTask: JSON.parse(backgroundTask) })));
            this.props.webSocketHubs.usersHub.on('UpdateBackgroundTask', (backgroundTask) => {
                backgroundTask = JSON.parse(backgroundTask);
                const tasks = [...this.state.tasks];
                let newTask = this.state.newTask;
                if (newTask !== null && newTask.id === backgroundTask.id) newTask = backgroundTask;
                const index = tasks.findIndex(t => t.id === backgroundTask.id);
                if (index !== -1) {
                    tasks[index] = backgroundTask;
                    this.setState({ tasks, newTask });
                }

                if (newTask.status)
                    setTimeout(() => {
                        if (this.state.newTask.status)
                            this.setState({ newTask: null })
                    }, 2000);
            });
        }
    }

    toggleTaskOpening = (task) => {
        if (task.content) {
            const taskCard = document.getElementById(`task-card-${task.id}`);
            if (taskCard.classList.contains('opened')) taskCard.classList.remove('opened');
            else taskCard.classList.add('opened');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        request: state.request,
        isOnline: state.isOnline,
        photosGalleries: state.photosGalleries,
        webSocketHubs: state.webSocketHubs,
        rootBaseProjectIds: state.rootBaseProjectIds,
        projects: state.projects,
        project: state.project,
        userProjects: state.userProjects,
        formulas: state.formulas,
        projectListState: state.projectListState
    };
};

const mapDispatchToProps = {
    setRequest,
    setPhotosGalleries,
    setCurrentAction,
    setFormulasResults,
    setUserProjects,
    setRootBaseProjectIds,
    setProjects,
    setProject,
    setProjectListState
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);