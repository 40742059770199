import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
// Utils
import OfflineUtil from '../utils/OfflineUtil';
import { showLoadingToast, showToast } from '../utils/ToastsUtil';
import WebSocketUtil from '../utils/WebSocketUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class GreenSpacesService {
    static addGreenSpace(greenSpace, action, webSocketHubs, shouldShowToast = true) {
        if (!greenSpace.id) {
            greenSpace.id = uuidv4();
            greenSpace.properties.greenSpaceId = greenSpace.id;
            if (greenSpace.properties.baseLine) greenSpace.properties.baseLine.propertiesId = greenSpace.id;
        }
        const promise = Axios.post(BACKEND_ENDPOINT + 'greenSpaces/' + webSocketHubs?.elementsHub?.connectionId, greenSpace, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            greenSpace.properties = response.data.greenSpace?.properties;
            return response.data;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (greenSpace.projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'greenSpaces/isMaxReached/' + greenSpace.projectId, (body) => {
                        if (body.state) showToast('greenspaces_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + 1)) <= 10) {
                            body.nbElements++;
                            showToast('greenspaces_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements++;
                        return body;
                    });
                }
                else if (greenSpace.projectId) showToast('greenspaces_limit_not_available');
            }
            return Promise.reject();
        });

        if (shouldShowToast) {
            const toastId = 'greenspace_' + (action === 'copyPasting' ? 'copy_pasting' : action);
            const successId = 'greenspace_' + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
            const errorId = 'greenspace_' + (action === 'adding' ? 'addition' : 'copy_paste') + '_failed';
            if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
            else showToast('will_sync');
        }
        return promise;
    }

    static addGreenSpaces(greenSpaces, action, webSocketHubs, shouldShowToast = true) {
        greenSpaces.forEach(gs => {
            if (!gs.id) {
                gs.id = uuidv4();
                gs.properties.greenSpaceId = gs.id;
                if (gs.properties.baseLine) gs.properties.baseLine.propertiesId = gs.id;
            }
        });

        const promise = Axios.post(BACKEND_ENDPOINT + 'greenSpaces/bulk/' + webSocketHubs?.elementsHub?.connectionId, greenSpaces, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            response.data.greenSpaces.forEach(gs => {
                const greenSpace = greenSpaces.find(x => x.id === gs.id);
                greenSpace.properties = gs.properties;
            });
            return response.data;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (greenSpaces[0].projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'greenSpaces/isMaxReached/' + greenSpaces[0].projectId, (body) => {
                        if (body.state) showToast('greenspaces_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + greenSpaces.length)) <= 10) {
                            body.nbElements += greenSpaces.length;
                            showToast('greenspaces_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements += greenSpaces.length;
                        return body;
                    });
                }
                else if (greenSpaces[0].projectId) showToast('greenspaces_limit_not_available');
            }
            return Promise.reject();
        });

        if (shouldShowToast) {
            const prefix = greenSpaces.length > 1 ? 'greenspaces_' : 'greenspace_';
            const toastId = prefix + (action === 'copyPasting' ? 'copy_pasting' : action);
            const successId = prefix + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
            const errorId = prefix + (action === 'adding' ? 'addition' : action === 'copyPasting' ? 'copy_paste' : 'duplication') + '_failed';
            if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise, greenSpaces.length);
            else showToast('will_sync');
        }
        return promise;
    }

    static getGreenSpaces(projectId, data) {
        return Axios.post((!BACKEND_ENDPOINT.includes('localhost') && (data?.page || data?.page === 0)
            ? BACKEND_ENDPOINT.replace('://', '://' + ((data.page % 10) + 1) + '.')
            : BACKEND_ENDPOINT) + 'greenSpaces/projects/' + projectId, data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine && !data?.cacheKey && !data?.cursor) showToast('greenspaces_loading_failed');
            return;
        });
    }

    static removeGreenSpaces(greenSpaces, webSocketHubs, shouldShowToast = true) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'greenSpaces/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: greenSpaces
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (greenSpaces[0].projectId !== 0) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'greenSpaces/isMaxReached/' + greenSpaces[0].projectId, (body) => {
                        body.nbElements -= greenSpaces.length;
                        if (body.maxElement > -1 && (body.maxElements - body.nbElements) <= 10) body.state = false;
                        return body;
                    });
                }
            }
            return Promise.reject();
        });

        let prefixe = greenSpaces.length > 1 ? 'greenspaces' : 'greenspace';
        if (shouldShowToast) {
            if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise);
            else showToast('will_sync');
        }
        return promise;
    }

    static updateGreenSpace(id, greenSpace, history, action, webSocketHubs, shouldShowToast = true) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'greenSpaces/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id + '&history=' + history, greenSpace, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            greenSpace.properties = { ...greenSpace.properties, ...response.data.greenSpace.properties };
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        if (shouldShowToast) {
            const toastId = 'greenspace_' + action;
            const successId = 'greenspace_' + (action === 'cutting' ? 'cut'
                : action === 'splitting' ? 'split'
                    : action.replace('ing', 'ed'));
            const errorId = 'greenspace_' + (['merging', 'updating'].includes(action) ? action.replace('ing', 'e')
                : ['cutting', 'splitting'].includes(action) ? action.substr(0, action.length - 4)
                    : ['deleting', 'rotating', 'subtracting'].includes(action) ? action.replace('ing', 'ion')
                        : action === 'restoring' ? 'restoration'
                            : action) + '_failed';
            if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
            else showToast('will_sync');
        }
        return promise;
    }

    static bulkUpdateGreenSpaces(greenSpaces, action, webSocketHubs, shouldShowToast = true) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'greenSpaces/bulk/' + webSocketHubs?.elementsHub?.connectionId, greenSpaces, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (Array.isArray(response?.data)) {
                response.data.forEach(feature => {
                    let greenSpace = greenSpaces.find(greenSpace => greenSpace.id === feature.id);
                    if (greenSpace) greenSpace.properties = { ...greenSpace.properties, ...feature.properties };
                });
            }

            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        if (shouldShowToast) {
            const toastId = 'greenspaces_' + action;
            const successId = 'greenspaces_' + (action === 'cutting' ? 'cut'
                : action === 'splitting' ? 'split'
                    : action.replace('ing', 'ed'));
            const errorId = 'greenspaces_' + (['merging', 'updating'].includes(action) ? action.replace('ing', 'e')
                : ['cutting', 'splitting'].includes(action) ? action.substr(0, action.length - 4)
                    : ['deleting', 'rotating', 'subtracting'].includes(action) ? action.replace('ing', 'ion')
                        : action === 'restoring' ? 'restoration'
                            : action) + '_failed';
            if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
            else showToast('will_sync');
        }
        return promise;
    }

    static async updateZIndex(greenSpaceId, isFirstPlan) {
        return Axios.put(`${BACKEND_ENDPOINT}greenSpaces/zIndex/${greenSpaceId}/${isFirstPlan}`, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        });
    }

    static getGreenSpaceHistory(id) {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/history/?id=' + id, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('element_history_loading_failed');
            return;
        });
    }

    static getSpaceFunctions() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/spaceFunctions', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('space_functions_loading_failed');
            return;
        });
    }

    static getSpaceTypes() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/spaceTypes', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('space_types_loading_failed');
            return;
        });
    }

    static getSpaceTypeCategories() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/spaceTypeCategories', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('space_types_categories_loading_failed');
            return;
        });
    }

    static getRunoffCoefficients() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/runoffCoefficients', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('runoff_coefficients_loading_failed');
            return;
        });
    }

    static getDominantCompositions() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/dominantCompositions', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('dominant_compositions_loading_failed');
            return;
        });
    }

    static getManagementClasses() {
        return Axios.get(BACKEND_ENDPOINT + 'greenSpaces/managementClasses', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('management_classes_loading_failed');
            return;
        });
    }

    static exportGreenSpacesFromProjectAsExcel(name, id, elementIds = [], projection) {
        return Axios.post(BACKEND_ENDPOINT + 'greenSpaces/exportXLSX/' + id, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id} - ${name} (${i18n.t("Espaces verts").toLowerCase()}).xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static exportGreenSpaceAsPDF(greenSpace, image, photosId, colorless, toastCustomTexts) {
        return Axios.post(BACKEND_ENDPOINT + 'greenSpaces/exportPDF/' + greenSpace.id, { image, photosId, colorless }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            let filename = greenSpace.properties.projectReference ? `${i18n.t("Fiche espace vert")}_${greenSpace.properties.projectReference}` : i18n.t("Fiche espace vert");
            if (greenSpace.properties.customReference) filename += '_' + greenSpace.properties.customReference;
            filename += '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('pdf_export_completed', ...toastCustomTexts);
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
        });
    }

    static exportGreenSpacesAsPDF(projectId, elementIds, { colorless = false, addPhotos = true } = {}) {
        return Axios.post(BACKEND_ENDPOINT + 'greenSpaces/exportAllPDF/' + projectId, { elementIds, colorless, addPhotos }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
            return;
        });
    }

    static exportGreenSpacesAsSHP(projectLabel, projectId, elementIds, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'greenSpaces/exportSHP/' + projectId, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            if (response.data?.size > 0) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${projectId} - ${projectLabel} (${i18n.t("Espaces verts").toLowerCase()}).zip`);
                document.getElementById('modal-content').appendChild(link);
                link.click();
                document.getElementById('modal-content').removeChild(link);
            }
        }, () => {
            if (navigator.onLine) showToast('elements_shp_export_failed');
        });
    }
}
