import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Form, Grid, Input, Message, Segment, Select } from 'semantic-ui-react';
import Dropzone from 'dropzone';
import { Slider } from 'react-semantic-ui-range';
// Librairies
import { faCheck, faTimes, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setProject } from '../../../actionCreators/projectsActions';
import i18n from '../../../locales/i18n';
import L from 'leaflet';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { booleanIntersects, polygon } from '@turf/turf';
// Redux
import { setDefaultWmsServices } from '../../../actionCreators/appActions';
// Services
import WmsService from '../../../services/WmsService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import AppSettings from '../../../AppSettings';
import { showToast } from '../../../utils/ToastsUtil';

const initialError = {
    hidden: true,
    messages: [],
    url: false,
    label: false,
    layers: false
};

const initialWmsService = {
    url: '',
    label: '',
    type: 'baseLayer',
    layers: [],
    opacity: 1,
    format: null,
    style: null,
    tileMatrixSet: null,
    maxNativeZoom: null,
    legendURL: null
};

class WmsServiceForm extends Component {
    state = {
        initialWmsService: null,
        wmsService: { ...initialWmsService, creatorId: jwtDecode(new Cookies().get('token')).id },
        error: initialError,
        isLoading: false,
        isFetching: false,
        isUrlValidated: false,
        availableLayers: [],
        dragEnter: false,
        preview: null,
        defaultWmsServices: null
    };

    render() {
        const { isOnline, wmsServiceToEdit, isAdmin } = this.props;
        const { initialWmsService, wmsService, error, isFetching, isLoading, availableLayers, isUrlValidated, preview, defaultWmsServices } = this.state;

        const isDisabled = !isOnline || isLoading || (initialWmsService && JSON.stringify(initialWmsService) === JSON.stringify(wmsService) && !preview);

        return (
            <Form className='modal-content' onSubmit={this.handleSubmit} loading={isLoading} error>
                <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px' }}>
                    <Grid style={{ margin: 0, flexDirection: 'column', minHeight: '100%', overflow: 'auto' }}>
                        <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                            <Form.Field control={Input} action label={i18n.t("URL") + '* : '}>
                                <Input name='url' placeholder={i18n.t("Indiquez une URL")} value={wmsService.url || ''} error={error.url} onChange={this.handleUrlChange} disabled={isFetching} style={{ borderRight: 'none' }} />
                                <Button title={i18n.t("Tester l'URL")} color={isUrlValidated ? 'green' : 'blue'} style={{ marginLeft: '-3px', zIndex: 1, padding: '5px 10px' }} disabled={isFetching || isUrlValidated} loading={isFetching} onClick={this.getCapabilities}>
                                    {isUrlValidated ? i18n.t("Validé") : i18n.t("Tester")}
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                        {!isMobileOnly &&
                            <Message
                                error hidden={error.hidden}
                                header={i18n.t("Erreur")} list={error.messages}
                                style={{ textAlign: 'left', overflow: 'auto', marginBottom: '10px' }}
                            />}
                        {availableLayers?.length > 0 ?
                            <>
                                <div style={{ display: 'flex', width: '100%', flexDirection: isMobileOnly ? 'column' : 'row' }}>
                                    {this.renderPreview()}
                                    <div style={{ marginLeft: !isMobileOnly && '20px', display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
                                        <Form.Field
                                            control={Input} label={i18n.t("Libellé") + '* : '} placeholder={i18n.t("Indiquez un libellé")}
                                            name='label' value={wmsService.label || ''} onChange={this.handleChange} error={error.label}
                                        />
                                        <Form.Group widths='equal'>
                                            <Form.Field
                                                control={Select} label={i18n.t("Type") + '* : '} selectOnBlur={false}
                                                name='type' value={wmsService.type} onChange={this.handleChange}
                                                options={[{ text: i18n.t("Fond de carte"), value: 'baseLayer' }, { text: i18n.t("Overlay"), value: 'overlay' }]}
                                            />
                                            {wmsService.type === 'overlay' &&
                                                <Form.Field>
                                                    <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginBottom: '12px' }}>{i18n.t("Opacité")} :</label>
                                                    <div style={{ display: 'flex' }}>
                                                        <Slider
                                                            style={{ width: '300px', marginRight: '5px' }}
                                                            discrete color='green'
                                                            settings={{
                                                                min: 0, max: 1, step: 0.01, start: wmsService.opacity,
                                                                onChange: this.handleOpacityChange
                                                            }}
                                                        />
                                                        <span>{Math.round(wmsService.opacity * 100) + '%'}</span>
                                                    </div>
                                                </Form.Field>}
                                        </Form.Group>
                                    </div>
                                </div>
                                {availableLayers.length > 1 &&
                                    <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                                        <Form.Field
                                            control={Select} placeholder={wmsService.url.includes('/wmts?') ? i18n.t("Sélectionnez une couche") : i18n.t("Sélectionnez une ou plusieurs couches")}
                                            label={<label>{wmsService.url.includes('/wmts?') ? i18n.t("Choix de la couche cartographique") : i18n.t("Choix des couches cartographiques")} :</label>}
                                            name='layers' options={availableLayers.map(({ text, value }) => ({ text, value }))} clearable
                                            value={wmsService.url.includes('/wmts?') ? wmsService.layers?.[0] || '' : wmsService.layers || []}
                                            selectOnBlur={false} multiple={!wmsService.url.includes('/wmts?')} selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            onChange={this.handleLayerChange}
                                        />
                                    </Grid.Column>}
                            </>
                            :
                            <>
                                {!isAdmin && !wmsServiceToEdit && (!defaultWmsServices || defaultWmsServices.length > 0) &&
                                    <>
                                        <Divider style={{ margin: '7px 0' }} />
                                        <Segment loading={!defaultWmsServices} style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                            <h3 style={{ marginBottom: 0 }}>{i18n.t("WMS préconfigurés")}</h3>
                                            {defaultWmsServices && this.renderDefaultWmsServices()}
                                        </Segment>
                                    </>}
                            </>}
                        {wmsService.layers.length > 0 &&
                            <div key='wms-service-preview' id='wms-service-preview' className='mapPreview' style={{ minHeight: '300px', width: '100%', margin: '10px 0', zIndex: 1, border: 'dashed 1px var(--grey-80)', borderRadius: '10px', flex: '1 1 1px' }}></div>}
                    </Grid>
                </Segment>
                <div className='modal-content-footer'>
                    {isMobile ?
                        <Button.Group style={{ width: '100%' }}>
                            <Button
                                type='button' className='form-button' color='red' style={{ width: '50%' }}
                                onClick={this.props.hideForm} disabled={isLoading}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' style={{ width: '50%' }} disabled={isDisabled}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Button.Group>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={this.props.hideForm} disabled={isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' disabled={isDisabled}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </Form >
        );
    }

    componentDidMount = () => {
        const { wmsServiceToEdit, defaultWmsServices, isAdmin, project } = this.props;

        const setDefaultWmsServices = (wmsServices) => {
            const surroundings = JSON.parse(project.surroundings);
            wmsServices = wmsServices.filter(wmsService => {
                if (!wmsService.boundingBox) return true;
                const boundingBox = polygon([[
                    [wmsService.boundingBox[0][0], wmsService.boundingBox[0][1]],
                    [wmsService.boundingBox[1][0], wmsService.boundingBox[0][1]],
                    [wmsService.boundingBox[1][0], wmsService.boundingBox[1][1]],
                    [wmsService.boundingBox[0][0], wmsService.boundingBox[1][1]],
                    [wmsService.boundingBox[0][0], wmsService.boundingBox[0][1]]
                ]]);
                return booleanIntersects(surroundings, boundingBox);
            });
            this.setState({ defaultWmsServices: wmsServices });
        }

        if (wmsServiceToEdit) {
            const wmsService = JSON.parse(JSON.stringify(wmsServiceToEdit));
            this.setState({ initialWmsService: JSON.parse(JSON.stringify(wmsService)), wmsService }, () => this.getCapabilities());
        } else if (!isAdmin && project) {
            if (!defaultWmsServices)
                WmsService.getDefaultWmsServices().then(wmsServices => {
                    if (Array.isArray(wmsServices)) this.props.setDefaultWmsServices(wmsServices);
                    setDefaultWmsServices(wmsServices);
                });
            else setDefaultWmsServices(defaultWmsServices);
        }
    }

    componentDidUpdate = (_, prevState) => {
        const { wmsService } = this.state;

        const dropZone = document.getElementById('dropZone');
        if (!this.dropZone !== !dropZone) {
            if (dropZone) {
                this.dropZone = new Dropzone('div#dropZone', { previewsContainer: false, autoProcessQueue: false, url: '/', maxFiles: 1 });
                this.dropZone.on('addedfile', file => this.setState({ dragEnter: false }, () => this.uploadPreview(file)));
                this.dropZone.on('dragover', () => {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                        this.timeout = null;
                    }
                    this.setState({ dragEnter: true });
                });
                this.dropZone.on('dragleave', () => {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                        this.timeout = null;
                    }
                    this.timeout = setTimeout(() => {
                        this.timeout = null;
                        this.setState({ dragEnter: false });
                    }, 200);
                });
            } else this.dropZone = null;
        }

        const map = document.getElementById('wms-service-preview');
        if (!this.map !== !map) {
            if (map) {
                const layers = wmsService.url.includes('/wmts?')
                    ? L.tileLayer.wmts(wmsService.url, { layer: wmsService.layers[0], maxZoom: 22, opacity: wmsService.type === 'overlay' ? wmsService.opacity : 1, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom })
                    : L.tileLayer.wms(wmsService.url, { layers: wmsService.layers.join(','), maxZoom: 22, opacity: wmsService.type === 'overlay' ? wmsService.opacity : 1, format: 'image/png', });
                this.map = L.map('wms-service-preview', { layers, minZoom: 3, zoomControl: false, attributionControl: false });
                setTimeout(() => {
                    if (this.props.map) this.map.fitBounds(this.props.map.getBounds());
                    else {
                        if (this.boundingBox)
                            this.map.setView([(this.boundingBox[1][1] + this.boundingBox[0][1]) / 2, (this.boundingBox[1][0] + this.boundingBox[0][0]) / 2], 14);
                        else this.map.setView([50.411279555115584, 4.44397880709991], 14);
                    }
                }, 100);
                this.map.keyboard.disable();
                if (this.map.tap) this.map.tap.disable();
                map.style.cursor = 'pointer';
            } else this.map = null;
        } else if (this.map && prevState.wmsService.layers !== this.state.wmsService.layers) {
            this.map.eachLayer(layer => this.map.removeLayer(layer));
            if (wmsService.url.includes('/wmts?')) L.tileLayer.wmts(wmsService.url, { layer: wmsService.layers[0], maxZoom: 22, opacity: wmsService.type === 'overlay' ? wmsService.opacity : 1, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom }).addTo(this.map);
            else L.tileLayer.wms(wmsService.url, { layers: wmsService.layers.join(','), maxZoom: 22, opacity: wmsService.type === 'overlay' ? wmsService.opacity : 1, format: 'image/png' }).addTo(this.map);
        }
    }

    renderDefaultWmsServices = () => {
        const { isDarkTheme } = this.props;
        const { defaultWmsServices, selectedDefaultWms } = this.state;
        const blobInfos = AppSettings.getBlobInfos();

        return (
            <div style={{ display: 'flex', overflowX: 'scroll', maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden', paddingBottom: '5px' }}>
                {defaultWmsServices.map(wmsService => {
                    const preview = wmsService?.preview && `${blobInfos.endpoint}${blobInfos.containers.photos}/${wmsService.preview}`;

                    return (
                        <div
                            key={wmsService.id}
                            style={{
                                display: 'flex', flexDirection: 'column', width: '130px', marginLeft: '8px',
                                marginRight: '8px', overflowWrap: 'break-word', cursor: 'pointer'
                            }}
                            onClick={() => this.selectDefaultWms(wmsService)}
                        >
                            <div
                                className='base-layer-preview'
                                style={{
                                    marginTop: '10px', border: `solid 2px var(--${selectedDefaultWms === wmsService ? 'primary' : (isDarkTheme ? 'white' : 'black')}-100)`,
                                    backgroundImage: preview ? `url(${preview})` : null, display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    fontSize: '14pt', fontWeight: 'bold', height: '120px', width: '120px'
                                }}
                            >
                                {!preview && `${wmsService?.label?.slice(0, 1).toUpperCase()}${wmsService?.label?.slice(1, 5).toLowerCase()}`}
                            </div>
                            <div style={{ marginTop: '5px', fontSize: '14px', fontWeight: 'bold', lineHeight: '15px', textAlign: 'center' }}>{wmsService.label}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderPreview = () => {
        const { isDarkTheme } = this.props;
        const { dragEnter } = this.state;

        const { preview, wmsService } = this.state;
        const blobInfos = AppSettings.getBlobInfos();
        const logoURL = preview ? URL.createObjectURL(preview) : wmsService.preview ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${wmsService.preview}` : null;
        const dropZoneStyle = {
            width: isMobileOnly ? '100%' : '150px', aspectRatio: '4 / 4', backgroundColor: logoURL ? 'black' : 'rgba(250,250,250,0.05)',
            border: !dragEnter ? '2px dashed var(--white-10)' : isDarkTheme ? '2px dashed white' : '2px dashed black', borderRadius: '20px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', overflow: 'hidden',
            marginBottom: isMobileOnly && '10px'
        };

        return (
            <div id='dropZone' title={i18n.t("Cliquez pour séléctionner un logo")} style={dropZoneStyle}>
                {logoURL ?
                    <div style={{ width: '100%', height: '100%', backgroundImage: `url('${logoURL}')`, pointerEvents: 'none', backgroundSize: 'cover' }}>
                    </div>
                    :
                    <div style={{ pointerEvents: 'none', textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faUpload} size='4x' style={{ margin: '0 5px' }} />
                        <h4 style={{ textAlign: 'center', margin: '10px 5px 0px 5px' }}>{!dragEnter ? i18n.t("Glissez le logo ici") : i18n.t("Déposez le logo ici")}</h4>
                    </div>}
            </div>
        );
    }

    uploadPreview = (file) => {
        if (file['type'].split('/')[0] !== 'image') showToast('file_format_not_supported');
        else this.setState({ preview: file });
    }

    selectDefaultWms = (wmsService) => {
        const { id, ...defaultWms } = wmsService;
        this.setState({
            selectedDefaultWms: wmsService, isUrlValidated: true, error: initialError, availableLayers: [],
            wmsService: { ...defaultWms, isGlobal: false, projectId: this.props.project.id, creatorId: jwtDecode(new Cookies().get('token')).id }
        });
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            wmsService: { ...prevState.wmsService, [name]: value },
            error: { ...prevState.error, [name]: false }
        }));
    }

    handleUrlChange = (_, { name, value }) => {
        this.boundingBox = null;
        this.setState(prevState => ({
            wmsService: { ...prevState.wmsService, [name]: value, layers: [], format: null, style: null, tileMatrixSet: null, maxNativeZoom: null, legendURL: null },
            error: { ...prevState.error, [name]: false },
            availableLayers: [],
            isUrlValidated: false
        }));
    }

    handleLayerChange = (_, { value }) => {
        const { isAdmin } = this.props;
        const { wmsService } = this.state;

        const layer = (wmsService.url.includes('/wmts?') || isAdmin) && this.state.availableLayers.find(layer => layer.value === (wmsService.url.includes('/wmts?') ? value : value[0]));
        if (isAdmin) {
            if (!value || value.length < 1) this.boundingBox = null;
            else if (!this.boundingBox) this.boundingBox = layer?.boundingBox;
        }

        this.setState(prevState => ({
            wmsService: {
                ...prevState.wmsService, layers: value?.length > 0 ? (wmsService.url.includes('/wmts?') ? [value] : value) : [],
                format: layer?.format || null, style: layer?.style || null, tileMatrixSet: layer?.tileMatrixSet || null, maxNativeZoom: layer?.maxNativeZoom || null,
                legendURL: layer?.legendURL || null, boundingBox: this.boundingBox || null
            },
            error: { ...prevState.error, layers: false }
        }));
    }

    handleOpacityChange = (value) => {
        if (this.opacityTimeout) clearTimeout(this.opacityTimeout);
        // Pour déclencher le rerender de la carte
        this.opacityTimeout = setTimeout(() => this.setState(prevState => ({ wmsService: { ...prevState.wmsService, layers: [...prevState.wmsService.layers] } })), 1000);
        this.setState(prevState => ({ wmsService: { ...prevState.wmsService, opacity: Number(value) } }));
    }

    getCapabilities = () => {
        const availableLayers = [];

        const addError = (message) => {
            this.setState({ isFetching: false, error: { hidden: false, url: true, messages: [message] } });
        };

        const extractLayers = (layers, isWMTS) => {
            const extract = (layer) => {
                if (layer.Layer) extractLayers(layer.Layer);
                else if (!isWMTS && layer.Name) {
                    let boundingBox = layer['EX_GeographicBoundingBox'];
                    if (boundingBox?.northBoundLatitude && boundingBox?.eastBoundLongitude && boundingBox?.southBoundLatitude && boundingBox?.westBoundLongitude) {
                        boundingBox = [
                            [Number(boundingBox.westBoundLongitude), Number(boundingBox.southBoundLatitude)],
                            [Number(boundingBox.eastBoundLongitude), Number(boundingBox.northBoundLatitude)]
                        ];
                    } else boundingBox = null;
                    availableLayers.push({ text: layer.Name, value: layer.Name, boundingBox });
                } else if (isWMTS && layer.Identifier?.toString) {
                    let boundingBox = layer['WGS84BoundingBox'];
                    if (boundingBox?.LowerCorner && boundingBox?.UpperCorner) {
                        boundingBox = [
                            [Number(boundingBox.LowerCorner.toString?.()?.split?.(' ')[0]), Number(boundingBox.LowerCorner.toString?.()?.split?.(' ')[1])],
                            [Number(boundingBox.UpperCorner.toString?.()?.split?.(' ')[0]), Number(boundingBox.UpperCorner.toString?.()?.split?.(' ')[1])]
                        ];
                    } else boundingBox = null;
                    const tileMatrixSetLink = Array.isArray(layer.TileMatrixSetLink)
                        ? layer.TileMatrixSetLink.find(tmsl => tmsl?.TileMatrixSet === 'PM') || layer.TileMatrixSetLink.find(tmsl => tmsl?.TileMatrixSet === 'WGS84') || layer.TileMatrixSetLink[0]
                        : layer.TileMatrixSetLink;
                    let maxNativeZoom = tileMatrixSetLink?.TileMatrixSetLimits?.TileMatrixLimits && Math.max(...tileMatrixSetLink?.TileMatrixSetLimits?.TileMatrixLimits.map(tml => Number(tml.TileMatrix?.includes(':') ? tml.TileMatrix.split(':')[1] : tml.TileMatrix) || 0));
                    if (!maxNativeZoom) maxNativeZoom = null;

                    availableLayers.push({
                        text: layer.Identifier.toString(), value: layer.Identifier.toString(), boundingBox, format: layer.Format || 'image/jpeg', style: layer.Style?.Identifier?.toString() || 'default',
                        tileMatrixSet: tileMatrixSetLink?.TileMatrixSet || 'PM', maxNativeZoom, legendURL: layer.Style?.LegendURL?.['_xlink:href']
                    });
                }
            };

            if (Array.isArray(layers)) layers.forEach(layer => extract(layer));
            else extract(layers);
        };

        this.setState(prevState => ({
            wmsService: {
                ...prevState.wmsService,
                url: prevState.wmsService.url.includes('?')
                    ? prevState.wmsService.url.slice(0, prevState.wmsService.url.lastIndexOf('?') + 1)
                    : prevState.wmsService.url + '?'
            }
        }), () => {
            this.setState({ isFetching: true });
            const isWMTS = this.state.wmsService.url.includes('/wmts?');
            WmsService.getCapabilities(this.state.wmsService.url, isWMTS).then(response => {
                if (!response) { addError(i18n.t("Le service renseigné ne répond pas")); return; }
                if (isWMTS ? !response?.Capabilities : !response?.WMS_Capabilities) { addError(i18n.t("Le service renseigné ne correspond pas à un sevice WMS")); return; }
                if (!isWMTS && !response?.WMS_Capabilities?.Capability?.Request?.GetMap?.Format?.includes('image/png')) {
                    addError(i18n.t("Le service renseigné ne supporte pas le format PNG")); return;
                }
                let layers = isWMTS ? response?.Capabilities?.Contents?.Layer : response?.WMS_Capabilities?.Capability?.Layer;
                if (!layers) { addError(i18n.t("Le service renseigné ne retourne aucune couche cartographique")); return; }
                extractLayers(layers, isWMTS);
                if (availableLayers.length < 1) { addError(i18n.t("Le service renseigné ne retourne aucune couche cartographique")); return; }

                this.setState({
                    isFetching: false,
                    isUrlValidated: true,
                    error: { hidden: true, url: false },
                    availableLayers
                }, () => { if (availableLayers.length === 1) this.handleLayerChange(null, { value: isWMTS ? availableLayers[0].value : [availableLayers[0].value] }) });
            });
        });
    }

    verifyProperties = () => {
        const { url, label, layers } = this.state.wmsService;

        let isValid = true;
        const error = {
            messages: [],
            label: false, category: false, property: false
        };

        const addError = (property, message) => {
            if (!isMobileOnly) {
                error.messages = [...(error.messages || []), message];
                error[property] = true;
            }
            isValid = false;
        };

        if (!this.state.isUrlValidated) addError('url', i18n.t("Veuillez tester l'URL à l'aide du bouton dédié"));
        else {
            if (!url.trim()) addError('url', i18n.t("L'URL ne peut être vide"));
            if (!label.trim()) addError('label', i18n.t("Le libellé ne peut être vide"));
            if (!layers.length) addError('layers', i18n.t("Veuillez sélectionner au moins une couche"));
        }

        if (!isValid) this.setState({ error: { hidden: error.messages.length > 0 ? false : true, ...error } });
        else this.setState({ error: initialError });

        return isValid;
    }

    handleSubmit = () => {
        const { isAdmin, defaultWmsServices } = this.props;
        const { wmsService, preview } = this.state;

        wmsService.projectId = !this.props.isAdmin ? this.props.project.id : null;
        wmsService.isGlobal = this.props.isAdmin;
        if (this.verifyProperties()) {
            this.setState({ isLoading: true });
            const formData = new FormData();
            formData.append('preview', preview);
            formData.append('wmsService', JSON.stringify(wmsService));

            const layer = wmsService.type === 'baseLayer'
                ? (wmsService.url.includes('/wmts?')
                    ? L.tileLayer.wmts(wmsService.url, { layer: wmsService.layers[0], maxZoom: 22, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom || undefined })
                    : L.tileLayer.wms(wmsService.url, { layers: wmsService.layers.join(','), maxZoom: 22, format: 'image/png' }))
                : (wmsService.url.includes('/wmts?')
                    ? L.tileLayer.wmts(wmsService.url, { layer: wmsService.layers[0], maxZoom: 22, pane: 'overlayPane', transparent: true, opacity: wmsService.opacity, format: wmsService.format, style: wmsService.style, tilematrixSet: wmsService.tileMatrixSet, maxNativeZoom: wmsService.maxNativeZoom || undefined })
                    : L.tileLayer.wms(wmsService.url, { layers: wmsService.layers.join(','), maxZoom: 22, pane: 'overlayPane', transparent: true, opacity: wmsService.opacity, format: 'image/png', }));

            if (wmsService.id) {
                WmsService.updateWmsService(formData).then(wmsService => {
                    if (wmsService) {
                        const project = { ...this.props.project };
                        const index = project.wmsServices.findIndex(tm => tm.id === wmsService.id);
                        if (index !== -1) {
                            project.wmsServices = [...project.wmsServices];
                            project.wmsServices[index] = wmsService;
                        }
                        this.props.setProject(project);
                        if (wmsService.type !== this.state.initialWmsService.type) {
                            if (wmsService.type === 'baseLayer') {
                                this.props.removeOverlayFromControlLayer(wmsService.id);
                                this.props.addBaseLayerToControlLayer(wmsService.label, layer, wmsService);
                            } else {
                                this.props.removeBaseLayerFromControlLayer(wmsService.id);
                                this.props.addOverlayToControlLayer(wmsService.label, { layer, customMap: wmsService });
                            }
                        }
                        else if (wmsService.type === 'baseLayer') this.props.updateBaseLayerInControlLayer(wmsService.id, wmsService.label, layer);
                        else this.props.updateOverlayInControlLayer(wmsService.label, layer, wmsService.id);
                    }
                    this.setState({ isLoading: false, initialWmsService: JSON.parse(JSON.stringify(this.state.wmsService)) });
                });
            } else WmsService.addWmsService(formData).then(wmsService => {
                if (isAdmin) {
                    this.setState({ isLoading: false, preview: null, wmsService: { ...initialWmsService, creatorId: jwtDecode(new Cookies().get('token')).id } });
                    if (defaultWmsServices) this.props.setDefaultWmsServices([...defaultWmsServices, wmsService]);
                } else {
                    if (wmsService) {
                        const project = { ...this.props.project };
                        if (!project.wmsServices) project.wmsServices = [];
                        project.wmsServices = [...project.wmsServices, wmsService];
                        this.props.setProject(project);
                        if (wmsService.type === 'baseLayer')
                            this.props.addBaseLayerToControlLayer(wmsService.label, layer, wmsService);
                        else this.props.addOverlayToControlLayer(wmsService.label, { layer, customMap: wmsService });
                    }
                    this.props.hideForm(true);
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        project: state.project,
        defaultWmsServices: state.defaultWmsServices,
        isDarkTheme: state.isDarkTheme
    };
};

const mapDispatchToProps = {
    setProject,
    setDefaultWmsServices
};

export default connect(mapStateToProps, mapDispatchToProps)(WmsServiceForm);