import React, { Component } from 'react';
import AppSettings from '../../../AppSettings';
// Composants
import { Button, Form, Grid, Input, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import { faCheck, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import Dropzone from 'dropzone';
import i18n from '../../../locales/i18n';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
// Redux
import { setActiveOrganization, setOrganizations } from '../../../actionCreators/usersActions';
// Services
import OrganizationsService from '../../../services/OrganizationsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';

class OrganizationForm extends Component {
    state = {
        organization: null,
        isLoading: false,
        dragEnter: false,
        logoPreview: null,
        error: {}
    }

    render() {
        const { isDarkTheme } = this.props;
        const { isLoading, error, dragEnter, organization } = this.state;
        const logoURL = this.getLogoUrl();

        const dropZoneStyle = {
            width: '100%', aspectRatio: '4 / 4', backgroundColor: logoURL ? 'black' : 'rgba(250,250,250,0.05)',
            border: !dragEnter ? '2px dashed var(--white-10)' : isDarkTheme ? '2px dashed white' : '2px dashed black', borderRadius: '20px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', overflow: 'hidden',
            marginBottom: isMobileOnly && '10px'
        };

        return (
            <>
                {organization && <Form loading={isLoading} onSubmit={this.handleSubmit} error>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={5} tablet={5} mobile={16}>
                                <div id='dropZone' title={i18n.t("Cliquez pour séléctionner un logo")} style={dropZoneStyle}>
                                    {logoURL ?
                                        <div style={{ width: '100%', height: '100%', background: `center / contain no-repeat url('${logoURL}')`, pointerEvents: 'none' }}>
                                        </div>
                                        :
                                        <div style={{ pointerEvents: 'none', textAlign: 'center' }}>
                                            <FontAwesomeIcon icon={faUpload} size='4x' style={{ margin: '0 5px' }} />
                                            <h4 style={{ textAlign: 'center', margin: '10px 5px 0px 5px' }}>{!dragEnter ? i18n.t("Glissez le logo ici") : i18n.t("Déposez le logo ici")}</h4>
                                        </div>}
                                </div>
                            </Grid.Column>
                            <Grid.Column computer={11} tablet={11} mobile={16}>
                                <Form.Field
                                    control={Input} label={`${i18n.t("Nom de l'organisation")}* :`} placeholder='Ex: Grality' name='label' value={organization.label || ''}
                                    error={!!error.label} onChange={this.handleChange} style={{ marginBottom: isMobileOnly && '5px' }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='left'>
                                <Message
                                    error style={{ textAlign: 'left' }} hidden={!Object.keys(error).length}
                                    header={i18n.t("Erreur")} list={Object.keys(error).map(property => error[property])}
                                />
                                <Button type='submit' color='green' className='form-button'>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>}
            </>
        );
    }

    componentDidMount = () => {
        Dropzone.autoDiscover = false;
        this.dropZone = null;
        this.setState({ organization: { ...this.props.activeOrganization } });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.activeOrganization && this.props.activeOrganization && JSON.stringify(prevProps.activeOrganization) !== JSON.stringify(this.props.activeOrganization))
            this.setState({ organization: { ...this.props.activeOrganization } });

        if (!this.dropZone) {
            this.dropZone = new Dropzone('div#dropZone', { previewsContainer: false, autoProcessQueue: false, url: '/', maxFiles: 1 });
            this.dropZone.on('addedfile', file => this.setState({ dragEnter: false }, () => this.uploadLogo(file)));
            this.dropZone.on('dragover', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.setState({ dragEnter: true });
            });
            this.dropZone.on('dragleave', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    this.setState({ dragEnter: false });
                }, 200);
            });
        }
    }

    handleChange = (_, { name, value }) => this.setState(prevState => ({ organization: { ...prevState.organization, [name]: value }, error: {} }));

    handleSubmit = () => {
        let { organization, logoPreview } = this.state;
        const error = {};

        if (!organization.label?.length) error.label = i18n.t("Le libellé ne peut pas être vide");

        if (!Object.keys(error).length) {
            const formData = new FormData();
            formData.append('file', logoPreview);
            formData.append('organization', JSON.stringify(organization));
            this.setState({ isLoading: true });
            OrganizationsService.updateOrganization(organization.id, formData).then(response => {
                this.setState({ isLoading: false });
                if (response) {
                    const { label, logoName } = response;
                    const organizations = [...this.props.organizations];
                    let updatedOrganization = null;
                    const index = organizations.findIndex(o => o.id === organization.id);
                    if (index !== -1) {
                        organizations[index] = { ...organizations[index], label, logoName };
                        updatedOrganization = organizations[index];
                        this.props.setOrganizations(organizations);
                    }
                    this.props.setActiveOrganization({ ...this.props.activeOrganization, label, logoName });

                    if (updatedOrganization) WebSocketUtil.updateOrganization(this.props.webSocketHubs, this.props.activeOrganization.id, updatedOrganization);
                }
            });
        } else this.setState({ error });
    }

    uploadLogo = (file) => {
        if (file['type'].split('/')[0] !== 'image') showToast('file_format_not_supported');
        else this.setState({ logoPreview: file });
    }

    getLogoUrl = () => {
        const { logoPreview, organization } = this.state;
        const blobInfos = AppSettings.getBlobInfos();
        return logoPreview ? URL.createObjectURL(logoPreview) : organization?.logoName ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${organization?.logoName}` : null;
    }
}

const mapStateToProps = (state) => ({
    activeOrganization: state.activeOrganization,
    organizations: state.organizations,
    webSocketHubs: state.webSocketHubs
});

const mapDispatchToProps = {
    setActiveOrganization,
    setOrganizations
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);