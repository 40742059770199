import React, { Component } from 'react';
// Composants
import { faFaceConfused, faFileAlt, faFlowerTulip, faSackDollar, faTablePicnic, faTree, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';

class StatisticsSidebar extends Component {
    state = {
        activeItem: this.props.activeItem
    };

    render() {
        const { activeItem } = this.state;
        const itemStyle = { textAlign: 'left' };
        const iconStyle = { fontSize: '20px', width: '20px', marginRight: '5px' };
        const labelStyle = { fontSize: '18px' };

        return (
            <Menu fluid vertical tabular style={{ borderRadius: '5px 0 0 5px', height: '100%' }}>
                <Menu.Item name='trees' active={activeItem === 'trees'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faTree} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Arbres")}</span>
                </Menu.Item>
                <Menu.Item name='greenSpaces' active={activeItem === 'greenSpaces'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faFlowerTulip} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Espaces verts")}</span>
                </Menu.Item>
                <Menu.Item name='furnitures' active={activeItem === 'furnitures'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faTablePicnic} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Mobiliers")}</span>
                </Menu.Item>
                <Menu.Item name='users' active={activeItem === 'users'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faUser} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Utilisateurs")}</span>
                </Menu.Item>
                <Menu.Item name='projects' active={activeItem === 'projects'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faFileAlt} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Projets")}</span>
                </Menu.Item>
                <Menu.Item name='errors' active={activeItem === 'errors'} style={itemStyle} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faFaceConfused} style={iconStyle} /> <span style={labelStyle}>{i18n.t("Erreurs")}</span>
                </Menu.Item>
            </Menu>
        );
    }

    handleClick = (_, { name }) => this.setState({ activeItem: name }, () => this.props.setActiveItem(name));
}

export default StatisticsSidebar;