import React, { Component } from 'react';
// Librairies
import { connect } from 'react-redux';
import { setWebSocketHubs } from '../../actionCreators/appActions';
import { setProject, setProjects, setProjectCollaborators, setUserProjects, setRootBaseProjectIds } from '../../actionCreators/projectsActions';
import { setPhotosGalleries, setFilesGalleries } from '../../actionCreators/elementsActions';
import { setFilterLists, setCustomCharts } from '../../actionCreators/componentsActions';
import { setActiveOrganization, setCustomFieldCategories, setOrganizationCustomFields, setOrganizations, setPriceLists, setThemes } from '../../actionCreators/usersActions';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
// Utils
import WebSocketUtil from '../../utils/WebSocketUtil';
import FileInfosUtil from '../../utils/FileInfosUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';

class GlobalWebSocket extends Component {
    render() {
        return (<></>);
    }

    componentDidMount = () => {
        this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators || []);
        this.startHubs();

        window.addEventListener('online', () => this.startHubs());
        window.addEventListener('offline', () => this.stopHubs());
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectCollaborators !== this.props.projectCollaborators)
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        this.startHubs();
        if (prevProps.project !== this.props.project) {
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            if (prevProps.project) {
                if (this.projectsHub?.state === 'Connected' && !ProjectsUtil.isUserInProject(prevProps.projectCollaborators))
                    this.projectsHub.invoke('RemoveFromGroup', prevProps.project.id.toString());
                if (this.fileInfosHub?.state === 'Connected' && !ProjectsUtil.isUserInProject(prevProps.projectCollaborators))
                    this.fileInfosHub.invoke('RemoveFromGroup', prevProps.project.id.toString());
            }
            if (this.props.project) {
                if (this.projectsHub?.state === 'Connected' && !ProjectsUtil.isUserInProject(this.props.projectCollaborators))
                    this.projectsHub.invoke('JoinGroup', this.props.project.id.toString());
                if (this.fileInfosHub?.state === 'Connected' && !ProjectsUtil.isUserInProject(this.props.projectCollaborators))
                    this.fileInfosHub.invoke('JoinGroup', this.props.project.id.toString());
            }
        }

        const userId = new Cookies().get('token') ? jwtDecode(new Cookies().get('token'))?.id : null;
        if (!this.usersHub && userId) this.handleUsersHub();
    }

    componentWillUnmount = () => this.stopHubs();

    startHubs = () => {
        const userId = new Cookies().get('token') ? jwtDecode(new Cookies().get('token'))?.id : null;
        if (!this.organizationsHub && userId) this.handleOrganizationsHub();
        if (!this.projectsHub && userId && (this.props.project || this.props.projects?.length)) this.handleProjectsHub();
        if (!this.fileInfosHub && userId && (this.publicFields?.main.photos || this.publicFields?.main.files) && (this.props.project || this.props.projects?.length))
            this.handleFileInfosHub();
        if (!this.formsHub) {
            this.formsHub = WebSocketUtil.createConnection('forms');
            if (this.formsHub) this.props.setWebSocketHubs({ ...this.props.webSocketHubs, formsHub: this.formsHub });
        }
        if (!this.usersHub && userId) this.handleUsersHub();
    }

    stopHubs = () => {
        if (this.organizationsHub) this.organizationsHub.stop().then(() => this.organizationsHub = null);
        if (this.projectsHub) this.projectsHub.stop().then(() => this.projectsHub = null);
        if (this.fileInfosHub) this.fileInfosHub.stop().then(() => this.fileInfosHub = null);
        if (this.formsHub) this.formsHub.stop().then(() => this.formsHub = null);
        if (this.usersHub) this.usersHub.stop().then(() => this.usersHub = null);
    }

    // TODO Gérer les thèmes, champs personnalisés et prix personnalisés
    handleOrganizationsHub = () => {
        this.organizationsHub = WebSocketUtil.createConnection('organizations');
        const handleHub = () => {
            const userId = new Cookies().get('token') ? jwtDecode(new Cookies().get('token'))?.id : null;
            this.props.setWebSocketHubs({ ...this.props.webSocketHubs, organizationsHub: this.organizationsHub });

            // Invokers
            this.organizationsHub.invoke('JoinGroup', userId);
            this.props.organizations.forEach(o => {
                this.organizationsHub.invoke('JoinGroup', o.id.toString());
            });

            // Events
            this.organizationsHub.on('SendOrganization', organization => {
                const organizationParsed = JSON.parse(organization);
                this.props.setOrganizations([...this.props.organizations, organizationParsed]);
                this.organizationsHub.invoke('JoinGroup', organizationParsed.id.toString());
            });

            this.organizationsHub.on('UpdateOrganization', organization => {
                const organizationParsed = JSON.parse(organization);
                const organizations = JSON.parse(JSON.stringify(this.props.organizations));
                const index = organizations.findIndex(o => o.id === organizationParsed.id);
                if (index !== -1) {
                    organizations[index] = organizationParsed
                    this.props.setOrganizations(organizations);
                }

                if (organizationParsed.id === this.props.activeOrganization.id)
                    this.props.setActiveOrganization({ ...this.props.activeOrganization, ...organizationParsed });
            });

            this.organizationsHub.on('RemoveOrganization', organizationId => {
                const organizationIdParsed = Number(organizationId);
                if (organizationIdParsed === this.props.activeOrganization.id) {
                    // TODO Que fait-on ?
                } else this.props.setOrganizations(this.props.organizations.filter(o => o.id !== organizationIdParsed));
                this.organizationsHub.invoke('RemoveFromGroup', organizationId);
            });

            this.organizationsHub.on('SendUserOrganizations', userOrganizations => {
                const userOrganizationsParsed = JSON.parse(userOrganizations);
                const { activeOrganization } = this.props;
                if (userOrganizationsParsed.length && userOrganizationsParsed[0].organizationId === activeOrganization.id)
                    this.props.setActiveOrganization({
                        ...activeOrganization,
                        linkedUsers: [...activeOrganization.linkedUsers, ...userOrganizationsParsed],
                        userOrganizations: [...activeOrganization.userOrganizations, ...userOrganizationsParsed]
                    });
            });

            this.organizationsHub.on('RemoveUserOrganizations', userOrganizationIds => {
                const userOrganizationIdsParsed = JSON.parse(userOrganizationIds);
                const { activeOrganization } = this.props;

                if (userOrganizationIdsParsed.length) {
                    this.props.setActiveOrganization({
                        ...activeOrganization,
                        linkedUsers: activeOrganization.linkedUsers.filter(lu => !userOrganizationIdsParsed.includes(lu.id)),
                        userOrganizations: activeOrganization.userOrganizations.filter(uo => !userOrganizationIdsParsed.includes(uo.userId))
                    });
                }
            });

            this.organizationsHub.on('SendOrganizationInvitations', invitations => {
                const invitationsParsed = JSON.parse(invitations);
                const { activeOrganization } = this.props;
                if (invitationsParsed.length && invitationsParsed[0].organizationId === activeOrganization.id)
                    this.props.setActiveOrganization({
                        ...activeOrganization,
                        invitations: [...activeOrganization.invitations, ...invitationsParsed],
                    });
            });

            this.organizationsHub.on('RemoveOrganizationInvitations', invitationIds => {
                const invitationIdsParsed = JSON.parse(invitationIds);
                const { activeOrganization } = this.props;
                if (invitationIdsParsed.length)
                    this.props.setActiveOrganization({
                        ...activeOrganization,
                        invitations: activeOrganization.invitations.filter(i => !invitationIdsParsed.includes(i.id)),
                    });
            });

            this.organizationsHub.on('SendOrganizationThemes', themes => {
                const themesParsed = JSON.parse(themes);
                const { activeOrganization } = this.props;
                if (themesParsed.length && themesParsed[0].organizationId === activeOrganization.id)
                    this.props.setThemes([...this.props.themes, ...themesParsed]);
            });

            this.organizationsHub.on('UpdateOrganizationThemes', themes => {
                const themesParsed = JSON.parse(themes);
                const { activeOrganization } = this.props;
                if (themesParsed.length && themesParsed[0].organizationId === activeOrganization.id) {
                    const updatedThemes = JSON.parse(JSON.stringify(this.props.themes));

                    themesParsed.forEach(theme => {
                        const index = updatedThemes.findIndex(tis => tis.id === theme.id);
                        if (index !== -1) updatedThemes[index] = theme;
                    });

                    this.props.setThemes(updatedThemes);
                }
            });

            this.organizationsHub.on('RemoveOrganizationThemes', themeIds => {
                const themeIdsParsed = JSON.parse(themeIds);
                this.props.setThemes(this.props.themes.filter(t => !themeIdsParsed.includes(t.id)));
            });

            this.organizationsHub.on('SendOrganizationPriceLists', priceLists => {
                const priceListsParsed = JSON.parse(priceLists);
                const { activeOrganization } = this.props;
                if (priceListsParsed.length && priceListsParsed[0].organizationId === activeOrganization.id)
                    this.props.setPriceLists([...this.props.priceLists, ...priceListsParsed]);
            });

            this.organizationsHub.on('UpdateOrganizationPriceLists', priceLists => {
                const priceListsParsed = JSON.parse(priceLists);
                const { activeOrganization } = this.props;
                if (priceListsParsed.length && priceListsParsed[0].organizationId === activeOrganization.id) {
                    const updatedPriceLists = JSON.parse(JSON.stringify(this.props.priceLists));

                    priceListsParsed.forEach(priceList => {
                        const index = updatedPriceLists.findIndex(upl => upl.id === priceList.id);
                        if (index !== -1) updatedPriceLists[index] = priceList;
                    });

                    this.props.setPriceLists(updatedPriceLists);
                }
            });

            this.organizationsHub.on('RemoveOrganizationPriceLists', priceListIds => {
                const priceListIdsParsed = JSON.parse(priceListIds);
                this.props.setPriceLists(this.props.priceLists.filter(pl => !priceListIdsParsed.includes(pl.id)));
            });

            this.organizationsHub.on('SendOrganizationCustomFields', customFields => {
                const customFieldsParsed = JSON.parse(customFields);
                const { activeOrganization } = this.props;
                if (customFieldsParsed.length && customFieldsParsed[0].organizationId === activeOrganization.id)
                    this.props.setOrganizationCustomFields([...this.props.organizationCustomFields, ...customFieldsParsed]);
            });

            this.organizationsHub.on('UpdateOrganizationCustomFields', customFields => {
                const customFieldsParsed = JSON.parse(customFields);
                const { activeOrganization } = this.props;
                if (customFieldsParsed.length && customFieldsParsed[0].organizationId === activeOrganization.id) {
                    const updatedCustomFields = JSON.parse(JSON.stringify(this.props.organizationCustomFields));

                    customFieldsParsed.forEach(customField => {
                        const index = updatedCustomFields.findIndex(ucf => ucf.id === customField.id);
                        if (index !== -1) updatedCustomFields[index] = customField;
                    });

                    this.props.setOrganizationCustomFields(updatedCustomFields);
                }
            });

            this.organizationsHub.on('RemoveOrganizationCustomFields', customFieldIds => {
                const customFieldIdsParsed = JSON.parse(customFieldIds);
                this.props.setOrganizationCustomFields(this.props.organizationCustomFields.filter(ocf => !customFieldIdsParsed.includes(ocf.id)));
            });

            this.organizationsHub.on('SendOrganizationCustomFieldCategories', customFieldCategories => {
                const customFieldCategoriesParsed = JSON.parse(customFieldCategories);
                const { activeOrganization } = this.props;
                if (customFieldCategoriesParsed.length && customFieldCategoriesParsed[0].organizationId === activeOrganization.id)
                    this.props.setCustomFieldCategories([...this.props.customFieldCategories, ...customFieldCategoriesParsed]);
            });

            this.organizationsHub.on('UpdateOrganizationCustomFieldCategories', customFieldCategories => {
                const customFieldCategoriesParsed = JSON.parse(customFieldCategories);
                const { activeOrganization } = this.props;

                if (customFieldCategoriesParsed.length && customFieldCategoriesParsed[0].organizationId === activeOrganization.id) {
                    const updatedCustomFieldCategories = JSON.parse(JSON.stringify(this.props.customFieldCategories));
                    customFieldCategoriesParsed.forEach(customFieldCategory => {
                        const index = updatedCustomFieldCategories.findIndex(ucfc => ucfc.id === customFieldCategory.id);
                        if (index !== -1) updatedCustomFieldCategories[index] = customFieldCategory;
                    });

                    this.props.setCustomFieldCategories(updatedCustomFieldCategories);
                }
            });

            this.organizationsHub.on('RemoveOrganizationCustomFieldCategories', customFieldCategoryIds => {
                const customFieldCategoryIdsParsed = JSON.parse(customFieldCategoryIds);
                this.props.setCustomFieldCategories(this.props.customFieldCategories.filter(cfc => !customFieldCategoryIdsParsed.includes(cfc.id)));
            });
        };

        this.organizationsHub.start().then(handleHub);
        this.organizationsHub.onreconnected(handleHub);
    }

    handleProjectsHub = () => {
        this.projectsHub = WebSocketUtil.createConnection('projects');
        const handleHub = () => {
            const isUserInProject = ProjectsUtil.isUserInProject(this.props.projectCollaborators);
            const userId = new Cookies().get('token') ? jwtDecode(new Cookies().get('token'))?.id : null;
            this.props.setWebSocketHubs({ ...this.props.webSocketHubs, projectsHub: this.projectsHub });

            // Invokers
            if (this.props.project) this.projectsHub.invoke('JoinGroup', this.props.project.id.toString());
            this.projectsHub.invoke('JoinGroup', userId);
            if (isUserInProject && this.props.project?.projectLogs?.find(x => !x.status)) {
                const projectLogIds = this.props.project.projectLogs.filter(x => !x.status).map(x => x.id);
                this.projectsHub.invoke('UpdateProjectLogs', 'imports', projectLogIds).then(projectLogs => {
                    projectLogs.forEach(projectLog => this.addOrUpdateProjectLog(projectLog));
                });
            }

            // Events
            if (isUserInProject) {
                this.projectsHub.on('SendProjectLogs', projectLogs => this.addOrUpdateProjectLog(JSON.parse(projectLogs)));
                this.projectsHub.on('UpdateProjectLog', projectLog => this.addOrUpdateProjectLog([JSON.parse(projectLog)]));
            }

            this.projectsHub.on('SendProject', baseProject => {
                const baseProjectParsed = JSON.parse(baseProject);
                let baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                let isRootBaseProject = !baseProjectParsed.parentFolderId || !baseProjects.find(bp => bp.id === baseProjectParsed.parentFolderId);
                this.props.setProjects([...baseProjects, baseProjectParsed]);
                if (isRootBaseProject) this.props.setRootBaseProjectIds([...this.props.rootBaseProjectIds, baseProjectParsed.id]);
                if (this.props.project && this.props.project.id === baseProjectParsed.id)
                    this.props.webSocketHubs.elementsHub.invoke('GetLockedElements', this.props.project.id.toString());
            });

            this.projectsHub.on('UpdateProject', project => {
                const projectParsed = JSON.parse(project);

                ProjectsUtil.updateProjectsInProps(projectParsed, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
                if (this.props.project?.id === projectParsed.id) {
                    if (this.props.projectCollaborators) {
                        const userBaseProjects = projectParsed.userBaseProjects;
                        this.props.setProjectCollaborators([...this.props.projectCollaborators.filter(pc => pc.baseProjectId !== userBaseProjects[0].baseProjectId), ...userBaseProjects]);
                    }
                }
            });

            this.projectsHub.on('MoveProjects', (projects) => {
                const projectsParsed = JSON.parse(projects);
                const projectIds = projectsParsed.map(p => p.id);
                let baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                let rootBaseProjectIds = [...this.props.rootBaseProjectIds];

                // On supprime les anciens projets
                baseProjects = baseProjects.filter(bp => !projectIds.includes(bp.id));
                rootBaseProjectIds = rootBaseProjectIds.filter(id => !projectIds.includes(id));

                // On ajoute les projets à jour
                baseProjects = [...baseProjects, ...projectsParsed];
                const newRootBaseProjectIds = projectsParsed.filter(p => !p.parentFolderId || !baseProjects.find(bp => bp.id === p.parentFolderId)).map(p => p.id);
                if (!newRootBaseProjectIds.length) rootBaseProjectIds = [...rootBaseProjectIds, ...newRootBaseProjectIds];

                this.props.setProjects(baseProjects);
                this.props.setRootBaseProjectIds(rootBaseProjectIds);
            });

            this.projectsHub.on('RemoveProject', (baseProjectId, path) => {
                const baseProjectIdParsed = Number(baseProjectId);
                let baseProjects = JSON.parse(JSON.stringify(this.props.projects));
                this.props.setProjects(baseProjects.filter(bp => bp.id !== baseProjectIdParsed));
                this.props.setRootBaseProjectIds(this.props.rootBaseProjectIds.filter(id => id !== baseProjectIdParsed));

                if (this.props.project?.id === Number(baseProjectId)) {
                    let project = JSON.parse(JSON.stringify(this.props.project));
                    if (!project.isPublic) this.props.setProject(null);
                    else {
                        project.userBaseProjects = project.userBaseProjects.filter(up => up.userId !== userId);
                        this.props.setProject(project);
                        this.props.setProjectCollaborators(this.props.projectCollaborators.filter(pc => pc.userId !== userId))
                    }
                }
            });

            this.projectsHub.on('UpdateUserBaseProjects', (userBaseProjects, path) => {
                const userBaseProjectsParsed = JSON.parse(userBaseProjects);
                let project = JSON.parse(JSON.stringify(this.props.project));
                let projects = JSON.parse(JSON.stringify(this.props.projects));

                // Projet ouvert
                if (project?.id === userBaseProjectsParsed[0].baseProjectId) {
                    if (this.props.projectCollaborators)
                        this.props.setProjectCollaborators([...this.props.projectCollaborators.filter(pc => pc.baseProjectId !== userBaseProjectsParsed[0].baseProjectId), ...userBaseProjectsParsed]);
                    project.userBaseProjects = [...project.userBaseProjects?.filter(ubp => ubp.baseProjectId !== userBaseProjectsParsed[0].baseProjectId), ...userBaseProjectsParsed];
                    this.props.setProject(project);
                }

                // Liste de projets
                let baseProject = ProjectsUtil.getBaseProject(userBaseProjectsParsed[0].baseProjectId, projects);
                if (baseProject) {
                    baseProject.userBaseProjects = [...baseProject.userBaseProjects?.filter(ubp => ubp.baseProjectId !== userBaseProjectsParsed[0].baseProjectId), ...userBaseProjectsParsed];
                    this.props.setProjects(projects);
                }

                // Éléments locked
                const lockedElements = JSON.parse(JSON.stringify(this.props.lockedElements));
                if (lockedElements.find(lep => !userBaseProjectsParsed.find(upp => upp.user.id === lep.userId)))
                    this.props.setLockedElements(lockedElements.filter(lep => userBaseProjectsParsed.find(upp => upp.user.id === lep.userId)));
            });

            this.projectsHub.on('UpdateProjectRoles', (projectRoles, path) => {
                const projectRolesParsed = JSON.parse(projectRoles);
                let project = JSON.parse(JSON.stringify(this.props.project));
                let projects = JSON.parse(JSON.stringify(this.props.projects));
                const defaultRole = project.projectRoles.find(pr => pr.type === 'default');

                // Projet ouvert
                if (project?.id === projectRolesParsed[0].baseProjectId) {
                    project.projectRoles = JSON.parse(JSON.stringify(projectRolesParsed));
                    this.props.setProject(project);

                    const userBaseProjects = project.userBaseProjects.map(ubp => ({ ...ubp, projectRole: project.projectRoles.find(prp => prp.id === ubp.projectRoleId) || defaultRole }));
                    const { projectCollaborators } = this.props;
                    this.props.setProjectCollaborators([...projectCollaborators.filter(pc => !userBaseProjects.find(ubp => ubp.userId === pc.userId)), ...userBaseProjects]);
                }

                // Liste de projets
                let baseProject = ProjectsUtil.getBaseProject(projectRolesParsed[0].baseProjectId, projects);
                if (baseProject) {
                    baseProject.projectRoles = JSON.parse(JSON.stringify(projectRolesParsed));
                    baseProject.userBaseProjects.forEach(ubp => {
                        const projectRole = projectRolesParsed.find(prp => prp.id === ubp.projectRoleId);
                        ubp.projectRoleId = projectRole?.id || defaultRole.id;
                        ubp.projectRole = projectRole || defaultRole;
                    });
                    this.props.setProjects(projects);
                }
            });

            this.projectsHub.on('SendTags', tags => {
                if (this.props.project) {
                    let project = JSON.parse(JSON.stringify(this.props.project));
                    project.tags = [...project.tags || [], ...JSON.parse(tags)];
                    this.props.setProject(project);
                } else if (this.props.projects?.length) {
                    let projects = JSON.parse(JSON.stringify(this.props.projects));
                    const index = projects.findIndex(p => p.id === tags[0].projectId);
                    if (index !== -1) {
                        projects[index].tags = [...projects[index].tags || [], ...JSON.parse(tags)];
                        this.props.setProjects(projects);
                    }
                }
            });

            this.projectsHub.on('SendFilter', filter => {
                const filterParsed = JSON.parse(filter);
                let filterLists = JSON.parse(JSON.stringify(this.props.filterLists));
                if (filterLists && filterParsed && !filterLists[filterParsed.projectId].find(filter => filter.id === filterParsed.id)) {
                    filterLists[filterParsed.projectId] = [...filterLists[filterParsed.projectId], filterParsed];
                    this.props.setFilterLists(filterLists);
                }
            });

            this.projectsHub.on('RemoveFilter', (projectId, userId, filterId) => {
                let filterLists = JSON.parse(JSON.stringify(this.props.filterLists));
                if (filterLists?.[Number(projectId)]) {
                    filterLists[Number(projectId)] = filterLists[Number(projectId)].filter(f => f.id !== Number(filterId));
                    this.props.setFilterLists(filterLists);
                }
            });

            this.projectsHub.on('SendCustomChart', customChart => {
                const customChartParsed = JSON.parse(customChart);
                let customCharts = JSON.parse(JSON.stringify(this.props.customCharts));
                if (customCharts && customChartParsed && !customCharts[customChartParsed.projectId].find(cc => !cc.find(c => c.id === customChartParsed.id))) {
                    customCharts[customChartParsed.projectId] = [...customCharts[customChartParsed.projectId], customChartParsed];
                    this.props.setCustomCharts(customCharts);
                }
            });

            this.projectsHub.on('RemoveCustomChart', (projectId, userId, customChartId) => {
                let customCharts = JSON.parse(JSON.stringify(this.props.customCharts));
                if (customCharts?.[Number(projectId)]) {
                    customCharts[Number(projectId)] = customCharts[Number(projectId)].filter(c => c.id !== Number(customChartId));
                    this.props.setCustomCharts(customCharts);
                }
            });
        };

        this.projectsHub.start().then(handleHub);
        this.projectsHub.onreconnected(handleHub);
    }

    addOrUpdateProjectLog = (projectLogs) => {
        let project = JSON.parse(JSON.stringify(this.props.project));
        projectLogs.forEach(projectLog => {
            const projectLogInLocal = project.projectLogs.find(x => x.id === projectLog.id);
            if (!projectLogInLocal) project.projectLogs.push(projectLog);
            else if (!projectLogInLocal.status && !projectLogInLocal.Status) project.projectLogs[project.projectLogs.indexOf(projectLogInLocal)] = projectLog;
        });
        this.props.setProject(project);
    }

    handleFileInfosHub = () => {
        this.fileInfosHub = WebSocketUtil.createConnection('fileInfos');
        const handleHub = () => {
            const isUserInProject = ProjectsUtil.isUserInProject(this.props.projectCollaborators);
            const userId = new Cookies().get('token') ? jwtDecode(new Cookies().get('token'))?.id : null;
            this.props.setWebSocketHubs({ ...this.props.webSocketHubs, fileInfosHub: this.fileInfosHub });

            // Invokers
            if (this.props.project) this.fileInfosHub.invoke('JoinGroup', this.props.project.id.toString());
            if (isUserInProject) this.fileInfosHub.invoke('JoinGroup', userId);

            // Events
            this.fileInfosHub.on('SendFileInfos', this.handleSendFileInfos);
            this.fileInfosHub.on('SendFileInfosToUsers', this.handleSendFileInfos);
            this.fileInfosHub.on('UpdateFileInfos', this.handleUpdateFileInfos);
            this.fileInfosHub.on('UpdateFileInfosToUsers', this.handleUpdateFileInfos);
            this.fileInfosHub.on('RemoveFileInfos', this.handleRemoveFileInfos);
            this.fileInfosHub.on('RemoveFileInfosToUsers', this.handleRemoveFileInfos);
        };

        this.fileInfosHub.start().then(handleHub);
        this.fileInfosHub.onreconnected(handleHub);
    }

    handleUsersHub = () => {
        this.usersHub = WebSocketUtil.createConnection('users');
        const handleHub = () => {
            this.props.setWebSocketHubs({ ...this.props.webSocketHubs, usersHub: this.usersHub });
        };

        this.usersHub.start().then(handleHub);
        this.usersHub.onreconnected(handleHub);
    }

    handleSendFileInfos = (fileInfos) => {
        const fileInfosParsed = JSON.parse(fileInfos);
        const type = fileInfosParsed[0].type;

        if (this.props.project && type === 'photo') {
            let photosGalleries = [...this.props.photosGalleries];
            fileInfosParsed.forEach(fileInfoParsed => {
                const index = photosGalleries.findIndex(x => x.blobName === fileInfoParsed.blobName || x.id === fileInfoParsed.id);
                if (index === -1) {
                    fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                    photosGalleries.push(fileInfoParsed);
                }
            });
            this.props.setPhotosGalleries(photosGalleries);
        } else if (this.props.project && fileInfosParsed[0].elementId) {
            let filesGalleries = [...this.props.filesGalleries];
            fileInfosParsed.forEach(fileInfoParsed => {
                const index = filesGalleries.findIndex(x => x.blobName === fileInfoParsed.blobName || x.id === fileInfoParsed.id);
                if (index === -1) {
                    fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                    filesGalleries.push(fileInfoParsed);
                }
            });
            this.props.setFilesGalleries(filesGalleries);
        } else if (!fileInfosParsed[0].elementId) {
            if (this.props.project) {
                let project = JSON.parse(JSON.stringify(this.props.project));
                project.fileInfos = project.fileInfos?.length ? [...project.fileInfos, ...fileInfosParsed] : fileInfosParsed;
                this.props.setProject(project);
            }

            if (this.props.projects?.length) {
                let projects = JSON.parse(JSON.stringify(this.props.projects));
                const index = projects.findIndex(x => x.id === fileInfosParsed[0].projectId);
                if (index !== -1) projects[index].fileInfos = [...projects[index].fileInfos || [], ...fileInfosParsed];
                this.props.setProjects(projects);
            }
        }
    }

    handleUpdateFileInfos = (fileInfos) => {
        const fileInfosParsed = JSON.parse(fileInfos);
        const type = fileInfosParsed[0].type;

        if (this.props.project && type === 'photo') {
            let photosGalleries = [...this.props.photosGalleries];
            fileInfosParsed.forEach(fileInfoParsed => {
                const index = photosGalleries.findIndex(x => x.id === fileInfoParsed.id);
                if (index !== -1) {
                    fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                    photosGalleries[index] = fileInfoParsed;
                }
            });
            this.props.setPhotosGalleries(photosGalleries);
        } else if (this.props.project && fileInfosParsed[0].elementId) {
            let filesGalleries = [...this.props.filesGalleries];
            fileInfosParsed.forEach(fileInfoParsed => {
                const index = filesGalleries.findIndex(x => x.id === fileInfoParsed.id);
                if (index !== -1) {
                    fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                    filesGalleries[index] = fileInfoParsed;
                }
            });
            this.props.setFilesGalleries(filesGalleries);
        } else if (!fileInfosParsed[0].elementId) {
            if (this.props.project) {
                let project = JSON.parse(JSON.stringify(this.props.project));
                fileInfosParsed.forEach(fileInfoParsed => {
                    const index = project.fileInfos.findIndex(x => x.id === fileInfoParsed.id);
                    if (index !== -1) {
                        fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                        project.fileInfos[index] = fileInfoParsed;
                    }
                });
                this.props.setProject(project);
            }

            if (this.props.projects?.length) {
                let projects = JSON.parse(JSON.stringify(this.props.projects));
                const index = projects.findIndex(x => x.id === fileInfosParsed[0].projectId);
                if (index !== -1)
                    fileInfosParsed.forEach(fileInfoParsed => {
                        const fiIndex = projects[index].fileInfos.findIndex(x => x.id === fileInfoParsed.id);
                        if (fiIndex !== -1) {
                            fileInfoParsed.url = FileInfosUtil.getUrl(fileInfoParsed);
                            projects[fiIndex].fileInfos[fiIndex] = fileInfoParsed;
                        }
                    });
                this.props.setProjects(projects);
            }
        }
    }

    handleRemoveFileInfos = (ids) => {
        const idsParsed = JSON.parse(ids);
        if (this.props.project && this.props.photosGalleries && this.props.photosGalleries.find(x => idsParsed.includes(x.id)))
            this.props.setPhotosGalleries(this.props.photosGalleries.filter(x => !idsParsed.includes(x.id)));
        else if (this.props.project && this.props.filesGalleries && this.props.filesGalleries.find(x => idsParsed.includes(x.id)))
            this.props.setFilesGalleries(this.props.filesGalleries.filter(x => !idsParsed.includes(x.id)));
        else {
            if (this.props.project) {
                let project = JSON.parse(JSON.stringify(this.props.project));
                project.fileInfos = project.fileInfos.filter(x => !idsParsed.includes(x.id));
                this.props.setProject(project);
            }

            if (this.props.projects?.length) {
                let projects = JSON.parse(JSON.stringify(this.props.projects));
                const index = projects.findIndex(x => x.fileInfos && x.fileInfos.find(fi => idsParsed.includes(fi.id)));
                if (index !== -1) {
                    projects[index].fileInfos = projects[index].fileInfos.filter(x => !idsParsed.includes(x.id));
                }
                this.props.setProjects(projects);
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        projects: state.projects,
        rootBaseProjectIds: state.rootBaseProjectIds,
        projectCollaborators: state.projectCollaborators,
        filterLists: state.filterLists,
        customCharts: state.customCharts,
        organizationCustomFields: state.organizationCustomFields,
        customFieldCategories: state.customFieldCategories,
        themes: state.themes,
        priceLists: state.priceLists,
        photosGalleries: state.photosGalleries,
        filesGalleries: state.filesGalleries,
        formulas: state.formulas,
        userProjects: state.userProjects,
        activeOrganization: state.activeOrganization,
        organizations: state.organizations,
        webSocketHubs: state.webSocketHubs,
    };
};

const mapDispatchToProps = {
    setProject,
    setProjects,
    setRootBaseProjectIds,
    setPhotosGalleries,
    setFilesGalleries,
    setFilterLists,
    setCustomCharts,
    setOrganizationCustomFields,
    setCustomFieldCategories,
    setThemes,
    setPriceLists,
    setWebSocketHubs,
    setProjectCollaborators,
    setUserProjects,
    setActiveOrganization,
    setOrganizations,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalWebSocket);