import React, { Component } from 'react';
// Librairies
import L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../locales/i18n';
import { length as lineLength, lineString, point, polygon, intersect, transformTranslate, featureCollection } from '@turf/turf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faFlowerTulip, faLink, faLinkSlash, faPencil, faTablePicnic, faTimes, faTrash, faTree } from '@fortawesome/pro-solid-svg-icons';
import { faEdit as faEditOutline } from '@fortawesome/pro-regular-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import LeafletGeometriesUtil from 'leaflet-geometryutil';
// Redux
import { connect } from 'react-redux';
import { setLayer } from '../../../actionCreators/elementsActions';
// Semantic UI
import { Button, Form, Input, Select } from 'semantic-ui-react';
// Services
import TreesService from '../../../services/TreesService';
import GreenSpacesService from '../../../services/GreenSpacesService';
import FurnituresService from '../../../services/FurnituresService';
// Utils
import GeometriesUtil from '../../../utils/GeometriesUtil';
import StylesUtil from '../../../utils/StylesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';

const NB_MAX_CLONES = 500;
const initialError = {
    hidden: true,
    messages: [],
    angle: false,
    distance: false,
    gap: false,
    nbElements: false
}

class DuplicateForm extends Component {
    state = {
        isLoading: false,
        areInside: false,
        areSuperimposed: false,
        layer: null,
        duplicatedElements: [],
        invalidElementsId: [],
        dimensions: {
            angle: 0,
            direction: 0,
            distance: 0,
            gap: 0,
            nbElements: 0
        },
        placementLine: null,
        options: {
            editLine: false,
            startLineOnElement: false
        },
        isLocked: false,
        error: initialError
    }

    render() {
        const { layer, isToolbarExpanded } = this.props;
        const { isLoading, placementLine, duplicatedElements, invalidElementsId, dimensions, isLocked, error } = this.state;
        const { editLine, startLineOnElement } = this.state.options;
        const category = this.state.layer?.feature?.properties.category;
        const nbValidElements = duplicatedElements.length - invalidElementsId.length;
        const nbInvalidElements = invalidElementsId.length;

        return (
            <>
                {category && !isLocked &&
                    <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                        <Form loading={isLoading} error style={{ width: '100%' }}>
                            <div style={{ overflow: 'auto', flexGrow: 1 }}>
                                {!this.state.layer?.feature.properties.baseLine ?
                                    <Form.Field
                                        control={Input} fluid type='number' label={`${i18n.t("Angle")} :`} step='1' error={error.angle}
                                        name='angle' value={!placementLine && dimensions.angle > 0 ? dimensions.angle : 0}
                                        disabled={!layer || placementLine ? true : false} onChange={this.handleChange}
                                    />
                                    : <Form.Field
                                        control={Select} fluid label={`${i18n.t("Direction")} :`} name='direction' value={dimensions.direction}
                                        options={[{ text: i18n.t("Gauche"), value: 0 }, { text: i18n.t("Droite"), value: 1 }]}
                                        selectOnBlur={false} onChange={this.handleChange} disabled={!layer ? true : false}
                                    />}
                                <Form.Field
                                    control={Input} fluid type='number' label={`${i18n.t("Distance")}* (m) :`} step='1' error={error.distance}
                                    name='distance' value={dimensions.distance !== '' && typeof dimensions.distance === 'number' ? Number(dimensions.distance.toFixed(2)) : ''}
                                    disabled={!layer || placementLine ? true : false} onChange={this.handleChange}
                                />
                                <Form.Field
                                    control={Input} fluid type='number' label={`${i18n.t("Écart")}* (m) :`} step='1' error={error.gap}
                                    name='gap' value={dimensions.gap !== '' && typeof dimensions.gap === 'number' ? Number(dimensions.gap.toFixed(2)) : ''}
                                    disabled={!layer ? true : false} onChange={this.handleChange}
                                />
                                <Form.Field
                                    control={Input} fluid type='number' label={`${i18n.t("Nombre d'éléments")} :`} step='1' error={error.nbElements}
                                    name='nbElements' value={dimensions.nbElements !== '' && typeof dimensions.nbElements === 'number' ? Math.floor(dimensions.nbElements) : duplicatedElements ? duplicatedElements.length : ''}
                                    disabled={!layer ? true : false} onChange={this.handleChange}
                                />
                            </div>
                            <div>
                                {['Arbre', 'Mobilier'].includes(category) &&
                                    <Button.Group style={{ width: '100%', marginTop: '10px' }}>
                                        <Button
                                            type='button' color={placementLine ? 'red' : 'blue'} size='mini'
                                            style={{ display: 'inline-flex', justifyContent: 'center', width: '33%' }}
                                            title={placementLine ? i18n.t("Supprimer la ligne de placement") : i18n.t("Traçer une ligne de placement")}
                                            onClick={placementLine ? this.removePlacementLine : this.props.drawPlacementLine}
                                        >
                                            <FontAwesomeIcon icon={placementLine ? faTrash : faPencil} />
                                        </Button>
                                        <Button
                                            type='button' color={editLine ? 'blue' : 'grey'} size='mini'
                                            style={{ display: 'inline-flex', justifyContent: 'center', width: '33%' }}
                                            title={editLine ? i18n.t("Masquer la modification de la ligne") : i18n.t("Modifier la ligne")}
                                            disabled={!placementLine} onClick={() => this.handleCheckboxChange(null, { name: 'editLine', checked: !editLine })}
                                        >
                                            <FontAwesomeIcon icon={editLine ? faEdit : faEditOutline} />
                                        </Button>
                                        <Button
                                            type='button' color={startLineOnElement ? 'blue' : 'grey'} size='mini'
                                            style={{ display: 'inline-flex', justifyContent: 'center', width: '33%' }}
                                            title={startLineOnElement ? i18n.t("Délier la ligne de l'élément") : i18n.t("Lier la ligne à l'élément")}
                                            disabled={!placementLine} onClick={() => this.handleCheckboxChange(null, { name: 'startLineOnElement', checked: !startLineOnElement })}
                                        >
                                            <FontAwesomeIcon icon={startLineOnElement ? faLink : faLinkSlash} />
                                        </Button>
                                    </Button.Group>}
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Button
                                        type='button' color='red' title={i18n.t("Annuler")} size='small'
                                        style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                        disabled={isLoading} onClick={this.handleCancel}
                                    >
                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button
                                        type='button' color='green' title={i18n.t("Valider")} size='small'
                                        style={{ display: 'inline-flex', justifyContent: 'center', width: '50%', marginRight: 0 }}
                                        disabled={isLoading || !nbValidElements} onClick={this.handleSubmit}
                                    >
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '5px' }}>
                                    {category && <>
                                        {nbValidElements && nbInvalidElements ?
                                            <>(<div style={{ color: '#21ba45' }}>{nbValidElements}</div> + <div style={{ color: '#db2828' }}>{nbInvalidElements}</div>)</>
                                            : nbValidElements ? <div style={{ color: '#21ba45' }}>{nbValidElements}</div>
                                                : nbInvalidElements ? <div style={{ color: '#db2828' }}>{nbInvalidElements}</div>
                                                    : 0}/{NB_MAX_CLONES}
                                        <FontAwesomeIcon
                                            icon={category === 'Arbre' ? faTree : category === 'Espace vert' ? faFlowerTulip : faTablePicnic}
                                            style={{ marginLeft: '5px', marginRight: '10px' }}
                                        />
                                    </>}
                                </div>
                            </div>
                        </Form>
                    </div>}
            </>
        );
    }

    componentDidUpdate = (prevProps) => {
        const duplicatedElements = this.state.duplicatedElements;
        let { angle, direction, distance, gap, nbElements } = this.state.dimensions;

        // Layer & Ligne
        if (this.props.project && this.props.layer && (this.state.layer?.feature?.id !== this.props.layer?.feature?.id
            || JSON.stringify(prevProps.lockedElements) !== JSON.stringify(this.props.lockedElements)))
            this.setState({ isLocked: ProjectsUtil.isElementLocked(this.props.lockedElements, this.props.layer.feature, this.props.project, this.props.projectCollaborators, []) });
        if (this.state.layer && this.props.layer && this.state.layer?.feature?.id !== this.props.layer?.feature?.id
            && !duplicatedElements.find(x => x.feature.id === this.props.layer.feature.id)) {
            if (this.props.layer?.feature?.properties?.category !== 'Arbre' && this.state.placementLine) {
                this.removePlacementLine(false);
                distance = gap * nbElements;
            }

            if (duplicatedElements.length) this.removeFromContainer(duplicatedElements);
            this.setState({ layer: this.props.layer, duplicatedElements: [] }, () => {
                if (distance >= 1 && gap >= 1 && Math.floor(distance / gap) <= NB_MAX_CLONES) this.duplicateElementLinearly(angle, direction, distance, gap);
            });
        }
        else if (this.props.layer && duplicatedElements.find(x => x.feature.id === this.props.layer.feature.id)) this.props.setLayer(this.state.layer);
        else if (!this.state.layer && this.props.layer) this.setState({ layer: this.props.layer });
        else if (!this.state.placementLine && this.props.placementLine) {
            const line = lineString(GeometriesUtil.convertPolylineLatLngsToCoordinates(this.props.placementLine.getLatLngs()));
            const lineDistance = lineLength(line, { units: 'meters' });
            gap = nbElements > 1 ? lineDistance / (nbElements - 1) : 0;

            if (duplicatedElements.length) this.removeFromContainer(duplicatedElements);
            this.setState(prevState => ({
                duplicatedElements: [],
                placementLine: this.props.placementLine,
                dimensions: { ...prevState.dimensions, gap, distance: lineDistance },
                error: { ...prevState.error, distance: false }
            }), () => {
                if (gap >= 1 && gap <= lineDistance) this.duplicateElementLinearly(angle, direction, lineDistance, gap);
            });

            // Events
            this.props.placementLine.on('pm:markerdragstart', ({ layer }) => {
                this.dragStartLatLng = layer.getLatLngs();
            });
            this.props.placementLine.on('pm:markerdragend', ({ layer }) => {
                const startLineOnElement = this.state.options.startLineOnElement;
                const { angle, direction, nbElements } = this.state.dimensions;
                let gap = this.state.dimensions.gap;

                const line = lineString(GeometriesUtil.convertPolylineLatLngsToCoordinates(layer.getLatLngs()));
                const newLineDistance = lineLength(line, { units: 'meters' });
                if (layer.pm.hasSelfIntersection()) layer.setLatLngs(this.dragStartLatLng);
                gap = !startLineOnElement ? newLineDistance / (nbElements - 1) : newLineDistance / nbElements;
                if (gap >= 1 && gap <= newLineDistance)
                    this.duplicateElementLinearly(angle, direction, newLineDistance, gap);
                this.setState(prevState => ({ dimensions: { ...prevState.dimensions, distance: newLineDistance, gap } }));
            });
            this.props.placementLine.on('pm:markerdrag', ({ layer }) => {
                if (this.state.options.startLineOnElement && this.state.layer.getLatLng() !== layer.getLatLngs()[0]) {
                    layer.pm.disable();
                    let latLngs = layer.getLatLngs();
                    latLngs[0] = this.state.layer.getLatLng();
                    layer.setLatLngs(latLngs);
                    layer.pm.enable();
                }
            });
            this.props.placementLine.on('pm:vertexremoved', ({ layer }) => {
                const startLineOnElement = this.state.options.startLineOnElement;
                const { angle, direction, nbElements } = this.state.dimensions;
                gap = this.state.dimensions.gap;

                if (layer.getLatLngs().length > 1) {
                    const line = lineString(GeometriesUtil.convertPolylineLatLngsToCoordinates(layer.getLatLngs()));
                    const newLineDistance = lineLength(line, { units: 'meters' });
                    gap = !startLineOnElement ? newLineDistance / (nbElements - 1) : newLineDistance / nbElements;
                    if (gap >= 1 && gap <= newLineDistance)
                        this.duplicateElementLinearly(angle, direction, newLineDistance, gap);
                    this.setState(prevState => ({ dimensions: { ...prevState.dimensions, distance: newLineDistance, gap } }));
                } else this.removePlacementLine();
            });
        }
    }

    componentWillUnmount = () => {
        if (!this.state.isLoading) this.removeFromContainer(this.state.duplicatedElements);
        if (this.state.placementLine) this.removePlacementLine(false);
    }

    handleSubmit = async () => {
        let isValid = true;
        let error = { ...initialError.error };
        let { dimensions, duplicatedElements, invalidElementsId } = this.state;
        const { distance, gap } = dimensions;
        const category = this.state.layer.feature.properties.category;

        const addError = (property) => {
            isValid = false;
            error[property] = true;
            error.hidden = false;
        }

        if (distance <= 0) addError('distance');
        if (gap <= 0) addError('gap');

        if (isValid) {
            this.setState({ isLoading: true });
            this.props.setLayer(null);

            // Suppression des éléments invalides
            if (invalidElementsId.length > 0) {
                const elementsToRemove = duplicatedElements.filter(de => invalidElementsId.includes(de.feature.id));
                duplicatedElements = duplicatedElements.filter(de => !invalidElementsId.includes(de.feature.id))
                this.removeFromContainer(elementsToRemove);
            }

            let elements = null, requestFailed = false;
            const callback = () => {
                this.removeFromContainer(duplicatedElements);
                if (!requestFailed || !this.props.isOnline) {
                    if (!elements) elements = duplicatedElements.map(x => x.feature);
                    elements.forEach(element => {
                        const layer = duplicatedElements.find(x => x.feature.id === element.id);
                        ['Arbre', 'Mobilier'].includes(category)
                            ? this.props.addMarker(category, layer, element, { updateLegend: false, updateHeatmaps: false })
                            : this.props.addGreenSpace(layer, element, { updateLegend: false });
                    });

                    if (category === 'Arbre') {
                        this.props.updateLegend(i18n.t("Arbres"));
                        this.props.updateHeatmaps();
                    } else if (category === 'Espace vert') this.props.updateLegend(i18n.t("Espaces verts"));
                    else this.props.updateLegend(i18n.t("Mobilier urbain"));
                }

                this.setState({ duplicatedElements: [], isLoading: false });
                this.props.hideForm(true);
            };

            if (category === 'Arbre') {
                const trees = duplicatedElements.map(de => de.feature);
                TreesService.addTrees(trees, 'duplicating', this.props.webSocketHubs).then(response => elements = response?.data).catch(() => requestFailed = true).finally(callback);
            } else if (category === 'Espace vert') {
                const greenSpaces = duplicatedElements.map(de => de.feature);
                GreenSpacesService.addGreenSpaces(greenSpaces, 'duplicating', this.props.webSocketHubs).then(response => elements = response?.data).catch(() => requestFailed = true).finally(callback);
            } else if (category === 'Mobilier') {
                const furnitures = duplicatedElements.map(de => de.feature);
                FurnituresService.addFurnitures(furnitures, 'duplicating', this.props.webSocketHubs).then(response => elements = response?.data).catch(() => requestFailed = true).finally(callback);
            }
        } else this.setState({ error: error });
    }

    handleCancel = () => this.props.setLayer(null).then(() => this.props.hideForm(false));

    handleChange = (_, { name, value }) => {
        const category = this.state.layer.feature.properties.category;
        const placementLine = this.state.placementLine;
        let { angle, direction, distance, gap, nbElements } = this.state.dimensions;
        let distanceError = false, gapError = false, nbElementsError = false, parsedValue, error = false;

        // Vérification des valeurs
        if (value !== '' && !isNaN(value)) {
            parsedValue = Number(value);
            if (value <= 0) error = true;
        } else {
            parsedValue = value;
            error = true;
        }
        if (name === 'distance') distance = parsedValue > 0 ? parsedValue : value;
        else if (name === 'gap') gap = parsedValue > 0 ? parsedValue : value;
        else if (name === 'nbElements') nbElements = parsedValue > 0 ? parsedValue : value;
        else if (name === 'angle') {
            angle = parsedValue >= 0 && parsedValue <= 360 ? parsedValue : parsedValue > 360 ? 0 : 360;
            parsedValue = angle;
        } else if (name === 'direction') direction = parsedValue;

        // Mise à jour des éléments
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            let areInside = this.state.areInside;
            if (['distance', 'nbElements'].includes(name) && (gap < 1 || placementLine) && distance >= 1 && nbElements >= 1)
                gap = this.state.options.startLineOnElement || !placementLine ? distance / nbElements : distance / (nbElements - 1);
            else if ((name === 'distance' && gap >= 1) || (name === 'gap' && distance >= 1 && !nbElements)) nbElements = Math.floor(distance / gap);
            if (['gap', 'nbElements'].includes(name) && gap >= 1 && nbElements >= 1 && !placementLine) distance = gap * nbElements;
            else if (name === 'gap' && placementLine && category === 'Arbre') {
                nbElements = Math.floor(distance / gap);
                if (nbElements >= 1 && !this.state.options.startLineOnElement) nbElements++;
            }

            if (distance >= 1 && gap >= 1) areInside = this.duplicateElementLinearly(angle, direction, distance, gap, nbElements);
            else {
                distanceError = !distance || distance < 0;
                gapError = !gap || gap < 0;
                nbElementsError = !nbElements || nbElements < 0;
            }

            this.setState(prevState => ({
                areInside,
                dimensions: { ...prevState.dimensions, nbElements, gap, distance, [name]: parsedValue },
                error: { ...prevState.error, distance: distanceError, gap: gapError, nbElements: nbElementsError, [name]: error }
            }));
        }, 250);

        this.setState(prevState => ({
            dimensions: { ...prevState.dimensions, [name]: parsedValue },
            error: { ...prevState.error, [name]: error }
        }));
    }

    handleCheckboxChange = (_, { name, checked }) => {
        let { distance, gap, nbElements } = this.state.dimensions;

        if (name === 'editLine' && checked) this.state.placementLine.pm.enable();
        else if (name === 'editLine' && !checked) this.state.placementLine.pm.disable();
        else if (name === 'startLineOnElement' && checked) {
            let { layer, placementLine } = this.state;
            let latLngs = [layer.getLatLng(), ...placementLine.getLatLngs()];
            placementLine.setLatLngs(latLngs);
            distance = lineLength(lineString(GeometriesUtil.convertLineLatLngsToCoordinates(placementLine.getLatLngs())), { units: 'meters' });
            gap = nbElements >= 1 ? distance / nbElements : 0;
            if (this.state.options.editLine) {
                this.state.placementLine.pm.disable();
                this.state.placementLine.pm.enable();
            }
        } else if (name === 'startLineOnElement' && !checked) {
            let { placementLine } = this.state;
            let latLngs = placementLine.getLatLngs();
            latLngs.shift();
            placementLine.setLatLngs(latLngs);
            distance = lineLength(lineString(GeometriesUtil.convertLineLatLngsToCoordinates(placementLine.getLatLngs())), { units: 'meters' });
            gap = nbElements - 1 >= 1 ? distance / (nbElements - 1) : 0;
            if (this.state.options.editLine) {
                this.state.placementLine.pm.disable();
                this.state.placementLine.pm.enable();
            }
        }

        this.setState(prevState => ({
            dimensions: { ...prevState.dimensions, distance, gap, nbElements },
            options: { ...prevState.options, [name]: checked }
        }), () => {
            const { angle, direction } = this.state.dimensions;
            if (distance >= 1 && gap >= 1) this.duplicateElementLinearly(angle, direction, distance, gap);
        });
    }

    duplicateElementLinearly = (angle, direction, distance, gap) => {
        const placementLine = this.state.placementLine;
        const category = this.state.layer.feature.properties.category;
        angle = category === 'Espace vert' ? angle + 90 : angle * -1;
        const coordinates = ['Arbre', 'Mobilier'].includes(category) ? [this.state.layer.getLatLng().lat, this.state.layer.getLatLng().lng]
            : GeometriesUtil.convertPolygonLatLngsToCoordinates(this.state.layer.getLatLngs());
        const hasBaseLine = !this.state.layer.feature.properties.baseLine ? false : true;
        let duplicatedElements = [], areSuperimposed = false;
        let areInside = true;

        // Calcul du nombre d'éléments à générer
        let nbClones = Math.floor(distance / gap);
        if (nbClones >= 1 && ['Arbre', 'Mobilier'].includes(category) && placementLine && !this.state.options.startLineOnElement) nbClones++;
        nbClones = nbClones <= NB_MAX_CLONES ? nbClones : NB_MAX_CLONES;

        // Suppression des layers dupliqués
        this.removeFromContainer(this.state.duplicatedElements);
        if (this.state.placementLine) { // Création des layers depuis la ligne
            const points = GeometriesUtil.getPointsOnLine(this.state.placementLine.getLatLngs(), { gap, firstPointOfLine: !this.state.options.startLineOnElement, maxPoints: nbClones });
            for (const p of points) {
                let feature = JSON.parse(JSON.stringify(this.state.layer.feature));
                feature.properties.customReference = null;
                let element = point(p);
                const elementCoordinates = JSON.parse(JSON.stringify(element.geometry.coordinates));
                feature.geometry = element.geometry;
                feature.geometry.coordinates = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
                feature.id = uuidv4();
                if (category === 'Arbre') (feature.properties.trunks || []).forEach(trunk => trunk.id = uuidv4());
                const elementAdded = this.props.addMarker(category,
                    new L.circleMarker(elementCoordinates), feature, {
                    updateLegend: false, updateHeatmaps: false, events: false, style: category === 'Arbre' ? StylesUtil.getTreeHelpStyle() : StylesUtil.getFurnitureHelpStyle(), showReferences: false
                });
                duplicatedElements.push(elementAdded);
            }
        } else { // Création des layers
            const baseLineString = hasBaseLine ? lineString(this.state.layer.feature.properties.baseLine.coordinates) : null;
            for (let i = 0; i < nbClones; i++) {
                const elementGap = (i + 1) * gap;
                let element, translatedElement, elementAdded, elementCoordinates;
                element = ['Arbre', 'Mobilier'].includes(category) ? point(coordinates) : polygon(coordinates);

                if (!hasBaseLine) { // Duplication du layer avec translation
                    if (category === 'Espace vert') {
                        translatedElement = transformTranslate(element, elementGap, angle, { units: 'meters' });
                        elementCoordinates = JSON.parse(JSON.stringify(translatedElement.geometry.coordinates));
                    } else {
                        translatedElement = JSON.parse(JSON.stringify(element));
                        elementCoordinates = LeafletGeometriesUtil.destination({ lat: coordinates[0], lng: coordinates[1] }, (angle - 90) * -1, elementGap);
                        translatedElement.geometry.coordinates = [elementCoordinates.lat, elementCoordinates.lng];
                    }
                    if (['Arbre', 'Mobilier'].includes(category)) translatedElement.geometry.coordinates = [translatedElement.geometry.coordinates[1], translatedElement.geometry.coordinates[0]];
                    translatedElement.properties = JSON.parse(JSON.stringify(this.state.layer.feature.properties));
                    translatedElement.properties.customReference = null;
                } else { // Duplication du layer et de la baseLine avec translation
                    const baseLine = this.state.layer.feature.properties.baseLine;
                    const lineGap = !direction ? elementGap * -1 : elementGap;

                    // Translation de la ligne
                    const translatedBaseLineString = GeometriesUtil.getLineOffset(baseLineString, lineGap);
                    // Création du polygone
                    translatedElement = polygon(GeometriesUtil.convertBufferedLineToCoordinates(translatedBaseLineString.geometry.coordinates, baseLine.width));
                    elementCoordinates = JSON.parse(JSON.stringify(translatedElement.geometry.coordinates));
                    translatedElement.properties = JSON.parse(JSON.stringify(this.state.layer.feature.properties));
                    translatedElement.properties.customReference = null;
                    translatedElement.properties.baseLine.coordinates = translatedBaseLineString.geometry.coordinates;
                }
                translatedElement.id = uuidv4();
                if (category === 'Arbre') (translatedElement.properties.trunks || []).forEach(trunk => trunk.id = uuidv4());
                else if (category === 'Espace vert') {
                    translatedElement.properties.greenSpaceId = translatedElement.id;
                    if (translatedElement.properties.baseLine) translatedElement.properties.baseLine.propertiesId = translatedElement.id;
                }
                translatedElement.projectId = this.state.layer.feature.projectId;

                // Ajout du layer à la carte
                elementAdded = ['Arbre', 'Mobilier'].includes(category)
                    ? this.props.addMarker(category,
                        new L.circleMarker(elementCoordinates), translatedElement, {
                        updateLegend: false, updateHeatmaps: false, events: false, style: category === 'Arbre' ? StylesUtil.getTreeHelpStyle() : StylesUtil.getFurnitureHelpStyle()
                    }) : this.props.addGreenSpace(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(elementCoordinates)), translatedElement, { updateLegend: false, events: false, style: StylesUtil.getGreenSpaceHelpStyle() });


                duplicatedElements.push(elementAdded);
            }
        }

        // Vérification des layers (intersections et délimitations du projet)
        let invalidElementsId = [];
        const shape = ['Arbre', 'Mobilier'].includes(category) ? 'marker' : 'polygon';
        const errorStyle = { fillColor: '#FF0000' };
        const layerPolygon = shape === 'polygon' ? polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(this.state.layer.getLatLngs())) : null;
        for (let i = 0; i < duplicatedElements.length; i++) {
            const element = duplicatedElements[i];
            const nextElement = i + 1 < duplicatedElements.length ? duplicatedElements[i + 1] : null;

            if (category === 'Espace vert') {
                const currentPolygon = polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(element.getLatLngs()));
                const nextPolygon = nextElement ? polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(nextElement.getLatLngs())) : null;

                if (nextPolygon && intersect(featureCollection([currentPolygon, nextPolygon]))) {
                    areSuperimposed = true;
                    if (!invalidElementsId.includes(element.feature.id)) invalidElementsId.push(element.feature.id);
                    if (!invalidElementsId.includes(nextElement.feature.id)) invalidElementsId.push(nextElement.feature.id);
                    element.setStyle(errorStyle);
                    nextElement.setStyle(errorStyle);
                } else if (intersect(featureCollection([currentPolygon, layerPolygon]))) {
                    areSuperimposed = true;
                    if (!invalidElementsId.includes(element.feature.id)) invalidElementsId.push(element.feature.id);
                    element.setStyle(errorStyle);
                }
            }
            if (element.feature.projectId > 0 && this.props.checkIfInsideSurroundings && !this.props.checkIfInsideSurroundings(shape, element)) {
                areInside = false;
                if (!invalidElementsId.includes(element.feature.id)) invalidElementsId.push(element.feature.id);
                if (['Arbre', 'Mobilier'].includes(category)) {
                    const layerContainerName = category === 'Arbre' ? i18n.t("Arbres") : i18n.t("Mobilier urbain");
                    const layerContainers = this.props.layerContainers;
                    Object.keys(layerContainers).filter(key => layerContainers[key].label === layerContainerName).forEach(key => {
                        const layer = layerContainers[key].getLayers().find(layer => element.feature.id === layer.feature?.id);
                        if (layer) layer.setStyle(errorStyle);
                    });
                } else element.setStyle(errorStyle);
            }
        }

        this.setState({ duplicatedElements, invalidElementsId, areSuperimposed });
        return areInside;
    }

    removePlacementLine = (updateElements = true) => {
        const { angle, direction, gap, nbElements } = this.state.dimensions;
        let distance = gap >= 1 && nbElements >= 1 ? gap * nbElements : 0;

        this.props.map.removeLayer(this.state.placementLine);
        this.props.removePlacementLine();
        this.setState(prevState => ({
            placementLine: null,
            dimensions: { ...prevState.dimensions, distance: distance },
            options: {
                ...prevState.options,
                editLine: false,
                startLineOnElement: false
            }
        }), () => {
            if (updateElements) this.duplicateElementLinearly(angle, direction, distance, gap);
        });
    }

    removeFromContainer = (layersToRemove) => {
        if (!this.state.duplicatedElements.length) return;
        const category = this.state.duplicatedElements[0]?.feature.properties.category;
        const legendName = category === 'Arbre' ? i18n.t("Arbres") : category === 'Espace vert' ? i18n.t("Espaces verts") : i18n.t("Mobilier urbain");
        // Pour chaque layer qui pourrait contenir un élément à supprimer
        const layerContainers = this.props.layerContainers;
        Object.keys(layerContainers).filter(key => layerContainers[key].label === legendName || layerContainers[key].label === legendName).forEach(key => {
            layerContainers[key].eachLayer(layer => {
                if (layersToRemove.find(l => l.feature.id === layer.feature?.id)) {
                    layerContainers[key].removeLayer(layer); // On supprime l'élément du layer
                }
            });
        });
        this.props.updateLegend(legendName);
        this.props.updateHeatmaps();
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        lockedElements: state.lockedElements,
        webSocketHubs: state.webSocketHubs,
        isToolbarExpanded: state.isToolbarExpanded,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setLayer
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateForm);