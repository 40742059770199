import React, { Component } from 'react';

export default class TextFilter extends Component {
    render() {
        const p = this.props.p;

        return (
            <div className='rdg-filter-container'>
                <input
                    className='rdg-filter' style={{ width: '100%' }} disabled={p.disabled}
                    value={p.disabled ? '' : p.value} onChange={e => p.onChange(e.target.value)}
                />
            </div>
        );
    }
}
