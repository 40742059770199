// Librairies
import proj4 from 'proj4';
import { lineString, length } from '@turf/turf';
// Utils
import GeometriesUtil from './GeometriesUtil';
import { showToast } from './ToastsUtil';

export default class GeoJsonUtil {
    static generateGeoJson(features) { // Permet de générer un bon GeoJSON lors de la lecture des données depuis la DB
        let geoJSON = {
            type: 'FeatureCollection',
            features: []
        }

        if (Array.isArray(features)) geoJSON.features = features;
        else geoJSON.features.push(features);

        return geoJSON;
    }

    static generatePolygonFeature(properties, latLngs, projectId = 0) { // Permet de générer le GeoJSON d'un polygone pour l'ajout en DB
        if (!latLngs || latLngs.length === 0) {
            showToast('coordinates_not_found');
            return;
        } else {
            if (properties) {
                properties.projectId = projectId;
                if (properties.baseLine) {
                    properties.baseLine.projectId = projectId;
                    properties.baseLine.length = Math.round(length(lineString(properties.baseLine.coordinates)) * 100000) / 100
                    properties.baseLine.fetchId = properties.fetchId;
                }
                properties.surface = GeometriesUtil.getPolygonSurface(latLngs);
            }

            return {
                type: 'Feature', projectId: projectId, fetchId: properties?.fetchId, properties: properties,
                geometry: {
                    type: 'Polygon',
                    coordinates: GeometriesUtil.convertPolygonLatLngsToCoordinates(latLngs)
                }
            };;
        }
    }

    static generateImageFeature(properties, coordinates, projectId = 0) { // Permet de générer le GeoJSON d'une image pour l'ajout en DB
        let geoJSON = {
            type: 'Feature',
            projectId,
            properties,
            geometry: {
                type: 'Image',
                coordinates: [],
                projectId
            }
        }

        // Mise en forme des coordonnées parents
        geoJSON.geometry.coordinates = GeometriesUtil.convertBackgroundImageLatLngsToCoordinates(coordinates);
        return geoJSON;
    }

    static generateMarkerFeature(properties, coordinates, projectId = 0) { // Permet de générer le GeoJSON d'un marker pour l'ajout en DB
        if (!coordinates || coordinates.length === 0) {
            showToast('coordinates_not_found');
            return;
        } else {
            properties.projectId = projectId;
            if (properties.dimensions) {
                properties.dimensions.projectId = projectId;
                properties.dimensions.propertiesId = properties.treeId;
                properties.dimensions.fetchId = properties.fetchId;
            }

            (properties.trunks || []).forEach(trunk => {
                if (trunk.trunkId) {
                    trunk.id = trunk.trunkId;
                    delete trunk.trunkId;
                }

                trunk.projectId = projectId;
                trunk.propertiesId = properties.treeId;
                trunk.fetchId = properties.fetchId;
            });

            return {
                type: 'Feature', projectId, fetchId: properties?.fetchId, properties,
                geometry: {
                    type: 'Point',
                    coordinates: [coordinates.lng, coordinates.lat]
                }
            };
        }
    }

    static generateLineFeature(properties, coordinates, projectId = 0) { // Permet de générer le GeoJSON d'une ligne pour l'ajout en DB
        if (!coordinates || coordinates.length === 0) {
            showToast('coordinates_not_found');
            return;
        }
        else {
            let geoJSON = {
                type: 'Feature',
                projectId: projectId,
                properties: properties,
                geometry: {
                    type: 'LineString',
                    coordinates: []
                }
            }

            for (let i = 0; i < coordinates.length; i++)
                geoJSON.geometry.coordinates.push([coordinates[i].lng, coordinates[i].lat]);

            return geoJSON;
        }
    }

    static projectMarkerCoordinates(coordinates, projections, projectionId) {
        if (!projections?.length || !projectionId) return coordinates;
        const proj4Source = projections.find(x => x.id === 1).proj4;
        const proj4Target = projections.find(x => x.id === projectionId).proj4;
        if (!proj4Source || !proj4Target) return coordinates;
        return proj4(proj4Source, proj4Target).forward(coordinates);
    }
}
