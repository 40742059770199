import React, { Component } from 'react';
// Composants
import { Button, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { faComputer, faMobile, faQuestionCircle, faTablet, faTimes, faUserGroup, faWarning } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import tinycolor from 'tinycolor2';
// Utils
import UsersUtil from '../../utils/UsersUtil';
import RightsUtil from '../../utils/RightsUtil';

class ConnectedUsers extends Component {
    state = {
        isOpen: false,
        users: []
    }

    render() {
        const { isToolbarExpanded, loginAsData, viewProjectAsData, activeOrganization } = this.props;
        const { isOpen, users } = this.state;
        const nbOnlineUsers = users.filter(user => user.isOnline).length;
        const nbOfflineUsers = users.length - nbOnlineUsers;
        const user = users.find(u => u.isMe);
        const isRestricted = !isOpen && user ? RightsUtil.isRestricted(activeOrganization?.subscription, user.projectRole) : false;

        return (
            <div
                id='connected-users' title={!isOpen ? i18n.t("Ouvrir la liste de collaborateurs connectés") : ''}
                onClick={() => { if (!isOpen) this.setState({ isOpen: true }); }}
                style={{
                    left: isToolbarExpanded ? '305px' : '45px', top: (!loginAsData && !viewProjectAsData) ? '65px' : '100px', cursor: isOpen ? 'default' : 'pointer', transition: 'left 500ms',
                }}
            >
                <div style={{ borderRadius: '10px', padding: !isOpen ? '10px 15px' : '10px', transition: '.2s 0s all' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faUserGroup} />
                        {!isOpen
                            ? <div style={{ marginLeft: '10px', textAlign: 'center' }}>{nbOnlineUsers}</div>
                            : <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: '10px' }}>
                                <h4 style={{ margin: 0 }}>{i18n.t("Collaborateurs")}</h4>
                                <Button color='blue' onClick={() => this.setState({ isOpen: false })} style={{ marginLeft: 'auto', marginRight: '0', padding: '5px 7px' }}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>}
                        {isRestricted &&
                            <Popup
                                trigger={<FontAwesomeIcon icon={faWarning} color='orange' style={{ marginLeft: '10px' }} />}
                                content={i18n.t("L'utilisateur n'ayant pas de licence, celui-ci est restreint en « lecture seule ».")}
                            />}
                    </div>
                    <div id='connected-users-list' style={{ maxWidth: !isOpen ? '0' : '500px', maxHeight: !isOpen ? '0' : '500px' }}>
                        <p style={{ marginTop: '15px', marginBottom: '7px', fontWeight: 'bolder' }}>{i18n.t("En ligne ({{nbUsers}})", { nbUsers: nbOnlineUsers })}</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxHeight: '150px', overflow: 'auto' }}>
                            {this.renderOnlineUsers()}
                        </div>
                        <p style={{ margin: '15px 0 7px 0', fontWeight: 'bolder' }}>{i18n.t("Hors ligne ({{nbUsers}})", { nbUsers: nbOfflineUsers })}</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxHeight: '150px', overflow: 'auto' }}>
                            {this.renderOfflineUsers()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.isWebSocketInitialized = false;
        this.setUsers();

        window.addEventListener('online', () => setTimeout(this.initWebSocket, 1000));
        window.addEventListener('offline', () => {
            this.isWebSocketInitialized = false;
            this.setUsers();
        });
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.webSocketHubs?.elementsHub && this.props.webSocketHubs?.elementsHub)
            this.initWebSocket()
        if (this.props.projectCollaborators?.length && JSON.stringify(prevProps.projectCollaborators) !== JSON.stringify(this.props.projectCollaborators))
            this.updateUsers();
        if (!prevProps.isOnline && this.props.isOnline)
            this.props.webSocketHubs.elementsHub.on('GetMembers', this.handleMemberJoined);
    }

    renderOnlineUsers = () => {
        const onlineUsers = this.state.users.filter(u => u.isOnline);
        if (!onlineUsers.length) return (<p>{i18n.t("Aucun collaborateur en ligne.")}</p>);

        return onlineUsers.sort((a, b) => b.isMe - a.isMe).map((user, index) => {
            const device = user.deviceInfos?.type === 'desktop' ? faComputer
                : user.deviceInfos?.type === 'tablet' ? faTablet
                    : user.deviceInfos?.type === 'mobile' ? faMobile
                        : faQuestionCircle
            const isDark = tinycolor(user.projectRole.color).getBrightness() < 180;
            const isRestricted = user.isMe && RightsUtil.isRestricted(this.props.activeOrganization?.subscription, user.projectRole);

            return (
                <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Popup
                        key={index}
                        trigger={
                            <div style={{ paddingTop: index && '5px' }}>
                                <FontAwesomeIcon icon={device} style={{ marginRight: '5px' }} />{user.user.firstName} {user.user.lastName} {user.isMe && `(${i18n.t("Vous")})`}
                            </ div>}
                        content={
                            <div>
                                <h4>{i18n.t("Informations")}</h4>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: '5px', fontWeight: 'bold' }}>{i18n.t("Rôle")} :</div>
                                    <div style={{ backgroundColor: user.projectRole.color, color: `var(--${isDark ? 'white' : 'black'}-100)`, padding: '2px 5px', borderRadius: '5px' }}>
                                        <FontAwesomeIcon icon={user.projectRole.icon} style={{ marginRight: '5px' }} />
                                        {user.projectRole.type ? i18n.t(user.projectRole.label) : user.projectRole.label}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    <div style={{ marginRight: '5px', fontWeight: 'bold' }}>{i18n.t("Système d'exploitation")} :</div>
                                    <div style={{ backgroundColor: 'var(--grey-80)', padding: '0px 5px', borderRadius: '5px' }}>
                                        {user.deviceInfos?.os || i18n.t("Inconnu")}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    <div style={{ marginRight: '5px', fontWeight: 'bold' }}>{i18n.t("Navigateur")} :</div>
                                    <div style={{ backgroundColor: 'var(--grey-80)', padding: '0px 5px', borderRadius: '5px' }}>
                                        {user.deviceInfos?.browser || i18n.t("Inconnu")}
                                    </div>
                                </div>
                            </div>}
                        style={{ minWidth: '275px' }}
                    />
                    {isRestricted &&
                        <Popup
                            trigger={<FontAwesomeIcon icon={faWarning} color='orange' style={{ marginLeft: 'auto' }} />}
                            content={i18n.t("L'utilisateur n'ayant pas de licence, celui-ci est restreint en « lecture seule ».")}
                        />}
                </div>
            );
        });
    }

    renderOfflineUsers = () => {
        const offlineUsers = this.state.users.filter(u => !u.isOnline);
        if (!offlineUsers.length) return (<p>{i18n.t("Aucun collaborateur hors ligne.")}</p>);

        return offlineUsers.map((user, index) => {
            return (
                <div key={index} style={{ paddingTop: index && '5px', color: 'grey' }}>
                    {user.user.firstName} {user.user.lastName}
                </div>
            );
        });
    }

    setUsers = () => {
        const userId = jwtDecode(new Cookies().get('token')).id;
        if (!this.props.projectCollaborators?.length) return;
        const users = JSON.parse(JSON.stringify(this.props.projectCollaborators.filter(pc => !pc.deletionDate)));
        users.forEach(user => {
            const isMe = user.userId === userId;
            user.isMe = isMe;
            user.isOnline = isMe;
            user.deviceInfos = isMe ? UsersUtil.getDevice() : null;
            user.connectionId = null;
        });
        this.setState({ users });
    }

    updateUsers = () => {
        const { projectCollaborators } = this.props;
        let users = JSON.parse(JSON.stringify(this.state.users))
        users.forEach(user => {
            if (!projectCollaborators.find(collaborator => collaborator.userId === user.userId))
                users = users.filter(u => u.userId !== user.userId);
        });

        projectCollaborators.forEach(collaborator => {
            if (!users.find(user => user.userId === collaborator.userId))
                users.push({ ...collaborator, isMe: false, isOnline: false, deviceInfos: null, connectionId: null });
        });

        this.setState({ users });
    }

    initWebSocket = () => {
        const handleHub = () => {
            this.props.webSocketHubs.elementsHub.invoke('GetMembers', this.props.project.id.toString());
            this.props.webSocketHubs.elementsHub.on('GetMembers', this.handleMemberJoined);
            this.props.webSocketHubs.elementsHub.on('MemberJoinedGroup', this.handleMemberJoined);
            this.props.webSocketHubs.elementsHub.on('MemberDisconnected', this.handleMemberDisconnected);
        };

        if (!this.isWebSocketInitialized && this.props.webSocketHubs?.elementsHub?.state === 'Connected') {
            handleHub();
            this.props.webSocketHubs.elementsHub.onreconnected(handleHub);
        }
    }

    handleMemberJoined = (members) => {
        const membersParsed = JSON.parse(members);
        let users = JSON.parse(JSON.stringify(this.state.users));
        membersParsed.forEach(member => {
            let user = users.find(u => u.userId === member.userId && (u.connectionId === member.connectionId || u.connectionId === null));
            if (user) {
                user.isMe = this.props.webSocketHubs.elementsHub.connectionId === member.connectionId;
                user.isOnline = true;
                user.connectionId = member.connectionId;
                user.deviceInfos = member.deviceInfos;
            } else {
                user = JSON.parse(JSON.stringify(users.find(u => u.userId === member.userId)));
                if (user) {
                    user.isMe = this.props.webSocketHubs.elementsHub.connectionId === member.connectionId;
                    user.isOnline = true;
                    user.deviceInfos = member.deviceInfos;
                    users.push({ ...user, connectionId: member.connectionId });
                }
            }
        });

        users = users.filter(u => membersParsed.find(m => m.connectionId === u.connectionId) || !u.connectionId);
        this.setState({ users });
    }

    handleMemberDisconnected = ({ connectionId, userId }) => {
        let users = JSON.parse(JSON.stringify(this.state.users));
        const user = users.find(u => u.connectionId === connectionId);
        if (user) {
            user.isOnline = false;
            if (users.filter(u => u.userId === userId).length > 1)
                users = users.filter(u => u.connectionId !== connectionId);
            else user.connectionId = null;
            this.setState({ users });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isToolbarExpanded: state.isToolbarExpanded,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        webSocketHubs: state.webSocketHubs,
        loginAsData: state.loginAsData,
        viewProjectAsData: state.viewProjectAsData,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization
    };
};

export default connect(mapStateToProps)(ConnectedUsers);