import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Divider, Form, Grid, Icon, Input, Modal, Segment, Select, Table, TextArea, TransitionablePortal } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
import { showToast } from '../../../utils/ToastsUtil';
// Ressources
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
// Services
import UsersService from '../../../services/UsersService';
import SubscriptionsService from '../../../services/SubscriptionsService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import SubscriptionsUtil from '../../../utils/SubscriptionsUtil';
import OrganizationsService from '../../../services/OrganizationsService';

const initialFormState = {
    subscription: {
        shortName: 'Pro',
        color: 'linear-gradient(90deg, rgba(109,60,158,1) 0%, rgba(167,89,245,1) 100%)',
        monthlyPrice: 0,
        yearlyPrice: 0,
        price: 0,
        duration: '1 - month',
        nbUsers: 1,
        nbProjects: -1,
        nbCollaborators: -1,
        nbTrees: 0,
        nbGreenSpaces: 0,
        nbFurnitures: 0,
        actions: true,
        customProjects: true,
        export: true,
        statistics: true,
        charts: true,
        canopyFormula: true,
        carbonStockFormula: true,
        amenityFormula: true,
        filters: true,
        coolingFormula: true,
        reminderMails: false,
        filesSharing: true,
        import: true,
        costEstimation: false,
        dataTable: true,
        customCharts: false,
        customFields: false,
        branding: false,
        thematicMaps: false,
        expertMode: false,
        helpPriority: true,
        organizationId: null
    },
    error: {
        organizationId: false,
        monthlyPrice: false,
        yearlyPrice: false,
        nbUsers: false,
        nbTrees: false,
        nbGreenSpaces: false,
        nbFurnitures: false
    },
    prices: {},
    priceWithoutDiscount: 0,
    discount: 0,
    vat: '21',
    observation: '',
    recipient: '',
    isLoading: false,
    isSaving: false,
    isExporting: false,
    newTemplateLabel: '',
    showExportQuoteForm: false
};

export default class SubscriptionForm extends Component {
    state = { ...initialFormState, freeOrganizations: [] };

    render() {
        const {
            subscription, priceWithoutDiscount, discount, freeOrganizations, isLoading, isSaving, isExporting, subscriptionTemplates, newTemplateLabel,
            showExportQuoteForm, vat, observation, recipient, error
        } = this.state;

        const subscriptionTemplateOptions = subscriptionTemplates?.map(template => ({ text: template.name, value: template.id })) || [];

        return (
            <Segment style={{ padding: '20px', width: '60vw' }}>
                <TransitionablePortal open={showExportQuoteForm} transition={{ animation: 'fade up', duration: 300 }}>
                    <Modal open mountNode={document.getElementById('admin-panel__grid')} style={{ maxHeight: '80%', overflowY: 'auto' }} onClose={() => { this.setState({ showExportQuoteForm: false }) }}>
                        <Form style={{ margin: '15px' }}>
                            <Form.Field
                                control={Input} width={8} type='number' step='0.01' min='0' label={i18n.t("Taux TVA") + ' (%) :'}
                                name='vat' value={vat} style={{ marginBottom: '15px' }}
                                onChange={(_, { value }) => this.setState({ vat: value })}
                            />
                            <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Remarque")} :</b></label>
                            <TextArea style={{ marginTop: '5px', marginBottom: '15px' }} value={observation} onChange={(_, { value }) => this.setState({ observation: value })} />
                            <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Destinataire")} :</b></label>
                            <TextArea style={{ marginTop: '5px' }} value={recipient} onChange={(_, { value }) => this.setState({ recipient: value })} />
                            <Button color='blue' style={{ marginTop: '10px' }} onClick={this.exportQuotePDF}>
                                <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
                                {i18n.t("Télécharger")}
                            </Button>
                        </Form>
                    </Modal>
                </TransitionablePortal>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'une nouvelle licence")}</h3>
                <Divider />
                <Form error style={{ textAlign: 'left' }}>
                    <Grid>
                        <Grid.Row style={{ padding: 0, paddingTop: '14px', marginBottom: '-14px' }}>
                            <Grid.Column style={{ display: 'flex', justifyContent: 'right' }}>
                                <Form style={{ display: 'flex', alignItems: 'center', marginRight: '42px' }}>
                                    <Select
                                        className='template-select' placeholder={i18n.t("Licences sauvegardées")} style={{ marginRight: '5px' }} disabled={!subscriptionTemplates} loading={!subscriptionTemplates} selectOnBlur={false}
                                        options={subscriptionTemplateOptions} value={this.state.subscriptionTemplateId || ''} onChange={this.restoreSubscriptionTemplate} selectOnNavigation={false}
                                    />
                                    <Input
                                        action placeholder={i18n.t("Nom du template")} value={newTemplateLabel || ''}
                                        onChange={(_, { value }) => this.setState({ newTemplateLabel: value.length > 30 ? value.substring(0, 30) : value })}
                                    >
                                        <input />
                                        <Button
                                            title={i18n.t("Sauvegarder le template")} icon='save' color='blue'
                                            disabled={isSaving} loading={isSaving} onClick={this.saveSubscriptionTemplate}
                                        />
                                    </Input>
                                </Form>
                                <Button color='blue' disabled={isLoading || isExporting} loading={isExporting} onClick={() => this.setState({ showExportQuoteForm: true })}>
                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
                                    {i18n.t("Devis")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0 }}>
                            <Grid.Column>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez l'organisation à lier")} error={error.organizationId}
                                    name='organizationId' label={<label>{i18n.t("Organisation")} :</label>}
                                    options={freeOrganizations} clearable value={subscription.organizationId || ''}
                                    selectOnBlur={false} selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ overflowY: 'auto', padding: 0 }}>
                            <Grid.Column>
                                <Table celled style={{ margin: '10px 0' }}>
                                    <Table.Body>
                                        {this.renderNumberFields([
                                            { title: i18n.t("Nombre maximum d'utilisateurs"), placeholder: 'Ex: 1', property: 'nbUsers' },
                                            { title: i18n.t("Nombre maximum d'arbres"), placeholder: 'Ex: 100', property: 'nbTrees' },
                                            { title: i18n.t("Nombre maximum d'espaces verts"), placeholder: 'Ex: 100', property: 'nbGreenSpaces' },
                                            { title: i18n.t("Nombre maximum de mobiliers"), placeholder: 'Ex: 100', property: 'nbFurnitures' }
                                        ])}
                                        {this.renderBooleanFields([
                                            { title: i18n.t("Thèmes"), property: 'branding' },
                                            { title: i18n.t("Rappels par mail"), property: 'reminderMails' },
                                            { title: i18n.t("Estimation des coûts"), property: 'costEstimation' },
                                            { title: i18n.t("Champs personnalisés"), property: 'customFields' },
                                            { title: i18n.t("Graphiques personnalisés"), property: 'customCharts' },
                                            { title: i18n.t("Cartes thématiques personnalisées"), property: 'thematicMaps' },
                                            { title: i18n.t("Module expert"), property: 'expertMode' }
                                        ])}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0 }}>
                            <Grid.Column>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Durée")} :</b></label><br />
                                <Form.Group widths='equal' style={{ marginBottom: 0 }}>
                                    <Form.Field
                                        control={Input} type='number' step='1' min='-1' placeholder='Ex: 24,99€'
                                        value={+subscription.duration.split(' - ')[0]}
                                        onChange={(_, { value }) => this.setState(prevState => ({ subscription: { ...prevState.subscription, duration: value + ' - ' + prevState.subscription.duration.split(' - ')[1] } }))}
                                    />
                                    <Form.Field
                                        control={Select} selectOnBlur={false}
                                        value={subscription.duration.split(' - ')[1]}
                                        options={[{ text: i18n.t("Mois"), value: 'month' }, { text: i18n.t("Année"), value: 'year' }]}
                                        onChange={(_, { value }) => this.setState(prevState => ({ subscription: { ...prevState.subscription, duration: prevState.subscription.duration.split(' - ')[0] + ' - ' + value } }))}
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0 }}>
                            <Grid.Column>
                                <Form.Group widths='equal' style={{ marginBottom: 0 }}>
                                    <Form.Field
                                        control={Input} type='number' step='0.01' min='0' label={<label>{i18n.t("Prix") + ' (€) :'}{+discount > 0 && <div style={{ color: 'grey', float: 'right' }}>{`(${i18n.t("Prix estimé")} : ${priceWithoutDiscount}€)`}</div>}</label>}
                                        name='price' value={subscription.price}
                                        onChange={this.handlePriceChange}
                                    />
                                    <Form.Field
                                        control={Input} type='number' step='0.01' min='0' label={`${i18n.t("Remise")} (%) :`}
                                        name='discount' value={discount}
                                        onChange={this.handleDiscountChange}
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button type='submit' color='green' className='form-button' disabled={isLoading} onClick={this.handleSubmit}>
                                    {i18n.t("Créer")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Segment>
        );
    }

    componentDidMount = () => {
        const prices = SubscriptionsUtil.getSubscriptionEstimatedPrice(this.state.subscription);
        this.setState(prevState => ({ prices, priceWithoutDiscount: prices.total, subscription: { ...prevState.subscription, price: prices.total } }));

        SubscriptionsService.getSubscriptionTemplates().then(templates => this.setState({ subscriptionTemplates: templates }));
        OrganizationsService.getFreeOrganizations().then(organizations => {
            if (Array.isArray(organizations))
                this.setState({
                    freeOrganizations: organizations.map(organization => {
                        const owner = organization.userOrganizations.find(uo => uo.isOwner)?.user;
                        return ({ text: `${organization.label} ${owner ? ` (${FormattersUtil.formatLastNameAndFirstName(owner.lastName, owner.firstName)})` : ''}`.trim(), value: organization.id })
                    })
                });
        });
    }

    componentDidUpdate = (_, prevState) => {
        const { subscription, discount } = this.state;
        const prices = SubscriptionsUtil.getSubscriptionEstimatedPrice(this.state.subscription);
        if (JSON.stringify(prices) !== JSON.stringify(this.state.prices) || prevState.subscription.duration !== this.state.subscription.duration) {
            let price = Math.round((subscription.duration.split(' - ')[1] === 'month'
                ? subscription.duration.split(' - ')[0] * prices.total
                : subscription.duration.split(' - ')[0] * prices.total * 12) * 100) / 100;
            const priceWithoutDiscount = price;
            if (discount) price -= price * discount / 100;
            this.setState(prevState => ({ prices, priceWithoutDiscount, subscription: { ...prevState.subscription, price } }));
        }
    }

    renderNumberFields = (fields) => {
        const { prices } = this.state;
        return fields.map((field) => (
            <Table.Row>
                <Table.Cell style={{ padding: '3px 11px' }}>{field.title}</Table.Cell>
                <Table.Cell style={{ padding: '3px 11px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: 'fit-content' }}>
                        <Form.Field
                            control={Input} placeholder={field.placeholder} style={{ width: '250px' }}
                            name={field.property} value={this.state.subscription[field.property]}
                            onChange={this.handleNumberChange} error={this.state.error[field.property]}
                        />
                        {prices[field.property] > 0 && <span style={{ position: 'absolute', right: '10px', fontWeight: 'bold' }}>{prices[field.property].toFixed(2)}€/mois</span>}
                    </div>
                </Table.Cell>
            </Table.Row>
        ));
    }

    renderBooleanFields = (fields) => {
        const { prices } = this.state;
        return fields.map(field => (
            <Table.Row>
                <Table.Cell style={{ padding: '3px 11px' }}>{field.title}</Table.Cell>
                <Table.Cell style={{ padding: '3px 11px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Field
                            control={Checkbox} toggle name={field.property} checked={this.state.subscription[field.property]}
                            onChange={this.handleCheckboxChange} style={{ display: 'flex' }}
                        />
                        {prices[field.property] > 0 && <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{prices[field.property].toFixed(2)}€/mois</span>}
                    </div>
                </Table.Cell>
            </Table.Row>
        ));
    }

    handleNumberChange = (_, { name, value }) => {
        if (isNaN(value)) return;
        if (parseFloat(value).toString() === value.toString() && parseFloat(value) < 0) value = '0';
        this.setState({ subscription: { ...this.state.subscription, [name]: value }, error: { ...this.state.error, [name]: false } });
    }

    handleChange = (_, { name, value }) => this.setState({ subscription: { ...this.state.subscription, [name]: value }, error: { ...this.state.error, [name]: false } });

    handlePriceChange = (_, { value }) => {
        const discount = Math.round((this.state.priceWithoutDiscount - value) / this.state.priceWithoutDiscount * 10000) / 100;
        this.setState(prevState => ({ discount, subscription: { ...prevState.subscription, price: value } }));
    }

    handleDiscountChange = (_, { value }) => {
        if (Number(value) > 100) value = 100;
        const price = this.state.priceWithoutDiscount - (this.state.priceWithoutDiscount * value / 100);
        this.setState(prevState => ({ discount: value, subscription: { ...prevState.subscription, price } }));
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState({ subscription: { ...this.state.subscription, [name]: checked } });
    }

    saveSubscriptionTemplate = () => {
        const { subscription, newTemplateLabel } = this.state;

        const subscriptionTemplate = { ...subscription, name: newTemplateLabel };
        delete subscriptionTemplate.id;
        this.setState({ isSaving: true });
        SubscriptionsService.addTemplate(subscriptionTemplate).then(subscriptionTemplate => {
            this.setState(prevState => ({
                newTemplateLabel: '', isSaving: false,
                subscriptionTemplates: subscriptionTemplate ? [...prevState.subscriptionTemplates, subscriptionTemplate] : prevState.subscriptionTemplates
            }));
        });
    }

    restoreSubscriptionTemplate = (_, { value }) => {
        const { id, name, ...subscriptionTemplate } = this.state.subscriptionTemplates.find(st => st.id === value);
        this.setState(prevState => ({ subscription: { ...prevState.subscription, ...subscriptionTemplate } }));
    }

    exportQuotePDF = () => {
        const { subscription, vat, recipient, observation } = this.state;
        this.setState({ isExporting: true, showExportQuoteForm: false });
        SubscriptionsService.exportQuotePDF(subscription, vat, recipient, observation).then(() => this.setState({ isExporting: false, recipient: '', observation: '' }));
    }

    handleSubmit = () => {
        let { subscription } = this.state;
        let isValid = true;
        let error = { ...initialFormState.error };

        isValid = this.isValid(error);
        if (isValid) {
            subscription.monthlyPrice = parseFloat(subscription.monthlyPrice);
            subscription.yearlyPrice = parseFloat(subscription.yearlyPrice);
            subscription.nbUsers = parseFloat(subscription.nbUsers);
            subscription.nbProjects = parseFloat(subscription.nbProjects);
            subscription.nbTrees = parseFloat(subscription.nbTrees);
            subscription.nbGreenSpaces = parseFloat(subscription.nbGreenSpaces);
            subscription.nbCollaborators = parseFloat(subscription.nbCollaborators);

            this.setState({ isLoading: true });
            SubscriptionsService.addSubscription(subscription).then(response => {
                if (response) this.setState(prevState => ({ ...initialFormState, freeOrganizations: prevState.freeOrganizations.filter(organization => organization.Id !== subscription.organizationId) }));
            });
        } else this.setState({ error: error });
    }

    isValid = (error = []) => {
        const { subscription } = this.state;
        let isValid = true;

        const addError = (property) => {
            isValid = false;
            error[property] = true;
        }

        if (!subscription.organizationId)
            addError('organizationId');
        if (subscription.monthlyPrice === '')
            addError('monthlyPrice');
        if (subscription.yearlyPrice === '')
            addError('yearlyPrice');
        if (subscription.nbUsers === '') // === '' utile car peut être 0
            addError('nbUsers');
        if (subscription.nbTrees === '')
            addError('nbTrees');
        if (subscription.nbGreenSpaces === '')
            addError('nbGreenSpaces');
        if (subscription.nbFurnitures === '')
            addError('nbFurnitures');

        return isValid;
    }
}