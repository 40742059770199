import React, { Component, createRef } from 'react';
// Composants
import Dropzone from 'dropzone';
import { Button, Loader, Dimmer, Input, List, Dropdown, Progress, Grid, Message, Form, Ref } from 'semantic-ui-react'
import ContextMenu from '../Utils/ContextMenu';
// Librairies
import i18n from '../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faClipboard, faDownload, faEraser, faEye, faFile, faFileAlt, faFileArchive, faFileCsv, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord, faSave, faTimesCircle, faTrash, faTrashAlt, faUpload } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
import tinycolor from 'tinycolor2';
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
import { contextMenu } from 'react-contexify';
// Services
import FileInfosService from '../../services/FileInfosService';
// Utils
import DatesUtil from '../../utils/DatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import { showToast } from '../../utils/ToastsUtil';
import FilesUtil from '../../utils/FilesUtil';
import StylesUtil from '../../utils/StylesUtil';
import FileInfosUtil from '../../utils/FileInfosUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import RightsUtil from '../../utils/RightsUtil';

const MAX_SIZE = 104857600; // 100MB
const initialMessage = i18n.t("Chargement en cours...");

const initialRenameState = {
    id: 0,
    isLoading: false,
    data: {
        baseName: ''
    },
    error: {
        baseName: false
    }
}

const initialState = {
    isLoading: true,
    message: initialMessage,
    search: '',
    files: null,
    filesStatus: [],
    selectedFiles: [],
    rename: initialRenameState,
    removeIds: [],
    dragEnter: false,
    sort: 'old'
}

class FilesGallery extends Component {
    state = {
        ...initialState
    }

    render() {
        const { isLoading, message, search, rename, removeIds, files, selectedFiles, dragEnter, sort } = this.state;
        const { isDarkTheme, isOnline, isLandscape } = this.props;
        const fileInfos = this.getFilteredFiles();
        const fileInfosGrouped = this.groupFiles(fileInfos);
        const currentStorageUsed = this.getCurrentStorageUsed();
        const fileInfosToManage = rename.id !== 0 ? files.filter(x => x.id === rename.id) : removeIds.length > 0 ? files.filter(x => removeIds.includes(x.id)) : [];
        const isRenameDisabled = fileInfosToManage.length ? rename.error.baseName || fileInfosToManage.find(x => x.baseName === rename.data.baseName) || rename.isLoading : false;
        const isSelectAllDisabled = files ? files.filter(x => !isNaN(x.id)).length === selectedFiles.length : true;
        const hasRight = this.checkIfUserHasRight();
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;

        return (<>
            {isLoading ?
                <Dimmer active inverted>
                    <Loader inverted>{message}</Loader>
                </Dimmer>
                : <>
                    <div className='modal-content'>
                        <div className='modal-content-header' style={{ display: 'flex', flexDirection: isMobileOnly && !isLandscape && 'column', marginTop: '10px', marginBottom: '20px', alignItems: 'center' }}>
                            <Form style={{ flexGrow: 1, width: isMobileOnly && '100%' }}>
                                <Form.Field
                                    control={Input} type='text' placeholder={i18n.t("Rechercher un fichier...")} name='search' value={search || ''}
                                    autoComplete='off' onChange={this.handleChange} style={{ width: '100%' }}
                                />
                            </Form>
                            <Button.Group style={{ marginLeft: (!isMobileOnly || isLandscape) && '15px', marginRight: isMobile && !isLandscape && '15px', marginTop: isMobileOnly && !isLandscape && '10px' }}>
                                <Dropdown floating button icon='sort' className='icon button--secondary' title={i18n.t("Trier les fichiers")} onClick={() => contextMenu.hideAll()}>
                                    <Dropdown.Menu style={{ zIndex: 10000 }}>
                                        <Dropdown.Header content={i18n.t("Trier les fichiers")} />
                                        <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                                        <Dropdown.Item
                                            text={this.renderDropdownText(i18n.t("Le plus récent"), sort, 'recent', checkIcon, null)}
                                            onClick={() => this.setState({ sort: 'recent' })}
                                        />
                                        <Dropdown.Item
                                            text={this.renderDropdownText(i18n.t("Le plus ancien"), sort, 'old', checkIcon, null)}
                                            onClick={() => this.setState({ sort: 'old' })}
                                        />
                                        <Dropdown.Item
                                            text={this.renderDropdownText(i18n.t("Nom"), sort, 'name', checkIcon, null)}
                                            onClick={() => this.setState({ sort: 'name' })}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button
                                    icon='list' className='icon button--secondary' size='mini' title={i18n.t("Sélectionner tous les éléments")} disabled={isSelectAllDisabled}
                                    onClick={() => this.setState({ selectedFiles: files.map(fileInfo => fileInfo.id).filter(id => !isNaN(id)) })}
                                />
                                <Button
                                    icon='times circle' className='icon button--secondary' size='mini' title={i18n.t("Annuler la sélection")}
                                    disabled={!selectedFiles.length} onClick={() => this.setState({ selectedFiles: [] })}
                                />
                                <Button
                                    icon='download' className='icon button--secondary' size='mini' title={i18n.t("Télécharger la sélection en ZIP")}
                                    disabled={!selectedFiles.length || !isOnline} onClick={this.downloadAndCompressFilesToZip}
                                />
                                {hasRight && <Button
                                    icon='trash' color='red' size='mini' title={i18n.t("Supprimer la sélection")}
                                    disabled={!selectedFiles.length} onClick={() => this.setState({ removeIds: selectedFiles.map(id => id) })}
                                />}
                            </Button.Group>
                            {(!isMobile || isLandscape) &&
                                <div style={{ marginLeft: (!isMobileOnly || isLandscape) && '15px', marginRight: !isMobileOnly && hasRight && '15px', marginTop: isMobileOnly && !isLandscape && '10px', width: isMobileOnly && '100%' }}>
                                    <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{i18n.t("Stockage utilisé")} ({FormattersUtil.numberPrettyBytesSI(currentStorageUsed)} / {FormattersUtil.numberPrettyBytesSI(this.props.maxSize || MAX_SIZE)})</div>
                                    <Progress
                                        value={currentStorageUsed} total={this.props.maxSize || MAX_SIZE} size='small' color='blue'
                                        style={{ width: !isMobileOnly ? '250px' : '100%', margin: 0 }}
                                    />
                                </div>}
                            {!isMobileOnly && hasRight && this.renderAddButton()}
                        </div>
                        <div id='dropZone' className='modal-content-body' style={dragEnter ? { backgroundColor: 'rgba(250,250,250,0.05)', borderRadius: '20px', border: isDarkTheme ? '2px dashed white' : '2px dashed black' } : { padding: '2px 3px' }}>
                            {files.length > 0 && this.renderFileInfosGroups(fileInfosGrouped)}
                            {(!fileInfos?.length || dragEnter) &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                                    <FontAwesomeIcon icon={!dragEnter ? faFileAlt : faUpload} size='6x' style={{ marginTop: 'auto' }} />
                                    <h4 style={{ marginBottom: 'auto' }}>{!dragEnter ? i18n.t("Aucun résultat trouvé") : i18n.t("Glissez-déposez les fichiers ici")}</h4>
                                </div>}
                        </div>
                        {isMobileOnly && <div className='modal-content-footer'>{this.renderAddButton()}</div>}
                        {fileInfosToManage.length > 0 &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ rename: initialRenameState, removeIds: [] }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{rename.id !== 0 ? i18n.t("Renommer") : i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        {fileInfosToManage.map((fileInfo, index) => <div key={index} style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{fileInfo.baseName}.{fileInfo.extension}</div>)}
                                                    </div>
                                                    {rename.id !== 0 ? <>
                                                        <Form>
                                                            <Ref innerRef={this.renameRef}>
                                                                <Form.Field
                                                                    control={Input} placeholder={i18n.t("Veuillez saisir un nom")} name='baseName' value={rename.data.baseName || ''}
                                                                    error={rename.error.baseName} onChange={this.handleRenameChange} style={{ marginTop: '10px', marginBottom: '10px' }}
                                                                />
                                                            </Ref>
                                                        </Form>
                                                        <Button color='grey' onClick={() => this.setState({ rename: initialRenameState, removeIds: [] })}>
                                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                        </Button>
                                                        <Button color='green' disabled={(isRenameDisabled) ? true : false} loading={rename.isLoading} onClick={this.renameFile}>
                                                            <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                                        </Button>
                                                    </>
                                                        : <>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {removeIds.length === 1
                                                                    ? i18n.t("Êtes-vous certain de vouloir supprimer ce fichier ? Vous ne pourrez plus le récupérer par la suite.")
                                                                    : i18n.t("Êtes-vous certain de vouloir supprimer ces fichiers ? Vous ne pourrez plus les récupérer par la suite.")}
                                                            </div>
                                                            <Button color='grey' onClick={() => this.setState({ rename: initialRenameState, removeIds: [] })}>
                                                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                            </Button>
                                                            <Button color='red' onClick={() => this.removeFiles(removeIds)}>
                                                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                            </Button>
                                                        </>}
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                    </div>
                    <ContextMenu id='context-menu-files-gallery' items={this.getContextMenuItems()} />
                </>}
        </>);
    }

    componentDidMount = () => {
        if (!this.props.project || this.checkIfUserHasRight()) {
            Dropzone.autoDiscover = false;
            this.dropZone = null;
        }

        this.renameRef = createRef();
        this.setState({ files: this.props.files || null, isLoading: this.props.isLoading, selectedFiles: [] });
    }

    componentDidUpdate = (prevProps) => {
        const shouldUpdate = prevProps.isLoading !== this.props.isLoading || JSON.stringify(prevProps.files) !== JSON.stringify(this.props.files);
        const isLoading = prevProps.isLoading !== this.props.isLoading ? this.props.isLoading : this.state.isLoading;
        const files = JSON.stringify(prevProps.files) !== JSON.stringify(this.props.files) ? this.props.files : this.state.files;

        if (shouldUpdate) this.setState({ isLoading, files });
        if (!this.dropZone && !this.state.isLoading && (!this.props.project || this.checkIfUserHasRight())) {
            this.dropZone = new Dropzone('div#dropZone', { clickable: false, previewsContainer: false, autoProcessQueue: false, url: '/' });
            this.dropZone.on('addedfiles', files => this.setState({ dragEnter: false }, () => this.uploadFiles(files)));
            this.dropZone.on('dragover', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.setState({ dragEnter: true });
            });
            this.dropZone.on('dragleave', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    this.setState({ dragEnter: false });
                }, 200);
            });
        }
    }

    renderAddButton = () => {
        const maxSize = this.props.maxSize || MAX_SIZE;
        const isMaxSizeReached = this.getCurrentStorageUsed() >= maxSize ? true : false;
        return (<>
            <Button color='green' as='label' htmlFor='file-input' disabled={isMaxSizeReached} style={{ width: isMobileOnly && '100%' }}>
                <FontAwesomeIcon icon={faUpload} style={{ marginRight: '10px' }} />{i18n.t("Ajouter")}
            </Button>
            <input type='file' id='file-input' multiple hidden onChange={({ target }) => {
                this.uploadFiles(Array.from(target.files));
                target.value = '';
            }} />
        </>);
    }

    renderFileInfosGroups = (fileInfosGrouped) => {
        const { isDarkTheme } = this.props;
        const backgroundColor = tinycolor(document.body.style.getPropertyValue('--red-100'));
        backgroundColor.setAlpha(.35);
        const borderColor = tinycolor(document.body.style.getPropertyValue('--red-100'));
        backgroundColor.setAlpha(.2);

        return Object.keys(fileInfosGrouped).map((replantingDate, index) => {
            const fileInfos = fileInfosGrouped[replantingDate];
            return (
                <List celled inverted={isDarkTheme} key={index} style={replantingDate !== 'none' ? { backgroundColor: backgroundColor.toPercentageRgbString(), borderColor: borderColor.toPercentageRgbString(), margin: 0 } : { margin: 0 }}>
                    {replantingDate !== 'none' &&
                        <List.Item style={{ display: 'flex', paddingTop: '4px', paddingBottom: '4px' }}>
                            <h3>Avant replantation du {DatesUtil.getFormattedLocaleDateString(replantingDate)}</h3>
                        </List.Item>}
                    {this.renderFileInfos(fileInfos)}
                </List>
            );
        });
    }

    renderFileInfos = (fileInfos) => {
        const { isDarkTheme } = this.props;
        const textColor = isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey';
        let items = [];

        fileInfos.forEach((fileInfo, index) => {
            const extension = fileInfo.extension.toLowerCase();
            const iconInfo = ['xls', 'xlsx'].includes(extension) ? { icon: faFileExcel, color: '#0F763F' } : extension === 'pdf'
                ? { icon: faFilePdf, color: '#EF4646' } : ['doc', 'docx'].includes(extension) ? { icon: faFileWord, color: '#2A75CA' }
                    : extension === 'csv' ? { icon: faFileCsv, color: '#79BC7B' } : ['png', 'jpg', 'jpeg', 'gif'].includes(extension) ? { icon: faFileImage, color: '#71AAC9' }
                        : ['mp4', 'wmv', 'avi'].includes(extension) ? { icon: faFileVideo, color: '#E58E09' } : ['zip', 'rar', '7z'].includes(extension) ? { icon: faFileArchive, color: '#94491B' }
                            : { icon: faFile, color: '#B7B7B9' };
            const user = this.props.projectCollaborators?.find(x => x.userId === fileInfo.createdBy)?.user;
            const username = user ? FormattersUtil.formatLastNameAndFirstName(user.lastName, user.firstName) + ', ' : '';
            const status = this.state.filesStatus.find(x => x.id === fileInfo.id);
            const isLoading = status ? true : false;
            let loadingColor = tinycolor(document.body.style.getPropertyValue('--grey-100'));
            loadingColor.setAlpha(.4);
            const isSelected = this.state.selectedFiles.find(id => id === fileInfo.id);
            let selectedColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
            selectedColor.setAlpha(isDarkTheme ? .2 : .4);
            const isViewable = ['png', 'jpg', 'jpeg', 'pdf'].includes(fileInfo.extension);

            items.push(
                <List.Item
                    key={index} onClick={(e) => this.handleRowClick(e, fileInfo.id)} style={{
                        display: 'flex', paddingTop: '8px', paddingBottom: '8px', backgroundColor: isLoading ? loadingColor : isSelected && selectedColor,
                        pointerEvents: isLoading && 'none', opacity: isLoading && '50%', cursor: 'pointer'
                    }} onContextMenu={(event) => this.handleContextMenu(event, fileInfo)}
                >
                    {isLoading ? <Loader active inline size='small' style={{ marginLeft: '8px', marginRight: '20px', alignSelf: 'center' }} />
                        : isSelected ? <FontAwesomeIcon icon={faCheckCircle} size='2x' style={{ marginLeft: '7px', marginRight: '16px', color: 'var(--primary-100)' }} />
                            : <FontAwesomeIcon icon={iconInfo.icon} size='2x' style={{ marginLeft: '10px', marginRight: '20px', color: iconInfo.color }} />}
                    <List.Content>
                        <List.Header style={{ textOverflow: 'ellipsis' }}>
                            <span className='link' title={i18n.t("Télécharger le fichier")} onClick={() => this.downloadFile(fileInfo)}>{fileInfo.baseName}</span>
                        </List.Header>
                        <div style={{ color: textColor, fontSize: '12px', cursor: 'default' }}>
                            {extension.toUpperCase()}, {FormattersUtil.numberPrettyBytesSI(fileInfo.size)}
                        </div>
                    </List.Content>
                    {isLoading && <div style={{ display: 'inline-block', alignSelf: 'center', marginLeft: 'auto', marginRight: '50px' }}>{`${status.message}${status.percentage >= 0 ? `(${status.percentage}%)` : ''}`}</div>}
                    {!isMobileOnly &&
                        <div
                            style={{
                                display: 'inline-block', alignSelf: 'center', marginLeft: !isLoading && 'auto', marginRight: '10px', color: textColor,
                                fontSize: '12px', cursor: 'default'
                            }}
                        >
                            {username}{DatesUtil.getFormattedLocaleDateString(fileInfo.createdAt)}
                        </div>}
                    <Dropdown icon='ellipsis vertical' direction='left' className='icon' style={{ alignSelf: 'center', marginLeft: isMobileOnly && 'auto' }}>
                        <Dropdown.Menu>
                            <Dropdown.Menu scrolling>
                                {this.renderOptions(fileInfo, isViewable)}
                            </Dropdown.Menu>
                        </Dropdown.Menu>
                    </Dropdown>
                </List.Item>);
        });

        return items;
    }

    renderOptions = (fileInfo, isViewable) => {
        const hasRight = this.checkIfUserHasRight();
        return (<>
            {isViewable && <Dropdown.Item icon='eye' text={i18n.t("Consulter")} disabled={!this.props.isOnline} onClick={() => this.viewFile(fileInfo)} />}
            <Dropdown.Item icon='clipboard' text={i18n.t("Copier le lien")} onClick={() => this.copyFileUrl(fileInfo)} />
            <Dropdown.Item icon='download' text={i18n.t("Télécharger")} disabled={!this.props.isOnline} onClick={() => this.downloadFile(fileInfo)} />
            {hasRight && <Dropdown.Item icon='erase' text={i18n.t("Renommer")} onClick={() => this.displayRenameForm(fileInfo)} />}
            {hasRight && <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} onClick={() => this.setState({ removeIds: [fileInfo.id] })} />}
        </>);
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    uploadFiles = (filesToUpload) => {
        let { files, filesStatus } = this.state;

        filesToUpload.forEach(file => {
            const maxSize = this.props.maxSize || MAX_SIZE;
            if (file?.size + this.getCurrentStorageUsed() <= maxSize) {
                const id = uuidv4();
                const extension = file.name.split('.').pop();
                const blobName = `${id}.${file.name.split('.').pop()}`;
                const tempFileInfo = {
                    id: id, projectId: this.props.project.id, userId: jwtDecode(new Cookies().get('token')).id,
                    blobName, baseName: file.name.replace(`.${extension}`, ''), extension: extension, elementId: this.props.elementId,
                    size: file.size, type: 'file', date: null
                };
                files.push(tempFileInfo);
                filesStatus.push({ id: id, message: i18n.t("Ajout du fichier en cours..."), percentage: 0 });
                this.setState({ files, filesStatus });

                setTimeout(() => {
                    const formData = new FormData();
                    formData.append('type', 'file');
                    formData.append('id', id);
                    formData.append('keepOrignal', true);
                    formData.append('projectId', this.props.project?.id || null);
                    formData.append('elementId', this.props.elementId || null);
                    formData.append('category', this.props.category || null);
                    formData.append('blobName', tempFileInfo.blobName);
                    formData.append('file', file);

                    const axiosOptions = {
                        onUploadProgress: (processEvent) => {
                            const { filesStatus } = this.state;
                            const { loaded, total } = processEvent;
                            let percent = Math.floor((loaded * 100) / total);
                            let status = filesStatus.find(status => status.id === id);
                            if (percent !== status.percentage) {
                                status.percentage = percent;
                                this.setState({ filesStatus });
                            }
                        }
                    }

                    FileInfosService.addFileInfo(formData, axiosOptions).then(response => {
                        files = this.state.files;
                        if (response) { // On supprime le fileInfo temporaire
                            files = files.filter(x => x.id !== id);
                            files.push(response);
                            if (response.elementId) WebSocketUtil.sendFileInfos(this.props.webSocketHubs, this.props.project.id, [response]);
                            else WebSocketUtil.sendFileInfosToUsers(this.props.webSocketHubs, this.props.projectCollaborators?.filter(up => up.user.id !== jwtDecode(new Cookies().get('token')).id).map(up => up.user.id), [response]);
                        }

                        filesStatus = this.state.filesStatus;
                        filesStatus = filesStatus.filter(x => x.id !== id);
                        this.setState({ files, filesStatus, message: initialMessage }, () => {
                            this.props.setFiles(files);
                            if (this.props.isOnline) showToast('file_added');
                            else showToast('will_sync');
                        });
                    });
                });
            } else if (!file) showToast('files_limit_reached');
        });
    }

    downloadAndCompressFilesToZip = () => {
        const { files, selectedFiles } = this.state;
        FilesUtil.downloadAndCompressFilesToZip('file', this.props.project.label, files.filter(x => selectedFiles.includes(x.id)));
        this.setState({ selectedFiles: [] });
    }

    downloadFile = (fileInfo) => {
        if (!this.props.isOnline) return;
        this.setState(prevState => ({ filesStatus: [...prevState.filesStatus, { id: fileInfo.id, message: i18n.t("Téléchargement du fichier en cours...") }] }));
        setTimeout(() => {
            const url = FileInfosUtil.getUrl(fileInfo);
            Axios.get(url, { responseType: 'blob' }).then(response => {
                if (response?.data) {
                    const blob = new Blob([response.data]);
                    const fileUrl = URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.href = fileUrl;
                    tempLink.setAttribute('download', `${fileInfo.baseName}.${fileInfo.extension}`);
                    tempLink.click();
                    tempLink.remove();
                    let filesStatus = this.state.filesStatus;
                    filesStatus = filesStatus.filter(x => x.id !== fileInfo.id);
                    this.setState({ filesStatus })
                }
            });
        }, 250);
    }

    removeFiles = (ids) => {
        let { files, filesStatus } = this.state;

        ids.forEach(id => filesStatus.push({ id, message: i18n.t("Suppression du fichier en cours...") }));
        this.setState({ filesStatus, removeIds: [] });

        const isProjectFileInfo = files.find(f => ids.includes(f.id)).elementId ? false : true;
        setTimeout(() => {
            FileInfosService.deleteFileInfos(ids).then((response) => {
                if (response) files = files.filter(x => !ids.includes(x.id));
                let filesStatus = this.state.filesStatus;
                filesStatus = filesStatus.filter(x => !ids.includes(x.id));
                let selectedFiles = this.state.selectedFiles;
                ids.forEach(id => {
                    const index = selectedFiles.indexOf(id);
                    if (index >= 0) selectedFiles.splice(index, 1);
                });
                this.setState({ files, filesStatus, selectedFiles }, () => {
                    if (response) {
                        if (!isProjectFileInfo) WebSocketUtil.removeFileInfos(this.props.webSocketHubs, this.props.project.id, ids);
                        else WebSocketUtil.removeFileInfosToUsers(this.props.webSocketHubs, this.props.projectCollaborators?.map(up => up.user.id), ids);
                    }
                    this.props.setFiles(files);
                });
            });
        }, 250);
    }

    displayRenameForm = (fileInfo) => {
        this.setState({ rename: { ...initialRenameState, id: fileInfo.id, data: { baseName: fileInfo.baseName } } }, () => {
            if (this.renameRef.current) {
                const input = this.renameRef.current.querySelector('input');
                input.focus();
                input.setSelectionRange(0, input.value.length);
            }
        });
    }

    renameFile = () => {
        const { files } = this.state;
        this.setState(prevState => ({ rename: { ...prevState.rename, isLoading: true } }));
        let { rename } = this.state;
        let fileInfo = files.find(x => x.id === rename.id);
        fileInfo.baseName = rename.data.baseName;

        FileInfosService.updateFileInfo(fileInfo).then(response => {
            if (response?.data) {
                const updatedFileInfo = response.data;
                const index = files.findIndex(x => x.id === updatedFileInfo.id);
                if (index >= 0) files[index] = updatedFileInfo;
                if (updatedFileInfo.elementId) WebSocketUtil.updateFileInfos(this.props.webSocketHubs, this.props.project.id, [updatedFileInfo]);
                else WebSocketUtil.updateFileInfosToUsers(this.props.webSocketHubs, this.props.projectCollaborators?.map(up => up.user.id), [updatedFileInfo]);
                this.setState({ files, rename: initialRenameState }, () => this.props.setFiles(files));
            } else this.setState({ rename: initialRenameState });
        });
    }

    handleRowClick = ({ target }, id) => {
        if (target.getAttribute('role') === 'listitem' || ['svg', 'path'].includes(target.nodeName)) {
            let { selectedFiles } = this.state;
            const index = selectedFiles.indexOf(id);
            if (index >= 0) selectedFiles.splice(index, 1);
            else selectedFiles.push(id);
            this.setState({ selectedFiles });
        }
    }

    viewFile = (fileInfo) => {
        Axios.get(FileInfosUtil.getUrl(fileInfo), { responseType: 'blob' }).then(response => {
            const extension = fileInfo.extension;
            const type = ['png', 'jpg', 'jpeg'].includes(extension) ? 'image/png' : 'application/pdf';
            const file = new Blob([response.data], { type });
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl);
        });
    }

    copyFileUrl = (fileInfo) => {
        navigator.clipboard.writeText(FileInfosUtil.getUrl(fileInfo));
        showToast('link_copied');
    }

    getCurrentStorageUsed = () => {
        const fileInfos = this.state.files;
        let total = 0;
        if (!fileInfos) return 0;
        fileInfos.forEach(fileInfo => total += fileInfo.size);
        return total;
    }

    getFilteredFiles = () => {
        const { search, files, sort } = this.state;
        let filteredFiles = [];

        // Recherche
        if (search.trim() === '') filteredFiles = files || [];
        else {
            files.forEach(file => {
                if (FormattersUtil.getNormalizedString(file.baseName).toLowerCase().includes(FormattersUtil.getNormalizedString(search).toLowerCase()))
                    filteredFiles.push(file);
            });
            return filteredFiles;
        }
        // Tri
        if (sort === 'recent') filteredFiles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        else if (sort === 'old') filteredFiles.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        else if (sort === 'name') filteredFiles.sort((a, b) => FormattersUtil.getNormalizedString(a.baseName).localeCompare(FormattersUtil.getNormalizedString(b.baseName)));
        return filteredFiles;
    }

    groupFiles = (photos) => {
        const groupedPhotos = photos.reduce((previousValue, photo) => {
            (previousValue[photo.replantingDate || 'none'] = previousValue[photo.replantingDate || 'none'] || []).push(photo);
            return previousValue;
        }, {});

        const groups = {};
        Object.keys(groupedPhotos).sort((a, b) => a === 'none' ? -1 : b === 'none' ? 1 : new Date(b) - new Date(a)).forEach(replantingDate => {
            groups[replantingDate] = groupedPhotos[replantingDate];
        });

        return groups;
    }

    handleChange = (_, { name, value }) => this.setState({ [name]: value });
    handleRenameChange = (_, { name, value }) => {
        const error = !value?.length;
        this.setState(prevState => ({
            rename: {
                ...prevState.rename,
                data: { ...prevState.rename.data, [name]: value },
                error: { ...prevState.rename.error, [name]: error }
            }
        }));
    }

    checkIfUserHasRight = () => {
        if (this.props.readOnly) return false;
        if (this.props.loginAsData?.readOnly) return false;
        const token = new Cookies().get('token');
        if (!token) return false;
        const userId = jwtDecode(token).id;
        if (!userId) return false;
        const userBaseProject = this.props.projectCollaborators?.find(x => x.userId === userId);
        if (!userBaseProject) return false;
        const right = this.props.category === 'Arbre' ? 'trees' : this.props.category === 'Espace vert' ? 'greenSpaces' : this.props.category === 'Mobilier' ? 'furnitures' : this.props.category === 'Repère' ? 'markers' : null;
        return right ? RightsUtil.canWrite(this.props.rights[right]) : true;
    }

    handleContextMenu = (event, fileInfo) => {
        this.contextMenuElement = fileInfo;
        contextMenu.show({ event, id: 'context-menu-files-gallery' });
        this.forceUpdate();
    }

    getContextMenuItems = () => {
        const fileInfo = this.contextMenuElement;
        if (!fileInfo) return [];
        const hasRight = this.checkIfUserHasRight();
        const isViewable = ['png', 'jpg', 'jpeg', 'pdf'].includes(fileInfo.extension);

        return [
            {
                icon: faEye,
                label: i18n.t("Consulter"),
                isVisible: () => isViewable,
                isDisabled: () => !this.props.isOnline,
                onClick: () => this.viewFile(fileInfo)
            },
            {
                icon: faClipboard,
                label: i18n.t("Copier le lien"),
                onClick: () => this.copyFileUrl(fileInfo)
            },
            {
                icon: faDownload,
                label: i18n.t("Télécharger"),
                isDisabled: () => !this.props.isOnline,
                onClick: () => this.downloadFile(fileInfo)
            },
            {
                icon: faEraser,
                label: i18n.t("Renommer"),
                isVisible: () => hasRight,
                onClick: () => this.displayRenameForm(fileInfo)
            },
            {
                icon: faTrashAlt,
                label: i18n.t("Supprimer"),
                className: 'delete',
                isVisible: () => hasRight,
                onClick: () => this.setState({ removeIds: [fileInfo.id] })
            }
        ];
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        rights: state.rights,
        projectCollaborators: state.projectCollaborators,
        userProjects: state.userProjects,
        webSocketHubs: state.webSocketHubs,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setProject
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(FilesGallery));