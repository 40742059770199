import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// Services
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class BookmarksService {
    static addBookmark(bookmark, projectId) {
        return Axios.post(BACKEND_ENDPOINT + 'bookmarks/' + projectId, bookmark, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let bookmark = { ...response.data };
            if (!bookmark.name?.trim() || bookmark.name?.length > 30)
                bookmark.name = 'Bookmark';
            bookmark.latlng = [bookmark.lat, bookmark.lng];

            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'bookmarks/?projectId=' + projectId + '&userId=' + jwtDecode(new Cookies().get('token')).id, (body) => {
                body.push(bookmark);
                return body;
            }).then(() => {
                showToast('bookmark_added');
                bookmark.id = String(bookmark.id)
                return bookmark;
            });
        }, () => {
            return;
        });
    }

    static removeBookmark(bookmarkId, projectId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'bookmarks/?projectId=' + projectId + '&userId=' + jwtDecode(new Cookies().get('token')).id, (body) => {
            return body.filter(bookmark => String(bookmark.id) !== bookmarkId);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'bookmarks/' + bookmarkId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('bookmark_deleted');
            return response.data;
        }, () => {
            showToast('bookmark_deletion_failed');
            return;
        });
    }

    static getBookmarks(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'bookmarks/?projectId=' + projectId + '&userId=' + jwtDecode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (Array.isArray(response.data)) {
                response.data.forEach(bookmark => {
                    bookmark.latlng = [bookmark.lat, bookmark.lng];
                    bookmark.id = String(bookmark.id) //! Important car leaflet-bookmarks compare l'id sous forme de string pour trouver le bon bookmark
                });
            }
            return response.data;
        }, () => {
            return;
        });
    }
}