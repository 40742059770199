import React, { Component } from 'react';
// Composants
import { Form, Button, Message, Tab } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormMemberList from '../../../Lists/FormMemberList';
import PopupMessage from '../../../Utils/PopupMessage';
// Librairies
import { difference, featureCollection, multiPolygon, polygon } from '@turf/turf';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties, setProjectListState, setExitFormWithChanges } from '../../../../actionCreators/componentsActions';
import { setNewProject, setProject, setProjects, setUserProjects } from '../../../../actionCreators/projectsActions';
import { setRequest } from '../../../../actionCreators/appActions';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
// Ressources
import countryList from '../../../../resources/countryList';
// Styles
import ProjectMainSettingsForm from './ProjectMainSettingsForm';
import ProjectElementsSettingsForm from './ProjectElementsSettingsForm';
import ProjectAdvancedSettingsForm from './ProjectAdvancedSettingsForm';
// Services
import ProjectsService from '../../../../services/ProjectsService';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../../utils/WebSocketUtil';
import GeometriesUtil from '../../../../utils/GeometriesUtil';
import TasksUtil from '../../../../utils/TasksUtil';

const initialError = {
    hidden: true,
    messages: [],
    label: false,
    surroundings: false
};

class ProjectForm extends Component {
    state = {
        isLoading: false,
        isCreating: false,
        initialProject: null,
        project: null,
        isRecommandation: false,
        showDuplicationPopup: false,
        duplicateElements: true,
        ownerId: null,
        municipalities: null,
        error: initialError,
        boundsToDelete: null
    }

    render() {
        const { projectToDuplicate, projectToEdit } = this.props;
        const { isLoading, project, ownerId, showDuplicationPopup, error } = this.state;
        const owner = this.props.userProjects?.find(up => up.projectRole.type === 'owner');
        const submitDisabled = (!this.hasProjectChanged() && (!owner || owner.userId === ownerId)) || !this.props.isOnline || this.props.loginAsData?.readOnly;
        const isDuplication = projectToDuplicate ? true : false;
        const handleSubmit = projectToEdit ? this.handleSubmitModification : this.handleSubmitCreation;
        if (project && !submitDisabled && !this.props.exitFormWithChanges) this.props.setExitFormWithChanges({ submit: handleSubmit });
        else if (project && submitDisabled && this.props.exitFormWithChanges) this.props.setExitFormWithChanges(null);

        return (
            <>
                {project &&
                    <Form onSubmit={handleSubmit} error className='modal-content'>
                        <div className='modal-content-body' style={{ marginTop: '10px', display: 'flex' }}>
                            <Tab panes={this.renderTabs(project, error)} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} />
                            {projectToEdit &&
                                <div style={{ position: 'absolute', top: '17px', right: 0 }}>
                                    <FormMemberList
                                        id={`project-form-${projectToEdit.id}`} stateToSend={this.state} setIsLoading={(isLoading) => this.setState({ isLoading })} updateForm={this.updateForm}
                                    />
                                </div>}
                        </div>
                        <div className='modal-content-footer' style={{ marginTop: '10px' }}>
                            <Message
                                error hidden={error.hidden} style={{ textAlign: 'left' }}
                                header='Erreur' list={error.messages}
                            />
                            {showDuplicationPopup &&
                                <PopupMessage
                                    title={i18n.t("Duplication du projet")}
                                    content={i18n.t("Votre demande de duplication a bien été prise en compte. Vous recevrez un email dès que celle-ci sera terminée.")}
                                    submit={this.props.finish}
                                />}
                            <Button className='form-button' type='submit' color='green' id='ed4JCCr4' disabled={submitDisabled} loading={isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{this.props.projectToEdit ? i18n.t("Valider") : isDuplication ? i18n.t("Dupliquer") : i18n.t("Créer")}
                            </Button>
                        </div>
                    </Form>}
            </>
        );
    }

    componentDidMount = () => {
        this.props.unlockEditedProperties(); // On débloque la sauvegarde des propriétés dans le store Redux
        if (this.props.editedProperties) { // Si un state a été sauvegardé, on le restore (contient les propriétés du formulaire mais aussi les données de projet)
            this.setState({ ...this.state, ...this.props.editedProperties }, () => { // Alors on remet ces propriétés dans le state
                this.props.setEditedProperties(null).then(this.props.unlockEditedProperties);
                if (this.props.layer) { // Si un layer a été dessiné
                    const newSurroundings = GeometriesUtil.invertPolygon(polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(this.props.layer.getLatLngs())));
                    this.setState({ // On stock le layer comme délimitations du projet
                        project: { ...this.state.project, surroundings: JSON.stringify(newSurroundings) },
                        error: initialError
                    });
                }
            });
        } else if (this.props.projectToEdit) {
            const owner = this.props.userProjects?.find(userProject => userProject.projectRole.type === 'owner');
            this.setState({ ownerId: owner?.userId || null });
            if (!this.state.municipalities?.length) this.loadMunicipalities();
            this.initProjectFormulaVersion();
        } else {
            if (!this.state.municipalities?.length) this.loadMunicipalities();
            const requiredFields = ProjectsUtil.getRequiredFields();
            const publicFields = ProjectsUtil.getPublicFields();
            const projectFormulaVersions = this.props.formulas.map(formula => ({ formulaVersionId: formula.formulaVersions[formula.formulaVersions.length - 1].id, projectId: 0 }));

            const currentFolder = this.props.projectListState?.currentFolder;
            const parentFolderId = currentFolder?.id || null;
            const path = currentFolder && `${currentFolder.path || '/'}${currentFolder.id}/`;
            this.setState(prevState => {
                let municipalities = this.state.municipalities;
                let municipality = 0;
                let project = {
                    userId: jwtDecode(new Cookies().get('token')).id,
                    status: 1,
                    trunkCircumferenceUnit: 'circumference',
                    ...(prevState.project || {}), projectFormulaVersions, parentFolderId, path,
                    requiredFields: JSON.stringify(requiredFields), publicFields: JSON.stringify(publicFields),
                };

                if (this.props.projectToDuplicate) {
                    project = JSON.parse(JSON.stringify(this.props.projectToDuplicate));
                    project.label += ' - ' + i18n.t('Copie');
                    municipality = JSON.parse(project.surroundings).id ?? -1;
                }

                ProjectsUtil.assignProjectFormulaVersionsType(project, this.props.formulas);
                return { project, municipality, municipalities };
            });
        }
    }

    componentDidUpdate = () => this.initProjectFormulaVersion();
    componentWillUnmount = () => {
        if (!this.props.editedProperties) { // Pour ne pas retirer les UP quand on modifie la zone géographique
            const { project } = this.state;
            const userProject = this.props.userProjects?.find(up => up.userId === jwtDecode(new Cookies().get('token')).id);
            if (!project.parentFolderId || userProject?.baseProjectId === project.id) this.props.setUserProjects(null);
            else this.props.setUserProjects(this.props.userProjects?.filter(up => up.baseProjectId !== project.id));
        }

        if (this.props.exitFormWithChanges) this.props.setExitFormWithChanges(null);
    }

    renderTabs = (project, error) => {
        const { isRecommandation, duplicateElements } = this.state;
        const requiredFields = project?.requiredFields;
        const organization = project.organization || this.props.activeOrganization;
        const isDuplication = this.props.projectToDuplicate ? true : false;
        // Tabs par défaut
        let tabs = [];

        if ((!this.props.projectToEdit && !this.props.projectToDuplicate) || !this.props.rights || this.props.rights.parameters)
            tabs.push({
                menuItem: i18n.t("Général"), render: () => <Tab.Pane style={{ flex: 1 }}>{
                    <ProjectMainSettingsForm
                        projectToEdit={project} handleChange={this.handleChange} handleDrawClick={this.handleDrawClick}
                        municipalities={this.state.municipalities} error={error} isDuplication={isDuplication}
                        isRecommandation={isRecommandation} duplicateElements={duplicateElements} handleCheckboxChange={this.handleCheckboxChange}
                    />
                }</Tab.Pane>
            });
        if ((!this.props.projectToEdit && !this.props.projectToDuplicate) || !this.props.rights || this.props.rights.publicFields)
            tabs.push({
                menuItem: isMobileOnly ? i18n.t("Modèle") : i18n.t("Modèle de données"), render: () => <Tab.Pane style={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column', flex: 1 }}>{
                    <ProjectElementsSettingsForm
                        project={project} subscription={organization?.subscription} updateSubEntitiesInProject={this.updateSubEntitiesInProject}
                        handleChange={this.handleChange} handleFieldCategory={this.handleFieldCategory}
                    />
                }</Tab.Pane>
            });
        if ((!this.props.projectToEdit && !this.props.projectToDuplicate) || (!this.props.rights || this.props.rights.requiredFields || this.props.rights.formulas))
            tabs.push({
                menuItem: isMobileOnly ? i18n.t("Avancés") : i18n.t("Paramètres avancés"), render: () => <Tab.Pane style={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column', flex: 1 }}>{
                    <ProjectAdvancedSettingsForm
                        projectToEdit={project} isDuplication={isDuplication} ownerId={this.state.ownerId} error={error} handleChange={this.handleChange}
                        handleFormulaVersionChange={this.handleFormulaVersionChange} subscription={organization?.subscription} updateSubEntitiesInProject={this.updateSubEntitiesInProject}
                    />}
                </Tab.Pane>
            });

        return tabs;
    }

    loadMunicipalities = () => {
        ProjectsService.getMunicipalities().then(response => {
            if (response) {
                // On map les communes pour qu'elles soient utilisables par le Select
                let municipalities = response.map(feature => {
                    const { country, postalCode, administrativeAreaLevel1, administrativeAreaLevel2 } = feature.properties;
                    const flag = countryList.find(cl => cl.value === country)?.flag;
                    let text = feature.properties.name;
                    let searchparams = [feature.properties.name];
                    if (postalCode) {
                        text += ` (${postalCode})`;
                        searchparams.push(postalCode);
                    }
                    if (administrativeAreaLevel1) {
                        text += ` (${administrativeAreaLevel1})`;
                        searchparams.push(administrativeAreaLevel1);
                    }
                    if (administrativeAreaLevel2) {
                        text += ` (${administrativeAreaLevel2})`;
                        searchparams.push(administrativeAreaLevel2);
                    }
                    if (country) searchparams.push(country);
                    return { text, value: feature.id, flag, searchparams };
                });

                municipalities.sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.text.toUpperCase();
                    const textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                if (this.props.projectToDuplicate || this.props.projectToEdit) {
                    const project = this.props.projectToDuplicate || this.props.projectToEdit;
                    const municipality = JSON.parse(project.surroundings).id ?? -1;
                    if (municipality === -1)
                        if (municipalities.length > 0 && municipalities[municipalities.length - 1].value !== -1)
                            municipalities = [...municipalities, { text: i18n.t("Zone personnalisée"), value: -1 }];
                        else if (municipalities.length === 0)
                            municipalities = [{ text: i18n.t("Zone personnalisée"), value: -1 }];
                }

                this.setState({ municipalities });
            }
        });
    }

    initProjectFormulaVersion = () => {
        let project = this.props.projectToEdit;
        const projectFormulaVersions = project?.projectFormulaVersions;
        if (!projectFormulaVersions?.length && !this.state.project) {
            project.projectFormulaVersions = this.props.formulas.map(formula => ({ formulaVersionId: formula.formulaVersions[formula.formulaVersions.length - 1].id, projectId: project.id }));
            this.setState({
                initialProject: JSON.parse(JSON.stringify(this.props.projectToEdit)),
                project: project
            });
        } else if (!this.state.project) {
            this.setState({
                initialProject: JSON.parse(JSON.stringify(this.props.projectToEdit)),
                project: project
            });
        }
    }

    handleDrawClick = () => { // Lorsqu'on clique pour dessiner une zone personnalisée
        this.props.setEditedProperties(this.state).then(() => { // On sauvegarde le state actuel
            if (this.state.project.surroundings) this.props.modifyProjectSurroundings();
            else this.props.drawProjectSurroundings(); // On appelle la méthode permettant de dessiner le polygone sur la carte
        });
    }

    handleChange = (name, value) => {
        let { ownerId } = this.state;
        let { themeId, priceListId } = this.state.project;
        if (['requiredFields', 'publicFields'].includes(name)) value = JSON.stringify(value);
        if (name === 'projectCustomFields' && !value.length) value = null;
        if (name === 'theme') themeId = value?.id || null;
        if (name === 'priceList') priceListId = value?.id || null;
        if (name === 'ownerId') ownerId = value || ownerId;
        this.setState(prevState => ({
            project: name !== 'ownerId' ? { ...prevState.project, themeId, priceListId, [name]: value } : prevState.project,
            error: { ...prevState.error, label: name === 'label' ? false : prevState.error.label },
            ownerId
        }), () => {
            if (this.props.projectToEdit) {
                clearTimeout(this.updateFormTimeout);
                const projectToEdit = this.props.projectToEdit;
                this.updateFormTimeout = setTimeout(() => {
                    this.updateFormTimeout = null;
                    if (projectToEdit) WebSocketUtil.updateForm(this.props.webSocketHubs, projectToEdit?.id, this.state);
                }, name === 'label' ? 1000 : 0);
            }
        });
    }

    updateSubEntitiesInProject = (subEntities = {}) => {
        if (!this.props.projectToEdit) return;

        this.setState(prevProps => ({
            initialProject: { ...prevProps.initialProject, ...subEntities },
            project: { ...prevProps.project, ...subEntities },
        }), () => {
            WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.project.id, this.state);
        });
    }

    handleCheckboxChange = (_, { name, checked }) => this.setState({ [name]: checked });
    handleFieldCategory = (category, shouldUpdate = false) => {
        let fieldCategories = JSON.parse(JSON.stringify(this.state.project.fieldCategories || []));
        const index = fieldCategories.findIndex(fc => String(fc.id) === category.id);
        if (index !== -1) {
            if (typeof category.id === 'string' && !isNaN(category.id))
                category.id = Number(category.id);

            if (shouldUpdate) fieldCategories[index] = category;
            else fieldCategories = fieldCategories.filter(fc => String(fc.id) !== String(category.id));
        } else fieldCategories.push(category);

        this.setState(prevState => ({ project: { ...prevState.project, fieldCategories } }), () => {
            if (this.props.projectToEdit)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.props.projectToEdit.id, this.state);
        });
    }

    handleFormulaVersionChange = (_, { name, value }) => {
        let projectFormulaVersions = JSON.parse(JSON.stringify(this.state.project.projectFormulaVersions));
        const index = projectFormulaVersions.findIndex(pfv => pfv.formulaVersionId === name);
        const formulaVersions = this.props.formulas.map(formula => (formula.formulaVersions)).flat();
        const newProjectFormulaVersion = formulaVersions.find(fv => fv.id === value);
        projectFormulaVersions[index] = { formulaVersionId: newProjectFormulaVersion.id, projectId: this.state.project.id };
        this.setState(prevState => ({
            project: { ...prevState.project, projectFormulaVersions }
        }), () => {
            if (this.props.projectToEdit)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.props.projectToEdit.id, this.state)
        });
    }

    hasProjectChanged = () => {
        const initialProject = JSON.parse(JSON.stringify(this.state.initialProject));
        const project = JSON.parse(JSON.stringify(this.state.project));
        if (project && !project.id) return true;
        if (!initialProject || !project) return false;

        const initialProjectCustomFields = (initialProject.projectCustomFields || []).sort((a, b) => a.customFieldId - b.customFieldId);
        initialProject.projectCustomFields = null;
        const projectCustomFields = (project.projectCustomFields || []).sort((a, b) => a.customFieldId - b.customFieldId) || [];
        project.projectCustomFields = null;

        return JSON.stringify(initialProject) !== JSON.stringify(project) || JSON.stringify(initialProjectCustomFields) !== JSON.stringify(projectCustomFields);
    }

    // TODO Gérer l'OrderConfig
    handleSubmitCreation = () => {
        this.setState({ isCreating: true });

        let flag = true, messages = [];
        let errors = { label: false, municipality: false };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            flag = false;
        }

        let project = JSON.parse(JSON.stringify(this.state.project));
        if (!project.label?.trim()) addError('label', i18n.t("Le nom de projet ne peut être vide"));
        if (!project.surroundings) addError('municipality', i18n.t("Veuillez sélectionner une zone géographique"));

        if (flag) {
            const fieldCategoriesWithTempId = project.fieldCategories ? JSON.parse(JSON.stringify(project.fieldCategories)) : [];
            const projectCustomFieldsWithTempId = project.projectCustomFields ? JSON.parse(JSON.stringify(project.projectCustomFields)) : [];

            if (this.props.projectToDuplicate) {
                const { isRecommandation, duplicateElements } = this.state;

                const fieldCategoriesWithFieldsToCreate = fieldCategoriesWithTempId.filter(fc => typeof fc.id === 'string');
                fieldCategoriesWithFieldsToCreate.forEach(fc => {
                    fc.projectCustomFields = projectCustomFieldsWithTempId.filter(pcf => pcf.fieldCategoryId === fc.id).map(pcf => {
                        delete pcf.fieldCategoryId;
                        return pcf;
                    });
                    delete fc.id;
                });
                project.fieldCategories = (project.fieldCategories || []).filter(fc => typeof fc.id !== 'string');
                project.projectCustomFields = (project.projectCustomFields || []).filter(pcf => typeof pcf.fieldCategoryId !== 'string');

                ProjectsService.duplicateProject({ ...project, theme: null }, fieldCategoriesWithFieldsToCreate, duplicateElements, isRecommandation);
                this.setState({ isCreating: false, showDuplicationPopup: true });
            } else {
                // On ajoute le projet à la liste de projets et dans la DB
                project.fieldCategories = [];
                project.projectCustomFields = null;

                ProjectsService.addProject({ ...project, theme: null }).then(({ project, addedAlertingBaseProject }) => {
                    if (project) {
                        project.fieldCategories = fieldCategoriesWithTempId ? JSON.parse(JSON.stringify(fieldCategoriesWithTempId)) : null;
                        if (project.fieldCategories) project.fieldCategories.forEach(fieldCategory => delete fieldCategory.id);
                        project.projectCustomFields = projectCustomFieldsWithTempId ? JSON.parse(JSON.stringify(projectCustomFieldsWithTempId)) : null;
                        if (project.projectCustomFields) project.projectCustomFields.forEach(pcf => pcf.projectId = project.id);
                        ProjectsService.updateFieldCategories(project).then(fieldCategories => {
                            if (fieldCategories) {
                                project.fieldCategories = fieldCategories;
                                if (projectCustomFieldsWithTempId?.length)
                                    projectCustomFieldsWithTempId.forEach(projectCustomField => {
                                        if (projectCustomField.fieldCategoryId && isNaN(projectCustomField.fieldCategoryId)) {
                                            const fieldCategoryWithTempId = fieldCategoriesWithTempId.find(fcwti => fcwti.id === projectCustomField.fieldCategoryId);
                                            if (fieldCategoryWithTempId) {
                                                const id = fieldCategories.find(fc => fc.label === fieldCategoryWithTempId.label && fc.color === fieldCategoryWithTempId.color && fc.category === fieldCategoryWithTempId.category)?.id;
                                                if (id) projectCustomField.fieldCategoryId = id;
                                            }
                                        }
                                    });
                                project.projectCustomFields = projectCustomFieldsWithTempId;

                                if (project.orderConfig) {
                                    project.orderConfig = JSON.parse(project.orderConfig);
                                    Object.keys(project.orderConfig).forEach(key => {
                                        for (let i = 0; i < project.orderConfig[key].length; i++) {
                                            const oldId = project.orderConfig[key][i];
                                            const id = fieldCategories.find(fc => fc.label === fieldCategoriesWithTempId.find(fcwti => fcwti.id === oldId)?.label)?.id;
                                            if (id) project.orderConfig[key][i] = String(id);
                                        }
                                    });
                                    project.orderConfig = JSON.stringify(project.orderConfig);
                                }
                            }

                            if (project.projectCustomFields?.length) project.projectCustomFields = project.projectCustomFields.map(pcf => ({ ...pcf, projectId: project.id }));
                            const updateNewProject = () => {
                                ProjectsUtil.assignProjectFormulaVersionsType(project, this.props.formulas);
                                this.props.setNewProject({ baseProject: project, addedAlertingBaseProject });
                                this.props.setEditedProperties(null).then(() => this.props.unlockEditedProperties());
                                this.setState({ isCreating: false });
                                this.props.changeModalContentType('ProjectList', i18n.t("Mes projets"));
                            }

                            if (fieldCategoriesWithTempId?.length || projectCustomFieldsWithTempId)
                                ProjectsService.updateProject(project, null, null).then(() => updateNewProject());
                            else updateNewProject();
                        });
                    }
                });
            }
        } else {
            this.setState({
                error: {
                    ...errors,
                    hidden: false,
                    messages: messages
                }
            });
        }
    }

    // TODO Gérer l'OrderConfig
    handleSubmitModification = async () => {
        let flag = true, labelError = false, surroundingsError = false, messagesError = [];
        if (!this.state.project.label?.trim()) {
            messagesError = [...(messagesError || []), i18n.t("Le nom de projet ne peut être vide")];
            labelError = true;
            flag = false;
        }

        if (!this.state.project.surroundings) {
            messagesError = [...(messagesError || []), i18n.t("Le projet doit posséder une zone géographique")];
            surroundingsError = true;
            flag = false;
        }

        if (flag) {
            this.setState({ isLoading: true });
            let project = JSON.parse(JSON.stringify(this.state.project));
            if (project.projectCustomFields?.length)
                project.projectCustomFields.forEach(projectCustomField => {
                    projectCustomField.customFieldId = parseInt(projectCustomField.customFieldId);
                    projectCustomField.projectId = project.id;
                });
            const initialProjectGeometry = JSON.parse(this.state.initialProject.surroundings).geometry;
            const projectGeometry = JSON.parse(this.state.project.surroundings).geometry;
            const removedArea = this.state.project.surroundings ? difference(featureCollection([ // Il faut que le nouveau polygone recouvre entièrement l'ancien
                initialProjectGeometry.type === 'MultiPolygon' ? multiPolygon(initialProjectGeometry.coordinates) : polygon(initialProjectGeometry.coordinates),
                projectGeometry.type === 'MultiPolygon' ? multiPolygon(projectGeometry.coordinates) : polygon(projectGeometry.coordinates)
            ])) : null;
            const fieldCategoriesWithTempId = JSON.parse(JSON.stringify(project.fieldCategories));
            if (project.fieldCategories)
                project.fieldCategories.forEach(fieldCategory => {
                    if (isNaN(fieldCategory.id)) delete fieldCategory.id;
                });

            if (JSON.stringify(this.state.initialProject.projectFormulaVersion) !== JSON.stringify(project.projectFormulaVersions))
                ProjectsUtil.assignProjectFormulaVersionsType(project, this.props.formulas);

            ProjectsService.updateFieldCategories(project).then(async fieldCategories => {
                if (fieldCategories) {
                    project.fieldCategories = fieldCategories.length ? fieldCategories : null;
                    if (project.projectCustomFields)
                        project.projectCustomFields.forEach(projectCustomField => {
                            if (projectCustomField.fieldCategoryId && isNaN(projectCustomField.fieldCategoryId)) {
                                const id = fieldCategories.find(fc => fc.label === fieldCategoriesWithTempId.find(fcwti => fcwti.id === projectCustomField.fieldCategoryId)?.label)?.id;
                                projectCustomField.fieldCategoryId = id;
                            }
                        });

                    if (project.orderConfig) {
                        project.orderConfig = JSON.parse(project.orderConfig);
                        Object.keys(project.orderConfig).forEach(key => {
                            for (let i = 0; i < project.orderConfig[key].length; i++) {
                                const oldId = project.orderConfig[key][i];
                                const id = fieldCategories.find(fc => fc.label === fieldCategoriesWithTempId.find(fcwti => fcwti.id === oldId)?.label)?.id;
                                if (id) project.orderConfig[key][i] = String(id);
                            }
                        });
                        project.orderConfig = JSON.stringify(project.orderConfig);
                    }

                    const projects = JSON.parse(JSON.stringify(this.props.projects));
                    const customProps = {
                        data: { project, removedArea, ownerId: this.state.ownerId }
                    };
                    this.props.setRequest(TasksUtil.createRequest({
                        functionName: 'updateProject', customProps,
                        processMessageId: i18n.t("Modification du projet en cours..."),
                        successMessageId: i18n.t("Le projet a été modifié"),
                        errorMessageId: i18n.t("La modification du projet a échoué")
                    }));

                    if (project.projectCustomFields)
                        project.projectCustomFields.forEach(projectCustomField => {
                            if (!projectCustomField.projectId) projectCustomField.projectId = project.id;
                        });

                    if (projects) {
                        ProjectsUtil.assignProjectFormulaVersionsType(project, this.props.formulas);
                        const index = projects.findIndex(bp => bp.id === project.id);
                        if (index !== -1) projects[index] = project;

                        ProjectsUtil.updateProjectsInProps(project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
                        if (this.props.project && project.id === this.props.project.id && removedArea)
                            this.props.removeOutsideElements(removedArea);
                    } else if (this.props.project?.id === project.id) this.props.setProject(project);

                    this.props.setEditedProperties(null).then(() => this.props.unlockEditedProperties());
                    this.setState({ isLoading: false, initialProject: project, project, error: initialError });

                    const userId = jwtDecode(new Cookies().get('token')).id;
                    let usersToAlert = this.props.userProjects.filter(up => up.user.id !== userId).map(up => up.user.id);
                    usersToAlert.push(project.id.toString());
                    WebSocketUtil.updateProject(this.props.webSocketHubs, usersToAlert, project);
                }
            });
        } else {
            this.setState({ // On affiche les messages d'erreurs correspondants
                error: { hidden: false, messages: messagesError, label: labelError, surroundings: surroundingsError }
            });
        }
    }

    updateForm = (state) => this.setState({ ...JSON.parse(state), isLoading: false });
}

const mapStateToProps = (state) => {
    return {
        editedProperties: state.editedProperties,
        layer: state.layer,
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        projections: state.projections,
        themes: state.themes,
        webSocketHubs: state.webSocketHubs,
        projectListState: state.projectListState,
        userProjects: state.userProjects,
        isOnline: state.isOnline,
        loginAsData: state.loginAsData,
        exitFormWithChanges: state.exitFormWithChanges,
        rights: state.rights,
        activeOrganization: state.activeOrganization
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProject,
    setProjects,
    setProjectListState,
    setUserProjects,
    setRequest,
    setExitFormWithChanges,
    setNewProject
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);